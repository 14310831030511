import { BriefCategory } from "api/enums";
import { AssetDetails } from "DAM/AssetDetails";
import { AssetViewType } from "DAM/components/AssetListItem";
import { lazy } from "react";
import { compose, Route } from "react-router-hoc";
import { SortFields, SortOrder } from "utils/order";
import { idRegEx } from "utils/regex";
import { Roles } from "utils/roles";
import { ProtectedRoute } from "utils/route";
import { Services } from "utils/services";
import { AssetRatingType } from "utils/types";

const params = {
  id: Route.params.regex(idRegEx),
  view: Route.query.oneOf(...Object.values(AssetViewType)),
  brands: Route.query.array(Route.query.string),
  projects: Route.query.array(Route.query.string),
  organizations: Route.query.array(Route.query.string),
  tags: Route.query.array(Route.query.string),
  rating: Route.query.array(
    Route.query.oneOf(...Object.values(AssetRatingType))
  ),
  search: Route.query.string,
  sort: Route.query.oneOf(...Object.values(SortFields)),
  sortOrder: Route.query.oneOf(...Object.values(SortOrder)),
  assetTypes: Route.query.array(Route.query.oneOf(...Object.values(Services))),
  categories: Route.query.array(
    Route.query.oneOf(...Object.values(BriefCategory))
  ),
  createdAt: Route.query.array(Route.query.string),
  page: Route.query.number,
};

export const CollectionDetailsRoute = compose(
  ProtectedRoute({
    access: [Roles.cs_admins, Roles.customer],
  }),
  Route(params, ({ id }) => `/dam/collections/${id}`)
);

export const CollectionDetails = CollectionDetailsRoute(
  lazy(
    () =>
      import(/* webpackChunkName: "CollectionDetails" */ "./CollectionDetails")
  )
);

export const CollectionDetailsAssetRoute = compose(
  ProtectedRoute({
    access: [Roles.cs_admins, Roles.customer],
  }),
  Route(
    {
      ...params,
      assetId: Route.params.regex(idRegEx),
      versionId: Route.query.regex(idRegEx),
    },
    ({ id, assetId }) => `/dam/collections/${id}/assets/${assetId}`
  )
);

export const CollectionDetailsAsset = CollectionDetailsAssetRoute(AssetDetails);
