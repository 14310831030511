import { Button, Dropdown, Input } from "@CreativelySquared/uikit";
import { OrganizationAccountNodeFragment } from "api/types";
import { ErrorMessage } from "components";
import { useFormik } from "formik";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  advancedErrorMessage,
  formatNumberValue,
  parseError,
} from "utils/form";
import { number, object, string } from "yup";
import clsx from "clsx";
import { MAX_CREDIT_BALANCE } from "utils/credits";

import styles from "./styles.module.scss";

export interface AllocateFormData {
  toAccountId?: string | null;
  fromAccountId?: string | null;
  amount?: string;
}

export type AllocateCreditsProps = {
  onClose: () => void;
  accounts: OrganizationAccountNodeFragment[];
  isTransfer?: boolean;
  onSubmit: (data: AllocateFormData) => Promise<void>;
  organizationCreditBalance?: number | null;
};

export const AllocateCreditsForm: FC<AllocateCreditsProps> = ({
  accounts,
  onClose,
  isTransfer = false,
  onSubmit,
  organizationCreditBalance,
}) => {
  const { t } = useTranslation("organizations");

  const validationSchema = useMemo(() => {
    return object({
      fromAccountId: string()
        .nullable()
        .when([], {
          is: () => isTransfer,
          then: string().nullable().required("common:validation.required"),
          otherwise: string().nullable().notRequired(),
        }),
      toAccountId: string()
        .nullable()
        .required("common:validation.required")
        .test(
          "different-from-fromAccountId",
          "details.accounts.transferCredits.to.sameAccount",
          function (value) {
            return value !== this.parent.fromAccountId;
          }
        ),
      amount: number()
        .required("common:validation.required")
        .min(
          1,
          advancedErrorMessage(
            "details.accounts.transferCredits.amount.error.min"
          )
        )
        .test("max", (value, context) => {
          if (isTransfer) {
            const fromAccount = accounts.find(
              (account) =>
                account.organizationId === context.parent.fromAccountId
            );
            const fromAccountCredits =
              fromAccount?.subscriptionPlan?.creditsLimit;

            if (
              fromAccountCredits !== undefined &&
              fromAccountCredits !== null
            ) {
              return (
                value! <= fromAccountCredits ||
                context.createError({
                  message: t(
                    "details.accounts.transferCredits.amount.error.insufficientFromCreditsLimit",
                    { max: fromAccountCredits }
                  ),
                })
              );
            }
          } else {
            const maxBalance =
              organizationCreditBalance !== null &&
              organizationCreditBalance !== undefined
                ? organizationCreditBalance
                : MAX_CREDIT_BALANCE;

            return (
              value! <= maxBalance ||
              context.createError({
                message: t(
                  "details.accounts.transferCredits.amount.error.insufficientOrganizationBalance",
                  { max: maxBalance }
                ),
              })
            );
          }

          return true;
        }),
    });
  }, [isTransfer, organizationCreditBalance, accounts]);

  const {
    values: { fromAccountId, toAccountId, amount },
    errors,
    touched,
    submitCount,
    isValid,
    handleBlur,
    setFieldValue,
    handleSubmit,
    isSubmitting,
  } = useFormik<AllocateFormData>({
    validationSchema,
    initialValues: {
      toAccountId: null,
      fromAccountId: null,
      amount: "",
    },
    onSubmit,
  });

  const activeAccountCreditLimitTotal = useMemo(() => {
    const account = accounts.find(
      (account) => account.organizationId === toAccountId
    );
    const creditsLimit = account?.subscriptionPlan?.creditsLimit;

    if (creditsLimit === undefined || creditsLimit === null) {
      return null;
    }

    return creditsLimit + Number(amount || 0);
  }, [toAccountId, accounts, amount]);

  return (
    <form onSubmit={handleSubmit} noValidate className={styles.container}>
      {isTransfer && (
        <fieldset className={styles.fieldset}>
          <label className={styles.label}>
            {t("details.accounts.transferCredits.from.label")}
          </label>
          <Dropdown
            popoverClassName={styles.popover}
            value={fromAccountId}
            error={!!submitCount && !!errors.fromAccountId}
            className="w-full"
            placement={Dropdown.placements.BottomEnd}
            shifting={false}
            variant={Button.variants.Soft}
            strategy="fixed"
            onSelect={(value) => setFieldValue("fromAccountId", value)}
          >
            {accounts.map((account) => (
              <Dropdown.Item
                key={account.organizationId}
                value={account.organizationId}
              >
                {account.name}
              </Dropdown.Item>
            ))}
          </Dropdown>
          {!!submitCount && errors.fromAccountId && (
            <ErrorMessage
              message={t(...parseError(errors.fromAccountId)).toString()}
              className={"mt-4"}
            />
          )}
        </fieldset>
      )}
      <fieldset className={styles.fieldset}>
        <label className={styles.label}>
          {t("details.accounts.transferCredits.to.label")}
        </label>
        <Dropdown
          popoverClassName={styles.popover}
          value={toAccountId}
          error={!!submitCount && !!errors.toAccountId}
          className="w-full"
          placement={Dropdown.placements.BottomEnd}
          shifting={false}
          variant={Button.variants.Soft}
          strategy="fixed"
          onSelect={(value) => setFieldValue("toAccountId", value)}
        >
          {accounts.map((account) => (
            <Dropdown.Item
              key={account.organizationId}
              value={account.organizationId}
            >
              {account.name}
            </Dropdown.Item>
          ))}
        </Dropdown>
        {!!submitCount && errors.toAccountId && (
          <ErrorMessage
            message={t(...parseError(errors.toAccountId)).toString()}
            className={"mt-4"}
          />
        )}
      </fieldset>
      <fieldset className={styles.fieldset}>
        <div
          className={clsx(styles.label, "flex items-center justify-between")}
        >
          <label>{t("details.accounts.transferCredits.amount.label")}</label>
          {activeAccountCreditLimitTotal !== null && (
            <div className="text-sm font-medium text-blue-steel">
              {t("details.accounts.transferCredits.amount.total", {
                total: activeAccountCreditLimitTotal,
              })}
            </div>
          )}
        </div>
        <Input
          variant={Input.variants.Secondary}
          placeholder={t("details.accounts.transferCredits.amount.placeholder")}
          value={`${amount}`}
          name="amount"
          error={!!touched.amount && errors.amount}
          onChange={({ target: { value } = {} }) =>
            setFieldValue(
              "amount",
              formatNumberValue(value, { allowZero: true })
            )
          }
          onBlur={handleBlur}
          className="w-full"
        />
        {!!touched.amount && errors.amount && (
          <ErrorMessage
            message={t(...parseError(errors.amount)).toString()}
            className={"mt-4"}
          />
        )}
      </fieldset>

      <section className="flex justify-end mt-8">
        <Button
          variant={Button.variants.Cancel}
          type="button"
          className="mr-5"
          onClick={onClose}
          outlined
        >
          {t("common:actions.cancel")}
        </Button>
        <Button
          type="submit"
          loading={isSubmitting}
          disabled={(!!submitCount && !isValid) || isSubmitting}
        >
          {t("common:actions.submit")}
        </Button>
      </section>
    </form>
  );
};
