import { GetRouteProps } from "utils/types";
import { FC, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { SortFields, SortOrder } from "utils/order";
import { Role } from "api/enums";
import {
  MemberRoles,
  Members,
  useMembersColumns,
  useMembersFilter,
} from "Customers/components/Members";
import { getFullName } from "utils/users";
import { useGetOrganizationUsersQuery } from "api/graphql";
import { usePagination } from "utils/hooks/pagination";
import { useDebounceValue } from "utils/hooks/useDebounceValue";
import { useSendActivationEmailMutation } from "api/graphql";
import { useNotification } from "components";

import { NewMemberModal } from "./NewMemberModal";
import { DeleteMemberModal } from "./DeleteMemberModal";
import { EditMemberModal } from "./EditMemberModal";

import { OrganizationMembersTabRoute } from "./index";

const ITEMS_PER_PAGE = 24;

interface Props {
  match: {
    query: {
      search?: string;
      page?: number;
      sort?: string;
      sortOrder?: SortOrder;
      roles?: Array<typeof MemberRoles[number] | undefined>;
      accountIds?: Array<string | undefined>;
    };
    params: Record<string, string>;
  };
  organizationId: string;
  link: (params: any) => string;
  history: {
    push: (path: string) => void;
  };
}

export const OrganizationMembers: FC<Props> = ({
  match: {
    query: {
      search,
      page = 1,
      sort = SortFields.createdAt,
      sortOrder = SortOrder.DESC,
      roles = [],
      accountIds = [],
    },
    params,
    query,
  },
  organizationId,
  link,
  history: { push },
}) => {
  const { t } = useTranslation("organizations");
  const [deletingUserData, setDeletingUserData] = useState<{
    userId: string;
    name: string;
  } | null>(null);
  const [editingUserData, setEditingUserData] = useState<{
    userId: string;
    name: string;
    surname: string;
    email: string;
    roles: Record<string, string[]>;
  } | null>(null);
  const [newMemberVisible, setNewMemberVisible] = useState(false);

  const pagination = usePagination({
    page,
    limit: ITEMS_PER_PAGE,
    sort,
    sortOrder,
  });

  const debouncedSearch = useDebounceValue(search);

  const {
    data: { users } = {},
    loading: usersLoading,
    refetch,
  } = useGetOrganizationUsersQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      masterOrganizationId: organizationId,
      pagination,
      role: roles.length ? (roles as Role[]) : MemberRoles,
      organizationIds: accountIds.length ? (accountIds as string[]) : undefined,
      search: debouncedSearch,
    },
  });

  const { setNotification, notificationTypes } = useNotification();

  const [sendActivationEmail] = useSendActivationEmailMutation({
    onCompleted: () => {
      setNotification({
        message: t("common:user.invitation.success"),
        type: notificationTypes.Success,
      });
    },
    onError: (error) => {
      setNotification({
        message: error.message ?? t("common:user.invitation.error"),
        type: notificationTypes.Error,
      });
    },
  });

  const closeDeleteModal = useCallback(() => {
    setDeletingUserData(null);
  }, []);

  const closeEditModal = useCallback(() => {
    setEditingUserData(null);
  }, []);

  const columns = useMembersColumns({
    onDelete: (cell) => {
      const { userId, name, surname, email } = cell.row.original;

      setDeletingUserData({
        userId: userId || "",
        name: name || surname ? getFullName({ name, surname }) : email || "",
      });
    },
    onEdit: (cell) => {
      const {
        userId,
        email,
        surname,
        name,
        role,
        accounts,
        subRows,
        hasActions,
      } = cell.row.original;

      if (!hasActions) {
        return;
      }

      const mapRoles = (
        role: string,
        accounts: Array<{ organizationId: string }>
      ) => ({
        [role]: accounts.map((account) => account.organizationId),
      });

      setEditingUserData({
        userId: userId || "",
        name: name || "",
        surname: surname || "",
        email: email || "",
        roles: {
          ...mapRoles(role, accounts),
          ...subRows?.reduce((acc, user) => {
            return {
              ...acc,
              ...mapRoles(user.role, user.accounts),
            };
          }, {}),
        },
      });
    },
    onResend: (cell) =>
      sendActivationEmail({
        variables: {
          userId: cell.row.original.userId || "",
          masterOrganizationId: organizationId,
        },
      }),
  });

  const {
    filterData,
    filterOptions,
    loading: filtersLoading,
  } = useMembersFilter({
    roles: roles as Role[],
    accountIds: accountIds as string[],
    organizationId,
  });

  const sortOptions = useMemo(
    () => [
      {
        label: t("common:sort.options.title.asc"),
        to: link({
          ...query,
          ...params,
          page: 1,
          sortOrder: SortOrder.ASC,
          sort: SortFields.fullName,
        }),
      },
      {
        label: t("common:sort.options.title.desc"),
        to: link({
          ...query,
          ...params,
          page: 1,
          sortOrder: SortOrder.DESC,
          sort: SortFields.fullName,
        }),
      },
    ],
    [query, params]
  );

  const toggleNewMemberModal = useCallback(() => {
    setNewMemberVisible((prev) => !prev);
  }, []);

  const onFilterChange = useCallback(
    (data) =>
      push(
        link({
          ...params,
          ...query,
          ...data,
          page: 1,
        })
      ),
    [params, query]
  );

  const onSearch = useCallback(
    (value: string) =>
      push(
        link({
          ...params,
          ...query,
          page: 1,
          search: value ? value : undefined,
        })
      ),
    [push, query]
  );

  return (
    <>
      <Members
        title={t("members.title")}
        toggleNewMemberModal={toggleNewMemberModal}
        page={page}
        search={search}
        sortValue={link({
          ...params,
          ...query,
          page: 1,
        })}
        sortOptions={sortOptions}
        filterData={filterData}
        filterOptions={filterOptions}
        onFilterChange={onFilterChange}
        loading={(usersLoading && !users?.nodes?.length) || filtersLoading}
        onSearch={onSearch}
        columns={columns}
        users={
          users?.nodes?.map((user) => ({
            ...user,
            roles: user?.masterOrganizationRoles || [],
          })) || []
        }
        totalPages={users?.pageInfo?.totalPages}
      />
      <NewMemberModal
        visible={newMemberVisible}
        onClose={toggleNewMemberModal}
        organizationId={organizationId}
        onComplete={refetch}
      />
      {deletingUserData && (
        <DeleteMemberModal
          visible={!!deletingUserData}
          onClose={closeDeleteModal}
          onSubmit={refetch}
          organizationId={organizationId}
          userId={deletingUserData.userId}
          name={deletingUserData.name}
        />
      )}
      {editingUserData && (
        <EditMemberModal
          visible={!!editingUserData}
          onClose={closeEditModal}
          organizationId={organizationId}
          userData={editingUserData}
        />
      )}
    </>
  );
};

const OrganizationMembersTab: FC<
  GetRouteProps<typeof OrganizationMembersTabRoute>
> = (props) => {
  const { t } = useTranslation("organizations");

  return (
    <>
      <Helmet>
        <title>{t("members.tab")}</title>
      </Helmet>
      <OrganizationMembers organizationId={props.match.params.id} {...props} />
    </>
  );
};

export default OrganizationMembersTab;
