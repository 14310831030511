import { HttpLink, ApolloClient, from, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { history, links } from "App";
import { ACCESS_TOKEN_KEY } from "Authorization/authorization.hooks";
import { isGQLError, AuthErrorCodes } from "utils/auth";
import { SubscriptionErrorCodes } from "utils/subscriptions";
import { getOperationName } from "@apollo/client/utilities";

import generatedIntrospection from "./introspection.json";
import { ProfileDocument } from "./graphql";

const uri = `${process.env.REACT_APP_GRAPHQL_API_URL}/graphql`;
const serviceUnavailableCode = 503;

export const httpLink = new HttpLink({
  uri,
});

const authLink = setContext((_, { headers }) => {
  const token =
    sessionStorage.getItem(ACCESS_TOKEN_KEY) ||
    localStorage.getItem(ACCESS_TOKEN_KEY);

  if (token) {
    return {
      headers: {
        authorization: `Bearer ${token}`,
        ...headers,
      },
    };
  }
  return {
    headers,
  };
});

const errorLink = onError(({ networkError, operation, response }) => {
  const isProfileQuery =
    operation.operationName === getOperationName(ProfileDocument);
  const isAuthorizationError = response?.errors?.some(
    (error) => error.extensions.code === AuthErrorCodes.AuthorizationError
  );

  if (isProfileQuery && isAuthorizationError) {
    return history.replace(links.Logout());
  }

  if (isGQLError(networkError)) {
    const isAuthError = Object.values(AuthErrorCodes).includes(
      networkError.result?.code as AuthErrorCodes
    );
    if (isAuthError) {
      return history.replace(links.Logout());
    }

    if (networkError.statusCode === serviceUnavailableCode) {
      return history.replace(links.Maintenance());
    }

    if (
      networkError.statusCode === 403 &&
      networkError.result?.code ===
        SubscriptionErrorCodes.SubscriptionPlanNotActiveError
    ) {
      return history.replace(
        links.Restricted({
          type: networkError.result.details.status,
        })
      );
    }
  }
});

export const client = new ApolloClient({
  name: "web",
  cache: new InMemoryCache({
    possibleTypes: generatedIntrospection.possibleTypes,
    typePolicies: {
      Query: {
        fields: {
          getSharedAsset(_, { args, toReference }) {
            return toReference({
              __typename: "Asset",
              assetId: args?.id,
            });
          },
        },
      },
      AssetComment: {
        keyFields: ["assetCommentId"],
      },
      Project: {
        keyFields: ["projectId"],
      },
      BrandProfile: {
        keyFields: ["brandProfileId"],
      },
      Brief: {
        keyFields: ["briefId"],
        fields: {
          concepts: {
            merge(_, incoming) {
              return incoming;
            },
          },
        },
      },
      Concept: {
        keyFields: ["conceptId"],
        fields: {
          featuredProducts: {
            merge(_, incoming) {
              return incoming;
            },
          },
          outcomes: {
            merge(_, incoming) {
              return incoming;
            },
          },
          moodboard: {
            merge(_, incoming) {
              return incoming;
            },
          },
        },
      },
      User: {
        keyFields: ["userId"],
      },
      Organization: {
        keyFields: ["organizationId"],
      },
      OrganizationInternalNote: {
        keyFields: ["organizationInternalNoteId"],
      },
      ProjectCoverImage: {
        keyFields: ["projectCoverImageId"],
      },
      ProjectInternalNote: {
        keyFields: ["projectInternalNoteId"],
      },
      Asset: {
        keyFields: ["assetId"],
      },
      Outcome: {
        keyFields: ["outcomeId"],
      },
      ProjectFeedback: {
        keyFields: ["projectFeedbackId"],
      },
      SubscriptionPlan: {
        keyFields: ["subscriptionPlanId"],
      },
      File: {
        keyFields: ["fileId"],
      },
      ConceptMoodboardItem: {
        keyFields: ["id"],
      },
      ConceptProduct: {
        keyFields: ["id"],
      },
      ConceptVisualGuide: {
        keyFields: ["id"],
      },
      AssetVersion: {
        keyFields: ["assetVersionId"],
        fields: {
          file: {
            merge(existing, incoming) {
              return Object.assign({}, existing, incoming);
            },
          },
        },
      },
      SignedUrl: {
        keyFields: ["url"],
      },
      Collection: {
        keyFields: ["collectionId"],
      },
      BrandProfileSharingLink: {
        keyFields: ["sharingLinkId"],
      },
      CollectionSharingLink: {
        keyFields: ["sharingLinkId"],
      },
      ProjectSharingLink: {
        keyFields: ["projectSharingLinkId"],
      },
      BriefComment: {
        keyFields: ["briefCommentId"],
      },
      Comment: {
        keyFields: ["commentId"],
      },
      Event: {
        keyFields: ["eventId"],
      },
      GenericBriefEvent: {
        keyFields: ["eventId"],
      },
      MasterOrganization: {
        keyFields: ["masterOrganizationId"],
      },
      Request: {
        keyFields: ["requestId"],
      },
      Me: {
        keyFields: () => "me",
      },
    },
  }),
  link: from([authLink, errorLink, httpLink]),
});
