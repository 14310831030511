import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { SectionDivider } from "components";

import { MainData } from "./MainData";
import { Plan } from "./Plan";
import { Members } from "./Members";
import { Usage } from "./Usage";
import { AccountDetailsDataRoute } from "./route";

export const AccountDetailsData = AccountDetailsDataRoute((routeProps) => {
  const { t } = useTranslation("accounts");

  const id = routeProps.match.params.id;

  return (
    <main className="mt-10">
      <Helmet title={t("details.title")} />
      <MainData accountId={id} />
      <SectionDivider />
      <Plan accountId={id} />
      <SectionDivider />
      <Usage id={id} />
      <SectionDivider />
      <Members accountId={id} {...routeProps} />
    </main>
  );
});
