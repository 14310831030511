import { Button, Dropdown, Label, Modal } from "@CreativelySquared/uikit";
import { ColumnDef } from "@tanstack/react-table";
import { OrganizationType, RequestStatuses } from "api/enums";
import {
  AccountNodeFragment,
  OrganizationAccountNodeFragment,
  SubscriptionPlanStatus,
} from "api/types";
import { Empty, PlanStatusLabel, Table } from "components";
import { FC, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { links } from "App";
import { CreateAccount } from "Customers/Accounts/Create";
import { ReactComponent as KeyIcon } from "images/key.svg";
import { ReactComponent as MoneyIcon } from "images/money.svg";
import { ReactComponent as PlusIcon } from "images/plus.svg";
import { ReactComponent as TransferIcon } from "images/loop.svg";
import clsx from "clsx";
import { getCreditLimit } from "Customers/utils/credits";
import { ReactComponent as ActionsIcon } from "images/ellipsis.svg";
import { useProtect } from "utils/hooks/protection";
import { Roles } from "utils/roles";
import { isNumber } from "lodash";

import { AllocateCreditsModal } from "./AllocateCreditsModal";
import { TransferCreditsModal } from "./TransferCreditsModal";

import styles from "./styles.module.scss";

type AccountType =
  | (OrganizationAccountNodeFragment & {
      requestStatus?: RequestStatuses | null;
    })
  | null;

interface Props {
  organizationId: string;
  accounts?: AccountType[] | null;
  onCreate?: () => void;
  clickable?: boolean;
  onRequestAccess?: (organizationId: string) => void;
}

export const Accounts: FC<Props> = ({
  organizationId,
  accounts,
  onCreate,
  clickable = true,
  onRequestAccess,
}) => {
  const { t } = useTranslation("organizations");
  const [newAccountVisible, setNewAccountVisible] = useState(false);
  const [isAllocateCreditsVisible, setAllocateCreditsVisible] = useState(false);
  const [isTransferCreditsVisible, setTransferCreditsVisible] = useState(false);

  const toggleAllocateCreditsModal = useCallback(() => {
    setAllocateCreditsVisible((prev) => !prev);
  }, []);

  const toggleTransferCreditsModal = useCallback(() => {
    setTransferCreditsVisible((prev) => !prev);
  }, []);

  // TODO: replace with permissions after implementing on BE
  const hasActions = useProtect()([Roles.cs_admins, Roles.master_org_owner]);

  const hasRequestAccessColumn = useMemo(
    () =>
      accounts?.some(
        (account) => account?.requestStatus !== RequestStatuses.Approved
      ),
    [accounts]
  );

  const integratedAccounts = useMemo(
    () =>
      accounts?.filter(
        (account) => account?.type === OrganizationType.Integrated
      ) || [],
    [accounts]
  );

  const columns = useMemo<ColumnDef<AccountType>[]>(() => {
    const cols: ColumnDef<AccountType>[] = [
      {
        header: t("details.accounts.name"),
        accessorKey: "name",
        cell: (cell) => {
          const name = cell.getValue() as string;

          const NameElement = (
            <div className="truncate" title={name}>
              {name}
            </div>
          );

          return clickable ? (
            <Link
              to={links.AccountDetailsData({
                id: cell.row.original?.organizationId || "",
              })}
              className="hover:underline hover:font-medium block -m-7 p-7"
            >
              {NameElement}
            </Link>
          ) : (
            NameElement
          );
        },
        size: 300,
        maxSize: 300,
      },
      {
        header: t("details.accounts.type"),
        accessorKey: "type",
        size: 150,
        cell: (cell) => {
          const type = cell.getValue() as OrganizationType;

          return <p>{t(`common:customers.types.${type}`)}</p>;
        },
      },
      {
        header: t("details.accounts.members"),
        accessorKey: "membersCount",
        size: 135,
      },
      {
        header: t("details.accounts.plan"),
        id: "plan",
        accessorKey: "subscriptionPlan",
        size: 135,
        cell: (cell) => {
          const plan =
            cell.getValue() as NonNullable<AccountType>["subscriptionPlan"];

          return (
            <Label variant={Label.variants.Teal}>
              {t(`common:customers.plans.${plan?.name}`)}
            </Label>
          );
        },
      },
      {
        header: t("details.accounts.status"),
        accessorKey: "subscriptionPlan.status",
        size: 135,
        cell: (cell) => {
          const status = cell.getValue() as SubscriptionPlanStatus;

          return (
            <PlanStatusLabel status={status}>
              {t(`common:customers.status.${status}`)}
            </PlanStatusLabel>
          );
        },
      },
      {
        header: t("details.accounts.credits"),
        id: "credits",
        accessorKey: "subscriptionPlan",
        size: 135,
        cell: (cell) => {
          const account = cell.row.original as AccountNodeFragment;
          const limit = getCreditLimit(account);

          return (
            isNumber(limit) && (
              <Label variant={Label.variants.Teal}>{limit}</Label>
            )
          );
        },
      },
    ];

    if (onRequestAccess && hasRequestAccessColumn) {
      cols.push({
        header: "",
        id: "requestAccess",
        cell: (cell) => {
          const organizationId = cell.row.original?.organizationId;

          if (
            !organizationId ||
            cell.row.original?.requestStatus === RequestStatuses.Approved
          )
            return null;

          if (cell.row.original?.requestStatus === RequestStatuses.Pending) {
            return (
              <Label
                variant={Label.variants.Teal}
                className="whitespace-nowrap ml-5"
              >
                {t("details.accounts.requestAccess.pending")}
              </Label>
            );
          }

          return (
            <Button
              onClick={() => onRequestAccess?.(organizationId)}
              variant={Button.variants.Soft}
              size={Button.sizes.Small}
              borderless
              className={clsx("text-teal -my-5", styles.button)}
            >
              <KeyIcon className="mr-4" />
              {t("details.accounts.requestAccess.action")}
            </Button>
          );
        },
      });
    }

    return cols;
  }, [hasRequestAccessColumn]);

  const toggleNewAccountModal = useCallback(() => {
    setNewAccountVisible((prev) => !prev);
  }, []);

  return (
    <section>
      <div className="flex justify-between items-center mb-8">
        <h2 className="text-2xl">{t("details.accounts.title")}</h2>

        {(onCreate || !!integratedAccounts.length) && hasActions && (
          <Dropdown
            render={() => (
              <Button variant={Button.variants.Icon} type="button">
                <ActionsIcon className="w-7 h-7" />
              </Button>
            )}
            placement={Dropdown.placements.BottomEnd}
          >
            {onCreate && (
              <Dropdown.Item
                onClick={toggleNewAccountModal}
                className="flex items-center"
              >
                <PlusIcon className="w-7 h-7 mr-3" />{" "}
                {t("details.accounts.add")}
              </Dropdown.Item>
            )}
            {!!integratedAccounts.length && (
              <Dropdown.Item
                className="flex items-center"
                onClick={toggleAllocateCreditsModal}
              >
                <MoneyIcon className="w-7 h-7 mr-3" />
                {t("details.accounts.transferCredits.assignFromBalance")}
              </Dropdown.Item>
            )}
            {integratedAccounts.length >= 2 && (
              <Dropdown.Item
                className="flex items-center"
                onClick={toggleTransferCreditsModal}
              >
                <TransferIcon className="w-7 h-7 mr-3" />
                {t("details.accounts.transferCredits.transferBetweenAccounts")}
              </Dropdown.Item>
            )}
          </Dropdown>
        )}
      </div>
      <Table data={accounts || []} columns={columns} />
      {!accounts?.length && (
        <div className="flex justify-center flex-col items-center">
          <Empty title={t("details.accounts.empty")} />
          {onCreate && (
            <Button onClick={toggleNewAccountModal}>
              {t("details.accounts.add")}
            </Button>
          )}
        </div>
      )}
      <Modal visible={newAccountVisible} onClose={toggleNewAccountModal}>
        <CreateAccount
          onClose={toggleNewAccountModal}
          onCreate={onCreate}
          organizationId={organizationId}
        />
      </Modal>
      <AllocateCreditsModal
        accounts={
          (integratedAccounts as OrganizationAccountNodeFragment[]) || []
        }
        visible={isAllocateCreditsVisible}
        onClose={toggleAllocateCreditsModal}
        organizationId={organizationId}
      />
      <TransferCreditsModal
        accounts={
          (integratedAccounts as OrganizationAccountNodeFragment[]) || []
        }
        visible={isTransferCreditsVisible}
        onClose={toggleTransferCreditsModal}
      />
    </section>
  );
};
