import { Organization } from "api/types";
import { groupBy } from "lodash";
import { useMemo } from "react";
import { Role } from "api/enums";
import { CellContext, ColumnDef } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { Label } from "@CreativelySquared/uikit";
import { Filter } from "components";
import { useGetOrganizationAccountsQuery, useProfileQuery } from "api/graphql";
import { getFullName } from "utils/users";

import { ActionsCell } from "./ActionsCell";
import { AccountType, UserData, UserType } from "./types";

export const useMembersData = (users: Array<UserData>) => {
  const tableData = useMemo(() => {
    if (!users) return [];

    const userRows = users.reduce<UserType[]>((acc, user) => {
      const userRoles = user?.roles;

      if (!user || !userRoles) return acc;

      const groupedRoles = groupBy(userRoles, "role");

      return [
        ...acc,
        ...Object.entries(groupedRoles).map(([role, data]) => ({
          name: user.name || "",
          surname: user.surname || "",
          email: user.email || "",
          role,
          userId: user.userId || "",
          accounts: data.map((userRole) => ({
            name: userRole?.organization?.name || "",
            organizationId: userRole?.organization?.organizationId || "",
          })),
          isEmailConfirmed: !!user.isEmailConfirmed,
        })),
      ];
    }, []);

    const groupedUsers = groupBy(userRows, "email");

    return Object.values(groupedUsers).map((data) => {
      return {
        ...data?.[0],
        hasActions: true,
        subRows: data.slice(1).map((user) => ({
          ...user,
          name: "",
          surname: "",
          email: "",
          isEmailConfirmed: undefined,
        })),
      };
    });
  }, [users]);

  return tableData;
};

enum ColumnIds {
  name = "name",
  role = "role",
  accounts = "accounts",
  actions = "actions",
}

export const useMembersColumns = ({
  onDelete,
  onEdit,
  onResend,
  visibleColumns,
}: {
  onEdit: (cell: CellContext<UserType, unknown>) => void;
  onDelete: (cell: CellContext<UserType, unknown>) => void;
  onResend?: (cell: CellContext<UserType, unknown>) => Promise<any>;
  visibleColumns?: { [key in ColumnIds]?: boolean };
}) => {
  const { t } = useTranslation("organizations");
  const { data: { me } = {} } = useProfileQuery();

  const columns = useMemo<ColumnDef<UserType>[]>(
    () => [
      {
        header: t("members.table.name"),
        id: ColumnIds.name,
        cell: ({ cell }) => {
          const userName = getFullName(cell.row.original);
          return (
            <div>
              <div className="flex gap-5 items-center">
                {userName && <div className="truncate">{userName}</div>}
                {cell.row.original.isEmailConfirmed === false && (
                  <p className="text-sm text-red font-medium whitespace-nowrap">
                    {t("members.table.invitationPending")}
                  </p>
                )}
              </div>
              <div className="text-sm text-mid-blue-steel truncate">
                {cell.row.original?.email}
              </div>
            </div>
          );
        },
        size: 320,
      },
      {
        header: t("members.table.role"),
        id: ColumnIds.role,
        accessorKey: "role",
        cell: (cell) => {
          const role = cell.getValue() as Role;

          return (
            <div className="whitespace-nowrap">
              {t(`common:user.roles.${role.toLocaleLowerCase()}`)}
            </div>
          );
        },
        size: 140,
      },
      {
        header: t("members.table.account"),
        id: ColumnIds.accounts,
        accessorKey: "accounts",
        cell: (cell) => {
          return (
            <div className="flex flex-wrap gap-4 items-center">
              {(cell.getValue() as AccountType[]).map(
                (account) =>
                  account.name && (
                    <Label
                      key={account.organizationId}
                      variant={Label.variants.Teal}
                      className="max-w-[200px] truncate"
                      title={account.name}
                    >
                      {account.name}
                    </Label>
                  )
              )}
            </div>
          );
        },
        size: 370,
      },
      {
        header: "",
        id: ColumnIds.actions,
        accessorKey: "userId",
        cell: (cell) => {
          const { hasActions, isEmailConfirmed, role, userId } =
            cell.row.original;
          const isEditableUser =
            role !== Role.MasterOrgOwner && userId !== me?.user?.userId;

          if (!hasActions || (!isEditableUser && isEmailConfirmed)) return null;

          return (
            <ActionsCell
              cell={cell}
              onEdit={isEditableUser ? onEdit : undefined}
              onDelete={isEditableUser ? onDelete : undefined}
              onResend={!isEmailConfirmed ? onResend : undefined}
            />
          );
        },
        size: 30,
      },
    ],
    []
  );

  return visibleColumns
    ? columns.filter((column) => visibleColumns[column.id as ColumnIds])
    : columns;
};

export enum MembersFilter {
  roles = "roles",
  accountIds = "accountIds",
}

export const MemberRoles = [Role.MasterOrgOwner, Role.OrgAdmin, Role.OrgUser];

export const useMembersFilter = ({
  roles,
  accountIds,
  organizationId,
  availableFilters,
  availableFilterRoles = MemberRoles,
}: {
  roles?: Role[];
  accountIds?: Array<Organization["organizationId"]>;
  organizationId?: string;
  availableFilters?: MembersFilter[];
  availableFilterRoles?: Role[];
}) => {
  const { t } = useTranslation("organizations");

  const { data: { accounts } = {}, loading } = useGetOrganizationAccountsQuery({
    variables: {
      filters: {
        masterOrganizationId: organizationId ? [organizationId] : undefined,
      },
    },
    fetchPolicy: "cache-and-network",
    skip: !organizationId,
  });

  const filterData = useMemo(() => {
    const data = [
      {
        key: MembersFilter.roles,
        value: roles,
      },
      {
        key: MembersFilter.accountIds,
        value: accountIds,
      },
    ].flatMap(({ key, value }) =>
      value?.length ? [{ key, value: value as string[] }] : []
    );

    return availableFilters
      ? data.filter(({ key }) => availableFilters.includes(key))
      : data;
  }, [roles, accountIds, availableFilters]);

  const filterOptions = useMemo(() => {
    const filters = [
      {
        key: MembersFilter.roles,
        label: t("members.table.role"),
        value: availableFilterRoles.map((role) => ({
          id: role,
          name: t(`common:user.roles.${role.toLocaleLowerCase()}`),
        })),
        type: Filter.types.Checkbox,
      },
      {
        key: MembersFilter.accountIds,
        label: t("members.table.filter.account"),
        value: accounts?.nodes?.map((account) => ({
          id: account?.organizationId || "",
          name: account?.name || "",
        })),
        type: Filter.types.Checkbox,
      },
    ];

    return availableFilters
      ? filters.filter(({ key }) => availableFilters.includes(key))
      : filters;
  }, [accounts]);

  return {
    filterData,
    filterOptions,
    loading,
  };
};
