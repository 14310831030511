import { FC } from "react";
import { ReactComponent as EditIcon } from "images/edit.svg";
import { Button } from "@CreativelySquared/uikit";
import { useTranslation } from "react-i18next";

interface Props {
  isEdit: boolean;
  submitDisabled: boolean;
  onToggleEdit: () => void;
  onCancel: () => void;
  loading?: boolean;
}

export const EditToggler: FC<Props> = ({
  isEdit,
  onToggleEdit,
  onCancel,
  submitDisabled,
  loading,
}) => {
  const { t } = useTranslation();

  return !isEdit ? (
    <Button onClick={onToggleEdit} variant={Button.variants.Icon}>
      <EditIcon className="w-7 h-7 stroke-[1.5]" />
    </Button>
  ) : (
    <div className="flex items-center">
      <Button
        variant={Button.variants.Cancel}
        type="button"
        className="mr-5"
        onClick={onCancel}
        outlined
      >
        {t("common:actions.cancel")}
      </Button>
      <Button type="submit" disabled={submitDisabled} loading={loading}>
        {t("common:actions.confirm")}
      </Button>
    </div>
  );
};
