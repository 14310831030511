import { Alert, Button, Loader, Tabs } from "@CreativelySquared/uikit";
import {
  useProjectAssetsZipLazyQuery,
  useProjectGalleryZipLazyQuery,
  useSharedAssetQuery,
  useSharedGalleryQuery,
  useGetProjectSharingTokenQuery,
} from "api/graphql";
import { useMemo, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { compose, Route } from "react-router-hoc";
import { idRegEx } from "utils/regex";
import { Roles } from "utils/roles";
import { ProtectedRoute } from "utils/route";
import { NavLink } from "react-router-dom";
import { GalleryGrid } from "components";
import { Asset } from "Assets/components";
import { useDownload } from "utils/hooks/download";
import { useAuthData } from "Authorization/authorization.hooks";
import { links } from "App";
import {
  Asset as AssetType,
  ProjectAssetsZipQuery,
  ProjectGalleryZipQuery,
} from "api/types";
import clsx from "clsx";
import { withSuspense } from "utils/hooks/suspense";
import { downloadURI } from "utils/file";
import { ReactComponent as ArrowIcon } from "images/arrowLeft.svg";

import { Footer } from "../components";

import styles from "./styles.module.scss";

const SharedGalleryRoute = compose(
  ProtectedRoute({ access: Roles.shared }),
  Route(
    {
      conceptId: Route.query.regex(idRegEx),
      sharedCode: Route.params.string,
      assetId: Route.params.string.optional,
    },
    ({ sharedCode, assetId }) => `/shared/gallery/${sharedCode}/${assetId}`
  )
);

const useGetProjectSharingTokenSuspenseQuery = withSuspense(
  useGetProjectSharingTokenQuery
);

export const SharedGallery = SharedGalleryRoute(
  ({
    match: {
      params: { sharedCode, assetId },
      query: { conceptId },
    },
    link,
  }) => {
    const { t } = useTranslation("shared");
    const [download, { downloading }] = useDownload();
    const auth = useAuthData();
    const mainRef = useRef<HTMLElement>(null);
    const [selected, setSelected] = useState<Array<AssetType["assetId"]>>([]);
    const {
      data: { sharedData } = {},
      error: authenticationTokenError,
      loading: tokenLoading,
    } = useGetProjectSharingTokenSuspenseQuery({
      variables: {
        linkCode: sharedCode,
      },
      onCompleted: ({ sharedData }) => {
        auth.setSessionToken(sharedData?.accessToken);
      },
      suspense: true,
    });
    const projectId = sharedData?.projectSharingLink?.projectId ?? "";

    const [galleryZip, { loading: galleryZipLoading }] =
      useProjectGalleryZipLazyQuery({
        fetchPolicy: "network-only",
      });
    const [assetsZip, { loading: assetsZipLoading }] =
      useProjectAssetsZipLazyQuery({
        fetchPolicy: "network-only",
      });

    const {
      data: { getProject: project } = {},
      error: sharedGalleryError,
      loading: projectLoading,
    } = useSharedGalleryQuery({
      variables: {
        projectId: sharedData?.projectSharingLink?.projectId ?? "",
      },
      skip: !(
        sharedData?.accessToken && sharedData.projectSharingLink?.projectId
      ),
    });

    const downloadAccess = sharedData?.projectSharingLink?.canDownloadAssets;
    const assets = useMemo(() => {
      if (conceptId) {
        return project?.deliveredAssets?.filter(
          (asset) => asset?.conceptId === conceptId
        );
      }

      return project?.deliveredAssets;
    }, [project?.deliveredAssets, conceptId]);

    const onDownload = async () => {
      const singleFile =
        selected.length === 1
          ? assets?.find((asset) => asset?.assetId === selected[0])
              ?.latestVersion?.file
          : null;

      if (singleFile) {
        return download([singleFile]);
      }

      const { data } = selected.length
        ? await assetsZip({
            variables: {
              projectId,
              assetsIds: selected as string[],
            },
          })
        : await galleryZip({
            variables: {
              projectId,
            },
          });

      const fileUrl = selected.length
        ? (data as ProjectAssetsZipQuery)?.getProjectAssetsZip
        : (data as ProjectGalleryZipQuery)?.getProjectGalleryZip?.download
            ?.downloadUrl?.url;

      if (fileUrl) {
        downloadURI(fileUrl);
      }
    };

    const { data: { getSharedAsset: asset } = {}, loading: assetLoading } =
      useSharedAssetQuery({
        skip: Boolean(!project?.deliveredAssets || !assetId),
        variables: {
          assetId: assetId!,
        },
      });

    const prev = useMemo(() => {
      const index = project?.deliveredAssets?.findIndex(
        (item) => item?.assetId === assetId
      );
      if (index === undefined) return;
      const asset = project?.deliveredAssets?.[index - 1];
      return asset && link({ assetId: asset.assetId!, sharedCode });
    }, [project, assetId]);

    const next = useMemo(() => {
      const index = project?.deliveredAssets?.findIndex(
        (item) => item?.assetId === assetId
      );
      if (index === undefined) return;
      const asset = project?.deliveredAssets?.[index + 1];
      return asset && link({ assetId: asset.assetId!, sharedCode });
    }, [project, assetId]);

    const errorMessage =
      authenticationTokenError || sharedGalleryError || assetLoading;

    const loading = tokenLoading || projectLoading;
    const downloadLoading =
      downloading || galleryZipLoading || assetsZipLoading;

    if (loading) {
      return (
        <div className="flex h-full w-full items-center justify-center">
          <Loader radius={50} />
        </div>
      );
    }

    return (
      <section>
        {project?.title && (
          <Helmet>
            <title>{project.title}</title>
          </Helmet>
        )}
        <header className={styles.header}>
          {project?.coverImage?.file?.thumbnail?.downloadUrl?.url && (
            <img
              src={project.coverImage.file.thumbnail.downloadUrl.url}
              className="w-full h-full object-cover brightness-[0.6]"
            />
          )}
          <div className={styles.info}>
            {project?.organization?.name && (
              <h5>{project.organization.name}</h5>
            )}
            {project?.title && <h4>{project.title}</h4>}
            {downloadAccess ? (
              <div className="flex justify-center mt-7">
                <Button
                  onClick={onDownload}
                  className={clsx("hover:bg-teal focus:bg-teal", {
                    "pointer-events-none": downloadLoading,
                  })}
                >
                  {downloadLoading && <Loader radius={16} className="mr-4" />}
                  {t("gallery.download", {
                    count: selected.length,
                  })}
                </Button>
              </div>
            ) : (
              <button
                className="mt-7 mx-auto"
                onClick={() =>
                  mainRef.current?.scrollIntoView({
                    behavior: "smooth",
                  })
                }
              >
                <ArrowIcon className="stroke-white rotate-[270deg] stroke-[1.5]" />
              </button>
            )}
          </div>
        </header>
        <main className={styles.main} ref={mainRef}>
          {errorMessage && (
            <Alert
              icon
              variant={Alert.variants.Error}
              className="my-8 w-full"
              title={errorMessage}
            />
          )}
          <section className="flex items-start justify-between my-10">
            <Tabs size={Tabs.sizes.Small} className="flex-wrap">
              <Tabs.Item
                isActive={() => !conceptId}
                component={NavLink}
                className="mr-8"
                to={link({
                  sharedCode,
                })}
              >
                {t("gallery.manage.concepts.tabs.all", {
                  count: project?.deliveredAssets?.length ?? 0,
                })}
              </Tabs.Item>
              {project?.brief?.concepts?.map((concept, key) => (
                <Tabs.Item
                  key={concept?.conceptId}
                  isActive={() => conceptId === concept?.conceptId}
                  component={NavLink}
                  className="mr-8"
                  to={link({
                    conceptId: concept?.conceptId ?? "",
                    sharedCode,
                  })}
                >
                  {concept?.name || t(`common:concepts.titles.${key + 1}`)}
                </Tabs.Item>
              ))}
            </Tabs>
          </section>
          <GalleryGrid
            assets={assets}
            onOpen={(id) => links.SharedGallery({ sharedCode, assetId: id! })}
            onSelect={
              downloadAccess ? (assets) => setSelected(assets) : undefined
            }
          />
        </main>
        <Footer />
        {asset && (
          <section className={styles.assetView}>
            <Asset
              prev={prev}
              next={next}
              readonly
              sidebar={false}
              backURL={links.SharedGallery({ sharedCode })}
              value={asset}
            />
          </section>
        )}
      </section>
    );
  }
);
