import "./locales";
import { Features } from "api/features";
import { lazy } from "react";
import { compose, Route } from "react-router-hoc";
import { Roles } from "utils/roles";
import { ProtectedRoute } from "utils/route";

import { AssetLibrary } from "./AssetLibrary";
import { Collections } from "./Collections";
import { CollectionDetails, CollectionDetailsAsset } from "./CollectionDetails";

export const DAMRoute = compose(
  ProtectedRoute({
    access: [Roles.cs_admins, Roles.customer],
    feature: Features.dam_page,
  }),
  Route("/dam")
);

const DAM = DAMRoute(lazy(() => import(/* webpackChunkName: "DAM" */ "./DAM")));

export const route = DAM;

export const links = {
  AssetLibrary,
  DAM,
  Collections,
  CollectionDetails,
  CollectionDetailsAsset,
};
