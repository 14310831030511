import { Button } from "@CreativelySquared/uikit";
import { DetailedHTMLProps, HTMLAttributes, memo } from "react";
import { useTranslation } from "react-i18next";
import { ShareLink } from "components";
import { VIEW_ONLY_ACCESS } from "Shared/utils/permissions";
import { ReactComponent as CrossIcon } from "images/close.svg";
import { Project } from "api/types";
import { Object } from "ts-toolbelt";
import clsx from "clsx";
import { useShareLink } from "Projects/hooks/useShareLink";
import { InternalRefetchQueriesInclude } from "@apollo/client";

import styles from "./styles.module.scss";

type Props = {
  project: Object.Partial<Project, "deep">;
  onClose: () => void;
  refetchQueries?: InternalRefetchQueriesInclude;
} & DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;

export const ShareBrief = memo<Props>(
  ({ project, onClose, refetchQueries, className, ...props }) => {
    const { t } = useTranslation("brief");

    const { linkData, linkLoading, createLink } = useShareLink({
      access: VIEW_ONLY_ACCESS,
      project,
      onUpdate: refetchQueries,
      type: "brief",
    });

    return (
      <section className={clsx(styles.shareBrief, className)} {...props}>
        <Button
          onClick={onClose}
          variant={Button.variants.Icon}
          className={styles.close}
        >
          <CrossIcon />
        </Button>
        <h3 className="mb-8"> {t("share.title")}</h3>
        <ShareLink
          link={linkData.link}
          loading={linkLoading}
          onCreate={createLink}
          placeholder={t("share.placeholder")}
        />
      </section>
    );
  }
);
