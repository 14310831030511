import { FC, useCallback, useMemo, useState } from "react";
import { Button, Input } from "@CreativelySquared/uikit";
import { useTranslation } from "react-i18next";
import { ErrorMessage, InputLabel, ModalTitle } from "components";
import { useFormik } from "formik";
import { parseError } from "utils/form";
import { object, string } from "yup";
import { Role } from "api/enums";
import { newUserValidationSchema } from "Customers/components/Members/utils";
import clsx from "clsx";

import { RoleInput } from "../RoleInput";

import styles from "./styles.module.scss";

export interface MemberFormData {
  email: string;
  name?: string;
  surname?: string;
  role?: Role;
}

interface Props {
  onSubmit: (data: MemberFormData) => Promise<any> | void;
  isSubmitting?: boolean;
  isEmailEditable?: boolean;
  initialValues?: MemberFormData;
  title?: string;
}

export const MemberForm: FC<Props> = ({
  onSubmit,
  isSubmitting,
  isEmailEditable = true,
  initialValues = {
    email: "",
  },
  title,
}) => {
  const { t } = useTranslation("accounts");

  const [isUpgradingRole, setUpgradingRole] = useState(false);
  const [isDowngradingRole, setDowngradingRole] = useState(false);

  const validationSchema = useMemo(() => {
    return object({
      email: newUserValidationSchema.fields.email,
      role: string().required("common:validation.required"),
    });
  }, [isEmailEditable]);

  const hasDowngradeRole = useCallback(
    (newUserRole?: string, currentUserRole?: string) =>
      newUserRole === Role.OrgUser && currentUserRole === Role.OrgAdmin,
    []
  );

  const hasUpgradeRole = useCallback(
    (newUserRole?: string, currentUserRole?: string) =>
      newUserRole === Role.OrgAdmin && currentUserRole === Role.OrgUser,
    []
  );

  const {
    values: { email, role },
    errors,
    submitCount,
    isValid,
    handleChange,
    setFieldValue,
    handleSubmit,
  } = useFormik<MemberFormData & { isEmailEditable: boolean }>({
    validationSchema,
    initialValues: {
      ...initialValues,
      isEmailEditable,
    },
    onSubmit(newUserData) {
      if (
        hasDowngradeRole(newUserData.role, initialValues.role) &&
        !isDowngradingRole
      ) {
        setDowngradingRole(true);

        return;
      }

      if (
        hasUpgradeRole(newUserData.role, initialValues.role) &&
        !isUpgradingRole
      ) {
        setUpgradingRole(true);

        return;
      }

      onSubmit(newUserData);
    },
  });

  return (
    <form onSubmit={handleSubmit} noValidate className="w-[450px]">
      <ModalTitle className="text-3xl mb-8">{title}</ModalTitle>

      {initialValues.name && initialValues.surname && (
        <>
          <InputLabel>{t("members.newMember.name.label")}</InputLabel>
          <div className="grid grid-flow-col gap-5">
            <fieldset className={styles.fieldset}>
              <Input
                variant={Input.variants.Secondary}
                className={styles.name}
                value={initialValues.name}
                disabled
              />
            </fieldset>
            <fieldset className={styles.fieldset}>
              <Input
                variant={Input.variants.Secondary}
                className={styles.name}
                value={initialValues.surname}
                disabled
              />
            </fieldset>
          </div>
        </>
      )}

      <fieldset className={clsx(styles.fieldset, "mt-7")}>
        <InputLabel>{t("members.newMember.email.label")}</InputLabel>
        <Input
          variant={Input.variants.Secondary}
          placeholder={t("members.newMember.email.placeholder")}
          className="w-full"
          value={email}
          name="email"
          error={!!submitCount && t(...parseError(errors.email)).toString()}
          onChange={handleChange}
          disabled={!isEmailEditable}
        />
        {!!submitCount && errors.email && (
          <ErrorMessage
            message={t(...parseError(errors.email)).toString()}
            className="mt-3"
          />
        )}
      </fieldset>

      <RoleInput
        className="mt-7"
        role={role}
        error={!!submitCount && !!errors.role}
        onChange={(role: Role) => setFieldValue("role", role)}
      />

      <div className="w-full flex justify-center">
        {isUpgradingRole && (
          <p className={styles.roleWarning}>
            {t("common:user.newUser.warnings.upgradingRole")}
          </p>
        )}
        {isDowngradingRole && (
          <p className={styles.roleWarning}>
            {t("common:user.newUser.warnings.downgradeRole")}
          </p>
        )}
      </div>

      <Button
        type="submit"
        className="mt-7 w-full"
        loading={isSubmitting}
        disabled={(!!submitCount && !isValid) || isSubmitting}
      >
        {isUpgradingRole || isDowngradingRole
          ? t("common:actions.confirm")
          : t("common:actions.save")}
      </Button>
    </form>
  );
};
