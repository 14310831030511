import { MemberRoles } from "Customers/components/Members";
import { lazy } from "react";
import { compose, Route } from "react-router-hoc";
import { SortFields, SortOrder } from "utils/order";
import { ProtectedRoute } from "utils/route";
import { Roles } from "utils/roles";

import { OrganizationTabs } from "../types";

export const SettingsOrganizationMembersRoute = compose(
  ProtectedRoute({
    access: Roles.master_org_owner,
  }),
  Route(
    {
      page: Route.query.number,
      search: Route.query.string,
      sort: Route.query.oneOf(SortFields.fullName),
      sortOrder: Route.query.oneOf(...Object.values(SortOrder)),
      roles: Route.query.array(Route.query.oneOf(...MemberRoles)),
      accountIds: Route.query.array(Route.query.string),
    },
    `/settings/organization/${OrganizationTabs.Members}`
  )
);

export const SettingsOrganizationMembers = SettingsOrganizationMembersRoute(
  lazy(
    () =>
      import(/* webpackChunkName: "SettingsOrganizationMembers" */ "./Members")
  )
);
