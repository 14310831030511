import { Alert, Dropdown, Input } from "@CreativelySquared/uikit";
import {
  ViewableDropdown,
  ViewableInput,
} from "AITools/components/ViewableInput";
import clsx from "clsx";
import { SupportGuide } from "components";
import { ChangeEventHandler, FC, ReactChild } from "react";
import { formatDate, tomorrow } from "utils/time";

import styles from "./styles.module.scss";

type SharedProps = {
  label: string | JSX.Element;
  placeholder?: string;
  name: string;
  value?: string;
  readOnly?: boolean;
  error?: boolean | string;
  tooltip?: string | boolean | null;
};

export const ViewableField: FC<
  Omit<SharedProps, "name" | "value"> & {
    className?: string;
    labelClassName?: string;
  }
> = ({
  label,
  error,
  readOnly,
  children,
  tooltip,
  className,
  labelClassName,
}) => (
  <div className={clsx("flex items-center", className)}>
    <div
      className={clsx(
        "flex items-center shrink-0 text-sm text-blue-steel w-[150px]",
        labelClassName
      )}
    >
      <span>{label}</span>
      {tooltip && (
        <SupportGuide size={SupportGuide.sizes.Small} className="ml-3">
          <div className="w-[320px]">{tooltip}</div>
        </SupportGuide>
      )}
    </div>
    <div
      className={clsx(
        "flex items-center shrink gap-3 whitespace-nowrap",
        readOnly && "max-w-[calc(100%-150px)]"
      )}
    >
      {children}
      {!readOnly && error && (
        <Alert
          banner={false}
          title={error}
          variant={Alert.variants.Error}
          className={"py-5 whitespace-normal"}
        />
      )}
    </div>
  </div>
);

export const ViewableInputField: FC<
  SharedProps & {
    onChange?: ChangeEventHandler<HTMLInputElement>;
    disabled?: boolean;
    postfix?: ReactChild;
  }
> = ({
  label,
  placeholder,
  name,
  value,
  error,
  readOnly,
  onChange,
  tooltip,
  disabled,
  postfix,
}) => (
  <ViewableField
    label={label}
    error={error}
    readOnly={readOnly}
    tooltip={tooltip}
  >
    <ViewableInput
      value={value}
      readOnly={readOnly}
      name={name}
      onChange={onChange}
      disabled={disabled}
      textClassName="!text-sm placeholder:text-sm placeholder:font-medium !text-blue-steel !font-medium"
      className={clsx(!readOnly && styles.input)}
      placeholder={placeholder}
    />
    {postfix}
  </ViewableField>
);

export const ViewableDropdownField: FC<
  SharedProps & {
    onSelect?: (value?: string | null) => void;
    options: Array<{ key: string; value: string }>;
    loading?: boolean;
  }
> = ({
  label,
  placeholder,
  name,
  value,
  error,
  readOnly,
  onSelect,
  tooltip,
  options,
  loading,
}) => (
  <ViewableField
    label={label}
    error={error}
    readOnly={readOnly}
    tooltip={tooltip}
  >
    <ViewableDropdown
      value={value}
      readOnly={readOnly}
      name={name}
      loading={loading}
      onSelect={onSelect}
      textClassName="!text-sm !text-blue-steel !font-medium placeholder:text-sm placeholder:font-medium"
      popoverClassName="w-full"
      visibleValue={options.find((option) => option.key === value)?.value}
      className={clsx(!readOnly && styles.input)}
      placeholder={placeholder}
    >
      {options.map(({ key, value }) => (
        <Dropdown.Item key={key} value={key}>
          {value}
        </Dropdown.Item>
      ))}
    </ViewableDropdown>
  </ViewableField>
);

export const ViewableDatePickerField: FC<
  Omit<SharedProps, "value"> & {
    onSelect?: (value?: string | null) => void;
    value?: Date | null;
    openToDate?: Date;
  }
> = ({
  label,
  placeholder,
  name,
  value,
  error,
  readOnly,
  onSelect,
  tooltip,
  openToDate,
}) => (
  <ViewableField
    label={label}
    error={error}
    readOnly={readOnly}
    tooltip={tooltip}
  >
    <Dropdown
      onSelect={onSelect}
      placement={Dropdown.placements.TopStart}
      render={({ children }) => (
        <Input
          value={value ? formatDate(value) : undefined}
          postfix={<div>{children}</div>}
          variant={Input.variants.Secondary}
          className={clsx(styles.input, styles.datePicker)}
          name={name}
          inputClassName="!text-sm !text-blue-steel !font-medium text-ellipsis placeholder:text-sm placeholder:font-medium"
          postfixClassName="pointer-events-none"
          visibility="instant"
          placeholder={placeholder}
        />
      )}
    >
      <Dropdown.Datepicker
        minDate={tomorrow}
        openToDate={openToDate ?? value ?? undefined}
      />
    </Dropdown>
  </ViewableField>
);
