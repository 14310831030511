import { compose, Route } from "react-router-hoc";
import { Roles } from "utils/roles";
import { ProtectedRoute } from "utils/route";

import { SettingsAccount as SettingsAccountComp } from "./SettingsAccount";
import { AccountTabs } from "./types";

export const SettingsAccountRoute = compose(
  ProtectedRoute({
    access: Roles.customer,
  }),
  Route(
    {
      tab: Route.params.oneOf(...Object.values(AccountTabs)).optional,
    },
    ({ tab }) => `/settings/account/${tab}`
  )
);

export const SettingsAccount = SettingsAccountRoute(SettingsAccountComp);
