import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { compose, Route } from "react-router-hoc";
import { Roles } from "utils/roles";
import { ProtectedRoute } from "utils/route";
import { Button, Loader, Modal } from "@CreativelySquared/uikit";
import {
  BriefDocument,
  useBriefQuery,
  useSubmitBriefMutation,
} from "api/graphql";
import { SectionPopup, useNotification } from "components";
import { isProjectEditable, ProjectStatuses } from "Projects/utils/types";
import { ApolloError } from "@apollo/client";
import { ReactComponent as EditIcon } from "images/edit.svg";
import { Link } from "react-router-dom";
import { links } from "App";
import { scrollTop } from "utils/scroll";
import { useEffect } from "react";
import { BriefComments } from "Brief/components/BriefComments";
import { ReactComponent as ShareIcon } from "images/shareAlt.svg";
import { BriefSection } from "Brief/utils/types";
import { ReactComponent as DuplicateIcon } from "images/copy.svg";

import { BriefProvider } from "../brief.context";
import { DuplicateProject } from "../components/DuplicateProject";
import { ShareBrief } from "../components/ShareBrief";
import { BriefCredits } from "../Credits";

import { SummaryOverview } from "./SummaryOverview";
import { SummaryConcept } from "./SummaryConcept";
import { SummaryLogistics } from "./SummaryLogistics";

import styles from "./styles.module.scss";

const BriefSummaryRoute = compose(
  ProtectedRoute({ access: Roles.customer }),
  Route(
    {
      id: Route.params.string,
      mode: Route.query.oneOf("duplicate", "share"),
    },
    ({ id }) => `/brief/${id}/summary`
  )
);

export const BriefSummary = BriefSummaryRoute(
  ({
    match: {
      params: { id },
      params,
      query: { mode },
      query,
    },
    history: { replace },
    link,
  }) => {
    useEffect(() => {
      scrollTop();
    }, []);
    const { t } = useTranslation("brief");
    const { setNotification, notificationTypes } = useNotification();
    const {
      data: { getBrief: brief } = {},
      loading,
      variables: briefVariables,
    } = useBriefQuery({
      variables: {
        projectId: id!,
        includeSharedLinks: true,
      },
      onError() {
        setNotification({
          message: t("common:common.error"),
          type: notificationTypes.Error,
        });
      },
      skip: !id,
    });
    const [submitBrief, { loading: submitLoading }] = useSubmitBriefMutation();

    const onCloseModal = () =>
      replace(
        link({
          ...query,
          ...params,
          mode: undefined,
        })
      );

    const onSubmit = () => {
      submitBrief({
        variables: {
          projectId: id,
        },
      })
        .then(() => {
          scrollTop();
          setNotification({
            message: t("summary.notifications.submitted"),
            type: notificationTypes.Success,
          });
        })
        .catch((error: ApolloError) => {
          setNotification({
            message: error.message ?? t("common:validation.email"),
            type: notificationTypes.Error,
          });
        });
    };

    const overwriteProtection = {
      isActive: true,
      brief: {
        briefId: brief?.briefId,
        updatedAt: brief?.briefUpdatedAt,
      },
      project: {
        projectId: id,
        updatedAt: brief?.project?.updatedAt,
      },
      concepts: brief?.concepts?.map((concept) => ({
        conceptId: concept?.conceptId,
        updatedAt: concept?.updatedAt,
      })),
    };

    return (
      <BriefProvider brief={brief ?? {}}>
        <main>
          <Helmet>
            <title>{t("summary.title")}</title>
          </Helmet>
          <section>
            {loading ? (
              <Loader radius={50} className="mx-auto mt-11" />
            ) : (
              <div className="flex justify-between items-center">
                <div className="flex-1">
                  <div className="flex justify-between items-center">
                    <h1>
                      {!isProjectEditable(brief?.project?.status) &&
                      brief?.project?.title
                        ? brief.project?.title
                        : t("summary.title")}
                    </h1>
                    <div className="flex items-center gap-5">
                      {!!brief?.category && (
                        <Link
                          to={link({
                            ...query,
                            ...params,
                            mode: "duplicate",
                          })}
                        >
                          <Button type="button" outlined borderless>
                            <DuplicateIcon className="mr-4 w-7 h-7 stroke-[1.5]" />
                            {t("summary.actions.duplicate")}
                          </Button>
                        </Link>
                      )}
                      {isProjectEditable(brief?.project?.status) && (
                        <Link to={links.Brief({ id })}>
                          <Button type="button" outlined borderless>
                            <EditIcon className="mr-4 w-7 h-7 stroke-[1.5]" />
                            {t("summary.actions.edit")}
                          </Button>
                        </Link>
                      )}

                      <Link
                        to={link({
                          ...query,
                          ...params,
                          mode: "share",
                        })}
                      >
                        <Button type="button" outlined borderless>
                          <ShareIcon className="mr-4 w-7 h-7 stroke-[1.5]" />
                          {t("summary.actions.share")}
                        </Button>
                      </Link>
                    </div>
                  </div>

                  {brief && (
                    <section className={styles.summaryForm}>
                      <SummaryOverview brief={brief} />

                      <section className="my-10">
                        <h2>{t("concept.title")}</h2>
                        {brief.concepts?.map(
                          (concept) =>
                            concept && (
                              <SummaryConcept
                                briefId={brief.briefId}
                                concept={concept}
                                key={concept.conceptId}
                                className="mt-8"
                              />
                            )
                        )}
                      </section>

                      <SummaryLogistics brief={brief} />

                      <section className="mt-10">
                        <SectionPopup
                          comment={{
                            briefId: brief.briefId,
                            sectionId: BriefSection.credits,
                          }}
                        >
                          <h2>{t("credits.title")}</h2>
                          <BriefCredits brief={brief} />
                        </SectionPopup>
                      </section>

                      {brief?.project?.status === ProjectStatuses.draft && (
                        <Button
                          type="button"
                          className="w-full mt-10"
                          onClick={onSubmit}
                          loading={submitLoading}
                        >
                          {t("summary.actions.submit")}
                        </Button>
                      )}
                    </section>
                  )}
                </div>
                {brief?.id && <BriefComments brief={brief} />}
              </div>
            )}
          </section>
          <Modal visible={mode === "duplicate"} onClose={onCloseModal}>
            <DuplicateProject
              onClose={onCloseModal}
              projectId={id}
              overwriteProtection={overwriteProtection}
            />
          </Modal>
          <Modal
            visible={mode === "share"}
            onClose={onCloseModal}
            className="p-0 bg-grey"
          >
            <ShareBrief
              onClose={onCloseModal}
              project={brief?.project ?? {}}
              refetchQueries={[
                {
                  query: BriefDocument,
                  variables: briefVariables,
                },
              ]}
            />
          </Modal>
        </main>
      </BriefProvider>
    );
  }
);
