import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { Dropdown, Loader, Slider, Tooltip } from "@CreativelySquared/uikit";
import { useTranslation } from "react-i18next";
import {
  Empty,
  Filter,
  Pagination,
  Protect,
  Search,
  useNotification,
} from "components";
import { ReactComponent as DetailsIcon } from "images/details.svg";
import { ReactComponent as GalleryIcon } from "images/appstore.svg";
import { ReactComponent as ListIcon } from "images/bars.svg";
import { ReactComponent as SortIcon } from "images/sort.svg";
import clsx from "clsx";
import { uniqBy } from "lodash";
import {
  useAssetLibraryQuery,
  useBrandNamesQuery,
  useAccountNamesQuery,
  useProfileQuery,
  useProjectNamesQuery,
  useTagsQuery,
  useActiveFilterValueQuery,
} from "api/graphql";
import { BriefCategory } from "api/enums";
import { Services } from "utils/services";
import {
  AssetItemFragment,
  AssetLibraryFiltersInput,
  OffsetPaginationParameters,
} from "api/types";
import { Features } from "api/features";
import { SortOrder } from "utils/order";
import { ReactComponent as ArrowIcon } from "images/left.svg";
import PhotoAlbum, { Photo } from "react-photo-album";
import { getFileMetadata } from "utils/file";
import { useProtect } from "utils/hooks/protection";
import { Roles } from "utils/roles";
import { AssetRatingType } from "utils/types";
import {
  filterSearchPagination,
  formatFilterOption,
  moreResultsCount,
} from "components/Filter/utils";

import { AssetListItemProps, AssetViewType } from "../AssetListItem";
import { ListItemWrapper } from "../ListItemWrapper";

import styles from "./styles.module.scss";

const minPerRow = 3;
const maxPerRow = 8;
const defaultRowSize = 5;

enum LibraryFilter {
  assetTypes = "assetTypes",
  brands = "brands",
  categories = "categories",
  projects = "projects",
  organizations = "organizations",
  createdAt = "createdAt",
  tags = "tags",
  rating = "rating",
}

interface Props {
  view?: AssetViewType;
  search?: string;
  serviceValues?: Array<{
    id: string;
    name: string;
  }>;
  assetTypes?: Services[];
  createdAt?: string[];
  sortOrder?: SortOrder;
  dataLoading?: boolean;
  pagination: OffsetPaginationParameters;
  page: number;
  selectedAssets?: string[];
  onSearch?: (value: string) => void;
  onSort?: (value: SortOrder) => void;
  onFilter?: (value: Record<string, string[]>) => void;
  onViewChange?: (value: AssetViewType) => void;
  onPageChange?: (value: number) => void;
  renderAsset: (
    props: Omit<AssetListItemProps, "onSelect"> & { onSelect?: () => void }
  ) => React.ReactNode;
  onSelectAsset?: (asset: AssetItemFragment) => void;
  emptyMessage?: string;
  filters: AssetLibraryFiltersInput;
  organizationId?: string | null;
  compact?: boolean;
  actionButton?: React.ReactNode;
}

type AssetPhoto = Photo & { asset: AssetItemFragment };

export const AssetsView = forwardRef<
  { getAllAssets: () => AssetItemFragment[] | null },
  Props
>(
  (
    {
      view = AssetViewType.Gallery,
      search,
      serviceValues,
      assetTypes,
      createdAt,
      filters,
      sortOrder,
      dataLoading,
      pagination,
      page,
      onSearch,
      onSort,
      onFilter,
      onViewChange,
      onPageChange,
      renderAsset,
      onSelectAsset,
      selectedAssets,
      emptyMessage,
      organizationId: initialOrganizationId,
      compact,
      actionButton,
    },
    ref
  ) => {
    const { t } = useTranslation("dam");
    const [size, setSize] = useState(defaultRowSize);
    const [filterSearch, setFilterSearch] = useState<{
      [key: string]: string | undefined;
    }>({});
    const { setNotification, notificationTypes } = useNotification();
    const isAdmin = useProtect()(Roles.cs_admins);

    const { data: { me: profile } = {}, loading: profileLoading } =
      useProfileQuery();
    const organizationId =
      initialOrganizationId || profile?.organization?.organizationId;

    const { data: { getAssetLibrary } = {}, loading: libraryLoading } =
      useAssetLibraryQuery({
        variables: {
          pagination,
          search: search || undefined,
          filters: {
            ...(organizationId && { organizations: [organizationId] }),
            ...filters,
          },
          includeDetails: view === AssetViewType.List,
        },
        fetchPolicy: "cache-and-network",
        onError(error) {
          setNotification({
            message: error.message,
            type: notificationTypes.Error,
          });
        },
      });

    const {
      data: { getBrandProfiles: brandList } = {},
      loading: brandsLoading,
    } = useBrandNamesQuery({
      variables: {
        organizationId,
        filters: {
          name: filterSearch?.[LibraryFilter.brands],
        },
        pagination: filterSearchPagination,
      },
      skip: !onFilter,
    });

    const {
      data: { getProjects: projectList } = {},
      loading: projectsLoading,
    } = useProjectNamesQuery({
      variables: {
        organizationId,
        filters: {
          isDeleted: false,
          title: filterSearch?.[LibraryFilter.projects],
        },
        pagination: filterSearchPagination,
      },
      skip: !onFilter,
    });

    const {
      data: { getOrganizations: organizationList } = {},
      loading: organizationLoading,
    } = useAccountNamesQuery({
      variables: {
        filters: {
          name: filterSearch?.[LibraryFilter.organizations],
        },
        pagination: filterSearchPagination,
      },
      skip: !onFilter,
    });

    const { data: { getTags: tagList } = {}, loading: tagsLoading } =
      useTagsQuery({
        variables: {
          filters: {
            search: filterSearch?.[LibraryFilter.tags],
          },
          pagination: filterSearchPagination,
        },
        skip: !onFilter,
      });

    const {
      data: activeFilterData,
      loading: activeFilterLoading,
      previousData: previousActiveFilterData,
    } = useActiveFilterValueQuery({
      variables: {
        organizationId,
        brandFilters: { brandProfileId: filters.brandProfileIds },
        projectsFilters: { projectId: filters.projects },
        tagsFilters: { tagId: filters.tagIds },
        organizationFilters: { organizationsIds: filters.organizations },
        includeBrandFilters: !!filters.brandProfileIds?.length,
        includeProjectFilters: !!filters.projects?.length,
        includeTagFilters: !!filters.tagIds?.length,
        includeOrganizationFilters: !!filters.organizations?.length,
      },
      skip: !onFilter,
    });

    const activeFilterValue = activeFilterLoading
      ? previousActiveFilterData
      : activeFilterData;

    const filterOptions = [
      ...(!!serviceValues?.length
        ? [
            {
              key: LibraryFilter.assetTypes,
              label: t("common:filter.value.assetType"),
              value: uniqBy(serviceValues, "name"),
              type: Filter.types.Checkbox,
            },
          ]
        : []),
      {
        key: LibraryFilter.categories,
        label: t("common:filter.value.category"),
        value: Object.values(BriefCategory).map((category) => ({
          id: category,
          name: t(`common:briefCategory.${category}.title`),
        })),
        type: Filter.types.Checkbox,
      },
      {
        key: LibraryFilter.brands,
        label: t("common:filter.value.brand"),
        value: formatFilterOption(brandList, "brandProfileId"),
        type: Filter.types.Checkbox,
        activeSearch: true,
        moreResults: moreResultsCount(brandList?.pageInfo?.totalItems),
        activeValue: formatFilterOption(
          activeFilterValue?.getBrandProfiles,
          "brandProfileId"
        ),
      },
      {
        key: LibraryFilter.projects,
        label: t("common:filter.value.project"),
        value: formatFilterOption(projectList, "projectId", "title"),
        type: Filter.types.Checkbox,
        activeSearch: true,
        moreResults: moreResultsCount(projectList?.pageInfo?.totalItems),
        activeValue: formatFilterOption(
          activeFilterValue?.getProjects,
          "projectId",
          "title"
        ),
      },
      ...(isAdmin && !initialOrganizationId
        ? [
            {
              key: LibraryFilter.organizations,
              label: t("common:filter.value.organization"),
              value: formatFilterOption(organizationList, "organizationId"),
              type: Filter.types.Checkbox,
              activeSearch: true,
              moreResults: moreResultsCount(
                organizationList?.pageInfo?.totalItems
              ),
              activeValue: formatFilterOption(
                activeFilterValue?.getOrganizations,
                "organizationId"
              ),
            },
          ]
        : []),
      {
        key: LibraryFilter.rating,
        label: t("common:filter.value.rating"),
        value: Object.values(AssetRatingType).map((rating) => ({
          id: rating,
          name: t(`assets:rating.${rating}`),
        })),
        type: Filter.types.Checkbox,
      },
      {
        key: LibraryFilter.createdAt,
        label: t("common:filter.value.createdAt"),
        type: Filter.types.Datepicker,
      },
      {
        key: LibraryFilter.tags,
        label: t("common:filter.value.tags"),
        value: formatFilterOption(tagList, "tagId"),
        type: Filter.types.Checkbox,
        activeSearch: true,
        moreResults: moreResultsCount(tagList?.pageInfo?.totalItems),
        activeValue: formatFilterOption(activeFilterValue?.getTags, "tagId"),
      },
    ];

    const filterData = [
      {
        key: LibraryFilter.assetTypes,
        value: assetTypes,
      },
      {
        key: LibraryFilter.categories,
        value: filters.projectCategories,
      },
      {
        key: LibraryFilter.brands,
        value: filters.brandProfileIds,
      },
      {
        key: LibraryFilter.projects,
        value: filters.projects,
      },
      {
        key: LibraryFilter.organizations,
        value: filters.organizations,
      },
      {
        key: LibraryFilter.rating,
        value: filters.rating?.map((rating) =>
          rating === 1 ? AssetRatingType.liked : AssetRatingType.disliked
        ),
      },
      {
        key: LibraryFilter.createdAt,
        value: createdAt,
      },
      {
        key: LibraryFilter.tags,
        value: filters.tagIds,
      },
    ];

    const loading =
      profileLoading ||
      activeFilterLoading ||
      (libraryLoading && !getAssetLibrary?.nodes?.length) ||
      dataLoading;
    const searchLoading =
      brandsLoading || projectsLoading || organizationLoading || tagsLoading;
    const hasActions = !!onSort || !!onFilter || !!onViewChange;
    const isFiltered = Object.values(filterData).some(
      (filter) =>
        filter.value &&
        (Array.isArray(filter.value) ? filter.value.length > 0 : !!filter.value)
    );
    const isEmpty = !getAssetLibrary?.pageInfo?.totalItems && !loading;

    const { assets, assetsPhotos } = useMemo(() => {
      const assets = getAssetLibrary?.nodes?.filter(
        (asset) => !!asset?.assetId && !!asset.latestVersion?.assetVersionId
      ) as AssetItemFragment[] | undefined;

      const assetsPhotos = assets?.map<AssetPhoto>((asset) => {
        const metadata = getFileMetadata(asset.latestVersion?.file);
        return {
          key: `${asset.assetId}`,
          src: asset.latestVersion?.file?.thumbnail?.downloadUrl?.url ?? "",
          width: metadata?.canvas?.width ?? 1,
          height: metadata?.canvas?.height ?? 1,
          title: asset.latestVersion?.file?.fileName ?? "",
          asset,
        };
      });

      return { assets, assetsPhotos };
    }, [getAssetLibrary]);

    const getColumns = useCallback(
      (containerWidth: number) => {
        const initialColumns = maxPerRow + minPerRow - size;

        if (containerWidth > 1200) return initialColumns;
        if (containerWidth > 900 && initialColumns > minPerRow + 1)
          return initialColumns - 1;
        if (containerWidth > 600 && initialColumns > minPerRow + 2)
          return initialColumns - 2;
        if (containerWidth > 300 && initialColumns > minPerRow + 3)
          return initialColumns - 3;
        else return minPerRow;
      },
      [size]
    );

    useImperativeHandle(
      ref,
      () => {
        return {
          getAllAssets() {
            return assets?.filter((asset) => !!asset) as AssetItemFragment[];
          },
        };
      },
      [assets]
    );

    return (
      <>
        {hasActions && (
          <div className={onSearch ? "mb-9" : "mb-5"}>
            <div className="flex justify-between items-center mb-7">
              <div className="grid gap-6 grid-flow-col">
                <Protect feature={Features.dam_filters}>
                  {actionButton}
                  {!!onSort && (
                    <Dropdown
                      icon={<SortIcon className="w-7 h-7 stroke-[1.5]" />}
                      label={t("common:sort.label")}
                      type={Dropdown.types.Light}
                      value={sortOrder}
                      onSelect={onSort}
                    >
                      <Dropdown.Item value={SortOrder.DESC}>
                        {t("common:sort.options.createdAt.desc")}
                      </Dropdown.Item>
                      <Dropdown.Item value={SortOrder.ASC}>
                        {t("common:sort.options.createdAt.asc")}
                      </Dropdown.Item>
                    </Dropdown>
                  )}
                  {!!onFilter && (
                    <Filter
                      value={filterData?.flatMap(({ key, value }) =>
                        value?.length ? [{ key, value: value as string[] }] : []
                      )}
                      options={filterOptions}
                      onChange={onFilter}
                      searchLoading={searchLoading}
                      searchValue={filterSearch}
                      onSearch={setFilterSearch}
                    />
                  )}
                </Protect>
              </div>
              <div className="flex items-center">
                {!!onSearch && view !== AssetViewType.List && (
                  <Slider
                    value={size}
                    onChange={setSize}
                    min={minPerRow}
                    max={maxPerRow}
                  />
                )}
                {!!onViewChange && (
                  <div className="flex ml-8 gap-6">
                    <Tooltip
                      placement={Tooltip.placements.Bottom}
                      title={t("common:gallery.mode.gallery")}
                      variant={Tooltip.variants.Secondary}
                      className="z-[1]"
                    >
                      <GalleryIcon
                        className={clsx(
                          {
                            [styles.assetLibraryViewActive]:
                              view === AssetViewType.Gallery,
                          },
                          styles.typeIcon
                        )}
                        onClick={() => onViewChange(AssetViewType.Gallery)}
                      />
                    </Tooltip>
                    <Tooltip
                      placement={Tooltip.placements.Bottom}
                      title={t("common:gallery.mode.details")}
                      variant={Tooltip.variants.Secondary}
                      className="z-[1]"
                    >
                      <DetailsIcon
                        className={clsx(
                          {
                            [styles.assetLibraryViewActive]:
                              view === AssetViewType.Details,
                          },
                          styles.typeIcon
                        )}
                        onClick={() => onViewChange(AssetViewType.Details)}
                      />
                    </Tooltip>
                    <Tooltip
                      placement={Tooltip.placements.Bottom}
                      title={t("common:gallery.mode.list")}
                      variant={Tooltip.variants.Secondary}
                      className="z-[1]"
                    >
                      <ListIcon
                        className={clsx(
                          {
                            [styles.assetLibraryViewActive]:
                              view === AssetViewType.List,
                          },
                          styles.typeIcon
                        )}
                        onClick={() => onViewChange(AssetViewType.List)}
                      />
                    </Tooltip>
                  </div>
                )}
              </div>
            </div>
            {onSearch && (
              <Search
                placeholder={t("library.search.placeholder")}
                onSearch={onSearch}
                value={search}
              />
            )}
          </div>
        )}
        {isEmpty && (
          <Empty
            title={t("library.empty.filtered.title")}
            description={
              isFiltered
                ? t("library.empty.filtered.description")
                : emptyMessage || t("library.empty.description")
            }
          />
        )}
        {!isEmpty &&
          !!assetsPhotos?.length &&
          (view === AssetViewType.List ? (
            <>
              <ListItemWrapper className={styles.columns}>
                <div>{t("common:gallery.list.columns.name")}</div>
                <div>{t("common:gallery.list.columns.brand")}</div>
                <div>{t("common:gallery.list.columns.dateCreated")}</div>
                <div>{t("common:gallery.list.columns.size")}</div>
                <div>{t("common:gallery.list.columns.fileType")}</div>
                <div>{t("common:gallery.list.columns.tags")}</div>
              </ListItemWrapper>
              <div className="flex flex-col gap-5">
                {assets?.map((asset) => {
                  return renderAsset({
                    asset,
                    selected: selectedAssets?.includes(asset.assetId || ""),
                    onSelect: onSelectAsset
                      ? () => onSelectAsset(asset || "")
                      : undefined,
                    view,
                  });
                })}
              </div>
            </>
          ) : (
            <PhotoAlbum<AssetPhoto>
              photos={assetsPhotos}
              layout="columns"
              renderPhoto={({ photo }) => {
                const asset = photo.asset;

                return renderAsset({
                  asset,
                  selected: selectedAssets?.includes(asset.assetId || ""),
                  onSelect: onSelectAsset
                    ? () => onSelectAsset(asset || "")
                    : undefined,
                  view,
                });
              }}
              spacing={6}
              columns={getColumns}
            />
          ))}
        {!isEmpty && (
          <Pagination
            className={clsx("mt-11 flex items-center justify-center w-full", {
              "mt-8": compact,
            })}
            page={page}
            total={getAssetLibrary?.pageInfo?.totalPages}
            loading={libraryLoading}
            renderPrev={
              onPageChange &&
              ((prevPage, props) => (
                <button {...props} onClick={() => onPageChange(prevPage)}>
                  <ArrowIcon />
                </button>
              ))
            }
            renderNext={
              onPageChange &&
              ((nextPage, props) => (
                <button {...props} onClick={() => onPageChange(nextPage)}>
                  <ArrowIcon style={{ transform: "rotate(180deg)" }} />
                </button>
              ))
            }
            renderPage={
              onPageChange &&
              ((item, props) => (
                <button {...props} onClick={() => onPageChange(item)}>
                  {item}
                </button>
              ))
            }
          />
        )}
        {loading && (
          <div className="flex justify-center items-center">
            <Loader radius={50} />
          </div>
        )}
      </>
    );
  }
);
