import { Dropdown, Input } from "@CreativelySquared/uikit";
import { ErrorMessage } from "components";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SubscriptionPlanName, SubscriptionPlanStatus } from "api/enums";
import { useFormikContext } from "formik";
import { formatNumberValue, parseError } from "utils/form";
import { formatDate, inAYear, tomorrow } from "utils/time";
import { getCreditsPlan } from "Customers/utils/credits";

import { PlanFormData, PlanFormFields } from "./types";

import styles from "./styles.module.scss";

interface Props {
  fields?: PlanFormFields[];
}

export const PlanForm: FC<Props> = ({
  fields = [
    PlanFormFields.PlanName,
    PlanFormFields.AccountStatus,
    PlanFormFields.CreditLimit,
    PlanFormFields.RenewalDate,
  ],
}) => {
  const { t } = useTranslation("accounts");

  const {
    submitCount,
    setFieldValue,
    setValues,
    values: { planName, accountStatus, creditLimit, renewalDate },
    values,
    errors,
    handleBlur,
  } = useFormikContext<PlanFormData>();

  const planCredits = useMemo(
    () => getCreditsPlan(Number(creditLimit)),
    [creditLimit]
  );

  return (
    <>
      {fields.map((field) => {
        switch (field) {
          case PlanFormFields.PlanName:
            return (
              <fieldset className={styles.fieldset} key={field}>
                <label className={styles.label}>{t("plan.type.label")}</label>
                <Dropdown
                  render={({ children }) => (
                    <Input
                      value={
                        planName
                          ? t(`common:customers.plans.${planName}`).toString()
                          : ""
                      }
                      placeholder={t("plan.type.placeholder")}
                      variant={Input.variants.Secondary}
                      className={styles.dropdown}
                      postfix={<div>{children}</div>}
                      postfixClassName="pointer-events-none"
                      visibility="instant"
                      readOnly
                      error={!!submitCount && errors.planName}
                    />
                  )}
                  placement={Dropdown.placements.BottomEnd}
                  popoverClassName="w-full"
                  name="planName"
                >
                  {Object.keys(planCredits).map((option) => (
                    <Dropdown.Item
                      key={option}
                      onSelect={() => {
                        setValues({
                          ...values,
                          planName: option as SubscriptionPlanName,
                          creditLimit:
                            option === SubscriptionPlanName.Custom
                              ? ""
                              : planCredits?.[option as SubscriptionPlanName],
                        });
                      }}
                      selected={planName === option}
                    >
                      {t(`common:customers.plans.${option}`)}
                    </Dropdown.Item>
                  ))}
                </Dropdown>
                {!!submitCount && errors.planName && (
                  <ErrorMessage
                    message={t(...parseError(errors.planName)).toString()}
                    className={styles.error}
                  />
                )}
              </fieldset>
            );
          case PlanFormFields.AccountStatus:
            return (
              <fieldset className={styles.fieldset} key={field}>
                <label className={styles.label}>{t("plan.status.label")}</label>
                <Dropdown
                  render={({ children }) => (
                    <Input
                      value={
                        accountStatus
                          ? t(
                              `common:customers.status.${accountStatus}`
                            ).toString()
                          : ""
                      }
                      placeholder={t("plan.status.placeholder")}
                      variant={Input.variants.Secondary}
                      className={styles.dropdown}
                      postfix={<div>{children}</div>}
                      readOnly
                      postfixClassName="pointer-events-none"
                      visibility="instant"
                      error={!!submitCount && errors.accountStatus}
                    />
                  )}
                  placement={Dropdown.placements.BottomEnd}
                  popoverClassName="w-full"
                >
                  {Object.values(SubscriptionPlanStatus).map((option) => (
                    <Dropdown.Item
                      key={option}
                      onSelect={() => setFieldValue("accountStatus", option)}
                      selected={accountStatus === option}
                    >
                      {t(`common:customers.status.${option}`)}
                    </Dropdown.Item>
                  ))}
                </Dropdown>
                {!!submitCount && errors.accountStatus && (
                  <ErrorMessage
                    message={t(...parseError(errors.accountStatus)).toString()}
                    className={styles.error}
                  />
                )}
              </fieldset>
            );
          case PlanFormFields.CreditLimit:
          case PlanFormFields.IntegratedCreditLimit:
            return (
              <fieldset className={styles.fieldset} key={field}>
                <label className={styles.label}>{t("plan.limit.label")}</label>
                <Input
                  variant={Input.variants.Secondary}
                  placeholder={t("plan.limit.placeholder")}
                  value={creditLimit ?? ""}
                  name="creditLimit"
                  error={!!submitCount && errors.creditLimit}
                  onChange={({ target: { value } = {} }) =>
                    setFieldValue(
                      "creditLimit",
                      formatNumberValue(value, { allowZero: true })
                    )
                  }
                  disabled={
                    planName &&
                    planName !== SubscriptionPlanName.Custom &&
                    field !== PlanFormFields.IntegratedCreditLimit
                  }
                  onBlur={handleBlur}
                />
                {!!submitCount && errors.creditLimit && (
                  <ErrorMessage
                    message={t(...parseError(errors.creditLimit)).toString()}
                    className={styles.error}
                  />
                )}
              </fieldset>
            );
          case PlanFormFields.RenewalDate:
            return (
              <fieldset className={styles.fieldset} key={field}>
                <label className={styles.label}>
                  {t("plan.expirationDate.label")}
                </label>

                <Dropdown
                  onSelect={(data) => setFieldValue("renewalDate", data)}
                  placement={Dropdown.placements.TopStart}
                  render={({ children }) => (
                    <Input
                      value={renewalDate ? formatDate(renewalDate) : ""}
                      variant={Input.variants.Secondary}
                      postfix={<div>{children}</div>}
                      className={styles.dropdown}
                      placeholder={t("plan.expirationDate.placeholder")}
                      postfixClassName="pointer-events-none"
                      visibility="instant"
                      readOnly
                      error={!!submitCount && errors.renewalDate}
                    />
                  )}
                >
                  <Dropdown.Datepicker
                    minDate={tomorrow}
                    openToDate={inAYear}
                  />
                </Dropdown>

                {!!submitCount && errors.renewalDate && (
                  <ErrorMessage
                    message={t(...parseError(errors.renewalDate)).toString()}
                    className={styles.error}
                  />
                )}
              </fieldset>
            );
          default:
            return null;
        }
      })}
    </>
  );
};
