import clsx from "clsx";
import { forwardRef, ReactNode, useMemo } from "react";
import {
  MentionsInput,
  Mention,
  MentionsInputProps,
  SuggestionDataItem,
} from "react-mentions";
import { useGetMentionableUsersQuery } from "api/graphql";
import { getFullName } from "utils/users";
import { Loader } from "@CreativelySquared/uikit";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

export const MentionsTextarea = forwardRef<
  HTMLTextAreaElement,
  Omit<
    MentionsInputProps,
    "onChange" | "children" | "className" | "classNames"
  > & {
    onChange?: (value: string, mentions: string[]) => void;
    containerClassName?: string;
    inputClassName?: string;
    organizationId?: string;
    projectId?: string;
  }
>(
  (
    {
      onChange,
      containerClassName,
      inputClassName,
      organizationId,
      projectId,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation();

    const { data, loading } = useGetMentionableUsersQuery({
      variables: {
        organizationId,
        projectId,
      },
      skip: !(organizationId || projectId),
    });

    const users = useMemo(() => {
      const usersArray =
        (data?.users
          ?.map(
            (user) =>
              user && {
                id: user?.userId,
                display: getFullName(user),
              }
          )
          ?.filter((user) => !!user && user.display) as SuggestionDataItem[]) ||
        [];

      return usersArray?.length ? usersArray : [{ id: "", display: "" }];
    }, [data?.users]);

    return (
      <MentionsInput
        inputRef={ref}
        {...(props as any)}
        onChange={(_event, newValue, _plainText, mentions) => {
          onChange?.(
            newValue,
            mentions.map((mention) => mention.id)
          );
        }}
        allowSuggestionsAboveCursor
        className="mentions"
        classNames={{
          mentions: clsx(containerClassName, styles.container),
          mentions__control: "h-full",
          mentions__highlighter: clsx(
            inputClassName,
            "box-border overflow-hidden !m-0 h-full resize-none",
            props.readOnly && "!border-0"
          ),
          mentions__input: inputClassName,
          mentions__suggestions__list: "z-[100]",
          mentions__suggestions__item: "!py-4 px-7 hover:bg-grey break-words",
          "mentions__suggestions__item--focused": "bg-grey",
        }}
        customSuggestionsContainer={(children: ReactNode) => (
          <div className={clsx(styles.list, loading && styles.loading)}>
            {loading ? (
              <Loader radius={50} className="m-auto" />
            ) : users.length === 1 && users[0].id === "" ? (
              <div className={styles.empty}>
                <p>{t("user.list.empty.title")}</p>
              </div>
            ) : (
              children
            )}
          </div>
        )}
      >
        <Mention
          trigger="@"
          data={users}
          displayTransform={(_id, display) => `@${display}`}
          className="bg-grey p-1 -m-1"
        />
      </MentionsInput>
    );
  }
);
