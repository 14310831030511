import { Input } from "@CreativelySquared/uikit";
import { ErrorMessage, OptionCard, SupportGuide } from "components";
import { DetailedHTMLProps, FC, HTMLAttributes } from "react";
import { useTranslation } from "react-i18next";
import { OrganizationType } from "api/enums";
import { useFormikContext } from "formik";
import { parseError } from "utils/form";
import clsx from "clsx";
import { AccountOrganizationSelector } from "Customers/Accounts/components/AccountOrganizationSelector";

import { FormData } from "../../types";
import styles from "../../styles.module.scss";

type Props = { defaultOrganizationId?: string } & DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

enum AccountVariant {
  Independent = "independent",
  PartOrganization = "partOrganization",
}

export const Details: FC<Props> = ({ defaultOrganizationId, ...props }) => {
  const { t } = useTranslation("accounts");
  const {
    submitCount,
    setFieldValue,
    values: { accountType, name, email, domain, organization },
    handleChange,
    errors,
    handleBlur,
  } = useFormikContext<FormData>();

  return (
    <div {...props}>
      <p className="text-l font-bold">{t("create.details.label")}</p>
      <div className="flex flex-col min-w-[500px]">
        <fieldset className={styles.fieldset}>
          <label className={styles.label}>
            {t("create.details.name.label")}
          </label>
          <Input
            variant={Input.variants.Secondary}
            placeholder={t("create.details.name.placeholder")}
            value={name}
            name="name"
            error={!!submitCount && errors.name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {!!submitCount && errors.name && (
            <ErrorMessage
              message={t(...parseError(errors.name)).toString()}
              className={styles.error}
            />
          )}
        </fieldset>
        <fieldset className={styles.fieldset}>
          <label className={styles.label}>
            {t("create.details.email.label")}
          </label>
          <Input
            variant={Input.variants.Secondary}
            placeholder={t("create.details.email.placeholder")}
            value={email}
            name="email"
            error={!!submitCount && errors.email}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {!!submitCount && errors.email && (
            <ErrorMessage
              message={t(...parseError(errors.email)).toString()}
              className={styles.error}
            />
          )}
        </fieldset>
        <fieldset className={styles.fieldset}>
          <div className="flex items-center mb-6">
            <label className={clsx(styles.label, "!mb-0")}>
              {t("create.details.domain.label")}
            </label>
            <SupportGuide size={SupportGuide.sizes.Small} className="ml-3">
              <div className="w-[320px]">
                {t("common:workspaceDomain.tooltip")}
              </div>
            </SupportGuide>
          </div>
          <Input
            variant={Input.variants.Secondary}
            placeholder={t("create.details.domain.placeholder")}
            value={domain}
            name="domain"
            error={!!submitCount && errors.domain}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {!!submitCount && errors.domain && (
            <ErrorMessage
              message={t(...parseError(errors.domain)).toString()}
              className={styles.error}
            />
          )}
        </fieldset>
        <fieldset className={styles.fieldset}>
          <label className={styles.label}>
            {t("create.details.accountType.label")}
          </label>
          <div className="flex gap-4 grid grid-cols-2">
            {Object.values(AccountVariant).map((type) => {
              const isStandalone = accountType === OrganizationType.Standalone;

              return (
                <OptionCard
                  key={type}
                  isSelected={
                    (type === AccountVariant.Independent && isStandalone) ||
                    (type === AccountVariant.PartOrganization && !isStandalone)
                  }
                  onClick={() =>
                    setFieldValue(
                      "accountType",
                      type === AccountVariant.Independent
                        ? OrganizationType.Standalone
                        : isStandalone
                        ? OrganizationType.Connected
                        : accountType
                    )
                  }
                  title={t(`create.details.accountType.${type}.title`)}
                  description={t(
                    `create.details.accountType.${type}.description`
                  )}
                  className="h-full"
                  disabled={!!defaultOrganizationId}
                />
              );
            })}
          </div>
        </fieldset>
        {accountType !== OrganizationType.Standalone && (
          <fieldset className={styles.fieldset}>
            <label className={styles.label}>
              {t("create.details.organization.label")}
            </label>
            <AccountOrganizationSelector
              organizationId={
                defaultOrganizationId ??
                organization?.masterOrganizationId ??
                ""
              }
              disabled={!!defaultOrganizationId}
              onSelect={(value) => setFieldValue("organization", value)}
              error={
                !!submitCount
                  ? t(...parseError(errors.organization)).toString()
                  : undefined
              }
              dropdownClassName="font-regular text-base"
              accountType={accountType}
              subscriptionPlan={organization?.subscriptionPlan}
            />
          </fieldset>
        )}
      </div>
    </div>
  );
};
