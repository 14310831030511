import { memo } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "@CreativelySquared/uikit";
import { ModalTitle } from "components/ModalTitle";
import { ModalDescription } from "components/ModalDescription";
import { ReactComponent as SuccessIcon } from "images/checkCircle.svg";

type Props = {
  visible: boolean;
  onClose: () => void;
};

export const SuccessModal = memo<Props>(({ visible, onClose }) => {
  const { t } = useTranslation("common");

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      className="flex items-center text-center flex-col w-full max-w-[460px]"
    >
      <SuccessIcon className="stroke-green stroke-[1.2] fill-white w-11 h-11" />
      <ModalTitle className="text-green mt-4">
        {t("comments.form.success.title")}
      </ModalTitle>
      <ModalDescription>
        {t("comments.form.success.description")}
      </ModalDescription>
      <Button
        variant={Button.variants.Cancel}
        type="button"
        onClick={onClose}
        outlined
      >
        {t("common:actions.close")}
      </Button>
    </Modal>
  );
});
