import { boolean, object, string } from "yup";
import { useNotification } from "components";
import { useCallback } from "react";
import { ApolloError } from "@apollo/client";
import { UserErrorCodes } from "utils/users";
import { useTranslation } from "react-i18next";

export const newUserValidationSchema = object({
  isEmailEditable: boolean(),
  email: string().when("isEmailEditable", {
    is: true,
    then: string()
      .required("common:validation.required")
      .email("common:validation.email"),
  }),
});

export const useNewUserErrorsHandler = () => {
  const { setNotification, notificationTypes } = useNotification();
  const { t } = useTranslation("common");

  return useCallback((error: unknown) => {
    if (
      error instanceof ApolloError &&
      error.graphQLErrors?.[0]?.extensions?.code ===
        UserErrorCodes.UserAlreadyInOrganizationError
    ) {
      setNotification({
        message: t("user.newUser.errors.exists"),
        type: notificationTypes.Error,
      });
    } else {
      setNotification({
        message: (error as ApolloError).message ?? t("common.error"),
        type: notificationTypes.Error,
      });
    }
  }, []);
};
