import { useProtect } from "utils/hooks/protection";
import { Roles } from "utils/roles";
import { links } from "App";
import { accesses } from "utils/accesses";
import { ProjectNodeFragment } from "api/types";

import { isProjectEditable } from "./types";

export const useProjectNavigation = () => {
  const getAccess = useProtect();
  const isAdmin = getAccess(Roles.cs_admins);

  const getProjectNavigationLink = (project: ProjectNodeFragment) => {
    if (isAdmin) {
      return links.ProjectDetails({ id: project.projectId });
    }
    if (getAccess(accesses.gallery.bind(null, project))) {
      return links.ProjectGallery({ id: project.projectId });
    }

    return isProjectEditable(project.status)
      ? links.Brief({ id: project.projectId })
      : links.BriefSummary({ id: project.projectId });
  };

  return getProjectNavigationLink;
};
