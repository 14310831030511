import { memo, useEffect, useState } from "react";
import { Media } from "components/Media";
import clsx from "clsx";
import { fileToBase64 } from "utils/file";
import { Loader } from "@CreativelySquared/uikit";
import { useTranslation } from "react-i18next";
import { ReactComponent as ImageIcon } from "images/image.svg";

const getInitials = (name: string) =>
  name
    .split(" ")
    .map((word) => word[0])
    .slice(0, 2)
    .join(" ");

import styles from "./styles.module.scss";

type Props = {
  src?: string;
  name?: string;
  highlight?: boolean;
  onUpdate?: (data: File) => Promise<any>;
  error?: string;
  loading?: boolean;
  className?: string;
};

const MAX_SIZE = 10000000;
const imageFormats = ["image/jpg", "image/jpeg", "image/png", "image/gif"];

export const Avatar = memo<Props>(
  ({
    src,
    name = "",
    loading,
    error: defaultError = null,
    className,
    onUpdate,
    highlight,
  }) => {
    const { t } = useTranslation();
    const [image, setImage] = useState<string | null>();
    const [error, setError] = useState<string | null>(defaultError);

    useEffect(() => {
      setImage(null);
    }, [src]);

    useEffect(() => setError(defaultError), [defaultError]);

    return (
      <section
        className={clsx(
          styles.avatar,
          { [styles.avatarEditable]: !!onUpdate },
          className
        )}
      >
        {(image || loading) && (
          <div className={styles.avatarLoader}>
            <Loader radius={50} />
          </div>
        )}

        {!image && (
          <label htmlFor={onUpdate && "upload-avatar"}>
            {!src && (
              <div className={styles.avatarPlaceholder}>
                {name ? getInitials(name) : <ImageIcon />}
              </div>
            )}
            {src && (
              <Media
                alt={name}
                className={clsx(styles.avatarPicture, {
                  [styles.avatarPictureHighlight]: highlight,
                })}
                url={src}
              />
            )}
          </label>
        )}
        {image && (
          <img className={styles.avatarPicture} src={image} alt={name} />
        )}
        {error && <p className={styles.avatarUploadError}>{error}</p>}
        {onUpdate && (
          <section className={styles.avatarUpload}>
            {!error && (
              <label htmlFor="upload-avatar">{t("avatar.update")}</label>
            )}
            <input
              id="upload-avatar"
              multiple={false}
              accept={imageFormats.join()}
              onChange={async (event) => {
                if (!event.target.files?.length) return;
                if (event.target.files[0]?.size > MAX_SIZE) {
                  return setError(
                    t("avatar.errors.size", {
                      size: (MAX_SIZE / 1000000).toFixed(2),
                    })
                  );
                }
                if (!imageFormats.includes(event.target.files[0]?.type)) {
                  return setError(t("avatar.errors.type"));
                }
                setError(null);
                const image = await fileToBase64(event.target.files[0]);
                setImage(image);

                onUpdate(event.target.files[0]);
              }}
              className={styles.avatarUpload}
              type="file"
              name="avatar"
            />
          </section>
        )}
      </section>
    );
  }
);
