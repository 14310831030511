import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ReactElement } from "react";

import { TableBody } from "./TableBody";
import { TableHead } from "./TableHead";

interface Props<D> {
  data: D[];
  columns: ColumnDef<D>[];
  rowClassName?: string;
}

export const Table = <D,>({
  data,
  columns,
  rowClassName,
}: Props<D>): ReactElement => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <table className="w-full">
      <TableHead data={table.getHeaderGroups()} />
      <TableBody data={table.getRowModel().rows} rowClassName={rowClassName} />
    </table>
  );
};
