import { compose, Route } from "react-router-hoc";
import { idRegEx } from "utils/regex";
import { Roles } from "utils/roles";
import { ProtectedRoute } from "utils/route";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useAccountNotesQuery } from "api/graphql";
import { Note, NoteForm } from "components/Note";
import { Loader } from "@CreativelySquared/uikit";
import { orderBy } from "lodash";
import { useMemo } from "react";

import styles from "./styles.module.scss";

export const AccountNotesRoute = compose(
  ProtectedRoute({ access: Roles.cs_admins }),
  Route(
    {
      id: Route.params.regex(idRegEx),
    },
    ({ id }) => `/customers/accounts/${id}/notes`
  )
);

export const AccountNotes = AccountNotesRoute(
  ({
    match: {
      params: { id },
    },
  }) => {
    const { t } = useTranslation("accounts");

    const { data: { getOrganizationInternalNotes } = {}, loading } =
      useAccountNotesQuery({
        variables: { organizationId: id, includeDeleted: false },
      });

    const notes = useMemo(
      () =>
        getOrganizationInternalNotes &&
        orderBy(getOrganizationInternalNotes, ["createdAt"], ["desc"]),
      [getOrganizationInternalNotes]
    );

    return (
      <main className="mt-10">
        <Helmet title={t("notes.title")} />

        {loading && (
          <div className="flex justify-center">
            <Loader radius={50} className="mt-6" />
          </div>
        )}

        <section className={styles.customerNotes}>
          {notes?.length === 0 && (
            <section className={styles.customerNotesEmpty}>
              <h2>{t("notes.empty.title")}</h2>
              <span>{t("notes.empty.description")}</span>
            </section>
          )}

          {notes?.length !== 0 && (
            <section>
              {notes?.map(
                (note) =>
                  note && (
                    <Note
                      border
                      data={note}
                      key={note?.organizationInternalNoteId}
                      enableMentioning={false}
                      enableAttaching={false}
                    />
                  )
              )}
            </section>
          )}

          {!loading && (
            <NoteForm
              noteType={Note.types.OrganizationInternalNote}
              targetId={id}
              enableMentioning={false}
              enableAttaching={false}
            />
          )}
        </section>
      </main>
    );
  }
);
