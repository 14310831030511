import { Link, NavLink } from "react-router-dom";
import { FC, memo, Suspense, useCallback, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Roles } from "utils/roles";
import { Button } from "@CreativelySquared/uikit";
import { Protect } from "components/Protect/Protect";
import logo from "images/logo.svg";
import { links } from "App";
import clsx from "clsx";
import { createInjection } from "components/Injector";
import { HelpCenter } from "components/HelpCenter";
import { Features } from "api/features";
import { ReactComponent as ArrowIcon } from "images/arrowLeft.svg";
import { useUserAccountStatus } from "utils/hooks/user";
import { SubscriptionPlanStatus } from "api/enums";
import { Restriction, RestrictionModal } from "components/Restriction";

import { Profile } from "../Profile";

import { icons } from "./icons";
import { ReactComponent as ViewOnlyIcon } from "./images/view-only.svg";

import styles from "./styles.module.scss";

const SidebarInjection = createInjection("sidebar");

const SidebarBackButton: FC<{ to?: string }> = ({ to = links.Projects() }) => {
  const { t } = useTranslation("common");

  return (
    <Link
      className="self-start mb-8 block -ml-[20px] sticky top-0 bg-white"
      to={to}
    >
      <Button outlined borderless>
        <ArrowIcon className="mr-5 w-7 h-7" /> {t("navigation.back")}
      </Button>
    </Link>
  );
};

export const Sidebar = Object.assign(
  memo(() => {
    const { t } = useTranslation();
    const accountStatus = useUserAccountStatus();

    const [isRestrictionVisible, setRestrictionVisible] = useState(false);

    const toggleRestrictionVisible = useCallback(
      () => setRestrictionVisible((prev) => !prev),
      []
    );

    return (
      <div className={styles.sidebar}>
        <Link to="/">
          <img alt={t("common.title")} className={styles.logo} src={logo} />
        </Link>
        <SidebarInjection.Area className={styles.sidebarInjected} />
        <nav className={styles.listWrapper}>
          <ul className={styles.list}>
            <Suspense
              fallback={
                <li className={styles.listItem}>
                  <div className={styles.sidebarLoading}>
                    <div className={styles.sidebarLoadingIcon} />
                    <div className={clsx(styles.sidebarLoadingText, "ml-7")} />
                  </div>
                </li>
              }
            >
              <Protect access={Roles.cs_admins}>
                <li className={styles.listItem}>
                  <NavLink
                    activeClassName={styles.listLinkActive}
                    className={styles.listLink}
                    to={links.Dashboard()}
                  >
                    <icons.dashboard className={styles.icon} />
                    <span className="ml-6">{t("navigation.dashboard")}</span>
                  </NavLink>
                </li>
              </Protect>
              <Protect access={Roles.customer}>
                <li className={styles.listItem}>
                  <NavLink
                    activeClassName={styles.listLinkActive}
                    className={styles.listLink}
                    to={links.CustomerDashboard()}
                  >
                    <icons.dashboard className={styles.icon} />
                    <span className="ml-6">
                      {t("navigation.customerdashboard")}
                    </span>
                  </NavLink>
                </li>
              </Protect>
              <Protect access={Roles.cs_admins}>
                <li className={styles.listItem}>
                  <NavLink
                    activeClassName={styles.listLinkActive}
                    className={styles.listLink}
                    to={links.Customers()}
                  >
                    <icons.customers className={styles.icon} />
                    <span className="ml-6">{t("navigation.customers")}</span>
                  </NavLink>
                </li>
              </Protect>
              <Protect access={Roles.authorized}>
                <li className={styles.listItem}>
                  <NavLink
                    activeClassName={styles.listLinkActive}
                    className={styles.listLink}
                    to={links.Projects()}
                  >
                    <icons.projects className={styles.icon} />
                    <span className="ml-6">{t("navigation.projects")}</span>
                  </NavLink>
                </li>
              </Protect>
              <Protect access={Roles.authorized}>
                <li className={styles.listItem}>
                  <NavLink
                    activeClassName={styles.listLinkActive}
                    className={styles.listLink}
                    to={links.Brands()}
                  >
                    <icons.brands className={styles.icon} />
                    <span className="ml-6">{t("navigation.brands")}</span>
                  </NavLink>
                </li>
              </Protect>
              <Protect access={Roles.authorized} feature={Features.dam_page}>
                <li className={styles.listItem}>
                  <NavLink
                    activeClassName={styles.listLinkActive}
                    className={styles.listLink}
                    to={links.DAM()}
                  >
                    <icons.dam className={styles.icon} />
                    <span className="ml-6">{t("navigation.dam")}</span>
                  </NavLink>
                </li>
              </Protect>
              <Protect access={Roles.authorized}>
                <li className={styles.listItem}>
                  <NavLink
                    activeClassName={styles.listLinkActive}
                    className={clsx(
                      styles.listLink,
                      accountStatus === SubscriptionPlanStatus.Trial &&
                        styles.blocked
                    )}
                    to={links.AITools()}
                    // TODO: replace trial with view only
                    onClick={
                      accountStatus === SubscriptionPlanStatus.Trial
                        ? (e) => {
                            e.preventDefault();
                            toggleRestrictionVisible();
                          }
                        : undefined
                    }
                  >
                    <icons.aitools className={styles.icon} />
                    <span className="ml-6">{t("navigation.aitools")}</span>
                  </NavLink>
                </li>
              </Protect>
              <Protect access={Roles.authorized}>
                <li className={styles.listItem}>
                  <NavLink
                    activeClassName={styles.listLinkActive}
                    className={styles.listLink}
                    to={links.Activity()}
                  >
                    <icons.activity className={styles.icon} />
                    <span className="ml-6">{t("navigation.activity")}</span>
                  </NavLink>
                </li>
              </Protect>
            </Suspense>
          </ul>
        </nav>
        <Protect access={Roles.customer}>
          <HelpCenter className={styles.additionalItem} />
        </Protect>
        <div className={styles.profile}>
          <Profile />
        </div>
        <RestrictionModal
          visible={isRestrictionVisible}
          onClose={toggleRestrictionVisible}
          Image={ViewOnlyIcon}
          title={t("restriction.viewOnly.title")}
          description={
            <Trans
              t={t}
              i18nKey={"restriction.viewOnly.description"}
              components={{ br: <br /> }}
            />
          }
          actions={[Restriction.actions.sales]}
        />
      </div>
    );
  }),
  {
    display: "Sidebar",
    Inject: SidebarInjection.Inject,
    BackButton: SidebarBackButton,
  }
);
