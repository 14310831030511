import {
  AssetComment,
  BriefComment,
  Comment,
  OrganizationInternalNote,
  ProjectInternalNote,
  User,
} from "api/types";
import clsx from "clsx";
import { Button, Card, Modal } from "@CreativelySquared/uikit";
import { Protect } from "components/Protect";
import { ActiveRegionType } from "components/Media";
import { Avatar } from "components/Avatar";
import { DeleteNote } from "components/DeleteNote";
import { GetProps } from "react-router-hoc/lib/types";
import { format, formatDuration, intervalToDuration } from "date-fns";
import { memo, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import type { Object as ObjectType } from "ts-toolbelt";
import { AssetPreview } from "Projects/components/AssetPreview";
import { useProtect } from "utils/hooks/protection";
import { ReactComponent as BinIcon } from "images/bin.svg";
import { ReactComponent as EditIcon } from "images/edit.svg";
import { isNumber } from "lodash";
import { ReactComponent as PinIcon } from "images/pushpin.svg";
import { BriefCommentMetadata } from "Brief/utils/types";
import { BriefContext } from "Brief/brief.context";
import { getFullName } from "utils/users";
import { isActiveSection } from "utils/comparison";
import { FileStatuses } from "utils/types";
import { useRemoveAttachmentMutation } from "api/graphql";
import { useNotification } from "components/Notifications";
import { Reference } from "@apollo/client";
import { Roles } from "utils/roles";

import { NoteType } from "./types";
import { NoteForm } from "./NoteForm";
import { MentionsTextarea } from "./MentionsTextarea";
import { Attachments } from "./Attachments";

import styles from "./styles.module.scss";

enum Mode {
  DELETE = "delete",
  EDIT = "edit",
  REPLY = "reply",
}

type Props = {
  border?: boolean;
  regionNumber?: number;
  readOnly?: boolean;
  onSelect?: (region: ActiveRegionType) => void;
  allowReplies?: boolean;
  targetId?: string;
  active?: boolean;
  briefComment?: BriefCommentMetadata;
  enableMentioning?: boolean;
  enableAttaching?: boolean;
  organizationId?: string;
  projectId?: string;
  data:
    | ObjectType.Partial<AssetComment, "deep">
    | ObjectType.Partial<BriefComment, "deep">
    | ObjectType.Partial<Comment, "deep">
    | ObjectType.Partial<ProjectInternalNote, "deep">
    | ObjectType.Partial<OrganizationInternalNote, "deep">;
} & Omit<GetProps<typeof Card>, "onSelect">;

export const Note = Object.assign(
  memo<Props>(
    ({
      border = false,
      className,
      regionNumber,
      readOnly,
      onSelect,
      allowReplies,
      targetId,
      active,
      briefComment,
      enableMentioning = true,
      enableAttaching = true,
      data: {
        content,
        createdAt,
        user,
        userId,
        __typename,
        deletedAt,
        ...rest
      },
      ...props
    }) => {
      const { t } = useTranslation("common");
      const [mode, setMode] = useState<Mode>();
      const { setNotification, notificationTypes } = useNotification();
      const onClose = () => setMode(undefined);
      const [hover, setHover] = useState(false);
      const { activeSection, setActiveSection } = useContext(BriefContext);
      const fullName = getFullName(user);
      const isAdmin = useProtect({ user })(Roles.cs_admins);

      const [removeAttachment] = useRemoveAttachmentMutation({
        onError(error) {
          setNotification({
            message: error?.message,
            type: notificationTypes.Error,
          });
        },
        optimisticResponse: ({ commentId, fileId }) => ({
          removeAttachment: {
            commentId,
            attachments: attachments.filter(
              (attachment) => attachment?.fileId !== fileId
            ),
            __typename: "Comment",
          },
        }),
        update(cache, { data }, { variables }) {
          // will be removed with Comment refactoring
          if (!data?.removeAttachment?.commentId) return;

          cache.modify({
            id: `BriefComment:{"briefCommentId":"${variables?.commentId}"}`,
            fields: {
              attachments(existingAttachments = [], { readField }) {
                return existingAttachments.filter(
                  (attachment: Reference) =>
                    readField("fileId", attachment) !== variables?.fileId
                );
              },
            },
          });

          cache.modify({
            id: `AssetComment:{"assetCommentId":"${variables?.commentId}"}`,
            fields: {
              attachments(existingAttachments = [], { readField }) {
                return existingAttachments.filter(
                  (attachment: Reference) =>
                    readField("fileId", attachment) !== variables?.fileId
                );
              },
            },
          });
        },
      });

      const noteType = NoteType[__typename!];

      const isComment = ["AssetComment", "BriefComment", "Comment"].includes(
        __typename as NoteType
      );
      const replies = [...((rest as AssetComment)?.replies ?? [])]?.sort(
        (current, next) => Number(current?.createdAt) - Number(next?.createdAt)
      );

      // Need to be refactored
      const attachments = [...((rest as AssetComment)?.attachments ?? [])];

      const noteId =
        __typename === "OrganizationInternalNote"
          ? (rest as OrganizationInternalNote).organizationInternalNoteId
          : isComment
          ? (rest as AssetComment).assetCommentId ??
            (rest as BriefComment).briefCommentId ??
            (rest as Comment).commentId
          : (rest as ProjectInternalNote).projectInternalNoteId;

      const titleDetails = isComment
        ? (rest as AssetComment).organization?.name
        : (rest as ProjectInternalNote).user?.role;

      // TODO: It's not an id but a project object!
      const projectId =
        __typename !== "OrganizationInternalNote"
          ? (rest as ProjectInternalNote)?.projectId
          : undefined;

      const briefId =
        __typename === "BriefComment"
          ? (rest as BriefComment)?.briefId
          : undefined;

      const metadata = (rest as AssetComment)?.extra;

      const asset = isComment && (rest as AssetComment)?.asset;
      const assetVersionId = (rest as AssetComment)?.assetVersionId ?? "";
      const editable =
        useProtect()(
          ({ id }) =>
            (isComment ? user?.userId === id : userId === id) && !deletedAt
        ) && !readOnly;
      const time = (rest as AssetComment)?.extra?.time;
      const onRegionSelect = () => onSelect?.({ index: regionNumber, time });

      const formattedTime = formatDuration(
        intervalToDuration({ start: 0, end: (time ?? 0) * 1000 }),
        {
          format: ["hours", "minutes", "seconds"],
          delimiter: ":",
          zero: true,
          locale: {
            formatDistance: (_token, num) => String(num).padStart(2, "0"),
          },
        }
      );
      const hasThread = !!replies?.length;
      const isUserExists = user?.userId;
      const isActive = Boolean(
        active || isActiveSection(activeSection, briefComment)
      );

      return (
        <>
          <Card
            {...props}
            className={clsx(
              styles.note,
              {
                [styles.hasPreview]: !!asset,
                [styles.hasBorder]: border,
                [styles.hasThread]: hasThread,
                [styles.active]: isActive,
              },
              className
            )}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            variant={Card.variants.Card}
          >
            {editable && mode !== Mode.EDIT && (
              <Card.Details
                className={clsx(styles.details, {
                  "-mt-8": targetId,
                  "opacity-100": hover,
                })}
                position="top"
              >
                <Button
                  className={styles.button}
                  onClick={() => setMode(Mode.EDIT)}
                  variant={Button.variants.Icon}
                >
                  <EditIcon className="w-7 h-7 stroke-[1.5]" />
                </Button>

                <Button
                  className={clsx(styles.button)}
                  onClick={() => {
                    setMode(Mode.DELETE);
                    onSelect?.(null);
                  }}
                  variant={Button.variants.Icon}
                >
                  <BinIcon className="w-7 h-7 stroke-[1.5]" />
                </Button>
              </Card.Details>
            )}

            <Card.Title
              className={clsx([styles.title], {
                [styles.titleEditable]: editable,
              })}
            >
              <Avatar
                src={(user as User)?.avatar?.thumbnail?.downloadUrl?.url}
                className={styles.avatar}
                name={fullName}
                highlight={
                  isAdmin &&
                  [NoteType.AssetComment, NoteType.BriefComment].includes(
                    noteType
                  )
                }
              />
              {regionNumber && (
                <button
                  type="button"
                  className={styles.regionNumber}
                  onClick={onRegionSelect}
                >
                  {regionNumber}
                </button>
              )}
              {briefComment && (
                <button
                  type="button"
                  className={styles.regionSection}
                  onClick={() =>
                    setActiveSection?.(isActive ? null : briefComment)
                  }
                >
                  <PinIcon />
                </button>
              )}
              <div>
                <p className="words-break">
                  {isUserExists ? fullName : t("notes.user.deleted")}
                </p>
                {createdAt && (
                  <span className="words-break">
                    {format(new Date(+createdAt), "d MMMM yyyy")}
                    {isUserExists && (
                      <>
                        <small>|</small>
                        <Protect access={Protect.roles.admin} user={user}>
                          {t("notes.item.label.admin")}
                        </Protect>
                        <Protect
                          access={Protect.roles.account_manager}
                          user={user}
                        >
                          {t("notes.item.label.account_manager")}
                        </Protect>
                        <Protect access={Protect.roles.customer} user={user}>
                          {titleDetails}
                        </Protect>
                      </>
                    )}
                  </span>
                )}
              </div>
            </Card.Title>

            <Card.Description className={styles.description}>
              {asset && (
                <AssetPreview
                  asset={asset}
                  versionId={assetVersionId}
                  className={styles.preview}
                />
              )}
              <article
                className={clsx("grow", {
                  "mb-7": hasThread,
                  "ml-11": targetId || hasThread,
                  "mr-9": targetId,
                  "mr-5": !targetId,
                })}
              >
                {mode !== Mode.EDIT ? (
                  <MentionsTextarea
                    readOnly
                    value={content || undefined}
                    inputClassName="whitespace-pre-line outline-none"
                  />
                ) : (
                  <NoteForm
                    targetId={assetVersionId ?? briefId ?? projectId}
                    content={content!}
                    commentId={noteId}
                    noteType={noteType}
                    className={styles.editForm}
                    commentMetadata={metadata}
                    onClose={onClose}
                    enableMentioning={enableMentioning}
                    enableAttaching={false}
                    organizationId={props.organizationId || ""}
                    projectId={props.projectId}
                  />
                )}
                {isNumber(time) && (
                  <button
                    type="button"
                    onClick={onRegionSelect}
                    className={styles.regionTime}
                  >
                    {formattedTime}
                  </button>
                )}
                <Attachments
                  files={attachments.map((attachment) => ({
                    id: attachment?.fileId ?? "",
                    type: attachment?.originalMetadata?.common?.type ?? "",
                    src: attachment?.thumbnail?.downloadUrl?.url ?? "",
                    name: attachment?.fileName ?? "",
                    status: attachment?.status as FileStatuses,
                  }))}
                  hasDownload
                  onRemove={
                    editable
                      ? (fileId) =>
                          removeAttachment({
                            variables: { fileId, commentId: noteId },
                          })
                      : undefined
                  }
                  className="mt-7"
                />
              </article>

              {!!replies?.length && (
                <div
                  onMouseEnter={() => setHover(false)}
                  onMouseLeave={() => setHover(true)}
                  className={styles.replies}
                >
                  {replies.map((reply, key) => {
                    const isLastItem = key === replies.length - 1;
                    return (
                      reply && (
                        <Note
                          key={reply?.commentId}
                          data={reply}
                          readOnly={readOnly}
                          allowReplies={isLastItem}
                          active={isActive}
                          targetId={noteId}
                          className={clsx("py-0", {
                            "z-[1]": isLastItem,
                            "pb-7": !isLastItem,
                          })}
                          organizationId={props.organizationId || ""}
                          projectId={props.projectId}
                        />
                      )
                    );
                  })}
                </div>
              )}
              {allowReplies && !hasThread && !readOnly && (
                <div
                  className={clsx("mt-6 mb-5", {
                    "ml-11": targetId || hasThread,
                  })}
                >
                  {mode === Mode.REPLY ? (
                    <NoteForm
                      targetId={targetId || noteId}
                      noteType={NoteType.Comment}
                      onClose={onClose}
                      compact
                      className={clsx({
                        "mr-9": targetId,
                      })}
                      autofocus
                      placeholder={t(
                        `notes.reply.placeholder.${
                          targetId || !isUserExists ? "default" : "directed"
                        }`,
                        {
                          name: fullName,
                        }
                      )}
                      enableMentioning={enableMentioning}
                      enableAttaching={enableAttaching}
                      organizationId={props.organizationId || ""}
                      projectId={props.projectId}
                    />
                  ) : (
                    <Button
                      outlined
                      borderless
                      onClick={() => setMode(Mode.REPLY)}
                      type="button"
                      className="p-0 text-xs"
                    >
                      {t("notes.reply.label")}
                    </Button>
                  )}
                </div>
              )}
            </Card.Description>
          </Card>

          <Modal visible={mode === Mode.DELETE} onClose={onClose}>
            <DeleteNote id={noteId!} onClose={onClose} noteType={noteType} />
          </Modal>
        </>
      );
    }
  ),
  {
    displayName: "Note",
    types: NoteType,
  }
);
