import { CollectionSortingField } from "api/enums";
import { CollectionCardVariants } from "components";
import { lazy } from "react";
import { compose, Route } from "react-router-hoc";
import { SortOrder } from "utils/order";
import { Roles } from "utils/roles";
import { ProtectedRoute } from "utils/route";

import { CollectionTypes } from "./Toolbar/Filters";

export const CollectionsRoute = compose(
  ProtectedRoute({
    access: [Roles.cs_admins, Roles.customer],
  }),
  Route(
    {
      page: Route.query.number,
      view: Route.query.oneOf(...Object.values(CollectionCardVariants)),
      sort: Route.query.oneOf(...Object.values(CollectionSortingField)),
      sortOrder: Route.query.oneOf(...Object.values(SortOrder)),
      createdAt: Route.query.array(Route.query.string),
      types: Route.query.array(
        Route.query.oneOf(...Object.values(CollectionTypes))
      ),
      users: Route.query.array(Route.query.string),
      search: Route.query.string,
    },
    "/dam/collections"
  )
);

export const Collections = CollectionsRoute(
  lazy(() => import(/* webpackChunkName: "Collections" */ "./Collections"))
);
