// TODO: review if we need to duplicate roles here
export enum Roles {
  admin = "cs_superadmin",
  account_manager = "cs_account_manager",
  master_org_owner = "master_org_owner",
  org_admin = "org_admin",
  org_user = "org_user",
  customer = "customer",
  cs_admins = "cs_admins",
  // Old roles
  // Org owner should be deleted. Keeped for backward compatibility for now
  organization_owner = "org_owner",
  authorized = "authorized",
  unauthorized = "unauthorized",
  shared = "shared",
  subsidiary_organization = "subsidiary_organization",
}

export type AccessOptions = {
  accessToken?: string | null;
  sharedAccessToken?: string | null;
  role?: Roles | null;
  id?: string | null;
  masterOrganizationId?: string | null;
};

export type AccessFactory = (options: AccessOptions) => boolean;

export type Access = Roles | Array<AccessFactory | Roles> | AccessFactory;

export const cs_adminRoles = [Roles.admin, Roles.account_manager];
export const customerRoles = [
  Roles.master_org_owner,
  Roles.org_admin,
  Roles.org_user,
  Roles.organization_owner,
];

export const hasAccess: {
  [P in Roles]: AccessFactory;
} = {
  shared({ sharedAccessToken }) {
    return Boolean(sharedAccessToken);
  },
  cs_superadmin({ role }) {
    return role === Roles.admin;
  },
  master_org_owner({ role }) {
    return role === Roles.master_org_owner;
  },
  cs_admins({ role }) {
    return cs_adminRoles.includes(role as Roles);
  },
  customer({ role }) {
    return customerRoles.includes(role as Roles);
  },
  org_owner({ role }) {
    return Roles.organization_owner === role;
  },
  org_user({ role }) {
    return role === Roles.org_user;
  },
  org_admin({ role }) {
    return role === Roles.org_admin;
  },
  cs_account_manager({ role }) {
    return role === Roles.account_manager;
  },
  authorized({ accessToken }: AccessOptions) {
    return Boolean(accessToken);
  },
  unauthorized({ accessToken }: AccessOptions) {
    return !Boolean(accessToken);
  },
  subsidiary_organization({ masterOrganizationId, role }: AccessOptions) {
    return (
      (role === Roles.org_user || role === Roles.org_admin) &&
      Boolean(masterOrganizationId)
    );
  },
};
