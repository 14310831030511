import "./locales";
import { lazy } from "react";
import { Redirect, Switch } from "react-router";
import { Route as RRDRoute } from "react-router-dom";
import { Route } from "react-router-hoc";
import { Sidebar, SidebarNavItem } from "components";
import { links as appLinks } from "App";
import { useTranslation } from "react-i18next";
import { ReactComponent as StarIcon } from "images/star.svg";
import { ReactComponent as AIToolsIcon } from "images/thunderbolt.svg";
import { useProtect } from "utils/hooks/protection";
import { Roles } from "utils/roles";

import { MindMap } from "./MindMap";
import { ConceptGenerator } from "./ConceptGenerator";
import { ScriptWriter } from "./ScriptWriter";
import { UGCStoryboardCreator } from "./UGCStoryboardCreator";
import { UGCIdeaGenerator } from "./UGCIdeaGenerator";
import { BriefBuilder } from "./BriefBuilder";
import { SavedItems } from "./SavedItems";

export const AIToolsRoute = Route("/ai/tools");

export const AITools = AIToolsRoute(
  lazy(() => import(/* webpackChunkName: "AITools" */ "./AITools"))
);

export const links = {
  AITools,
  MindMap,
  ConceptGenerator,
  ScriptWriter,
  UGCIdeaGenerator,
  SavedItems,
  BriefBuilder,
  UGCStoryboardCreator,
};

export const route = Route("/ai")(() => {
  const { t } = useTranslation("ai");
  const isAdmin = useProtect()(Roles.cs_admins);

  return (
    <>
      <Sidebar.Inject>
        <nav>
          <Switch>
            <RRDRoute
              exact
              path={[
                links.AITools.defaultProps?.path as string,
                SavedItems.defaultProps?.path as string,
              ]}
            >
              <Sidebar.BackButton
                to={
                  isAdmin ? appLinks.Dashboard() : appLinks.CustomerDashboard()
                }
              />
            </RRDRoute>
            <RRDRoute path={links.AITools.defaultProps?.path}>
              <Sidebar.BackButton to={links.AITools.link()} />
            </RRDRoute>
          </Switch>

          <SidebarNavItem
            to={appLinks.AITools()}
            Icon={AIToolsIcon}
            label={t(`navigation.aiTools`)}
          />
          <SidebarNavItem
            to={appLinks.SavedItems()}
            Icon={StarIcon}
            label={t(`navigation.savedItems`)}
          />
        </nav>
      </Sidebar.Inject>
      <Switch>
        <AITools exact />
        <MindMap exact />
        <ConceptGenerator exact />
        <ScriptWriter exact />
        <UGCIdeaGenerator exact />
        <UGCStoryboardCreator exact />
        <BriefBuilder exact />
        <SavedItems />
        <Redirect to={AITools.link()} />
      </Switch>
    </>
  );
});
