import { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button, Input, Label, Modal } from "@CreativelySquared/uikit";
import { ModalTitle } from "components/ModalTitle";
import { ModalDescription } from "components/ModalDescription";
import { useFormik } from "formik";
import { object, string } from "yup";
import {
  GetCollectionsDocument,
  useCreateCollectionMutation,
  useProfileQuery,
} from "api/graphql";
import { getOperationName } from "@apollo/client/utilities";
import { useNotification } from "components/Notifications";
import { Asset, CollectionItemNodeFragment } from "api/types";
import clsx from "clsx";
import { ReactComponent as PrivateIcon } from "images/eyeInvisible.svg";
import { ReactComponent as PublicIcon } from "images/eye.svg";

import styles from "./styles.module.scss";

type FormData = {
  name: string;
  isPrivate: boolean;
};

type Props = {
  visible: boolean;
  onClose: () => void;
  onSubmit: (collection: CollectionItemNodeFragment) => void;
  assetsIds: Array<Asset["assetId"]>;
};

export const CreateCollection = memo<Props>(
  ({ visible, onClose, onSubmit, assetsIds }) => {
    const { t } = useTranslation("dam");
    const { setNotification, notificationTypes } = useNotification();
    const { data: { me: profile } = {}, loading: profileLoading } =
      useProfileQuery();
    const organizationId = profile?.organization?.organizationId;

    const [createCollection, { loading: createCollectionLoading }] =
      useCreateCollectionMutation({
        refetchQueries: [getOperationName(GetCollectionsDocument)!],
        awaitRefetchQueries: true,
        onError(error) {
          setNotification({
            message: error.message ?? t("common:common.error"),
            type: notificationTypes.Error,
          });
        },
      });

    const {
      handleSubmit,
      handleChange,
      handleBlur,
      resetForm,
      setFieldValue,
      isValid,
      values: { name, isPrivate },
    } = useFormik<FormData>({
      async onSubmit(values) {
        const newCollection = await createCollection({
          variables: {
            organizationId,
            collectionFields: { ...values },
            assetsIds: assetsIds as string[],
          },
        });
        if (newCollection.data?.createCollection?.collectionId) {
          resetForm();
          onSubmit(newCollection.data.createCollection);
        }
      },
      validationSchema: object({
        name: string().trim().required("common:validation.required"),
      }),
      initialErrors: { name: "" },
      initialValues: { name: "", isPrivate: false },
    });

    const handleClose = useCallback(() => {
      resetForm();
      onClose();
    }, [resetForm, onClose]);

    const loading = profileLoading || createCollectionLoading;

    return (
      <Modal
        visible={visible}
        onClose={handleClose}
        className="w-full pb-9 max-w-[545px]"
      >
        <form noValidate onSubmit={handleSubmit}>
          <ModalTitle>{t("collections.create.heading")}</ModalTitle>
          <ModalDescription>
            {t("collections.create.description")}
          </ModalDescription>
          <Input
            name="name"
            variant={Input.variants.Secondary}
            value={name}
            className="w-full"
            placeholder={t("collections.create.placeholder")}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <div className="flex gap-6 mt-7">
            <Label
              variant={Label.variants.Primary}
              className={clsx(styles.type, !isPrivate && styles.active)}
              onClick={() => setFieldValue("isPrivate", false)}
            >
              <PublicIcon />
              {t("collections.type.public")}
            </Label>
            <Label
              variant={Label.variants.Primary}
              className={clsx(styles.type, isPrivate && styles.active)}
              onClick={() => setFieldValue("isPrivate", true)}
            >
              <PrivateIcon />
              {t("collections.type.private")}
            </Label>
          </div>
          <section className="flex justify-end mt-7">
            <Button
              variant={Button.variants.Cancel}
              type="button"
              className="mr-5"
              onClick={handleClose}
              outlined
            >
              {t("common:actions.cancel")}
            </Button>
            <Button
              loading={loading}
              type="submit"
              disabled={!isValid || loading}
            >
              {t("common:actions.create")}
            </Button>
          </section>
        </form>
      </Modal>
    );
  }
);
