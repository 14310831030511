import { Button, Tabs } from "@CreativelySquared/uikit";
import { links as appLinks } from "App";
import { Route as ReactRoute } from "react-router";
import { ProjectHeaderInjector } from "Projects/components/ProjectHeaderInjector";
import { useTranslation } from "react-i18next";
import { Link, NavLink, Redirect, Switch } from "react-router-dom";
import { compose, Route } from "react-router-hoc";
import { Roles } from "utils/roles";
import { ProtectedRoute } from "utils/route";
import { useProjectQuery } from "api/graphql";
import { ConceptDeliverables } from "components";

import { SidebarInjector } from "../components/SidebarInjector";

import { ProjectGalleryUpload } from "./ProjectGalleryUpload";
import { ProjectGalleryAssign } from "./ProjectGalleryAssign";
import { ProjectGalleryCover } from "./ProjectGalleryCover";
import { ProjectGalleryComplete } from "./ProjectGalleryComplete";

export const ProjectGalleryManagementRoute = compose(
  ProtectedRoute({ access: Roles.cs_admins }),
  Route(
    {
      id: Route.params.string,
    },
    ({ id }) => `/projects/${id}/manage`
  )
);

const tabs = {
  ProjectGalleryUpload,
  ProjectGalleryAssign,
  ProjectGalleryCover,
  ProjectGalleryComplete,
};

const TabsRoute = compose(
  ProtectedRoute({ access: Roles.cs_admins }),
  Route(Object.values(tabs).map((route) => route.defaultProps?.path as string))
)(() => {
  return (
    <Switch>
      {Object.values(tabs).map((Tab, index) => (
        <Tab key={index} />
      ))}
    </Switch>
  );
});

const ProjectGalleryManagement = ProjectGalleryManagementRoute(
  ({
    match: {
      params: { id },
    },
  }) => {
    const { t } = useTranslation("projects");

    const { data: { getProject: project } = {} } = useProjectQuery({
      variables: {
        projectId: id,
        includeBrief: true,
      },
    });

    return (
      <section className="flex grow flex-col">
        <ProjectHeaderInjector>
          <Link to={appLinks.ProjectBrief({ id })}>
            <Button>{t("gallery.manage.heading.actions.brief")}</Button>
          </Link>
        </ProjectHeaderInjector>
        <SidebarInjector>
          {project?.estimatedCost?.conceptsCosts && (
            <ConceptDeliverables
              costs={project?.estimatedCost?.conceptsCosts}
            />
          )}
        </SidebarInjector>
        <Switch>
          <ReactRoute
            path={Object.values(tabs).map(
              (route) => route.defaultProps?.path as string
            )}
          >
            <Tabs>
              <Tabs.Item
                component={NavLink}
                to={appLinks.ProjectGalleryUpload({ id })}
              >
                {t("gallery.manage.tabs.upload")}
              </Tabs.Item>
              <Tabs.Item
                component={NavLink}
                to={appLinks.ProjectGalleryAssign({ id })}
              >
                {t("gallery.manage.tabs.assign")}
              </Tabs.Item>
              <Tabs.Item
                component={NavLink}
                to={appLinks.ProjectGalleryCover({ id })}
              >
                {t("gallery.manage.tabs.cover")}
              </Tabs.Item>
              <Tabs.Item
                component={NavLink}
                to={appLinks.ProjectGalleryComplete({ id })}
              >
                {t("gallery.manage.tabs.complete")}
              </Tabs.Item>
            </Tabs>
          </ReactRoute>
          <Redirect
            to={appLinks.ProjectGalleryUpload({
              id,
              conceptId: project?.brief?.concepts?.[0]?.conceptId ?? undefined,
            })}
          />
        </Switch>

        <TabsRoute />
      </section>
    );
  }
);

export const links = {
  ProjectGalleryManagement,
  ProjectGalleryUpload,
  ProjectGalleryAssign,
  ProjectGalleryCover,
  ProjectGalleryComplete,
};

export const route = ProjectGalleryManagement;
