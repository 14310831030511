import { FC, useCallback, useMemo, useState } from "react";
import { Alert, Button, Input } from "@CreativelySquared/uikit";
import { useTranslation } from "react-i18next";
import { ErrorMessage, InputLabel, ModalTitle } from "components";
import { useFormik } from "formik";
import { parseError } from "utils/form";
import { Role } from "api/enums";
import { intersection } from "lodash";
import { newUserValidationSchema } from "Customers/components/Members/utils";
import { object } from "yup";

import { RoleInput } from "./RoleInput";

import styles from "./styles.module.scss";

export interface MemberFormData {
  name?: string;
  surname?: string;
  email: string;
  roles: {
    [key in Role]?: string[];
  };
}

interface Props {
  organizationId: string;
  onSubmit: (data: MemberFormData) => Promise<any> | void;
  isSubmitting?: boolean;
  isEmailEditable?: boolean;
  initialValues?: MemberFormData;
  title?: string;
}

export const MemberForm: FC<Props> = ({
  organizationId,
  onSubmit,
  isSubmitting,
  isEmailEditable = true,
  initialValues = {
    email: "",
    roles: {},
  },
  title,
}) => {
  const { t } = useTranslation("organizations");

  const [isUpgradingRole, setUpgradingRole] = useState(false);
  const [isDowngradingRole, setDowngradingRole] = useState(false);

  const validationSchema = useMemo(() => {
    return object({
      email: newUserValidationSchema.fields.email,
      roles: object().test(
        "required",
        "common:validation.required",
        (value) => {
          return Object.values(value as MemberFormData["roles"]).some(
            (role) => role?.length
          );
        }
      ),
    });
  }, [isEmailEditable]);

  const hasDowngradeRole = useCallback(
    (newUserData: MemberFormData, currentUserData?: MemberFormData) =>
      intersection(
        currentUserData?.roles.ORG_ADMIN || [],
        newUserData.roles.ORG_USER || []
      ).length > 0,
    []
  );

  const hasUpgradeRole = useCallback(
    (newUserData: MemberFormData, currentUserData?: MemberFormData) =>
      intersection(
        currentUserData?.roles.ORG_USER || [],
        newUserData.roles.ORG_ADMIN || []
      ).length > 0,
    []
  );

  const {
    values: { email, roles },
    errors,
    submitCount,
    isValid,
    handleChange,
    setFieldValue,
    handleSubmit,
  } = useFormik<MemberFormData & { isEmailEditable: boolean }>({
    validationSchema,
    initialValues: {
      ...initialValues,
      isEmailEditable,
    },
    onSubmit(newUserData) {
      if (hasDowngradeRole(newUserData, initialValues) && !isDowngradingRole) {
        setDowngradingRole(true);

        return;
      }

      if (hasUpgradeRole(newUserData, initialValues) && !isUpgradingRole) {
        setUpgradingRole(true);

        return;
      }

      onSubmit(newUserData);
    },
  });

  return (
    <form onSubmit={handleSubmit} noValidate>
      <ModalTitle className="text-3xl mb-8">{title}</ModalTitle>
      <section>
        <h3 className="mb-7">{t("members.newMember.details.title")}</h3>
        <div className="grid grid-cols-2 gap-7">
          {initialValues.name && initialValues.email && (
            <>
              <fieldset className={styles.fieldset}>
                <InputLabel>
                  {t("members.newMember.details.name.label")}
                </InputLabel>
                <Input
                  variant={Input.variants.Secondary}
                  className="w-full"
                  value={initialValues.name}
                  disabled
                />
              </fieldset>
              <fieldset className={styles.fieldset}>
                <InputLabel>
                  {t("members.newMember.details.surname.label")}
                </InputLabel>
                <Input
                  variant={Input.variants.Secondary}
                  className="w-full"
                  value={initialValues.surname}
                  disabled
                />
              </fieldset>
            </>
          )}
          <fieldset className={styles.fieldset}>
            <InputLabel>
              {t("members.newMember.details.email.label")}
            </InputLabel>
            <Input
              variant={Input.variants.Secondary}
              placeholder={t("members.newMember.details.email.placeholder")}
              className="w-full"
              value={email}
              name="email"
              error={!!submitCount && t(...parseError(errors.email)).toString()}
              onChange={handleChange}
              disabled={!isEmailEditable}
            />
            {!!submitCount && errors.email && (
              <ErrorMessage
                message={t(...parseError(errors.email)).toString()}
                className="mt-3"
              />
            )}
          </fieldset>
        </div>
      </section>
      <div className="h-1 my-8 bg-light-blue-steel" />
      <section>
        <div className="flex items-center gap-5 mb-7">
          <h3>{t("members.newMember.roles.title")}</h3>
          {!!submitCount && errors.roles && (
            <Alert
              variant={Alert.variants.Error}
              banner={false}
              title={t(...parseError(errors.roles as string)).toString()}
            />
          )}
        </div>
        <RoleInput
          organizationId={organizationId}
          value={roles}
          onChange={(newValue) => {
            setUpgradingRole(false);
            setDowngradingRole(false);
            setFieldValue("roles", newValue);
          }}
        />
      </section>
      {isUpgradingRole && (
        <p className={styles.roleWarning}>
          {t("common:user.newUser.warnings.upgradingRole")}
        </p>
      )}
      {isDowngradingRole && (
        <p className={styles.roleWarning}>
          {t("members.editMember.downgradeRole")}
        </p>
      )}
      <Button
        type="submit"
        className="mt-7 w-full"
        loading={isSubmitting}
        disabled={(!!submitCount && !isValid) || isSubmitting}
      >
        {isUpgradingRole || isDowngradingRole
          ? t("common:actions.confirm")
          : t("common:actions.save")}
      </Button>
    </form>
  );
};
