import { useTranslation } from "react-i18next";
import { FC, useCallback, useMemo, useState } from "react";
import { Formik } from "formik";
import {
  OrganizationCreditsEvent,
  OrganizationType,
  SubscriptionPlanName,
  SubscriptionPlanStatus,
  SubscriptionPlanType,
} from "api/enums";
import { useAccountQuery, useSetSubscriptionPlanMutation } from "api/graphql";
import { useNotification } from "components";
import { getCreditLimit } from "Customers/utils/credits";
import { formatShortUTC, inAYear } from "utils/time";
import { SubscriptionErrorCodes } from "utils/subscriptions";
import {
  PlanSection,
  PlanFormData,
  UpdateCreditsFormData,
  validationSchema,
} from "Customers/components/PlanSection";
import { ApolloError } from "@apollo/client";

interface Props {
  accountId: string;
  readOnly?: boolean;
}

export const Plan: FC<Props> = ({ accountId, readOnly }) => {
  const { t } = useTranslation("accounts");
  const { setNotification, notificationTypes } = useNotification();
  const [isEdit, setEdit] = useState(false);

  const { data: { account } = {}, loading: accountLoading } = useAccountQuery({
    variables: { id: accountId },
    onError: (error) => {
      setNotification({
        message: error.message ?? t("common:common.error"),
        type: notificationTypes.Error,
      });
    },
  });

  const isIntegrated = useMemo(
    () => account?.type === OrganizationType.Integrated,
    [account?.type]
  );

  const [updateCredits, { loading: updateCreditsLoading }] =
    useSetSubscriptionPlanMutation({
      onError: (error) => {
        setNotification({
          message: error.message ?? t("common:common.error"),
          type: notificationTypes.Error,
        });
      },
      onCompleted() {
        setNotification({
          message: t("plan.balance.update.success"),
          type: notificationTypes.Success,
        });
      },
    });

  const [setPlan] = useSetSubscriptionPlanMutation();

  const defaultExpirationDate = useMemo(
    () =>
      ((account?.subscriptionPlan?.expirationDate &&
        new Date(+account?.subscriptionPlan?.expirationDate)) as Date) ??
      inAYear,
    [account?.subscriptionPlan?.expirationDate]
  );

  const toggleEdit = useCallback(() => {
    setEdit((prev) => !prev);
  }, []);

  const onUpdateBalance = useCallback(
    async (data: UpdateCreditsFormData, limit?: number | null) => {
      await updateCredits({
        variables: {
          organizationId: accountId,
          type: SubscriptionPlanType.Custom,
          credits: {
            credits: Number(data.amount),
            previousValue:
              account?.subscriptionPlan?.credits ?? Number(data.amount) ?? 0,
            type: data.reason,
          },
          custom: { creditsLimit: limit ?? 0 },
        },
      });
    },
    [account?.subscriptionPlan?.credits]
  );

  return (
    <Formik<PlanFormData>
      onSubmit={async (data) => {
        try {
          await setPlan({
            variables: {
              organizationId: accountId,
              type: SubscriptionPlanType.Custom,
              expirationDate: formatShortUTC(data.planExpirationDate),
              name: data.planType,
              status: data.planStatus,
              custom: {
                creditsLimit: data.planLimit ?? 0,
              },
              credits: {
                credits: data.planBalance ?? 0,
                previousValue: account?.subscriptionPlan?.credits ?? 0,
                type: OrganizationCreditsEvent.ResetBalance,
              },
            },
          });
          toggleEdit();
          setNotification({
            message: t("plan.edit.success"),
            type: notificationTypes.Success,
          });
        } catch (_error) {
          const error = _error as ApolloError;

          if (
            error.graphQLErrors?.[0]?.extensions?.code ===
            SubscriptionErrorCodes.SubscriptionPlanExpirationDateError
          ) {
            setNotification({
              message: t("plan.balance.errors.expirationDate"),
              type: notificationTypes.Error,
            });
          } else {
            setNotification({
              message: error.message ?? t("common:common.error"),
              type: notificationTypes.Error,
            });
          }
        }
      }}
      initialValues={{
        planType: account?.subscriptionPlan?.name as SubscriptionPlanName,
        planExpirationDate: defaultExpirationDate,
        planLimit: getCreditLimit(account),
        planBalance: account?.subscriptionPlan?.credits,
        planStatus: account?.subscriptionPlan?.status as SubscriptionPlanStatus,
      }}
      validationSchema={validationSchema}
      enableReinitialize
    >
      <PlanSection
        readOnly={readOnly}
        isEdit={isEdit}
        toggleEdit={toggleEdit}
        isIntegrated={isIntegrated}
        loading={accountLoading}
        onUpdateBalance={onUpdateBalance}
        updateCreditsLoading={updateCreditsLoading}
      />
    </Formik>
  );
};
