import { memo, useContext, useMemo, useState } from "react";
import {
  useProfileQuery,
  useProfileAccountsQuery,
  useChangeActiveAccountLazyQuery,
} from "api/graphql";
import { getFullName } from "utils/users";
import { Avatar } from "components/Avatar";
import { ReactComponent as AngleIcon } from "images/down.svg";
import { ReactComponent as CheckIcon } from "images/check.svg";
import { Dropdown, Loader } from "@CreativelySquared/uikit";
import { ReactComponent as SettingsIcon } from "images/setting.svg";
import { ReactComponent as UserIcon } from "images/user.svg";
import { Link } from "react-router-dom";
import { links } from "App";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { AuthContext } from "Authorization/authorization.context";

import styles from "./styles.module.scss";

export const Profile = memo(() => {
  const { t } = useTranslation("common");
  const { data: { me } = {}, loading: profileLoading } = useProfileQuery({
    fetchPolicy: "cache-only",
  });
  const [changeActiveAccount] = useChangeActiveAccountLazyQuery();
  const { setAccessToken, setAccountId } = useContext(AuthContext);
  const [accountLoading, setAccountLoading] = useState("");
  const organizationId = useMemo(
    () => me?.masterOrganization?.masterOrganizationId ?? "",
    [me]
  );
  const { data: { profileAccounts } = {}, loading: profileAccountsLoading } =
    useProfileAccountsQuery({
      variables: {
        includeMasterOrganization: true,
      },
      skip: !organizationId,
    });
  const fullName = useMemo(() => getFullName(me?.user), [me?.user]);
  const accountsLength = useMemo(
    () => profileAccounts?.availableOrganizations?.length ?? 0,
    [profileAccounts]
  );
  const organizationAvatar = useMemo(
    () =>
      profileAccounts?.masterOrganization?.avatar?.thumbnail?.downloadUrl?.url,
    [profileAccounts]
  );

  const changeAccount = async (accountId: string) => {
    setAccountLoading(accountId);
    const { data: { changeAuthenticationToken } = {} } =
      await changeActiveAccount({
        variables: {
          masterOrganizationId: organizationId,
          organizationId: accountId,
        },
      });

    if (changeAuthenticationToken) {
      setAccountId(accountId);
      setAccessToken(changeAuthenticationToken.accessToken);
      window.location.replace(links.CustomerDashboard());
    }
    setAccountLoading("");
  };

  const loading = profileLoading || profileAccountsLoading;

  if (loading) {
    return (
      <div className="flex items-center justify-center">
        <Loader radius={30} />
      </div>
    );
  }

  if (!me?.organization?.organizationId) {
    return (
      <Link to={links.SettingsProfile()} className={styles.profile}>
        <Avatar
          name={fullName}
          src={me?.user?.avatar?.thumbnail?.downloadUrl?.url}
        />
        <p className="text-sm text-blackberry font-bold truncate flex-1 ml-5">
          {fullName}
        </p>
      </Link>
    );
  }

  return (
    <Dropdown
      render={() => (
        <div className={styles.profile}>
          <Avatar
            name={fullName}
            className={styles.avatar}
            src={
              organizationId
                ? organizationAvatar
                : me?.user?.avatar?.thumbnail?.downloadUrl?.url
            }
          />
          <div className={styles.details}>
            <p className={styles.accountName}>{me?.organization?.name}</p>
            <p className={styles.userName}>{fullName}</p>
          </div>

          {accountsLength > 1 && (
            <div className="ml-auto">
              <AngleIcon className="rotate-180 w-7 h-7 -mb-5 stroke-[1.5]" />
              <AngleIcon className="w-7 h-7 stroke-[1.5]" />
            </div>
          )}
        </div>
      )}
      size={Dropdown.sizes.Large}
      placement={Dropdown.placements.RigntEnd}
      popoverClassName={styles.popover}
    >
      {accountsLength > 1 &&
        profileAccounts?.availableOrganizations?.map((item, key) => {
          const accountId = item?.organizationId;
          if (!accountId) return null;

          const isActive = accountId === me?.organization?.organizationId;
          return (
            <Dropdown.Item
              key={accountId}
              className={clsx(styles.accountItem, {
                [styles.accountItemLast]: key === accountsLength - 1,
                [styles.accountItemActive]: isActive,
              })}
              onClick={() => changeAccount(accountId)}
            >
              <Avatar
                name={fullName}
                className={styles.accountItemAvatar}
                src={organizationAvatar}
              />
              <p className={styles.accountItemName}>{item?.name}</p>
              {isActive && (
                <CheckIcon className="stroke-teal stroke-[1.5] w-7 h-7" />
              )}
              {accountLoading === accountId && <Loader />}
            </Dropdown.Item>
          );
        })}
      <Dropdown.Item
        component={Link}
        to={links.SettingsProfile()}
        className={styles.navigation}
      >
        <UserIcon />
        <p>{t("navigation.profile")}</p>
      </Dropdown.Item>
      <Dropdown.Item
        component={Link}
        to={links.SettingsAccount()}
        className={styles.navigation}
      >
        <SettingsIcon />
        <p>{t("navigation.settings")}</p>
      </Dropdown.Item>
    </Dropdown>
  );
});
