import { Modal } from "@CreativelySquared/uikit";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useRemoveAccountUserMutation } from "api/graphql";
import { useNotification } from "components";
import { Reference } from "@apollo/client";
import { DeleteMemberModal as DeleteMemberForm } from "Customers/components/DeleteMemberModal";

type Props = {
  accountId: string;
  userId: string;
  name: string;
  visible?: boolean;
  onClose: () => void;
};

export const DeleteMemberModal: FC<Props> = ({
  onClose,
  userId,
  visible,
  accountId,
  name,
}) => {
  const { t } = useTranslation("common");
  const { setNotification, notificationTypes } = useNotification();

  const [removeUser, { loading: deleting }] = useRemoveAccountUserMutation({
    onError(error) {
      setNotification({
        message: error.message ?? t("common.error"),
        type: notificationTypes.Error,
      });
    },
    onCompleted() {
      onClose();
    },
    update(cache, { data }) {
      if (!data?.removeUserFromOrganization) return;

      cache.modify<{
        getUsers: {
          nodes: Reference[];
          pageInfo: {
            totalItems: number;
          };
        };
      }>({
        fields: {
          getUsers(existingUsers, { readField, INVALIDATE }) {
            if ("nodes" in existingUsers) {
              return {
                ...existingUsers,
                nodes: existingUsers.nodes.filter(
                  (user) => userId !== readField("userId", user)
                ),
              };
            } else {
              return INVALIDATE;
            }
          },
        },
      });
    },
  });

  return (
    <Modal visible={visible} onClose={onClose} className="min-w-[545px]">
      <DeleteMemberForm
        onClose={onClose}
        onSubmit={() =>
          removeUser({
            variables: {
              userId: userId,
              organizationId: accountId,
            },
          })
        }
        isSubmitting={deleting}
        name={name}
      />
    </Modal>
  );
};
