import { Button, Input, Loader } from "@CreativelySquared/uikit";
import {
  useChangePasswordMutation,
  useProfileQuery,
  useUpdateAvatarMutation,
  useUpdateUserMutation,
} from "api/graphql";
import { Avatar } from "components/Avatar";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Route } from "react-router-hoc";
import {
  advancedErrorMessage,
  handleErrorMessage,
  parseError,
} from "utils/form";
import { UploadType, useUpload } from "utils/hooks/upload";
import { FileStatuses } from "utils/types";
import { object, ref, string } from "yup";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { links } from "App";
import { useNotification } from "components";
import { passwordValidationSchema } from "utils/validation";

import styles from "./styles.module.scss";

export const SettingsProfileRoute = Route("/settings/profile");

const validationSchema = object().shape(
  {
    name: string()
      .required("common:validation.required")
      .max(254, advancedErrorMessage("common:validation.max")),
    surname: string()
      .required("common:validation.required")
      .max(254, advancedErrorMessage("common:validation.max")),
    roleNotes: string().max(254, advancedErrorMessage("common:validation.max")),
    oldPassword: string().when(["newPassword", "confirmPassword"], {
      is: (newPassword: string, confirmPassword: string) =>
        newPassword || confirmPassword,
      then: string().required("common:validation.required"),
      otherwise: string(),
    }),
    newPassword: string().when(["oldPassword", "confirmPassword"], {
      is: (oldPassword: string, confirmPassword: string) =>
        oldPassword || confirmPassword,
      then: string()
        .required("common:validation.required")
        .withMutation((schema) => passwordValidationSchema(schema)),
      otherwise: string(),
    }),
    confirmPassword: string().when(["oldPassword", "newPassword"], {
      is: (oldPassword: string, newPassword: string) =>
        oldPassword || newPassword,
      then: string()
        .required("common:validation.required")
        .oneOf([ref("newPassword")], "common:validation.passwordMatch"),
      otherwise: string(),
    }),
  },
  [
    ["oldPassword", "newPassword"],
    ["oldPassword", "confirmPassword"],
    ["newPassword", "confirmPassword"],
  ]
);

export const SettingsProfile = SettingsProfileRoute(() => {
  const { t } = useTranslation("settings");
  const {
    data: { me: profile } = {},
    refetch,
    loading: profileLoading,
  } = useProfileQuery();
  const [upload, { uploadStatus }] = useUpload({
    completeStatus: FileStatuses.completed,
  });
  const [newAvatar, setNewAvatar] = useState("");
  const { setNotification, notificationTypes } = useNotification();
  const [updateAvatar, { error: updateAvatarError }] =
    useUpdateAvatarMutation();
  const [updateUser, { error: updateUserError, loading: updateUserLoading }] =
    useUpdateUserMutation();
  const [
    changePassword,
    { error: changePasswordError, loading: changePasswordLoading },
  ] = useChangePasswordMutation();
  const userId = profile?.user?.userId ?? "";

  const {
    values,
    handleChange,
    handleBlur,
    setValues,
    handleSubmit,
    touched,
    errors,
  } = useFormik({
    initialValues: {
      name: profile?.user?.name ?? "",
      surname: profile?.user?.surname ?? "",
      roleNotes: profile?.user?.roleNotes ?? "",
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    enableReinitialize: true,
    onSubmit: async ({
      name,
      surname,
      roleNotes,
      oldPassword,
      newPassword,
      confirmPassword,
    }) => {
      const user = await updateUser({
        variables: {
          userId,
          userFields: {
            name,
            surname,
            roleNotes,
          },
        },
      });

      if (oldPassword && newPassword && confirmPassword) {
        await changePassword({
          variables: {
            userId,
            oldPassword,
            newPassword,
          },
        }).then(() => {
          setValues({
            ...values,
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
          });
        });
      }

      if (user.data?.editUser?.userId) {
        setNotification({
          message: t("profile.notifications.edit"),
        });
      }
    },
    validationSchema,
  });

  useEffect(() => {
    const file = uploadStatus?.[0];

    if (file?.status === FileStatuses.ready_to_attach) {
      updateAvatar({
        variables: {
          userId: userId,
          userAvatarFields: {
            fileId: file.fileId,
          },
        },
      });
    }

    if (file?.status === FileStatuses.completed) refetch();
  }, [uploadStatus?.[0]?.status]);

  const error = updateAvatarError || updateUserError || changePasswordError;
  const loading = updateUserLoading || changePasswordLoading;
  const overviewError =
    (touched.name || touched.surname || touched.roleNotes) &&
    t(
      ...parseError(errors.name || errors.surname || errors.roleNotes)
    ).toString();

  useEffect(() => {
    if (error) {
      setNotification({
        message: t(handleErrorMessage(error)),
        type: notificationTypes.Error,
      });
    }
  }, [error]);

  if (profileLoading) {
    return <Loader radius={50} className="m-auto" />;
  }

  return (
    <section className={styles.settingsProfile}>
      <Helmet>
        <title>{t("profile.title")}</title>
      </Helmet>
      <h3 className="text-l mb-8">{t("profile.overview")}</h3>
      <Avatar
        src={newAvatar || profile?.user?.avatar?.thumbnail?.downloadUrl?.url}
        className={styles.avatar}
        loading={
          !!uploadStatus?.[0]?.status &&
          uploadStatus?.[0]?.status !== FileStatuses.completed
        }
        onUpdate={async (file) => {
          setNewAvatar(URL.createObjectURL(file));
          const files = await upload({
            files: [file],
            type: UploadType.user_avatar,
          });

          if (!files.length) {
            setNewAvatar("");
          }
        }}
      />
      <form onSubmit={handleSubmit} className="mt-8">
        {(["name", "surname", "roleNotes"] as const).map((field) => (
          <fieldset key={field} className={styles.field}>
            <label className={styles.fieldLabel}>
              {t(`profile.form.${field}`)}
            </label>
            <Input
              value={values?.[field]}
              name={field}
              variant={Input.variants.Secondary}
              className={styles.fieldInput}
              error={
                touched?.[field] && t(...parseError(errors?.[field])).toString()
              }
              placeholder={t(`profile.form.${field}`)}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </fieldset>
        ))}
        {overviewError && (
          <span className="text-xs text-red ml-[166px]">{overviewError}</span>
        )}
        <h3 className="text-l mb-8 mt-9 pt-10 border-t-[1px] border-light-blue-steel">
          {t("profile.credentials")}
        </h3>
        <fieldset className={styles.field}>
          <label className={styles.fieldLabel}>{t("profile.form.email")}</label>
          <h4 className={styles.email}>{profile?.user?.email}</h4>
        </fieldset>
        <fieldset className={clsx(styles.field, "items-start")}>
          <label className={styles.fieldLabel}>
            {t("profile.form.password.label")}
            <span className={styles.fieldInfo}>
              {t("profile.form.password.info")}
            </span>
          </label>
          <div className="flex flex-col w-full flex-1">
            {(["oldPassword", "newPassword", "confirmPassword"] as const).map(
              (field) => (
                <Input
                  key={field}
                  value={values?.[field]}
                  name={field}
                  variant={Input.variants.Secondary}
                  autoComplete="off"
                  className={clsx(styles.fieldInput, "mb-6")}
                  error={
                    touched?.[field] &&
                    t(...parseError(errors?.[field])).toString()
                  }
                  placeholder={t(`profile.form.password.${field}`)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              )
            )}
            {!!touched.confirmPassword &&
              (!!errors.confirmPassword || errors.newPassword) && (
                <span className="text-xs text-red -mt-4">
                  {errors.newPassword
                    ? t(...parseError(errors.newPassword))
                    : t("common:validation.passwordMatch")}
                </span>
              )}
          </div>
        </fieldset>
        <div className="flex mt-9">
          <Link to={links.Logout()} className="mr-6 ml-auto">
            <Button type="button" variant={Button.variants.Secondary}>
              {t("profile.actions.logout")}
            </Button>
          </Link>
          <Button type="submit" loading={loading}>
            {t("profile.actions.save")}
          </Button>
        </div>
      </form>
    </section>
  );
});
