import { memo, useMemo, useState } from "react";
import { ReactComponent as SortIcon } from "images/sort.svg";
import { Dropdown, Loader } from "@CreativelySquared/uikit";
import { Protect } from "components/Protect/Protect";
import { Link } from "react-router-dom";
import { Roles } from "utils/roles";
import { CreateCard, Empty, Filter, Pagination, Search } from "components";
import { ReactComponent as ArrowIcon } from "images/left.svg";
import clsx from "clsx";
import { cn } from "utils/styles";
import { links } from "App";
import { ReactComponent as SearchIcon } from "images/search.svg";
import { isString, some } from "lodash";
import { useTranslation } from "react-i18next";
import { GetProjectsFilter, ProjectNodeFragment } from "api/types";
import {
  parseStatuses,
  ProjectFilter,
  ProjectSort,
  ProjectStatuses,
  statusOrder,
} from "Projects/utils/types";
import { BriefCategory, ProjectSortingField } from "api/enums";
import {
  useBrandNamesQuery,
  useProjectsQuery,
  useUserNamesQuery,
} from "api/graphql";
import { getFullName } from "utils/users";
import gridIcon from "images/appstore.svg";
import listIcon from "images/bars.svg";
import { useProtect } from "utils/hooks/protection";
import { usePagination } from "utils/hooks/pagination";
import { SortOrder } from "utils/order";
import { BriefShareableField } from "Brief/utils/types";
import { format, formatISO } from "date-fns";

import { ProjectsViewType } from "./index";

import styles from "./styles.module.scss";

type Props = {
  sort?: ProjectSort;
  onSort?: (value: ProjectSort) => void;
  filters: Omit<GetProjectsFilter, "status"> & { status?: ProjectStatuses[] };
  initialFilters?: {
    [ProjectFilter.categories]?: BriefCategory[];
    [ProjectFilter.statuses]?: ProjectStatuses[];
  };
  submittedAt?: string[];
  onFilter?: (value: Record<string, string[]>) => void;
  organizationId?: string | null;
  search?: string;
  onSearch?: (value?: string, page?: number) => void;
  view?: ProjectsViewType;
  onViewChange?: (value: ProjectsViewType) => void;
  page: number;
  onPageChange?: (value: number) => void;
  filterOptions?: Array<ProjectFilter>;
  renderCard?: (props: {
    project?: ProjectNodeFragment | null;
    selected?: boolean;
    onSelect?: (project: ProjectNodeFragment) => void;
    view?: ProjectsViewType;
  }) => React.ReactNode;
  selectedProjects?: ProjectNodeFragment[];
  onSelectProject?: (project: ProjectNodeFragment) => void;
  className?: string;
  containerClassName?: string;
  paginationClassName?: string;
  hasCreateCard?: boolean;
};

export const ProjectsView = memo<Props>(
  ({
    sort,
    onSort,
    filters,
    initialFilters,
    submittedAt,
    onFilter,
    organizationId,
    search,
    onSearch,
    view = ProjectsViewType.Grid,
    onViewChange,
    page,
    onPageChange,
    filterOptions,
    renderCard,
    selectedProjects,
    onSelectProject,
    className,
    containerClassName,
    paginationClassName,
    hasCreateCard = true,
  }) => {
    const { t } = useTranslation("projects");
    const isCustomer = useProtect()(Roles.customer);
    const [filterSearch, setFilterSearch] = useState<{
      [key: string]: string | undefined;
    }>({});

    const sortField = useMemo(() => {
      if (!sort) return undefined;

      const dateSort =
        !sort || [ProjectSort.Newest, ProjectSort.Oldest].includes(sort);
      const nameSort = [
        ProjectSort.NameAscending,
        ProjectSort.NameDescending,
      ].includes(sort);

      if (dateSort) {
        return !filters.status?.length ||
          filters.status?.includes(ProjectStatuses.draft)
          ? ProjectSortingField.CreatedAt
          : ProjectSortingField.SubmittedAt;
      }

      if (nameSort) {
        return ProjectSortingField.Title;
      }

      if (sort === ProjectSort.Active) {
        return ProjectSortingField.Status;
      }

      return undefined;
    }, [sort, filters]);

    const sortOrder = useMemo(() => {
      const isDescendingSort =
        !sort ||
        [ProjectSort.Newest, ProjectSort.NameDescending].includes(sort);
      return isDescendingSort ? SortOrder.DESC : SortOrder.ASC;
    }, [sort]);

    const pagination = usePagination({
      page,
      sort: sortField,
      sortOrder,
      priority: sort === ProjectSort.Active ? statusOrder : undefined,
    });
    const {
      data: { getBrandProfiles: brandList } = {},
      loading: brandsLoading,
    } = useBrandNamesQuery({
      variables: {
        organizationId,
      },
      skip:
        !onFilter ||
        (!!filterOptions && !filterOptions.includes(ProjectFilter.brands)),
    });

    const { data: { getUsers: userList } = {}, loading: usersLoading } =
      useUserNamesQuery({
        variables: {
          filters: {
            organizationIds: organizationId ? [organizationId] : undefined,
          },
        },
        skip:
          !onFilter ||
          (!!filterOptions && !filterOptions.includes(ProjectFilter.users)),
      });

    const { data: { getProjects } = {}, loading: projectsLoading } =
      useProjectsQuery({
        variables: {
          filters: {
            ...filters,
            briefCategory:
              filters.briefCategory ??
              initialFilters?.[ProjectFilter.categories],
            status: parseStatuses(
              filters.status ?? initialFilters?.[ProjectFilter.statuses]
            ),
            isShareableOnDate:
              filters.isShareableOnDate &&
              formatISO(new Date(+filters.isShareableOnDate), {
                representation: "date",
              }),
          },
          pagination,
          organizationId,
        },
        fetchPolicy: "cache-and-network",
      });

    const shareableValue = [
      filters.shareable,
      filters.isShareableOnDate
        ? {
            filterKey: ProjectFilter.shareableOn,
            filterValue: filters.isShareableOnDate,
            value: BriefShareableField.yes_custom,
          }
        : undefined,
    ].filter(Boolean);

    const filterData = [
      {
        key: ProjectFilter.categories,
        value: filters.briefCategory,
      },
      {
        key: ProjectFilter.statuses,
        value: [
          ...(filters.status ?? []),
          ...(filters.isDeleted ? [ProjectStatuses.deleted] : []),
        ],
      },
      {
        key: ProjectFilter.brands,
        value: filters.brandProfileId,
      },
      {
        key: ProjectFilter.users,
        value: filters.userId,
      },
      {
        key: ProjectFilter.submittedAt,
        value: submittedAt,
      },
      {
        key: ProjectFilter.shareable,
        value: !!shareableValue.length ? shareableValue : undefined,
      },
    ];

    const filterList = [
      {
        key: ProjectFilter.categories,
        label: t("common:filter.value.category"),
        value: (
          initialFilters?.[ProjectFilter.categories] ??
          Object.values(BriefCategory)
        ).map((category) => ({
          id: category,
          name: t(`common:briefCategory.${category}.title`),
        })),
        type: Filter.types.Checkbox,
      },
      {
        key: ProjectFilter.statuses,
        label: t("common:filter.value.status"),
        value: Object.values(
          initialFilters?.[ProjectFilter.statuses] ?? ProjectStatuses
        ).flatMap((status) =>
          !(status === ProjectStatuses.deleted && isCustomer)
            ? [{ id: status, name: t(`filters.statuses.${status}`) }]
            : []
        ),
        type: Filter.types.Checkbox,
      },
      {
        key: ProjectFilter.brands,
        label: t("common:filter.value.brand"),
        value: brandList?.nodes?.map((brand) => ({
          id: brand?.brandProfileId ?? "",
          name: brand?.name ?? "",
        })),
        type: Filter.types.Checkbox,
      },
      {
        key: ProjectFilter.users,
        label: t("common:filter.value.user"),
        value: userList?.nodes?.map((user) => ({
          id: user?.userId ?? "",
          name: getFullName(user),
        })),
        type: Filter.types.Checkbox,
      },
      {
        key: ProjectFilter.submittedAt,
        label: t("common:filter.value.submittedAt"),
        type: Filter.types.Datepicker,
      },
      {
        key: ProjectFilter.shareable,
        label: t("common:filter.value.shareable.title"),
        value: [
          {
            id: BriefShareableField.no,
            name: t("common:filter.value.shareable.options.no"),
          },
          {
            id: BriefShareableField.yes_custom,
            name: t("common:filter.value.shareable.options.yes", {
              customDate: format(
                filters.isShareableOnDate
                  ? new Date(+filters.isShareableOnDate)
                  : new Date(),
                "dd MMM yyyy"
              ),
            }),
            filterKey: ProjectFilter.shareableOn,
            type: Filter.optionTypes.ToggleDatepicker,
          },
        ],
        type: Filter.types.Checkbox,
      },
    ];

    const searchLoading = brandsLoading || projectsLoading;
    const loading =
      (projectsLoading || usersLoading || brandsLoading) &&
      !getProjects?.nodes?.length;
    const isEmpty =
      !getProjects?.pageInfo?.totalItems &&
      !Object.values(filters).some(Boolean);

    const translationStatus =
      filters.status?.length === 1 ? filters.status?.[0]?.toLowerCase() : "all";

    return (
      <section className={className}>
        <nav className="flex justify-between items-center">
          <div className="grid gap-6 grid-flow-col">
            {!!onSort && (
              <Dropdown
                icon={<SortIcon className="w-7 h-7 stroke-[1.5]" />}
                label={t("common:sort.label")}
                type={Dropdown.types.Light}
                value={sort}
                onSelect={onSort}
              >
                <Dropdown.Item value={ProjectSort.Newest}>
                  {t("common:sort.options.createdAt.desc")}
                </Dropdown.Item>
                <Dropdown.Item value={ProjectSort.Oldest}>
                  {t("common:sort.options.createdAt.asc")}
                </Dropdown.Item>
                <Dropdown.Item value={ProjectSort.NameAscending}>
                  {t("common:sort.options.title.asc")}
                </Dropdown.Item>
                <Dropdown.Item value={ProjectSort.NameDescending}>
                  {t("common:sort.options.title.desc")}
                </Dropdown.Item>
                <Dropdown.Item value={ProjectSort.Active}>
                  {t("common:sort.options.projects")}
                </Dropdown.Item>
              </Dropdown>
            )}
            {!!onFilter && (
              <Filter
                value={filterData?.flatMap(({ key, value }) =>
                  value?.length ? [{ key, value: value as string[] }] : []
                )}
                options={
                  filterOptions
                    ? filterList.filter(({ key }) =>
                        filterOptions?.includes(key)
                      )
                    : filterList
                }
                onChange={onFilter}
                searchLoading={searchLoading}
                searchValue={filterSearch}
                onSearch={setFilterSearch}
              />
            )}
          </div>
          {(!!onSearch || !!onViewChange) && (
            <div className="flex items-center h-10">
              {!!onSearch && (
                <>
                  {isString(search) && (
                    <Search
                      className="mr-7 w-[300px]"
                      placeholder={t("search.placeholder")}
                      onSearch={onSearch}
                      value={search}
                      autoFocus
                    />
                  )}
                  <button
                    type="button"
                    onClick={() => {
                      const searchValue = isString(search) ? undefined : "";
                      const searchPage = search ? undefined : page;

                      onSearch(searchValue, searchPage);
                    }}
                    className="mr-6"
                  >
                    <SearchIcon className="w-7 h-7 stroke-[1.5]" />
                  </button>
                </>
              )}
              {onViewChange &&
                (view === ProjectsViewType.List ? (
                  <button
                    type="button"
                    onClick={() => onViewChange(ProjectsViewType.Grid)}
                  >
                    <img src={gridIcon} className="w-7 h-7" />
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() => onViewChange(ProjectsViewType.List)}
                  >
                    <img src={listIcon} className="w-7 h-7" />
                  </button>
                ))}
            </div>
          )}
        </nav>

        {loading && !getProjects?.nodes?.length && (
          <div className="flex h-full items-center justify-center">
            <Loader radius={50} className="mt-11" />
          </div>
        )}

        {!loading && (
          <>
            <Protect access={Roles.cs_admins}>
              {getProjects?.nodes?.length === 0 && (
                <Empty
                  title={t(`empty.${translationStatus}.title`)}
                  description={t(`empty.${translationStatus}.description`)}
                />
              )}
            </Protect>

            <Protect access={Roles.customer}>
              {isEmpty && hasCreateCard ? (
                <Link to={links.CreateBrief()} className="inline-block">
                  <CreateCard title={t("empty.create")} className="mt-11" />
                </Link>
              ) : (
                getProjects?.nodes?.length === 0 && (
                  <Empty
                    title={t(`empty.${translationStatus}.title`, {
                      context: isCustomer && "customer",
                    })}
                    description={t(`empty.${translationStatus}.description`, {
                      context: isCustomer && "customer",
                    })}
                  />
                )
              )}
            </Protect>
          </>
        )}

        <section
          className={clsx(
            styles[cn("projectsList", view as ProjectsViewType)],
            "mt-8",
            containerClassName
          )}
        >
          {getProjects?.nodes?.map((project) => {
            return (
              !!project &&
              !!renderCard &&
              renderCard({
                project,
                selected: some(selectedProjects, {
                  projectId: project.projectId,
                }),
                onSelect: onSelectProject
                  ? () => onSelectProject(project)
                  : undefined,
                view,
              })
            );
          })}
        </section>

        {getProjects?.nodes && !loading && (
          <Pagination
            className={clsx("mt-11", paginationClassName)}
            page={page}
            loading={projectsLoading}
            total={getProjects?.pageInfo?.totalPages}
            renderPrev={
              onPageChange &&
              ((prevPage, props) => (
                <button {...props} onClick={() => onPageChange(prevPage)}>
                  <ArrowIcon />
                </button>
              ))
            }
            renderNext={
              onPageChange &&
              ((nextPage, props) => (
                <button {...props} onClick={() => onPageChange(nextPage)}>
                  <ArrowIcon style={{ transform: "rotate(180deg)" }} />
                </button>
              ))
            }
            renderPage={
              onPageChange &&
              ((item, props) => (
                <button {...props} onClick={() => onPageChange(item)}>
                  {item}
                </button>
              ))
            }
          />
        )}
      </section>
    );
  }
);
