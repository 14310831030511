import { FC, useCallback, useState } from "react";
import { Button } from "@CreativelySquared/uikit";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { ApproveModal } from "../ApproveModal";
import { DeclineModal } from "../DeclineModal";

import styles from "./styles.module.scss";

interface Props {
  requestId: string;
}

export const Actions: FC<Props> = ({ requestId }) => {
  const { t } = useTranslation("organizations");

  const [isApproveModalVisible, setApproveModalVisible] = useState(false);
  const [isDeclineModalVisible, setDeclineModalVisible] = useState(false);

  const toggleApproveModal = useCallback(() => {
    setApproveModalVisible((isVisible) => !isVisible);
  }, []);

  const toggleDeclineModal = useCallback(() => {
    setDeclineModalVisible((isVisible) => !isVisible);
  }, []);

  return (
    <>
      <div className="flex justify-end flex-nowrap">
        <Button
          variant={Button.variants.Soft}
          size={Button.sizes.Small}
          borderless
          className={styles.button}
          onClick={toggleDeclineModal}
        >
          {t("accessRequests.table.actions.decline")}
        </Button>
        <Button
          variant={Button.variants.Soft}
          size={Button.sizes.Small}
          borderless
          className={clsx("text-teal -mr-7", styles.button)}
          onClick={toggleApproveModal}
        >
          {t("accessRequests.table.actions.approve")}
        </Button>
      </div>
      <ApproveModal
        visible={!!isApproveModalVisible}
        onClose={toggleApproveModal}
        requestId={requestId}
      />
      <DeclineModal
        visible={!!isDeclineModalVisible}
        onClose={toggleDeclineModal}
        requestId={requestId}
      />
    </>
  );
};
