import {
  cloneElement,
  ComponentType,
  isValidElement,
  memo,
  PropsWithChildren,
  useMemo,
} from "react";
import { useProtect } from "utils/hooks/protection";
import { Access, Roles } from "utils/roles";
import { mergeProps } from "utils/props";
import type { Object as ObjectType } from "ts-toolbelt";
import type { Features } from "api/features";
import { GetProps } from "react-router-hoc/lib/types";
import { User } from "api/types";
import { Permission } from "utils/hooks/permission";
import { useHasPermission } from "UserSettingsContext";

type Props = {
  user?: ObjectType.Partial<User, "deep"> | null;
  access?: Access;
  suspense?: boolean;
  feature?: Features;
  permission?: Permission;
};

export const Protect = Object.assign(
  memo<PropsWithChildren<Props>>(
    ({ children, access, user, suspense, feature, permission, ...rest }) => {
      const accessible = useProtect({ suspense, user, feature })(access);
      const hasPermission = useHasPermission(permission);
      const hasAccess = access ? accessible : true;

      const element = useMemo(() => {
        return isValidElement(children)
          ? cloneElement(children as any, mergeProps(rest, children.props))
          : children;
      }, [children, rest]);
      return hasAccess && hasPermission ? <>{element}</> : null;
    }
  ),
  {
    roles: Roles,
  }
);

export function withProtect<P extends ComponentType>(Component: P): P {
  const Protected = Object.assign(
    memo(
      ({
        accessKey,
        ...rest
      }: GetProps<P> & { accessKey?: Props["feature"] }) => {
        return (
          <Protect feature={accessKey}>
            <Component {...(rest as any)} />
          </Protect>
        );
      }
    ),
    {
      displayName: Component.displayName,
    }
  );

  return Protected as any;
}
