import { lazy } from "react";
import { compose, Route } from "react-router-hoc";
import { idRegEx } from "utils/regex";
import { Roles } from "utils/roles";
import { ProtectedRoute } from "utils/route";

export const OrganizationDetailsRoute = compose(
  ProtectedRoute({ access: Roles.cs_admins }),
  Route(
    {
      id: Route.params.regex(idRegEx),
    },
    ({ id }) => `/customers/organizations/${id}`
  )
);

export const OrganizationDetails = OrganizationDetailsRoute(
  lazy(
    () =>
      import(
        /* webpackChunkName: "OrganizationDetails" */ "./OrganizationDetails"
      )
  )
);
