import {
  ActionBar,
  ModalDescription,
  ModalTitle,
  useNotification,
} from "components";
import { FC, useCallback, useState } from "react";
import { ReactComponent as SelectIcon } from "images/selectAll.svg";
import { ReactComponent as RemoveIcon } from "images/bin.svg";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "@CreativelySquared/uikit";
import { useDestroyAssetsMutation } from "api/graphql";

import styles from "./styles.module.scss";

interface Props {
  selectedAssets: string[];
  onSelectAll: () => void;
  onClearSelection: () => void;
  onDelete: (ids: string[]) => void;
}

export const UploadActionBar: FC<Props> = ({
  onSelectAll,
  onClearSelection,
  selectedAssets,
  onDelete,
}) => {
  const { t } = useTranslation("projects");
  const [isConfirmationVisible, setConfirmationVisible] = useState(false);
  const { setNotification, notificationTypes } = useNotification();

  const [deleteAssets, { loading: isDeleting }] = useDestroyAssetsMutation({
    variables: {
      assetsIds: selectedAssets,
    },
    onError: (error) => {
      setNotification({
        message: error.message ?? t("common:common.error"),
        type: notificationTypes.Error,
      });
    },
    onCompleted: (_data) => {
      onDelete(selectedAssets);
      onCloseBar();
    },
  });

  const onToggleConfirmation = useCallback(
    () => setConfirmationVisible((prev) => !prev),
    []
  );

  const onCloseBar = useCallback(() => {
    onClearSelection();
    setConfirmationVisible(false);
  }, [onClearSelection]);

  return (
    <>
      <ActionBar
        data={[
          [
            {
              tooltip: t("common:gallery.select"),
              icon: <SelectIcon />,
              onClick: onSelectAll,
            },
            {
              tooltip: t("common:gallery.removeAssets"),
              icon: <RemoveIcon />,
              onClick: onToggleConfirmation,
            },
          ],
        ]}
        count={selectedAssets.length}
        onClose={onCloseBar}
      />
      <Modal
        visible={isConfirmationVisible}
        onClose={onToggleConfirmation}
        className={styles.modal}
      >
        <ModalTitle>
          {t("gallery.manage.upload.deleteAssets.title", {
            count: selectedAssets.length,
          })}
        </ModalTitle>
        <ModalDescription>
          {t("gallery.manage.upload.deleteAssets.description")}
        </ModalDescription>
        <div className="flex justify-end mt-9">
          <Button
            variant={Button.variants.Cancel}
            type="button"
            className="mr-5"
            onClick={onToggleConfirmation}
            outlined
          >
            {t("common:actions.cancel")}
          </Button>
          <Button
            type="submit"
            onClick={() => {
              deleteAssets();
            }}
            loading={isDeleting}
          >
            {t("common:actions.delete")}
          </Button>
        </div>
      </Modal>
    </>
  );
};
