import { ActivityView } from "Activity/components/ActivityView";
import { useGetMasterOrganizationEventsQuery } from "api/graphql";
import { ActivityFilter } from "components";
import { FC } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { EventType } from "utils/event";
import { usePagination } from "utils/hooks/pagination";
import { GetRouteProps } from "utils/types";

import { OrganizationActivityTabRoute } from "./index";

type OrganizationActivityTabProps = GetRouteProps<
  typeof OrganizationActivityTabRoute
>;

const allEvents = [
  EventType.master_org_created,
  EventType.master_org_edited,
  EventType.org_linked_to_master_org,
  EventType.org_unlinked_from_master_org,
  EventType.org_created,
  EventType.user_added_to_org,
  EventType.user_removed_from_organization,
  EventType.user_removed_from_master_organization,
  EventType.user_added_to_master_organization,
  EventType.org_credits_under_threshold,
  EventType.org_credits_updated,
  EventType.subscription_plan_edited,
  EventType.subscription_plan_expired,
  EventType.subscription_plan_renewed,
  EventType.credits_expire_alert,
  EventType.auto_renewal_alert,
  EventType.custom_subscription_expire_alert,
  EventType.subscription_plan_name_edited,
  EventType.subscription_plan_credits_limit_edited,
  EventType.subscription_plan_status_edited,
  EventType.subscription_plan_date_edited,
  EventType.credits_transferred_between_organizations,
  EventType.credits_assigned_from_master_organization_credit_balance,
];

interface Props {
  query: {
    page?: number;
    type?: Array<EventType | undefined>;
  };
  params: Record<string, string>;
  link: (params: any) => string;
  organizationId: string;
}

export const OrganizationActivity: FC<Props> = ({
  query: { page = 1, type = allEvents },
  params,
  query,
  link,
  organizationId,
}) => {
  const pagination = usePagination({ page, limit: 25 });

  const { data: { events } = {}, loading } =
    useGetMasterOrganizationEventsQuery({
      variables: {
        masterOrganizationId: organizationId,
        pagination,
        eventNames: type as EventType[],
      },
      fetchPolicy: "no-cache",
    });

  return (
    <>
      <header className="flex justify-between items-center relative">
        <div className="absolute right-0 top-2">
          <ActivityFilter
            className="min-w-min"
            value={{ ...query, ...params, page: 1 }}
            link={link}
            events={allEvents}
          />
        </div>
      </header>
      <ActivityView
        events={events?.nodes}
        loading={!events?.nodes?.length && loading}
        pagination={pagination}
        totalPages={events?.pageInfo?.totalPages}
        query={query}
        params={params}
        link={link}
        className="-mt-10"
      />
    </>
  );
};

const OrganizationActivityTab: FC<OrganizationActivityTabProps> = ({
  match: { params, query },
  link,
}) => {
  const { t } = useTranslation("organizations");

  return (
    <>
      <Helmet>
        <title>{t("activity.tab")}</title>
      </Helmet>
      <OrganizationActivity
        link={link}
        params={params}
        query={query}
        organizationId={params.id}
      />
    </>
  );
};

export default OrganizationActivityTab;
