import { createContext } from "react";

export type AuthContext = {
  accessToken?: string | null;
  setAccessToken: (token?: string | null) => void;
  accountId?: string | null;
  setAccountId: (accountId?: string | null) => void;
};

export const AuthContext = createContext<AuthContext>({
  accessToken: undefined,
  setAccessToken: () => {},
  accountId: undefined,
  setAccountId: () => {},
});
