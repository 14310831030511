import {
  FC,
  memo,
  SyntheticEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import clsx from "clsx";
import { GetProps } from "react-router-hoc/lib/types";
import {
  Button,
  Checkbox,
  Datepicker,
  Dropdown,
  Float,
} from "@CreativelySquared/uikit";
import { useTranslation } from "react-i18next";
import { ReactComponent as PlusIcon } from "images/plus.svg";
import { ReactComponent as BinIcon } from "images/bin.svg";
import { MultiInput } from "components/MultiInput";
import { Search } from "components/Search";
import { sortBy, uniqBy, xor } from "lodash";
import { OptionsList } from "components";
import { startOfDay, getTime } from "date-fns";
import { Placements } from "@CreativelySquared/uikit/dist/types/positions";
import { ReactComponent as FilterIcon } from "images/filter.svg";

import styles from "./styles.module.scss";

enum OptionsType {
  Checkbox = "Checkbox",
  Datepicker = "Datepicker",
}

enum OptionType {
  ToggleDatepicker = "ToggleDatepicker",
}

type OptionValue = Array<{
  id: string;
  name: string;
  disabled?: boolean;
  filterKey?: string;
  type?: OptionType;
}>;

export type FilterType = {
  key: string;
  label: string;
  type: OptionsType;
  value?: OptionValue;
  activeSearch?: boolean;
  moreResults?: number;
  activeValue?: OptionValue;
};

// If filterKey is not provided, it means that the value belongs to the DataType[key], which is used in query params;
// Filter accepts filterKey key if the value should be used in search query with a different key
// Currently available in the Checkbox type only
type DataTypeValue =
  | string
  | {
      filterKey: string;
      value: string;
      // Currently it's used only for the ToggleDatepicker (Shareable option); It can be extended to the object later.
      // It's considered to be a rare case, so it's not scalable.
      // We can convert filterValue to the object and provide parseValue utility function.
      filterValue?: string;
    };

export type DataType = Array<{
  key?: string;
  value?: DataTypeValue[];
}>;

type Props = {
  value?: DataType;
  options: Array<FilterType>;
  onChange: (data: { [key: string]: string[] }) => void;
  onSearch?: (data: { [key: string]: string | undefined }) => void;
  searchValue?: { [key: string]: string | undefined };
  searchLoading?: boolean;
} & Omit<GetProps<typeof Button>, "value" | "onChange">;

const findValue = (values: UnwrapArray<DataType>["value"], id: string) =>
  values?.find((item) =>
    typeof item === "string" ? item === id : item.value === id
  );

export const CheckboxSelector: FC<{
  value?: DataTypeValue[];
  options?: FilterType["value"];
  onChange: (data?: DataTypeValue[]) => void;
  onSearch?: (value: string) => void;
  searchValue?: string;
  searchLoading?: boolean;
  disabled?: boolean;
  moreResults?: number;
  activeValue?: OptionValue;
  popoverClassName?: string;
  placement?: Placements;
}> = ({
  value,
  options,
  disabled,
  onChange,
  searchValue,
  searchLoading,
  onSearch,
  moreResults,
  activeValue,
  popoverClassName,
  placement = Dropdown.placements.BottomStart,
}) => {
  const { t } = useTranslation();

  return (
    <Dropdown
      render={({ visible }) => (
        <div className={clsx({ "pointer-events-none": disabled })}>
          <MultiInput
            active={visible}
            arrow
            placeholder={t("filter.placeholder.value")}
            className={styles.multiInput}
            value={sortBy(
              uniqBy([...(options ?? []), ...(activeValue ?? [])], "id"),
              "name"
            )?.flatMap((option) =>
              findValue(value, option.id) ? [option] : []
            )}
            disabled={disabled}
            onChange={(data: FilterType["value"]) =>
              onChange(data?.map((el) => findValue(value, el.id)!))
            }
            format={(el) => el.name ?? ""}
            keyExtractor={(el) => el.id}
          />
        </div>
      )}
      className={clsx(styles.dropdown, {
        [styles.empty]: !value,
      })}
      popoverClassName={popoverClassName}
      placement={placement}
      shifting={false}
    >
      {onSearch && (
        <Search
          value={searchValue}
          onSearch={onSearch}
          autoFocus
          autoComplete="off"
          className={styles.search}
          placeholder={t("filter.search.placeholder")}
          onClick={(event) => event.stopPropagation()}
        />
      )}
      <OptionsList
        loading={searchLoading}
        options={options || []}
        moreResults={moreResults}
        renderOption={({ id, name, disabled, filterKey, type }) => {
          const checkedValue = findValue(value, id);

          return (
            <Checkbox
              key={id}
              checked={!!checkedValue}
              className={styles.checkbox}
              labelClassName={clsx("relative", {
                "overflow-visible": type === OptionType.ToggleDatepicker,
              })}
              disabled={disabled}
              onChange={(e) => {
                if (filterKey) {
                  if (e.target.checked) {
                    onChange([
                      ...(value || []),
                      {
                        filterKey,
                        value: id,
                        filterValue:
                          type === OptionType.ToggleDatepicker
                            ? `${getTime(startOfDay(new Date()))}`
                            : undefined,
                      },
                    ]);
                  } else {
                    onChange(
                      value?.filter((item) => {
                        if (typeof item === "string") return true;

                        return item.value !== id;
                      })
                    );
                  }
                } else {
                  onChange(xor(value, [id]));
                }
              }}
            >
              {name}
              {type === OptionType.ToggleDatepicker && (
                <Datepicker
                  selected={
                    typeof checkedValue === "string"
                      ? new Date()
                      : checkedValue?.filterValue
                      ? new Date(+checkedValue.filterValue)
                      : new Date()
                  }
                  inline={false}
                  className={styles.datepicker}
                  wrapperClassName={styles.datepickerWrapper}
                  dateFormat="dd MMM yyyy"
                  onChange={(date: Date, e?: SyntheticEvent<any, Event>) => {
                    e?.preventDefault();
                    e?.stopPropagation();

                    const dateTimestamp = date?.valueOf().toString();

                    if (!!checkedValue) {
                      onChange(
                        value?.map((item) => {
                          if (typeof item === "string") return item;

                          if (item.value === id) {
                            return {
                              ...item,
                              filterValue: dateTimestamp,
                            };
                          }

                          return item;
                        })
                      );
                    } else {
                      onChange([
                        ...(value || []),
                        {
                          filterKey,
                          value: id,
                          filterValue: dateTimestamp,
                        },
                      ]);
                    }
                  }}
                />
              )}
            </Checkbox>
          );
        }}
        className="gap-6 py-6 px-8"
      />
    </Dropdown>
  );
};

const DateSelector: FC<{
  value?: string[];
  onChange: (data: string[]) => void;
}> = ({ value, onChange }) => {
  const { t } = useTranslation();
  const [startDate, endDate] =
    value?.map((date) => (date ? new Date(+date) : null)) ?? [];

  return (
    <Dropdown
      className={clsx(styles.dropdown, styles.date, {
        [styles.dateSelected]: startDate,
      })}
      onSelect={(dates) => {
        const formatted = (dates as Array<Date | null>).map(
          (date) => date?.valueOf().toString() ?? ""
        );
        onChange(formatted);
      }}
      label={t("filter.placeholder.value")}
      placement={Dropdown.placements.BottomStart}
      shifting={false}
    >
      <Dropdown.Datepicker
        selected={startDate}
        startDate={startDate}
        endDate={endDate}
        maxDate={new Date()}
        selectsRange
      />
    </Dropdown>
  );
};

export const Filter = Object.assign(
  memo<Props>(
    ({
      value,
      options,
      onChange,
      onSearch,
      searchValue,
      searchLoading,
      className,
      ...props
    }) => {
      const { t } = useTranslation();
      const [open, setOpen] = useState<boolean | undefined>(false);
      const filteredValue = useMemo(
        () =>
          value?.filter((item) => options.some(({ key }) => item.key === key)),
        [value, options]
      );
      const [data, setData] = useState<DataType>(
        !!filteredValue?.length ? filteredValue : [{}]
      );

      const isInitialCallRef = useRef(true);

      const usedKeys = data.map((el) => el.key);

      useEffect(() => {
        if (isInitialCallRef.current) {
          isInitialCallRef.current = false;

          return;
        }

        const allKeys = options.reduce<string[]>((acc, { key, value }) => {
          const keysSet = new Set<string>([...acc, key]);

          value?.forEach((el) => {
            if (el.filterKey) {
              keysSet.add(el.filterKey);
            }
          });

          return Array.from(keysSet);
        }, []);

        const result = options.reduce(
          (resultAcc, { key }) => {
            const dataValue = data.find((el) => el.key === key)?.value;

            if (!dataValue?.length) return resultAcc;

            return dataValue?.reduce<Record<string, string[]>>((acc, item) => {
              if (typeof item === "string") {
                return {
                  ...acc,
                  [key]: [...(acc[key] ?? []), item],
                };
              }

              return {
                ...acc,
                [item.filterKey]: [
                  ...(acc[item.filterKey] ?? []),
                  item.filterValue || item.value,
                ],
              };
            }, resultAcc);
          },
          Object.values(allKeys).reduce(
            (acc, key) => ({ ...acc, [key]: undefined }),
            {}
          )
        );

        onChange(result);
      }, [data]);

      useEffect(() => {
        const hasEmptyValue =
          !value?.length && data.some((el) => !!el?.value?.length);
        if (hasEmptyValue) setData([{}]);
      }, [value]);

      return (
        <Float
          placement={Float.placements.BottomStart}
          trigger="click"
          onToogle={setOpen}
          visible={open}
          shifting={false}
        >
          <Button
            className={clsx(
              styles.button,
              {
                [styles.active]:
                  !!open || options.some(({ key }) => usedKeys.includes(key)),
              },
              className
            )}
            type="button"
            variant={Button.variants.Cancel}
            {...props}
          >
            <FilterIcon className="mr-4 w-7 h-7 stroke-[1.5]" />
            {t("filter.label")}
          </Button>
          <Float.Content className={styles.popover}>
            <div className="grid gap-4">
              {data.map(({ key, value }) => {
                const currentOptions = options.find(
                  (option) => option.key === key
                );

                const onUpdate = (item: DataType[number]) =>
                  setData((prev) =>
                    prev.map((el) => (el.key === key ? item : el))
                  );

                return (
                  <div key={key || "empty"} className={styles.filter}>
                    <Dropdown
                      className={clsx(styles.dropdown, {
                        [styles.empty]: !key,
                      })}
                      buttonContainerClassName="justify-between w-full"
                      value={key}
                      label={t("filter.placeholder.id")}
                      onSelect={(key) => onUpdate({ key })}
                      placement={Dropdown.placements.BottomStart}
                      shifting={false}
                    >
                      {options.map(
                        ({ key: optionKey, label }) =>
                          (!usedKeys.includes(optionKey) ||
                            key === optionKey) && (
                            <Dropdown.Item value={optionKey} key={optionKey}>
                              {label}
                            </Dropdown.Item>
                          )
                      )}
                    </Dropdown>
                    {currentOptions?.type === OptionsType.Datepicker ? (
                      <DateSelector
                        value={value as string[]}
                        onChange={(value) => onUpdate({ key, value })}
                      />
                    ) : (
                      <CheckboxSelector
                        value={value}
                        options={currentOptions?.value}
                        onChange={(value) => onUpdate({ key, value })}
                        disabled={!key}
                        searchLoading={searchLoading}
                        moreResults={currentOptions?.moreResults}
                        searchValue={
                          currentOptions && searchValue
                            ? searchValue[currentOptions.key]
                            : undefined
                        }
                        onSearch={
                          currentOptions?.activeSearch
                            ? (value) => {
                                if (currentOptions?.key && onSearch) {
                                  onSearch({
                                    ...searchValue,
                                    [currentOptions.key]: value || undefined,
                                  });
                                }
                              }
                            : undefined
                        }
                        popoverClassName="w-[285px]"
                        activeValue={currentOptions?.activeValue}
                      />
                    )}
                    {data.length > 1 && (
                      <Button
                        type="button"
                        variant={Button.variants.Icon}
                        className={clsx(styles.actionRemove)}
                        onClick={() =>
                          setData((prev) => prev.filter((el) => el.key !== key))
                        }
                      >
                        <BinIcon />
                      </Button>
                    )}
                  </div>
                );
              })}
            </div>
            <div className="flex items-center justify-between">
              {usedKeys.every(Boolean) && usedKeys.length !== options.length && (
                <Button
                  borderless
                  outlined
                  type="button"
                  className={styles.actionButton}
                  onClick={() => setData((prev) => [...prev, {}])}
                >
                  <PlusIcon className="mr-4" />
                  {t("filter.action.add")}
                </Button>
              )}
              <Button
                borderless
                outlined
                type="button"
                className={clsx("ml-auto", styles.actionButton)}
                onClick={() => {
                  setData([{}]);
                  onSearch?.({});
                }}
              >
                {t("filter.action.clear")}
              </Button>
            </div>
          </Float.Content>
        </Float>
      );
    }
  ),
  {
    types: OptionsType,
    optionTypes: OptionType,
  }
);
