import { SubscriptionPlanName, SubscriptionPlanType } from "api/enums";
import { SubscriptionPlan } from "api/types";
import { useMemo } from "react";

// Plans are sorted in ascending credit cost to be correctly displayed in the UI
export const getCreditsPlan = (custom?: number | null) => ({
  [SubscriptionPlanName.Launch]: 30,
  [SubscriptionPlanName.Grow]: 60,
  [SubscriptionPlanName.Scale]: 120,
  [SubscriptionPlanName.Custom]: custom,
});

export const getCreditLimit = (
  customer?: {
    credits?: number | null;
    subscriptionPlan?: Pick<SubscriptionPlan, "creditsLimit" | "type"> | null;
  } | null
) => {
  const isPAYG = useMemo(
    () => customer?.subscriptionPlan?.type === SubscriptionPlanType.Credits,
    [customer?.subscriptionPlan?.type]
  );

  return isPAYG ? customer?.credits : customer?.subscriptionPlan?.creditsLimit;
};
