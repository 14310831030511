import { Route } from "react-router-hoc";
export * from "./Authorization";
import { Switch } from "react-router";

import { Login } from "./Login";
import { ForgotPassword } from "./ForgotPassword";
import { ResetPassword } from "./ResetPassword";
import { Maintenance } from "./Maintenance";
import { Restricted } from "./Restricted";
import { Logout } from "./Logout";
import { Authorization } from "./Authorization";
import { ActivateAccount } from "./ActivateAccount";
import { AccountSetup } from "./AccountSetup";

export const links = {
  Login,
  ForgotPassword,
  ResetPassword,
  Logout,
  ActivateAccount,
  AccountSetup,
  Maintenance,
  Restricted,
};

export const route = Route(
  Object.values(links).map((route) => route.defaultProps?.path as string)
)(() => {
  return (
    <Switch>
      <Restricted />
      <Authorization />
    </Switch>
  );
});
