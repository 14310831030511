import { SubscriptionPlanName, SubscriptionPlanStatus } from "api/enums";

export enum PlanFormFields {
  PlanName = "planName",
  AccountStatus = "accountStatus",
  CreditLimit = "creditLimit",
  IntegratedCreditLimit = "integratedCreditLimit",
  RenewalDate = "renewalDate",
}

export type PlanFormData = {
  planName?: SubscriptionPlanName;
  accountStatus?: SubscriptionPlanStatus;
  creditLimit?: string | number | null;
  renewalDate?: Date | null;
};
