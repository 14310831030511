import {
  OrganizationType,
  SubscriptionPlanName,
  SubscriptionPlanStatus,
} from "api/enums";
import { OrganizationItemNodeFragment } from "api/types";
import { MAX_CREDIT_BALANCE } from "utils/credits";
import { advancedErrorMessage } from "utils/form";
import { tomorrow } from "utils/time";
import { date, number, object, string } from "yup";

interface FormData {
  accountType?: OrganizationType;
  organization?: OrganizationItemNodeFragment;
}

export const validationSchema = object().shape({
  planName: string()
    .nullable()
    .required("common:validation.required")
    .oneOf(Object.values(SubscriptionPlanName)),
  accountStatus: string()
    .nullable()
    .required("common:validation.required")
    .oneOf(Object.values(SubscriptionPlanStatus)),
  creditLimit: number()
    .required("common:validation.required")
    .test("max", (value, context: any) => {
      const data = context.from?.[context.from.length - 1]?.value as FormData;

      const isIntegrated = data?.accountType === OrganizationType.Integrated;
      const subscriptionPlan = data?.organization?.subscriptionPlan;
      const maxCreditBalance = isIntegrated
        ? subscriptionPlan?.credits
        : MAX_CREDIT_BALANCE;

      if (isIntegrated && subscriptionPlan && !subscriptionPlan.name) {
        return context.createError({
          message: "accounts:details.allocatedCredits.errors.plan",
        });
      }

      if (Number(value) > Number(maxCreditBalance)) {
        return context.createError({
          message: isIntegrated
            ? "accounts:details.allocatedCredits.errors.limit"
            : advancedErrorMessage("common:validation.credits.max")({
                max: maxCreditBalance,
              }),
        });
      }

      return true;
    }),
  renewalDate: date()
    .nullable()
    .min(tomorrow, "common:validation.date.tomorrow")
    .required("common:validation.required"),
});
