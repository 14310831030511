import { Table } from "components";
import { FC, useMemo, useRef } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { Role } from "api/enums";
import { useTranslation } from "react-i18next";

import { RoleCell } from "./RoleCell";

import styles from "./styles.module.scss";

interface Props {
  organizationId: string;
  value: {
    [key in Role]?: string[];
  };
  onChange: (value: {
    [key in Role]?: string[];
  }) => void;
}

interface TableData {
  role: Role;
  accounts: string[];
}

export const RoleInput: FC<Props> = ({ organizationId, value, onChange }) => {
  const { t } = useTranslation("organizations");

  const selectedOptionsRef = useRef<string[]>([]);
  const valueRef = useRef<Props["value"]>({});

  const selectedOptions = useMemo(() => Object.values(value).flat(), [value]);

  selectedOptionsRef.current = selectedOptions;
  valueRef.current = value;

  const columns = useMemo<ColumnDef<TableData>[]>(
    () => [
      {
        header: t("members.table.role"),
        accessorKey: "role",
        size: 175,
        cell: (cell) => {
          const role = cell.getValue() as Role;

          return t(`common:user.roles.${role.toLocaleLowerCase()}`);
        },
      },
      {
        header: t("members.table.account"),
        accessorKey: "accounts",
        maxSize: 711,
        size: 711,
        minSize: 711,
        cell: (cell) => (
          <RoleCell
            value={cell.getValue() as string[]}
            onChange={(newValue) => {
              onChange({
                ...valueRef.current,
                [cell.row.original.role]: newValue,
              });
            }}
            organizationId={organizationId}
            selectedValues={selectedOptionsRef.current}
          />
        ),
      },
    ],
    [organizationId]
  );

  const tableData = useMemo<TableData[]>(() => {
    return [Role.OrgAdmin, Role.OrgUser].map((role) => ({
      role,
      accounts: value[role] || [],
    }));
  }, [value]);

  return <Table columns={columns} data={tableData} rowClassName={styles.row} />;
};
