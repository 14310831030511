import "./locales";
import { lazy } from "react";
import { compose, Route } from "react-router-hoc";
import { Roles } from "utils/roles";
import { ProtectedRoute } from "utils/route";

import { OrganizationDetails } from "./OrganizationDetails";
import { OrganizationDetailsTab } from "./DetailsTab";
import { OrganizationMembersTab } from "./MembersTab";
import { OrganizationActivityTab } from "./ActivityTab";
import { OrganizationAccessRequestsTab } from "./AccessRequestsTab";

export const OrganizationsRoute = compose(
  ProtectedRoute({ access: Roles.cs_admins }),
  Route(
    {
      id: Route.query.string,
      search: Route.query.string,
      page: Route.query.number,
    },
    "/customers/organizations"
  )
);

export const Organizations = OrganizationsRoute(
  lazy(() => import(/* webpackChunkName: "Organizations" */ "./Organizations"))
);

export const links = {
  Organizations,
  OrganizationDetails,
  OrganizationDetailsTab,
  OrganizationMembersTab,
  OrganizationActivityTab,
  OrganizationAccessRequestsTab,
};
