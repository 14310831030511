import { Modal } from "@CreativelySquared/uikit";
import { FC, useCallback } from "react";
import { useEditUserRoleMutation } from "api/graphql";
import { ApolloError } from "@apollo/client";
import { useNotification } from "components";
import { useTranslation } from "react-i18next";
import {
  MemberForm,
  MemberFormData,
} from "Customers/Accounts/components/MemberForm";

interface Props {
  visible: boolean;
  onClose: () => void;
  accountId: string;
  userData?: MemberFormData & { userId: string };
}

export const EditMemberModal: FC<Props> = ({
  accountId,
  visible,
  onClose,
  userData,
}) => {
  const { t } = useTranslation("accounts");
  const { setNotification, notificationTypes } = useNotification();

  const [editUserRole] = useEditUserRoleMutation({
    onCompleted() {
      setNotification({
        message: t("common:user.edit.success"),
        type: notificationTypes.Success,
      });
    },
    onError(error) {
      setNotification({
        message: (error as ApolloError).message ?? t("common:common.error"),
        type: notificationTypes.Error,
      });
    },
  });

  const onSubmit = useCallback(
    async (data: MemberFormData) => {
      editUserRole({
        variables: {
          organizationId: accountId,
          userId: userData?.userId || "",
          role: {
            organizationId: accountId,
            role: data.role!,
          },
        },
        optimisticResponse({ userId, role }) {
          return {
            editUserRole: {
              userId,
              organizationRoles: [
                {
                  organization: {
                    organizationId: role?.organizationId || "",
                    name: "",
                  },
                  role: role?.role,
                },
              ],
              __typename: "User",
            },
          };
        },
      });
      onClose();
    },
    [accountId, userData?.userId]
  );

  return (
    <Modal visible={visible} onClose={onClose} closable>
      <MemberForm
        onSubmit={onSubmit}
        initialValues={userData}
        isEmailEditable={false}
        title={t("members.editMember.title")}
      />
    </Modal>
  );
};
