import { Dropdown, Input } from "@CreativelySquared/uikit";
import clsx from "clsx";
import { ErrorMessage, InputLabel } from "components";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Role } from "api/enums";
import { parseError } from "utils/form";

import styles from "./styles.module.scss";

interface Props {
  className?: string;
  role?: Role;
  error?: string | boolean | null;
  onChange: (role: Role) => void;
}

export const RoleInput: FC<Props> = ({ className, role, error, onChange }) => {
  const { t } = useTranslation("accounts");

  return (
    <fieldset className={clsx("flex flex-col relative", className)}>
      <InputLabel>{t("members.newMember.role.label")}</InputLabel>
      <Dropdown
        render={({ children }) => (
          <Input
            value={
              role && String(t(`common:user.roles.${role.toLocaleLowerCase()}`))
            }
            placeholder={t("members.newMember.role.placeholder")}
            variant={Input.variants.Secondary}
            className={styles.dropdown}
            postfix={<div>{children}</div>}
            postfixClassName="pointer-events-none"
            visibility="instant"
            readOnly
            error={error}
          />
        )}
        popoverClassName="w-full"
      >
        {[Role.OrgAdmin, Role.OrgUser].map((role) => (
          <Dropdown.Item
            key={role}
            value={role}
            onSelect={() => onChange(role)}
          >
            {t(`common:user.roles.${role.toLocaleLowerCase()}`)}
          </Dropdown.Item>
        ))}
      </Dropdown>
      {!!error && typeof error === "string" && (
        <ErrorMessage
          message={t(...parseError(error)).toString()}
          className="mt-3"
        />
      )}
    </fieldset>
  );
};
