import { BrandProfile } from "api/types";
import { memo } from "react";
import type { Object as ObjectType } from "ts-toolbelt";
import { Card } from "@CreativelySquared/uikit";
import { Media, Protect } from "components";
import { GetProps } from "react-router-hoc/lib/types";
import clsx from "clsx";
import { Roles } from "utils/roles";

import styles from "./styles.module.scss";

type Props = {
  brand: ObjectType.Partial<BrandProfile, "deep">;
  onClick?: () => void;
} & GetProps<typeof Card>;

export const BrandCard = Object.assign(
  memo<Props>(
    ({
      brand: { name, avatar, organization },
      onClick,
      className,
      ...rest
    }) => {
      const image = avatar?.thumbnail?.downloadUrl?.url;

      return (
        <Card
          className={clsx(styles.brandCard, className)}
          onClick={onClick}
          {...rest}
        >
          <Card.Title className={styles.brandCardTitle}>
            <span className="text-l text-blackberry font-bold text-center truncate">
              {name}
            </span>
            <Protect access={Roles.cs_admins}>
              <p className="text-md text-blue-steel font-regular text-center text-ellipsis overflow-hidden">
                {organization?.name}
              </p>
            </Protect>
          </Card.Title>
          <Card.Image className={styles.image}>
            {image && <Media url={image} />}
          </Card.Image>
        </Card>
      );
    }
  )
);

BrandCard.displayName = "BrandCard";
