import { Modal } from "@CreativelySquared/uikit";
import { FC, useCallback, useState } from "react";
import { useCreateAccountUserMutation } from "api/graphql";
import { useNotification } from "components";
import { useTranslation } from "react-i18next";
import { useNewUserErrorsHandler } from "Customers/components/Members/utils";
import {
  MemberForm,
  MemberFormData,
} from "Customers/Accounts/components/MemberForm";

interface Props {
  visible: boolean;
  onClose: () => void;
  accountId: string;
  onComplete?: () => Promise<any>;
}

export const NewMemberModal: FC<Props> = ({
  accountId,
  visible,
  onClose,
  onComplete,
}) => {
  const { t } = useTranslation("accounts");
  const { setNotification, notificationTypes } = useNotification();

  const handleUserError = useNewUserErrorsHandler();

  const [isCreating, setCreating] = useState(false);

  const [addUser] = useCreateAccountUserMutation();

  const onSubmit = useCallback(async (data: MemberFormData) => {
    try {
      setCreating(true);
      await addUser({
        variables: {
          organizationId: accountId,
          email: data.email,
          organizations: data.role
            ? {
                organizationId: accountId,
                role: data.role,
              }
            : undefined,
        },
      });
      await onComplete?.();
      setNotification({
        message: t("common:user.newUser.success"),
        type: notificationTypes.Success,
      });
      onClose();
    } catch (error) {
      handleUserError(error);
    } finally {
      setCreating(false);
    }
  }, []);

  return (
    <Modal visible={visible} onClose={onClose} closable>
      <MemberForm
        onSubmit={onSubmit}
        isSubmitting={isCreating}
        title={t("members.newMember.title")}
      />
    </Modal>
  );
};
