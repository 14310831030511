import "./locales";
import { lazy } from "react";
import { compose, Route } from "react-router-hoc";
import { SortOrder } from "utils/order";
import { Roles } from "utils/roles";
import { ProtectedRoute } from "utils/route";
import { idRegEx } from "utils/regex";
import {
  OrganizationType,
  SubscriptionPlanName,
  SubscriptionPlanStatus,
} from "api/enums";

import { AccountProjects } from "./Projects";
import { AccountDetailsData } from "./DetailsData";
import { AccountActivity } from "./Activity";
import { AccountNotes } from "./Notes";
import { AccountBrands } from "./Brands";
import {
  AccountCollectionDetails,
  AccountCollections,
  AccountCollectionDetailsAsset,
} from "./Collections";
import { AccountSavedItems } from "./SavedItems";

export const AccountsRoute = compose(
  ProtectedRoute({
    access: Roles.cs_admins,
  }),
  Route(
    {
      id: Route.query.string,
      search: Route.query.string,
      page: Route.query.number,
      sort: Route.query.oneOf("name", "credits", "projects", "createdAt"),
      sortOrder: Route.query.oneOf(...Object.values(SortOrder)),
      statuses: Route.query.array(
        Route.query.oneOf(...Object.values(SubscriptionPlanStatus))
      ),
      managers: Route.query.array(Route.query.string),
      organizations: Route.query.array(Route.query.string),
      organizationTypes: Route.query.array(
        Route.query.oneOf(...Object.values(OrganizationType))
      ),
      plan: Route.query.array(
        Route.query.oneOf(...Object.values(SubscriptionPlanName))
      ),
    },
    "/customers/accounts"
  )
);

export const Accounts = AccountsRoute(
  lazy(() => import(/* webpackChunkName: "Accounts" */ "./Accounts"))
);

export const AccountDetailsRoute = compose(
  ProtectedRoute({
    access: Roles.cs_admins,
  }),
  Route(
    {
      id: Route.params.regex(idRegEx),
    },
    ({ id }) => `/customers/accounts/${id}`
  )
);

export const AccountDetails = AccountDetailsRoute(
  lazy(
    () => import(/* webpackChunkName: "AccountDetails" */ "./AccountsDetails")
  )
);

export const links = {
  AccountDetails,
  Accounts,
  AccountBrands,
  AccountProjects,
  AccountDetailsData,
  AccountActivity,
  AccountNotes,
  AccountCollections,
  AccountCollectionDetails,
  AccountCollectionDetailsAsset,
  AccountSavedItems,
};
