import { useProfileQuery, useGetIntercomUserTokenLazyQuery } from "api/graphql";
import { useEffect } from "react";
import { getFullName } from "utils/users";
import * as Intercom from "@intercom/messenger-js-sdk";

export const useIntercom = () => {
  const { data: { me } = {} } = useProfileQuery({
    fetchPolicy: "cache-only",
  });
  const user = me?.user;

  const [loadToken] = useGetIntercomUserTokenLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: ({ intercom }) => {
      if (me && intercom?.token) {
        Intercom.update({
          email: user?.email || "",
          name: getFullName(user),
          user_hash: intercom.token,
        });
      }
    },
  });

  useEffect(() => {
    if (user?.userId) {
      loadToken();
    }
  }, [user?.userId]);
};
