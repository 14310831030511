import { lazy } from "react";
import { compose, Route } from "react-router-hoc";
import { ProtectedRoute } from "utils/route";
import { Roles } from "utils/roles";

import { OrganizationTabs } from "../types";

export const SettingsOrganizationAccessRequestsRoute = compose(
  ProtectedRoute({
    access: Roles.master_org_owner,
  }),
  Route(
    {
      page: Route.query.number,
    },
    `/settings/organization/${OrganizationTabs.AccessRequests}`
  )
);

export const SettingsOrganizationAccessRequests =
  SettingsOrganizationAccessRequestsRoute(
    lazy(
      () =>
        import(
          /* webpackChunkName: "SettingsOrganizationAccessRequests" */ "./AccessRequests"
        )
    )
  );
