import { Protect, Sidebar } from "components";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Switch, Route } from "react-router-dom";
import { links } from "App";
import { SidebarNavItem } from "components";
import { Roles } from "utils/roles";
import { ReactComponent as accounts } from "images/card.svg";
import { ReactComponent as organizations } from "images/company.svg";
import { useHomePageUrl } from "utils/hooks/useHomePageUrl";

import { Accounts, AccountDetails } from "./Accounts";
import { Organizations } from "./Organizations";
import { OrganizationDetails } from "./Organizations/OrganizationDetails";

const icons = { organizations, accounts };
const navigation = {
  Organizations,
  Accounts,
};

const Customers = memo(() => {
  const { t } = useTranslation("common");
  const homePageUrl = useHomePageUrl();

  return (
    <>
      <Protect access={Roles.cs_admins}>
        <Sidebar.Inject>
          <nav>
            <Switch>
              <Route path={OrganizationDetails.defaultProps?.path}>
                <Sidebar.BackButton to={links.Organizations()} />
              </Route>
              <Route path={AccountDetails.defaultProps?.path}>
                <Sidebar.BackButton to={links.Accounts()} />
              </Route>
              <Route>
                <Sidebar.BackButton to={homePageUrl} />
              </Route>
            </Switch>
            {Object.entries(navigation).map(([title, { link }]) => {
              const Icon = icons[title.toLowerCase() as keyof typeof icons];
              return (
                <SidebarNavItem
                  key={title}
                  to={link()}
                  Icon={Icon}
                  label={t(`navigation.customersTab.${title.toLowerCase()}`)}
                />
              );
            })}
          </nav>
        </Sidebar.Inject>
      </Protect>
      <Switch>
        <Organizations exact />
        <OrganizationDetails />
        <Accounts exact />
        <AccountDetails />
        <Redirect exact to={Organizations.link()} />
      </Switch>
    </>
  );
});

export default Customers;
