import { Button, Dropdown } from "@CreativelySquared/uikit";
import clsx from "clsx";
import { ErrorMessage } from "components";
import { ReactElement } from "react";
import { GetProps } from "react-router-hoc/lib/types";

import { ViewableComponent, Size } from "./ViewableComponent";

type Props = Omit<GetProps<typeof Dropdown>, "onSelect"> & {
  readOnly?: boolean;
  fontSize?: Size;
  textClassName?: string;
  value?: string;
  visibleValue?: string;
  placeholder?: string;
  onSelect?: (value?: string | null) => void;
};

export const ViewableDropdown = Object.assign(
  ({
    readOnly,
    label,
    fontSize,
    className,
    textClassName,
    error,
    placeholder,
    children,
    visibleValue,
    ...props
  }: Props): ReactElement => (
    <ViewableComponent
      readOnly={readOnly}
      label={label}
      fontSize={fontSize}
      className={className}
      textClassName={textClassName}
      value={visibleValue}
    >
      <Dropdown<string | null>
        label={placeholder}
        variant={Button.variants.Soft}
        error={error}
        className={clsx("w-full h-10", textClassName)}
        popoverClassName="z-10"
        {...props}
      >
        {children}
      </Dropdown>
      {!!error && typeof error === "string" && (
        <ErrorMessage message={error} className="mt-5 block" />
      )}
    </ViewableComponent>
  ),
  {
    size: Size,
  }
);
