import { lazy } from "react";
import { compose, Route } from "react-router-hoc";
import { Roles } from "utils/roles";
import { ProtectedRoute } from "utils/route";

export const CustomerDashboardRoute = compose(
  ProtectedRoute({ access: Roles.customer }),
  Route("/dashboard")
);

export const CustomerDashboard = CustomerDashboardRoute(
  lazy(
    () =>
      import(/* webpackChunkName: "CustomerDashboard" */ "./CustomerDashboard")
  )
);
