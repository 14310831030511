import { Modal } from "@CreativelySquared/uikit";
import { ModalTitle, useNotification } from "components";
import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useTransferCreditsMutation } from "api/graphql";
import { ApolloError } from "@apollo/client";

import {
  AllocateCreditsForm,
  AllocateCreditsProps,
  AllocateFormData,
} from "../AllocateCreditsForm";

type Props = Pick<
  AllocateCreditsProps,
  "accounts" | "isTransfer" | "onClose"
> & {
  visible: boolean;
};

export const TransferCreditsModal: FC<Props> = ({
  visible,
  onClose,
  ...props
}) => {
  const { t } = useTranslation("organizations");
  const { setNotification, notificationTypes } = useNotification();

  const [onAssign] = useTransferCreditsMutation();

  const onSubmit = useCallback(
    async (data: AllocateFormData) => {
      try {
        await onAssign({
          variables: {
            fromOrganizationId: data.fromAccountId || "",
            toOrganizationId: data.toAccountId || "",
            amount: data.amount ? Number(data.amount) : 0,
          },
        });
        onClose();
      } catch (error) {
        const _error = error as ApolloError;
        setNotification({
          message: _error.message ?? t("common:common.error"),
          type: notificationTypes.Error,
        });
      }
    },
    [onAssign]
  );

  return (
    <Modal visible={visible} onClose={onClose} disableBodyScroll>
      <ModalTitle>
        {t("details.accounts.transferCredits.transferBetweenAccounts")}
      </ModalTitle>
      <AllocateCreditsForm
        onClose={onClose}
        onSubmit={onSubmit}
        isTransfer
        {...props}
      />
    </Modal>
  );
};
