import { useTranslation } from "react-i18next";
import { Button } from "@CreativelySquared/uikit";
import { GetProps } from "react-router-hoc/lib/types";
import { memo, useEffect, useState } from "react";
import { usePrevious } from "utils/hooks/previous";
import clsx from "clsx";
import { ReactComponent as CloseIcon } from "images/check.svg";

import styles from "./styles.module.scss";

type Props = { reset?: boolean } & GetProps<typeof Button>;

export const SaveButton = memo<Props>(
  ({ className, disabled, loading, readonly, reset, ...props }) => {
    const [saved, setSaved] = useState(false);
    const wasLoading = usePrevious(loading);
    const { t } = useTranslation("common");

    useEffect(() => {
      if (wasLoading && !loading) {
        setSaved(true);
      }
    }, [loading, setSaved, wasLoading]);

    useEffect(() => {
      if (reset) setSaved(false);
    }, [reset]);

    return (
      <Button
        className={clsx(className, styles.saveButton, {
          [styles.saved]: saved,
        })}
        disabled={disabled && !saved}
        loading={loading}
        readonly={saved || readonly}
        variant={Button.variants.Action}
        {...props}
      >
        {saved && <CloseIcon className="mr-5 w-5 h-5" />}
        {t(`actions.${loading ? "saving" : saved ? "saved" : "save"}`)}
      </Button>
    );
  }
);
