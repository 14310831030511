import { FC, MouseEvent, useRef, useState } from "react";
import { ReactComponent as ActionsIcon } from "images/ellipsis.svg";
import { Button, Dropdown, Loader } from "@CreativelySquared/uikit";
import { useTranslation } from "react-i18next";
import { CellContext } from "@tanstack/react-table";
import clsx from "clsx";

import { UserType } from "../types";

interface Props {
  cell: CellContext<UserType, unknown>;
  onEdit?: (cell: CellContext<UserType, unknown>) => void;
  onDelete?: (cell: CellContext<UserType, unknown>) => void;
  onResend?: (cell: CellContext<UserType, unknown>) => Promise<any>;
}

export const ActionsCell: FC<Props> = ({
  onEdit,
  onDelete,
  onResend,
  cell,
}) => {
  const { t } = useTranslation("organizations");
  const [isResending, setResending] = useState(false);
  const dropdownRef = useRef<{ close: () => void }>(null);

  return (
    <Dropdown
      render={() => (
        <Button
          variant={Button.variants.Icon}
          type="button"
          className="ml-auto"
        >
          <ActionsIcon className="w-7 h-7" />
        </Button>
      )}
      placement={Dropdown.placements.BottomEnd}
      containerRef={dropdownRef}
    >
      {onEdit && (
        <Dropdown.Item onClick={() => onEdit(cell)}>
          {t("members.table.actions.edit")}
        </Dropdown.Item>
      )}
      {onDelete && (
        <Dropdown.Item onClick={() => onDelete(cell)}>
          {t("members.table.actions.remove")}
        </Dropdown.Item>
      )}
      {onResend && (
        <Dropdown.Item
          onClick={async (e: unknown) => {
            try {
              (e as MouseEvent<HTMLLIElement>).stopPropagation();
              setResending(true);
              await onResend(cell);
              dropdownRef.current?.close();
            } finally {
              setResending(false);
            }
          }}
          className={clsx(
            "flex items-center",
            isResending && "pointer-events-none"
          )}
        >
          <span>{t("common:user.invitation.resend")}</span>
          <Loader className={clsx("ml-4", !isResending && "invisible")} />
        </Dropdown.Item>
      )}
    </Dropdown>
  );
};
