import { flexRender, Row } from "@tanstack/react-table";
import { HTMLAttributes, ReactElement } from "react";

import { TableCell } from "../TableCell";
import { TableRow } from "../TableRow";

interface Props<D> {
  data: Row<D>[];
  rowClassName?: string;
  getRowProps?: (row: Row<D>) => HTMLAttributes<HTMLTableRowElement>;
}

export const TableBody = <D,>({
  data,
  rowClassName,
  getRowProps,
}: Props<D>): ReactElement => {
  return (
    <tbody>
      {data.map((row) => {
        const rowProps = getRowProps?.(row) ?? {};

        return (
          <TableRow key={row.id} className={rowClassName} {...rowProps}>
            {row.getVisibleCells().map((cell) => (
              <TableCell
                key={cell.id}
                style={{
                  maxWidth: `${cell.column.columnDef.maxSize}px`,
                  width: cell.column.getSize(),
                }}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </TableCell>
            ))}
          </TableRow>
        );
      })}
    </tbody>
  );
};
