import { Button, Modal, Tooltip } from "@CreativelySquared/uikit";
import clsx from "clsx";
import { memo, PropsWithChildren, useState } from "react";
import isEqual from "react-fast-compare";
import { GetProps } from "react-router-hoc/lib/types";
import { cn } from "utils/styles";
import { ReactComponent as PlayIcon } from "images/caretRight.svg";
import { useTranslation } from "react-i18next";
import { ReactComponent as InfoIcon } from "images/infoCircle.svg";

import styles from "./styles.module.scss";

enum Sizes {
  Regular = "regular",
  Small = "small",
}

type Props = {
  size?: Sizes;
  demoUrl?: string;
} & GetProps<typeof InfoIcon> &
  Pick<GetProps<typeof Tooltip>, "placement">;

export const SupportGuide = Object.assign(
  memo<PropsWithChildren<Props>>(
    ({
      children,
      demoUrl,
      size = Sizes.Regular,
      placement = Tooltip.placements.Right,
      className,
      ...props
    }) => {
      const { t } = useTranslation();
      const [activeDemo, setActiveDemo] = useState(false);

      return (
        <>
          <Tooltip
            pointer={true}
            variant={Tooltip.variants.Secondary}
            placement={placement}
            className="z-10"
            title={
              <section className={styles.supportGuideContent}>
                {children}
                {!!demoUrl && (
                  <Button
                    className={clsx(styles.supportGuideContentDemo, {
                      "my-4": !children,
                    })}
                    onClick={() => setActiveDemo(true)}
                    type="button"
                  >
                    <PlayIcon className="mr-4 w-[20px] h-[20px]" />
                    {t("support.demo")}
                  </Button>
                )}
              </section>
            }
          >
            <InfoIcon
              className={clsx(
                "stroke-[1.3]",
                styles[cn("supportGuideButton", size)],
                className
              )}
              {...props}
            />
          </Tooltip>
          <Modal
            visible={activeDemo}
            onClose={() => setActiveDemo(false)}
            closable
            className="p-0 w-[70vw] h-[40vw] flex items-center justify-center bg-[transparent]"
          >
            <iframe src={demoUrl} className="w-full h-full" />
          </Modal>
        </>
      );
    },
    isEqual
  ),
  {
    sizes: Sizes,
    placements: Tooltip.placements,
  }
);
