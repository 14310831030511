import { EventType } from "utils/event";
import { ReactComponent as comment } from "images/speach.svg";
import { ReactComponent as credit } from "images/money.svg";
import { ReactComponent as download } from "images/download.svg";
import { ReactComponent as like } from "images/thumbUp.svg";
import { ReactComponent as organization } from "images/user.svg";
import { ReactComponent as accept } from "images/check.svg";
import { ReactComponent as decline } from "images/closeCircle.svg";
import { ReactComponent as remove } from "images/bin.svg";
import { ReactComponent as change } from "images/pencil.svg";
import { ReactComponent as create } from "images/brand.svg";
import { ReactComponent as revert } from "images/reload.svg";
import { ReactComponent as prioritise } from "images/frontQueue.svg";
import { ReactComponent as loading } from "images/loading.svg";
import { ReactComponent as check } from "images/eye.svg";
import { ReactComponent as upload } from "images/upload.svg";
import { ReactComponent as plus } from "images/plus.svg";
import { ReactComponent as pending } from "images/hourglass.svg";
import { ReactComponent as star } from "images/star.svg";
import { ReactComponent as warning } from "images/warning.svg";
import { ReactComponent as calendar } from "images/calendar.svg";
import { ReactComponent as logo } from "images/CS.svg";
import { ReactComponent as discuss } from "images/bulkSpeech.svg";
import { ReactComponent as search } from "images/search.svg";
import { ReactComponent as camera } from "images/camera.svg";
import { ReactComponent as company } from "images/company.svg";
import { ReactComponent as card } from "images/card.svg";
import { ReactComponent as userAdd } from "images/userAdd.svg";
import { ReactComponent as userDelete } from "images/userDelete.svg";

const icons = {
  comment,
  credit,
  download,
  upload,
  like,
  organization,
  accept,
  decline,
  remove,
  change,
  create,
  revert,
  prioritise,
  loading,
  check,
  plus,
  pending,
  star,
  warning,
  calendar,
  logo,
  discuss,
  search,
  camera,
  company,
  card,
  userAdd,
  userDelete,
};

export const eventIcon = {
  [EventType.brand_profile_edited]: icons.change,
  [EventType.brand_profile_created]: icons.create,
  [EventType.project_partial_delivery]: icons.upload,
  [EventType.project_regressed_to_draft]: icons.revert,
  [EventType.new_asset_version_created]: icons.plus,
  [EventType.project_canceled]: icons.decline,
  [EventType.project_moved_in_queue]: icons.prioritise,
  [EventType.project_approved]: icons.loading,
  [EventType.project_submitted]: icons.check,
  [EventType.project_moved_to_under_revision]: icons.change,
  [EventType.project_finalized]: icons.accept,
  [EventType.project_completed]: icons.accept,
  [EventType.project_moved_to_queue]: icons.pending,
  [EventType.project_deleted]: icons.remove,
  [EventType.org_credits_under_threshold]: icons.credit,
  [EventType.organization_account_manager_assigned]: icons.organization,
  [EventType.project_account_manager_assigned]: icons.organization,
  [EventType.project_gallery_downloaded]: icons.download,
  [EventType.asset_downloaded]: icons.download,
  [EventType.asset_comment_created]: icons.comment,
  [EventType.brief_comment_created]: icons.comment,
  [EventType.asset_rating_edited]: icons.like,
  [EventType.project_feedback_created]: icons.star,
  [EventType.project_feedback_edited]: icons.star,
  [EventType.subscription_plan_expired]: icons.warning,
  [EventType.credits_expire_alert]: icons.calendar,
  [EventType.subscription_plan_renewed]: icons.accept,
  [EventType.custom_subscription_expire_alert]: icons.calendar,
  [EventType.auto_renewal_alert]: icons.revert,
  [EventType.asset_needs_changes]: icons.change,
  [EventType.brief_updated]: icons.change,
  [EventType.bulk_comments_created]: icons.discuss,
  [EventType.project_clarification_requested]: icons.search,
  [EventType.project_created]: icons.camera,
  [EventType.master_org_created]: icons.create,
  [EventType.master_org_edited]: icons.change,
  [EventType.org_linked_to_master_org]: icons.company,
  [EventType.org_unlinked_from_master_org]: icons.card,
  [EventType.org_created]: icons.create,
  [EventType.user_added_to_org]: icons.userAdd,
  [EventType.user_removed_from_organization]: icons.userDelete,
  [EventType.user_removed_from_master_organization]: icons.userDelete,
  [EventType.user_added_to_master_organization]: icons.userAdd,
  [EventType.org_credits_updated]: undefined,
  [EventType.subscription_plan_name_edited]: icons.accept,
  [EventType.subscription_plan_credits_limit_edited]: icons.credit,
  [EventType.subscription_plan_date_edited]: icons.calendar,
  [EventType.org_type_edited]: icons.change,
  [EventType.credits_transferred_between_organizations]: icons.credit,
  [EventType.credits_assigned_from_master_organization_credit_balance]:
    icons.credit,
};

export const subscriptionPlanIcon = {
  SUSPENDED: icons.warning,
  TRIAL: icons.accept,
  CANCELED: icons.decline,
  ACTIVE: icons.accept,
  PENDING: icons.logo,
  EXPIRED: icons.warning,
  VIEW_ONLY: icons.warning,
  default: icons.change,
};
