import { Button } from "@CreativelySquared/uikit";
import { DetailedHTMLProps, HTMLAttributes, memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as CrossIcon } from "images/close.svg";
import clsx from "clsx";
import { UploadMedia } from "components/UploadMedia";
import { FileStatuses, assetFormats } from "utils/types";
import { UploadType, useUpload } from "utils/hooks/upload";
import { UploadProgress } from "components/UploadProgress";
import {
  AssetDocument,
  ProjectAssetsDocument,
  GalleryProjectDocument,
  ProjectDocument,
  useCreateAssetVersionMutation,
} from "api/graphql";
import { useApolloClient } from "@apollo/client";
import { getOperationName } from "@apollo/client/utilities";

type Props = {
  organizationId: string;
  assetId: string;
  onClose: () => void;
  onComplete?: (assetVersionId: string) => void;
} & DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;

export const UploadAssetVersion = memo<Props>(
  ({ organizationId, assetId, onClose, onComplete, className, ...props }) => {
    const { t } = useTranslation();
    const client = useApolloClient();

    const [createAssetVersion, { data }] = useCreateAssetVersionMutation();

    const [upload, { uploadStatus }] = useUpload({
      onAttach: (fileIds) =>
        createAssetVersion({
          variables: {
            assetId,
            fileId: fileIds?.[0],
          },
        }),
    });

    const fileStatus = uploadStatus?.[0]?.status;

    useEffect(() => {
      if (fileStatus === FileStatuses.completed) {
        client
          .refetchQueries({
            include: [
              getOperationName(ProjectDocument)!,
              getOperationName(GalleryProjectDocument)!,
              getOperationName(ProjectAssetsDocument)!,
              {
                query: AssetDocument,
                variables: {
                  assetId,
                },
              } as unknown as string,
            ],
          })
          .then(() =>
            onComplete
              ? onComplete(data?.createAssetVersion?.assetVersionId ?? "")
              : onClose()
          );
      }
    }, [fileStatus]);

    return (
      <section
        className={clsx("relative p-10 w-[455px]", className)}
        {...props}
      >
        <Button
          onClick={onClose}
          variant={Button.variants.Icon}
          className="absolute right-4 top-4"
        >
          <CrossIcon className="w-8 h-8" />
        </Button>
        <h3>{t("common:asset.versions.title")}</h3>
        <UploadMedia
          onUpload={(file) => {
            upload({
              files: [file],
              type: UploadType.asset,
              organizationId,
            });
          }}
          className={clsx("mt-8 overflow-hidden")}
          formats={assetFormats}
          type={UploadMedia.types.Cover}
          size={UploadMedia.sizes.Tiny}
          name={t("common:asset.versions.name")}
        />
        <div className="absolute bottom-6 p-6 left-0 w-full">
          {fileStatus && <UploadProgress status={fileStatus as FileStatuses} />}
        </div>
      </section>
    );
  }
);
