import { OptionCard } from "components";
import { DetailedHTMLProps, FC, HTMLAttributes, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { OrganizationType } from "api/enums";
import { useFormikContext } from "formik";
import { PlanForm, PlanFormFields } from "Customers/components/PlanForm";

import { FormData } from "../../types";
import styles from "../../styles.module.scss";

type Props = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const Management: FC<Props> = ({ ...props }) => {
  const { t } = useTranslation("accounts");
  const {
    values: { accountType },
    values,
    setValues,
  } = useFormikContext<FormData>();

  const fields = useMemo(
    () =>
      accountType !== OrganizationType.Integrated
        ? [
            PlanFormFields.PlanName,
            PlanFormFields.AccountStatus,
            PlanFormFields.CreditLimit,
            PlanFormFields.RenewalDate,
          ]
        : [PlanFormFields.IntegratedCreditLimit],
    [accountType]
  );

  return (
    <div {...props}>
      <p className="text-l font-bold">{t("create.management.label")}</p>
      <div>
        {accountType !== OrganizationType.Standalone && (
          <fieldset className={styles.fieldset}>
            <label className={styles.label}>
              {t("create.management.option.title")}
            </label>
            <div className="flex gap-4 grid-cols-2">
              {[OrganizationType.Connected, OrganizationType.Integrated].map(
                (type) => (
                  <div key={type} className="relative">
                    <OptionCard
                      isSelected={type === accountType}
                      title={t(`create.management.option.${type}.title`)}
                      className="h-full"
                      descriptionClassName="mt-5"
                      onClick={() => {
                        setValues({
                          ...values,
                          accountType: type,
                          creditLimit: undefined,
                          planName: undefined,
                          accountStatus: undefined,
                          renewalDate: undefined,
                        });
                      }}
                      description={
                        <Trans
                          i18nKey={`create.management.option.${type}.description`}
                          t={t}
                          components={{
                            ul: <ul className="list-disc ml-7 mt-7" />,
                            li: <li />,
                          }}
                        />
                      }
                    />
                  </div>
                )
              )}
            </div>
          </fieldset>
        )}
        <PlanForm fields={fields} />
      </div>
    </div>
  );
};
