import { Modal } from "@CreativelySquared/uikit";
import { Role } from "api/enums";
import clsx from "clsx";
import { FC, useCallback, useState } from "react";
import { useAddUserToOrganizationsMutation } from "api/graphql";
import { ApolloError } from "@apollo/client";
import { UserErrorCodes } from "utils/users";
import { useNotification } from "components";
import { useTranslation } from "react-i18next";

import { MemberForm, MemberFormData } from "../MemberForm";

interface Props {
  visible: boolean;
  onClose: () => void;
  organizationId: string;
  onComplete?: () => Promise<any>;
}

export const NewMemberModal: FC<Props> = ({
  organizationId,
  visible,
  onClose,
  onComplete,
}) => {
  const { t } = useTranslation("organizations");
  const { setNotification, notificationTypes } = useNotification();

  const [isCreating, setCreating] = useState(false);

  const [addUser] = useAddUserToOrganizationsMutation();

  const onSubmit = useCallback(async (data: MemberFormData) => {
    try {
      setCreating(true);
      await addUser({
        variables: {
          masterOrganizationId: organizationId,
          email: data.email,
          organizations: Object.entries(data.roles)
            .map(([role, accountIds]) => {
              return accountIds.map((accountId) => ({
                organizationId: accountId,
                role: role as Role,
              }));
            })
            .flat(),
        },
      });
      await onComplete?.();
      setNotification({
        message: t("common:user.newUser.success"),
        type: notificationTypes.Success,
      });
      onClose();
    } catch (error) {
      if (
        error instanceof ApolloError &&
        error.graphQLErrors?.[0]?.extensions?.code ===
          UserErrorCodes.UserAlreadyInOrganizationError
      ) {
        setNotification({
          message: t("common:user.newUser.errors.exists"),
          type: notificationTypes.Error,
        });
      } else {
        setNotification({
          message: (error as ApolloError).message ?? t("common:common.error"),
          type: notificationTypes.Error,
        });
      }
    } finally {
      setCreating(false);
    }
  }, []);

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      className={clsx("w-full max-w-[1032px]")}
      closable
    >
      <MemberForm
        organizationId={organizationId}
        onSubmit={onSubmit}
        isSubmitting={isCreating}
        title={t("members.newMember.title")}
      />
    </Modal>
  );
};
