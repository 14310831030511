import { lazy } from "react";
import { compose, Route } from "react-router-hoc";
import { EventType } from "utils/event";
import { ProtectedRoute } from "utils/route";
import { PermissionActions, PermissionResources } from "api/enums";

import { OrganizationTabs } from "../types";

export const SettingsOrganizationActivityRoute = compose(
  ProtectedRoute({
    permission: {
      resource: PermissionResources.MasterOrganizationEvents,
      actions: PermissionActions.Read,
    },
  }),
  Route(
    {
      page: Route.query.number,
      type: Route.query.array(Route.query.oneOf(...Object.values(EventType))),
    },
    `/settings/organization/${OrganizationTabs.Activity}`
  )
);

export const SettingsOrganizationActivity = SettingsOrganizationActivityRoute(
  lazy(
    () =>
      import(
        /* webpackChunkName: "SettingsOrganizationActivity" */ "./Activity"
      )
  )
);
