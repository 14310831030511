import { lazy } from "react";
import { Route } from "react-router-hoc";

import { OrganizationTabs } from "../types";

export const SettingsOrganizationDetailsRoute = Route(
  `/settings/organization/${OrganizationTabs.Details}`
);

export const SettingsOrganizationDetails = SettingsOrganizationDetailsRoute(
  lazy(
    () =>
      import(/* webpackChunkName: "SettingsOrganizationDetails" */ "./Details")
  )
);
