import { SuspenseQueryHookOptions, useSuspenseQuery } from "@apollo/client";
import { PermissionResources, PermissionActions } from "api/enums";
import { PermissionsDocument } from "api/graphql";
import { PermissionsQuery, PermissionsQueryVariables } from "api/types";
import { useCallback, useMemo } from "react";

export type Permission = {
  resource: PermissionResources;
  actions: PermissionActions | PermissionActions[];
};

export const usePermissions = (
  options?: SuspenseQueryHookOptions<
    PermissionsQuery,
    PermissionsQueryVariables
  >
) => {
  const { data: { getUserPermissions } = {}, error } = useSuspenseQuery<
    PermissionsQuery,
    PermissionsQueryVariables
  >(PermissionsDocument, {
    fetchPolicy: "cache-first",
    ...options,
  });

  const permissions = useMemo(
    () =>
      getUserPermissions?.permissions?.reduce((acc, permission) => {
        if (!permission?.resource) return acc;

        const resource = permission.resource as PermissionResources;
        const actions = permission.actions as PermissionActions[];

        if (acc.has(resource)) {
          acc.get(resource)?.push(...actions);
        } else {
          acc.set(resource, actions);
        }

        return acc;
      }, new Map<PermissionResources, PermissionActions[]>()),
    [getUserPermissions]
  );

  const checkPermission = useCallback(
    ({ resource, actions }: Permission) => {
      const resourcePermissions =
        permissions?.get(resource) || permissions?.get(PermissionResources.All);

      if (!resourcePermissions) return false;

      return (
        resourcePermissions.includes(PermissionActions.All) ||
        (Array.isArray(actions)
          ? actions.every((action) => resourcePermissions.includes(action))
          : resourcePermissions.includes(actions))
      );
    },
    [permissions]
  );

  return {
    error,
    permissions,
    checkPermission,
  };
};
