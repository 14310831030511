import { number, object } from "yup";

import { validationSchema as planFormValidationSchema } from "../PlanForm";

export const validationSchema = object({
  planType: planFormValidationSchema.fields.planName,
  planLimit: planFormValidationSchema.fields.creditLimit,
  planExpirationDate: planFormValidationSchema.fields.renewalDate,
  planStatus: planFormValidationSchema.fields.accountStatus,
  planBalance: number().required("common:validation.required"),
});
