import { compose, Route } from "react-router-hoc";
import { Roles } from "utils/roles";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { ProtectedRoute } from "utils/route";
import { Alert, Button } from "@CreativelySquared/uikit";
import { useFormik } from "formik";
import { object, string } from "yup";
import {
  ProjectsDocument,
  useCreateProjectMutation,
  useProfileQuery,
} from "api/graphql";
import { getOperationName } from "@apollo/client/utilities";
import { BriefCategory } from "api/enums";
import { links } from "App";
import { idRegEx } from "utils/regex";
import { SelectBriefCategory } from "components";

import styles from "../styles.module.scss";

const CreateBriefRoute = compose(
  ProtectedRoute({
    access: [Roles.cs_admins, Roles.customer],
  }),
  Route(
    {
      organizationId: Route.query.regex(idRegEx),
    },
    "/brief/create"
  )
);

const validationSchema = object({
  category: string().required().oneOf(Object.values(BriefCategory)),
});

export const CreateBrief = CreateBriefRoute(
  ({
    match: {
      query: { organizationId },
    },
    history: { replace },
  }) => {
    const { t } = useTranslation("brief");
    const { data: { me } = {} } = useProfileQuery();
    const [
      createProject,
      { loading: createProjectLoading, error: createProjectError },
    ] = useCreateProjectMutation({
      refetchQueries: [getOperationName(ProjectsDocument)!],
    });

    const {
      handleSubmit,
      setFieldValue,
      touched,
      submitCount,
      errors,
      isSubmitting,
      values: { category },
    } = useFormik<{ category?: BriefCategory | null }>({
      initialValues: {
        category: null,
      },
      onSubmit: async ({ category }) => {
        if (!category) return;
        const project = await createProject({
          variables: {
            organizationId:
              (organizationId || me?.organization?.organizationId) ?? "",
            projectFields: {},
            fields: {
              category,
            },
          },
        });

        if (project.data?.createProject?.projectId)
          replace(
            links.Brief({
              id: project.data?.createProject?.projectId,
            })
          );
      },
      validationSchema,
    });

    const error =
      !!createProjectError?.message ||
      (Boolean(touched.category || submitCount) && errors.category);

    return (
      <main className={styles.briefStep}>
        <Helmet>
          <title>{t("create.title")}</title>
        </Helmet>
        <h1>{t("create.title")}</h1>

        <form
          noValidate
          onSubmit={handleSubmit}
          className="flex flex-col justify-center"
        >
          {error && (
            <Alert
              icon
              className="w-full mb-8"
              title={createProjectError?.message ?? t("brief.error")}
              variant={Alert.variants.Error}
            />
          )}
          <SelectBriefCategory
            value={category}
            onSelect={(briefCategory) =>
              setFieldValue("category", briefCategory)
            }
          />
          <Button
            type="submit"
            loading={isSubmitting || createProjectLoading}
            className="w-full mt-10"
          >
            {t("common:navigation.next")}
          </Button>
        </form>
      </main>
    );
  }
);
