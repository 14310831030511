import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { useProfileQuery } from "api/graphql";
import { withSuspense } from "utils/hooks/suspense";
import { Permission, usePermissions } from "utils/hooks/permission";
import { Login } from "Authorization/Login";
import { Redirect } from "react-router";

const USER_SETTINGS_KEY = "userSettings";

type UserSettings = {
  levelUpBanner: boolean;
};

interface ContextType {
  userSettings?: UserSettings;
  updateUserSettings: (settings: Partial<UserSettings>) => void;
  checkPermission: (permission: Permission) => boolean;
}

type Settings = { [userId: string]: UserSettings };

const defaultUserSettings: UserSettings = {
  levelUpBanner: true,
};
const UserSettingsContext = createContext<ContextType>({
  userSettings: defaultUserSettings,
  updateUserSettings: () => {},
  checkPermission: () => true,
});

const useProfileSuspenseQuery = withSuspense(useProfileQuery);

const getSettings = () => {
  const settings: Settings = JSON.parse(
    localStorage.getItem(USER_SETTINGS_KEY) || "{}"
  );

  return settings;
};

const getUserSettings = (userId?: string) => {
  if (!userId) return defaultUserSettings;

  const settings = getSettings();

  return settings[userId] || defaultUserSettings;
};

export const UserSettingsProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { data: { me } = {} } = useProfileSuspenseQuery({
    suspense: true,
  });
  const { permissions, error, checkPermission } = usePermissions();

  const [userSettings, setUserSettings] = useState<UserSettings>(
    getUserSettings(me?.user?.userId)
  );

  const updateUserSettings = useCallback(
    (userSettings: Partial<UserSettings>) => {
      if (!me?.user?.userId) return;

      const settings = getSettings();
      const newUserSettings = { ...settings[me.user?.userId], ...userSettings };

      setUserSettings(newUserSettings);
    },
    [me]
  );

  useEffect(() => {
    if (!me) return;

    const userSettings = getUserSettings(me.user?.userId);

    if (userSettings) setUserSettings(userSettings);
  }, [me]);

  useEffect(() => {
    if (!me) return;

    const settings: Settings = JSON.parse(
      localStorage.getItem(USER_SETTINGS_KEY) || "{}"
    );

    localStorage.setItem(
      USER_SETTINGS_KEY,
      JSON.stringify({ ...settings, [me.user?.userId ?? ""]: userSettings })
    );
  }, [userSettings]);

  if (!permissions || !!error) {
    return <Redirect to={Login.link()} />;
  }

  return (
    <UserSettingsContext.Provider
      value={{ userSettings, updateUserSettings, checkPermission }}
    >
      {children}
    </UserSettingsContext.Provider>
  );
};

export const useUserSettings = () => useContext(UserSettingsContext);

export const useHasPermission = (permission?: Permission) => {
  const { checkPermission } = useContext(UserSettingsContext);

  const hasPermission = useMemo(() => {
    if (!permission) return true;

    return checkPermission(permission);
  }, [checkPermission]);

  return hasPermission;
};
