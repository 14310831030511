import { FC, useMemo } from "react";
import { Dropdown } from "@CreativelySquared/uikit";
import { ReactComponent as BinIcon } from "images/bin.svg";
import { useGetOrganizationAccountsQuery } from "api/graphql";
import { CheckboxSelector } from "components";

import styles from "./styles.module.scss";

interface Props {
  value: string[];
  selectedValues: string[];
  organizationId: string;
  onChange: (newValue: string[]) => void;
}

export const RoleCell: FC<Props> = ({
  value,
  organizationId,
  selectedValues,
  onChange,
}) => {
  const { data: { accounts } = {}, loading: accountsLoading } =
    useGetOrganizationAccountsQuery({
      variables: {
        filters: {
          masterOrganizationId: [organizationId],
        },
      },
    });

  const accountOptions = useMemo(
    () =>
      accounts?.nodes?.map((account) => ({
        id: account?.organizationId || "",
        name: account?.name || "",
      })),
    [accounts]
  );

  const options = accountOptions?.map((option) => ({
    ...option,
    disabled: !value.includes(option.id) && selectedValues.includes(option.id),
  }));

  return (
    <div className="flex items-center gap-3 w-full">
      <div className="w-full">
        <CheckboxSelector
          value={value}
          options={options}
          onChange={(newValue) => {
            onChange(newValue as string[]);
          }}
          searchLoading={accountsLoading}
          popoverClassName={styles.popover}
          placement={Dropdown.placements.TopStart}
        />
      </div>
      <button
        className={styles.delete}
        onClick={() => {
          onChange([]);
        }}
        type="button"
      >
        <BinIcon />
      </button>
    </div>
  );
};
