import { ActivityView } from "Activity/components/ActivityView";
import { useProjectEventsQuery } from "api/graphql";
import { ActivityFilter } from "components";
import { ProjectHeaderInjector } from "Projects/components/ProjectHeaderInjector";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { compose, Route } from "react-router-hoc";
import { EventType } from "utils/event";
import { usePagination } from "utils/hooks/pagination";
import { Roles } from "utils/roles";
import { ProtectedRoute } from "utils/route";

export const ProjecActivityRoute = compose(
  ProtectedRoute({ access: Roles.cs_admins }),
  Route(
    {
      id: Route.params.string,
      type: Route.query.array(Route.query.oneOf(...Object.values(EventType))),
      page: Route.query.number,
      eventId: Route.query.string,
    },
    ({ id }) => `/projects/${id}/activity`
  )
);

const projectEvents = [
  EventType.project_created,
  EventType.brief_updated,
  EventType.asset_comment_created,
  EventType.brief_comment_created,
  EventType.bulk_comments_created,
  EventType.project_deleted,
  EventType.project_completed,
  EventType.project_submitted,
  EventType.project_approved,
  EventType.project_canceled,
  EventType.project_regressed_to_draft,
  EventType.project_partial_delivery,
  EventType.project_moved_to_queue,
  EventType.project_moved_to_under_revision,
  EventType.project_finalized,
  EventType.project_moved_in_queue,
  EventType.project_clarification_requested,
  EventType.new_asset_version_created,
  EventType.asset_needs_changes,
];

export const ProjectActivity = ProjecActivityRoute(
  ({
    match: {
      params: { id },
      query: { type, page },
      query,
      params,
    },
    link,
  }) => {
    const { t } = useTranslation();
    const pagination = usePagination({ page });
    const {
      data: { getProjectEvents } = {},
      loading: getProjectEventsLoading,
    } = useProjectEventsQuery({
      variables: {
        projectId: id,
        eventNames: (type || projectEvents) as EventType[],
        pagination,
      },
      fetchPolicy: "no-cache",
    });
    const loading = getProjectEventsLoading && !getProjectEvents?.nodes?.length;

    return (
      <section>
        <Helmet title={t("activity:title")} />
        <ProjectHeaderInjector>
          <ActivityFilter
            className="min-w-min"
            value={{ ...query, ...params, page: 1 }}
            link={link}
            events={projectEvents}
          />
        </ProjectHeaderInjector>
        <ActivityView
          events={getProjectEvents?.nodes}
          loading={loading}
          pagination={pagination}
          totalPages={getProjectEvents?.pageInfo?.totalPages}
          projectId={id}
          query={query}
          params={params}
          link={link}
        />
      </section>
    );
  }
);
