import { lazy } from "react";
import { Route } from "react-router-hoc";
import { membersRouteParams } from "Customers/Accounts/DetailsData";

import { AccountTabs } from "../types";

export const SettingsAccountMembersRoute = Route(
  membersRouteParams,
  `/settings/account/${AccountTabs.Members}`
);

export const SettingsAccountMembers = SettingsAccountMembersRoute(
  lazy(
    () => import(/* webpackChunkName: "SettingsAccountMembers" */ "./Members")
  )
);
