import { useBrandsQuery } from "api/graphql";
import { Helmet } from "react-helmet";
import { compose, Route } from "react-router-hoc";
import { idRegEx } from "utils/regex";
import { Roles } from "utils/roles";
import { ProtectedRoute } from "utils/route";
import { useTranslation } from "react-i18next";
import { Loader } from "@CreativelySquared/uikit";
import { Pagination } from "components";
import { usePagination } from "utils/hooks/pagination";
import { ProjectSortingField } from "api/types";
import { BrandCard } from "Brands/components/BrandCard";
import { links } from "App";

export const AccountBrandsRoute = compose(
  ProtectedRoute({ access: Roles.cs_admins }),
  Route(
    {
      id: Route.params.regex(idRegEx),
      page: Route.query.number,
    },
    ({ id }) => `/customers/accounts/${id}/brands`
  )
);

export const AccountBrands = AccountBrandsRoute(
  ({
    match: {
      params: { id },
      query: { page = 1 },
    },
    history: { push },
  }) => {
    const { t } = useTranslation("accounts");
    const pagination = usePagination<ProjectSortingField>({ page });

    const { data: { getBrandProfiles: brands } = {}, loading: brandsLoading } =
      useBrandsQuery({
        variables: {
          organizationId: id,
          pagination,
        },
        fetchPolicy: "cache-and-network",
      });

    const loading = brandsLoading && !brands?.nodes?.length;

    return (
      <main>
        <Helmet title={t("projects.title")} />

        {loading && (
          <div className="flex h-full items-center justify-center">
            <Loader radius={50} className="mt-6" />
          </div>
        )}

        {!!brands?.nodes?.length && (
          <section className="mt-10 grid grid-cols-4 gap-8">
            {brands?.nodes?.map((brand) => (
              <BrandCard
                key={brand?.brandProfileId}
                brand={brand}
                onClick={() =>
                  push(links.BrandDetails({ id: brand?.brandProfileId ?? "" }))
                }
              />
            ))}
          </section>
        )}

        {!loading && brands?.nodes?.length === 0 && (
          <section className="mt-10">
            <h2 className="font-medium mb-7 text-lg text-blackberry">
              {t(`brands.empty.title`)}
            </h2>
            <span className="text-sm text-blue-steel">
              {t(`brands.empty.description`)}
            </span>
          </section>
        )}

        {brands?.nodes && !loading && (
          <Pagination
            className="mt-11"
            page={page}
            loading={brandsLoading}
            total={brands?.pageInfo?.totalPages}
          />
        )}
      </main>
    );
  }
);
