import { useTranslation } from "react-i18next";
import { FC } from "react";
import { useAccountQuery } from "api/graphql";
import { Loader } from "@CreativelySquared/uikit";

interface Props {
  id: string;
}

export const Usage: FC<Props> = ({ id }) => {
  const { t } = useTranslation("accounts");

  const { data: { account } = {}, loading: accountLoading } = useAccountQuery({
    variables: { id },
  });

  const { credits, assets, projects } = account?.subscriptionPlan?.usage ?? {};

  return (
    <section>
      <h3>{t("usage.title")}</h3>
      <div className="flex gap-6 mt-8">
        {Object.entries({ credits, assets, projects }).map(([name, value]) => (
          <div
            key={name}
            className="flex flex-1 flex-col items-center bg-grey rounded-lg font-bold text-blackberry pt-9 pb-11"
          >
            {accountLoading ? (
              <Loader radius={40} className="mb-7" />
            ) : (
              <p className="text-4xl mb-6">{value ?? 0}</p>
            )}
            <p className="text-l">{t(`usage.${name}`)}</p>
          </div>
        ))}
      </div>
    </section>
  );
};
