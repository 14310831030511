import { Modal } from "@CreativelySquared/uikit";
import { Role } from "api/types";
import clsx from "clsx";
import { FC, useCallback } from "react";
import {
  useEditMasterOrganizationUserMutation,
  useGetOrganizationAccountsQuery,
} from "api/graphql";
import { ApolloError } from "@apollo/client";
import { useNotification } from "components";
import { useTranslation } from "react-i18next";

import { MemberForm, MemberFormData } from "../MemberForm";

interface Props {
  visible: boolean;
  onClose: () => void;
  organizationId: string;
  userData?: MemberFormData & { userId: string };
}

export const EditMemberModal: FC<Props> = ({
  organizationId,
  visible,
  onClose,
  userData,
}) => {
  const { t } = useTranslation("organizations");
  const { setNotification, notificationTypes } = useNotification();

  const { data: { accounts: accountsData } = {} } =
    useGetOrganizationAccountsQuery({
      variables: {
        filters: {
          masterOrganizationId: [organizationId],
        },
      },
    });

  const [editUser] = useEditMasterOrganizationUserMutation({
    onCompleted() {
      setNotification({
        message: t("common:user.edit.success"),
        type: notificationTypes.Success,
      });
    },
    onError(error) {
      setNotification({
        message: (error as ApolloError).message ?? t("common:common.error"),
        type: notificationTypes.Error,
      });
    },
  });

  const getMasterOrganizationRole = useCallback(
    (role: Role, organizationIds: string[]) => {
      const accounts = accountsData?.nodes || [];

      return organizationIds.map((organizationId) => ({
        role,
        organization: {
          name:
            accounts.find(
              (account) => account?.organizationId === organizationId
            )?.name || "",
          organizationId,
        },
      }));
    },
    [accountsData]
  );

  const onSubmit = useCallback(
    async (data: MemberFormData) => {
      editUser({
        variables: {
          masterOrganizationId: organizationId,
          userId: userData?.userId || "",
          roles: Object.entries(data.roles)
            .map(([role, accountIds]) => {
              return {
                role: role as Role,
                organizationIds: accountIds,
              };
            })
            .filter((data) => !!data.organizationIds.length),
        },
        optimisticResponse({ userId, roles }) {
          return {
            editMasterOrganizationUser: {
              userId,
              masterOrganizationRoles: Array.isArray(roles)
                ? roles
                    ?.map(({ role, organizationIds }) =>
                      getMasterOrganizationRole(role, organizationIds)
                    )
                    .flat()
                : getMasterOrganizationRole(roles.role, roles.organizationIds),
              __typename: "User",
            },
          };
        },
      });
      onClose();
    },
    [getMasterOrganizationRole, organizationId, userData?.userId]
  );

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      className={clsx("w-full max-w-[1032px]")}
      closable
    >
      <MemberForm
        organizationId={organizationId}
        onSubmit={onSubmit}
        initialValues={userData}
        isEmailEditable={false}
        title={t("members.editMember.title")}
      />
    </Modal>
  );
};
