import { useState, useEffect } from "react";
import debounce from "lodash/debounce";

export const useDebounceValue = <T>(value: T, delay = 700) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = debounce((newValue) => {
      setDebouncedValue(newValue);
    }, delay);

    handler(value);

    return () => {
      handler.cancel();
    };
  }, [value, delay]);

  return debouncedValue;
};
