import { FC, useMemo } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { GetRouteProps } from "utils/types";
import { Empty, Pagination, Table } from "components";
import { useGetAccessRequestsQuery } from "api/graphql";
import { RequestStatuses } from "api/enums";
import { usePagination } from "utils/hooks/pagination";
import { ColumnDef } from "@tanstack/react-table";
import { GetAccessRequestsQuery } from "api/types";
import { formatDate } from "utils/time";
import { Loader } from "@CreativelySquared/uikit";

import { Actions } from "./Actions";

import { OrganizationAccessRequestsTabRoute } from "./index";

type TabProps = GetRouteProps<typeof OrganizationAccessRequestsTabRoute>;

type AccessRequestsType = NonNullable<
  GetAccessRequestsQuery["accessRequests"]
>["nodes"];

type AccessRequestType = UnwrapArray<AccessRequestsType>;

interface Props {
  organizationId: string;
  page: number;
}

export const AccessRequests: FC<Props> = ({ organizationId, page }) => {
  const { t } = useTranslation("organizations");

  const pagination = usePagination({
    page,
  });

  const { data: { accessRequests } = {}, loading } = useGetAccessRequestsQuery({
    variables: {
      filters: {
        status: [RequestStatuses.Pending],
        masterOrganizationId: organizationId,
      },
      pagination,
    },
    fetchPolicy: "cache-and-network",
  });

  const columns = useMemo<ColumnDef<AccessRequestType>[]>(
    () => [
      {
        header: t("accessRequests.table.userName"),
        id: "userName",
        cell: (cell) => {
          const user = cell.row.original?.createdBy;

          return (
            <div>
              <div className="truncate">{user?.fullName}</div>
              <div className="text-sm text-mid-blue-steel truncate">
                {user?.email}
              </div>
            </div>
          );
        },
        size: 310,
      },
      {
        header: t("accessRequests.table.accountName"),
        accessorKey: "organization.name",
        size: 270,
      },
      {
        header: t("accessRequests.table.createdDate"),
        accessorKey: "createdAt",
        size: 200,
        cell: (cell) => {
          const createdDate = cell.getValue() as string;

          return formatDate(Number(createdDate));
        },
      },
      {
        header: "",
        id: "actions",
        cell: (cell) => {
          return (
            cell.row.original?.requestId && (
              <Actions requestId={cell.row.original?.requestId} />
            )
          );
        },
        size: 140,
      },
    ],
    []
  );

  if (loading && !accessRequests) {
    return (
      <div className="flex justify-center">
        <Loader radius={50} className="mt-6" />
      </div>
    );
  }

  return (
    <>
      <h2 className="text-2xl mb-8">{t("accessRequests.tab")}</h2>

      <Table data={accessRequests?.nodes || []} columns={columns} />
      {!accessRequests?.nodes?.length && (
        <div className="flex justify-center flex-col items-center">
          <Empty title={t("accessRequests.table.empty")} />
        </div>
      )}
      {accessRequests && (
        <Pagination
          className="mt-11"
          page={page}
          total={accessRequests.pageInfo?.totalPages}
        />
      )}
    </>
  );
};

const OrganizationAccessRequestsTab: FC<TabProps> = ({
  match: { params, query },
}) => {
  const { t } = useTranslation("organizations");

  return (
    <>
      <Helmet>
        <title>{t("accessRequests.tab")}</title>
      </Helmet>
      <AccessRequests organizationId={params.id} page={query.page || 1} />
    </>
  );
};

export default OrganizationAccessRequestsTab;
