import { compose, Route } from "react-router-hoc";
import { idRegEx } from "utils/regex";
import { Roles } from "utils/roles";
import { ProtectedRoute } from "utils/route";
import { SortFields, SortOrder } from "utils/order";
import { MemberRoles } from "Customers/components/Members";

export const membersRouteParams = {
  members_page: Route.query.number,
  members_search: Route.query.string,
  members_sort: Route.query.oneOf(SortFields.fullName),
  members_sortOrder: Route.query.oneOf(...Object.values(SortOrder)),
  members_roles: Route.query.array(Route.query.oneOf(...MemberRoles)),
};

export const AccountDetailsDataRoute = compose(
  ProtectedRoute({
    access: Roles.cs_admins,
  }),
  Route(
    {
      id: Route.params.regex(idRegEx),
      ...membersRouteParams,
    },
    ({ id }) => `/customers/accounts/${id}/details`
  )
);
