import { Button } from "@CreativelySquared/uikit";
import { ComponentType, memo, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { contactSalesURL, pricingURL } from "utils/links";

enum Actions {
  pricing = "pricing",
  sales = "sales",
}

export interface Props {
  Image: ComponentType<{ className?: string }>;
  title: string;
  description: string | ReactElement;
  actions?: Actions[];
}

export const Restriction = Object.assign(
  memo<Props>(({ Image, title, description, actions }) => {
    const { t } = useTranslation();

    return (
      <section className="flex flex-col items-center">
        {<Image className="mb-8" />}
        <h3>{title}</h3>
        <p className="text-blackberry text-center whitespace-pre-line mt-4">
          {description}
        </p>
        <section className="mt-8 flex gap-x-6">
          {actions?.map((action) => (
            <a
              href={action === Actions.pricing ? pricingURL : contactSalesURL}
              key={action}
            >
              <Button
                variant={
                  action === Actions.pricing
                    ? Button.variants.Secondary
                    : Button.variants.Primary
                }
              >
                {t(`actions.${action}`)}
              </Button>
            </a>
          ))}
        </section>
      </section>
    );
  }),
  {
    actions: Actions,
  }
);
