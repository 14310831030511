/* eslint-disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  JSON: any;
};

export type ActivityNotificationPreferencesInput = {
  assetCommentCreated?: InputMaybe<Scalars['Boolean']>;
  assetVersionUpdated?: InputMaybe<Scalars['Boolean']>;
  briefCommentCreated?: InputMaybe<Scalars['Boolean']>;
};

export enum ActivityPreferences {
  AssetCommentCreated = 'ASSET_COMMENT_CREATED',
  AssetVersionUpdated = 'ASSET_VERSION_UPDATED',
  BriefCommentCreated = 'BRIEF_COMMENT_CREATED'
}

export type AdConceptInput = {
  message: Scalars['String'];
  productDescription?: InputMaybe<Scalars['String']>;
  productName?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  type: Scalars['String'];
};

export type AdStoryboardFrameInput = {
  message: Scalars['String'];
  title: Scalars['String'];
};

export type AdStoryboardInput = {
  frames: Array<AdStoryboardFrameInput>;
  productDescription?: InputMaybe<Scalars['String']>;
  productName?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  type?: InputMaybe<Scalars['String']>;
};

export type AddUserToOrganizationsInput = {
  organizationId: Scalars['String'];
  role: Role;
};

export enum AiBriefItemType {
  AdConcept = 'AD_CONCEPT',
  AdMindMap = 'AD_MIND_MAP',
  AdStoryboard = 'AD_STORYBOARD',
  ProductionBrief = 'PRODUCTION_BRIEF',
  UgcIdea = 'UGC_IDEA',
  UgcStoryboard = 'UGC_STORYBOARD'
}

export enum AiConceptFramework {
  CustomerJourney = 'CUSTOMER_JOURNEY',
  Emotional = 'EMOTIONAL',
  PersonaBased = 'PERSONA_BASED',
  ProblemSolution = 'PROBLEM_SOLUTION',
  ValueProposition = 'VALUE_PROPOSITION'
}

export enum AiStoryboardFramework {
  ChallengeDriven = 'CHALLENGE_DRIVEN',
  SolutionFirst = 'SOLUTION_FIRST'
}

export enum AssetCommentNotificationRules {
  AssetCommentCreated = 'ASSET_COMMENT_CREATED'
}

export type AssetLibraryFiltersInput = {
  assetStatuses?: InputMaybe<Array<AssetStatus>>;
  assetTypes?: InputMaybe<Array<Scalars['ID']>>;
  brandProfileIds?: InputMaybe<Array<Scalars['ID']>>;
  collectionIds?: InputMaybe<Array<Scalars['ID']>>;
  conceptIds?: InputMaybe<Array<Scalars['ID']>>;
  dateCreated?: InputMaybe<DataRangeInput>;
  isShareableOnDate?: InputMaybe<Scalars['String']>;
  likedByUserIds?: InputMaybe<Array<Scalars['ID']>>;
  masterOrganizationId?: InputMaybe<Array<Scalars['ID']>>;
  organizations?: InputMaybe<Array<Scalars['ID']>>;
  projectCategories?: InputMaybe<Array<BriefCategory>>;
  projectStatuses?: InputMaybe<Array<ProjectStatusValues>>;
  projects?: InputMaybe<Array<Scalars['ID']>>;
  rating?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  shareable?: InputMaybe<Scalars['String']>;
  tagIds?: InputMaybe<Array<Scalars['ID']>>;
};

export enum AssetNotificationRules {
  AssetVersionUpdated = 'ASSET_VERSION_UPDATED'
}

export enum AssetStatus {
  Accepted = 'ACCEPTED',
  Delivered = 'DELIVERED',
  NeedsChanges = 'NEEDS_CHANGES',
  NeedsReview = 'NEEDS_REVIEW'
}

export type AudioMetadataFieldsInput = {
  bitrate?: InputMaybe<Scalars['String']>;
  codec?: InputMaybe<Scalars['String']>;
  codecLong?: InputMaybe<Scalars['String']>;
  codecProfile?: InputMaybe<Scalars['String']>;
  durationInSeconds?: InputMaybe<Scalars['Float']>;
};

export type BetweenDatesFilter = {
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};

export type BrandProfileFileIdsInput = {
  assets?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  avatar?: InputMaybe<Scalars['ID']>;
  guidelines?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  references?: InputMaybe<Array<InputMaybe<BrandProfileReferenceInput>>>;
};

export type BrandProfileReferenceInput = {
  caption?: InputMaybe<Scalars['String']>;
  fileId: Scalars['ID'];
};

export enum BriefCategory {
  AdaptAndOptimise = 'ADAPT_AND_OPTIMISE',
  ContentCreationCreative = 'CONTENT_CREATION_CREATIVE',
  OriginalCreative = 'ORIGINAL_CREATIVE'
}

export enum BriefCommentNotificationRules {
  BriefCommentCreated = 'BRIEF_COMMENT_CREATED'
}

export enum BriefProductAcquisitionMethod {
  NoProduct = 'NO_PRODUCT',
  Online = 'ONLINE',
  Shipped = 'SHIPPED',
  Store = 'STORE'
}

export type BriefTimestampInput = {
  briefId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['String']>;
};

export type CanvasMetadataFieldsInput = {
  /** Vertical dimension measured in pixels */
  height?: InputMaybe<Scalars['Int']>;
  /** Indicates if the canvas is a landscape, portrait or square */
  orientation?: InputMaybe<Scalars['String']>;
  /** Compression quality regarding the original canvas. 100 means no losses or original */
  quality?: InputMaybe<Scalars['Int']>;
  /** Estimated ratio of the canvas. It aproximates to the most common ratio (like 4:3, 16:9). The ratio is expressed as if the canvas is horizontal, so a vertical canvas would also be 16:9 */
  ratioEstimated?: InputMaybe<Scalars['String']>;
  /** The percent of error between the real and the estimated ratio */
  ratioEstimationErrorPercent?: InputMaybe<Scalars['Int']>;
  /** Real ratio of the canvas. It can be 4:3, 16:9 if the dimensions are perfect but also weird numbers like 443:324. The ratio is expressed as if the canvas is horizontal, so a vertical canvas would also be 16:9 */
  ratioReal?: InputMaybe<Scalars['String']>;
  /** Horizontal dimension measured in pixels */
  width?: InputMaybe<Scalars['Int']>;
};

export enum CollectionSortingField {
  CreatedAt = 'CREATED_AT',
  Name = 'NAME',
  UpdatedAt = 'UPDATED_AT'
}

export enum CommentTargetType {
  AssetVersion = 'ASSET_VERSION',
  Brief = 'BRIEF',
  Comment = 'COMMENT'
}

export type CommonMetadataFieldsInput = {
  mime?: InputMaybe<Scalars['String']>;
  /** Hash to enable file integrity verification and identify duplicates */
  sha256?: InputMaybe<Scalars['String']>;
  /** Size in bytes of the file */
  size?: InputMaybe<Scalars['Int']>;
  /** Indicates which kind of data is stored, inferred from the mime type: image, video, document... */
  type?: InputMaybe<Scalars['String']>;
};

export type ConceptCreativeVisionInput = {
  caption?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
};

export type ConceptDirectionInput = {
  caption?: InputMaybe<Scalars['String']>;
  fileId: Scalars['ID'];
  order: Scalars['Int'];
};

export type ConceptFieldsInput = {
  actionNotes?: InputMaybe<Scalars['String']>;
  areOutcomesDigitalAds?: InputMaybe<Scalars['String']>;
  bodiesAges?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  bodiesGender?: InputMaybe<Scalars['String']>;
  bodiesNote?: InputMaybe<Scalars['String']>;
  bodiesPeopleAmount?: InputMaybe<Scalars['String']>;
  bodiesRaces?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  bodiesRequests?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentDescription?: InputMaybe<Scalars['String']>;
  coreVisualsNotes?: InputMaybe<Scalars['String']>;
  creativeVisions?: InputMaybe<Array<InputMaybe<ConceptCreativeVisionInput>>>;
  description?: InputMaybe<Scalars['String']>;
  directions?: InputMaybe<Array<InputMaybe<ConceptDirectionInput>>>;
  featuredProducts?: InputMaybe<Array<ConceptProductInput>>;
  hasProducts?: InputMaybe<Scalars['Boolean']>;
  hasProps?: InputMaybe<Scalars['Boolean']>;
  hasTalents?: InputMaybe<Scalars['Boolean']>;
  keyNote?: InputMaybe<Scalars['String']>;
  limbsGender?: InputMaybe<Scalars['String']>;
  limbsNote?: InputMaybe<Scalars['String']>;
  limbsPeopleAmount?: InputMaybe<Scalars['String']>;
  limbsRaces?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  locationsNote?: InputMaybe<Scalars['String']>;
  moodboard?: InputMaybe<EditConceptMoodboardInput>;
  name?: InputMaybe<Scalars['String']>;
  optimiseAudioNotes?: InputMaybe<Scalars['String']>;
  orientations?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  originalAudioNotes?: InputMaybe<Scalars['String']>;
  outcomeImageAmount?: InputMaybe<Scalars['Int']>;
  outcomeImageEditing?: InputMaybe<Scalars['Boolean']>;
  outcomeIntensiveStopMotionAmount?: InputMaybe<Scalars['Int']>;
  outcomeLongVideoAmount?: InputMaybe<Scalars['Int']>;
  outcomePremiumImageAmount?: InputMaybe<Scalars['Int']>;
  outcomePremiumStopMotionAmount?: InputMaybe<Scalars['Int']>;
  outcomePremiumVideoAmount?: InputMaybe<Scalars['Int']>;
  outcomeRetouching?: InputMaybe<Scalars['Boolean']>;
  outcomeStopMotionAmount?: InputMaybe<Scalars['Int']>;
  outcomeUgcVideoAmount?: InputMaybe<Scalars['Int']>;
  outcomeVideoClipAmount?: InputMaybe<Scalars['Int']>;
  outcomeVideoEditing?: InputMaybe<Scalars['Boolean']>;
  outcomeVideoStoryAmount?: InputMaybe<Scalars['Int']>;
  participantsNotes?: InputMaybe<Scalars['String']>;
  petsAges?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  petsAmount?: InputMaybe<Scalars['String']>;
  petsNote?: InputMaybe<Scalars['String']>;
  petsSizes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  petsSpecies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  presentationNotes?: InputMaybe<Scalars['String']>;
  productAdquisitionMethod?: InputMaybe<Scalars['String']>;
  productAdquisitionOptions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  productAvailableCountries?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  productSamplesMustBeReturned?: InputMaybe<Scalars['Boolean']>;
  products?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  propTypes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  props?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  propsNote?: InputMaybe<Scalars['String']>;
  suppliedVisualsNotes?: InputMaybe<Scalars['String']>;
  talents?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type?: InputMaybe<ConceptType>;
  visualGuides?: InputMaybe<Array<InputMaybe<CreateConceptVisualGuideInput>>>;
};

export type ConceptMindMapFrameworkInput = {
  framework_title: Scalars['String'];
  items?: InputMaybe<Array<ConceptMindMapFrameworkItemInput>>;
};

export type ConceptMindMapFrameworkItemInput = {
  message: Scalars['String'];
  title: Scalars['String'];
};

export type ConceptMindMapInput = {
  fileId: Scalars['ID'];
  list: ConceptMindMapListInput;
  productDescription?: InputMaybe<Scalars['String']>;
  productName?: InputMaybe<Scalars['String']>;
  whimsicalUrl: Scalars['String'];
};

export type ConceptMindMapListInput = {
  frameworks?: InputMaybe<Array<ConceptMindMapFrameworkInput>>;
  title: Scalars['String'];
};

export type ConceptMoodboardInput = {
  assetId?: InputMaybe<Scalars['ID']>;
  caption?: InputMaybe<Scalars['String']>;
  fileId?: InputMaybe<Scalars['ID']>;
};

export type ConceptMoodboardItemFieldsInput = {
  caption?: InputMaybe<Scalars['String']>;
};

export type ConceptProductInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type ConceptTimestampInput = {
  conceptId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['String']>;
};

export enum ConceptType {
  LegacyV1 = 'LEGACY_V1',
  Lifestyle = 'LIFESTYLE',
  Premium = 'PREMIUM',
  Studio = 'STUDIO',
  Ugc = 'UGC',
  UserGenerated = 'USER_GENERATED'
}

export type CreatFileV2Input = {
  assetId?: InputMaybe<Scalars['String']>;
  brandProfileId?: InputMaybe<Scalars['String']>;
  conceptId?: InputMaybe<Scalars['String']>;
  conceptVisualGuideId?: InputMaybe<Scalars['String']>;
  fileName: Scalars['String'];
  projectId?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};

export type CreatFilesV2Input = {
  brandProfileId?: InputMaybe<Scalars['String']>;
  conceptId?: InputMaybe<Scalars['String']>;
  fileNames: Array<InputMaybe<Scalars['String']>>;
  projectId?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};

export type CreateAssetFieldsInput = {
  conceptId: Scalars['ID'];
  fileId: Scalars['ID'];
  mainOutcomeId?: InputMaybe<Scalars['ID']>;
};

export type CreateBrandProfileInput = {
  colors?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description?: InputMaybe<Scalars['String']>;
  guidelinesRequirements?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  organizationId: Scalars['String'];
  unwantedCharacteristics?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  wantedCharacteristics?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CreateCollectionInput = {
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type CreateCommentInput = {
  content: Scalars['String'];
  extra?: InputMaybe<Scalars['JSON']>;
  fileIds?: InputMaybe<Array<Scalars['ID']>>;
  targetId: Scalars['ID'];
  targetType: CommentTargetType;
};

export type CreateConceptDirectionFieldsInput = {
  assetId?: InputMaybe<Scalars['ID']>;
  caption?: InputMaybe<Scalars['String']>;
  conceptId: Scalars['ID'];
  fileId?: InputMaybe<Scalars['ID']>;
  order: Scalars['Int'];
};

export type CreateConceptVisualGuideInput = {
  description?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type CreateFileFieldsInput = {
  /** File name (customized or original) */
  fileName?: InputMaybe<Scalars['String']>;
  masterOrganizationId?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};

export type CreateFilesFieldsInput = {
  /** File name (customized or original) */
  fileName?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};

export type CreateFormatFieldsInput = {
  fileId: Scalars['String'];
  /** Name and version of the function that generated this format */
  generator?: InputMaybe<Scalars['String']>;
  /** As a file may have different formats (sizes or versions of an image, for example), we use this field to know which kind of format it is: a big image version? a thumbnail? a 16x16 icon? */
  name: Scalars['String'];
  storedDataId: Scalars['String'];
};

export type CreateFormatsFieldsInput = {
  /** Name and version of the function that generated this format */
  generator?: InputMaybe<Scalars['String']>;
  /** As a file may have different formats (sizes or versions of an image, for example), we use this field to know which kind of format it is: a big image version? a thumbnail? a 16x16 icon? */
  name: Scalars['String'];
  storedDataId: Scalars['String'];
};

export type CreateInternalBotInput = {
  /** Which problem does this bot solves? Who is responsible of it? */
  description: Scalars['String'];
  name: Scalars['String'];
};

export type CreateMasterOrganizationInput = {
  avatarFileId?: InputMaybe<Scalars['ID']>;
  domain: Scalars['String'];
  name: Scalars['String'];
  ownerEmail: Scalars['String'];
};

export type CreateOrganizationUserFromInvitationInput = {
  code: Scalars['String'];
  /** Country in ISO 3166-1 alpha-2 format (US, GB, ES, UA) */
  country?: InputMaybe<Scalars['String']>;
  /** Locale in IETF Language Types format (en-US, es-ES) */
  locale?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  password: Scalars['String'];
  /** Phone in E.164 format (+34912760000) */
  phone?: InputMaybe<Scalars['String']>;
  surname: Scalars['String'];
  /** Timezone in IANA format (Europe/Madrid) */
  timezone?: InputMaybe<Scalars['String']>;
};

export type CreateOutcomeFieldsInput = {
  amount: Scalars['Int'];
  conceptId?: InputMaybe<Scalars['ID']>;
  detailsNote?: InputMaybe<Scalars['String']>;
  orientation?: InputMaybe<OutcomeOrientation>;
  placement?: InputMaybe<Scalars['String']>;
  platform?: InputMaybe<OutcomePlatform>;
  ratio?: InputMaybe<OutcomeRatio>;
  serviceId: Scalars['ID'];
};

export type CreateProjectBriefFieldsInput = {
  category: BriefCategory;
  concept?: InputMaybe<CreateProjectConceptFieldsInput>;
};

export type CreateProjectConceptFieldsInput = {
  contentDescription?: InputMaybe<Scalars['String']>;
  coreVisualsNotes?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  featuredProducts?: InputMaybe<Array<ConceptProductInput>>;
  name?: InputMaybe<Scalars['String']>;
  optimiseAudioNotes?: InputMaybe<Scalars['String']>;
  originalAudioNotes?: InputMaybe<Scalars['String']>;
  products?: InputMaybe<Array<Scalars['String']>>;
  suppliedVisualsNotes?: InputMaybe<Scalars['String']>;
  visualGuides?: InputMaybe<Array<CreateConceptVisualGuideInput>>;
};

export type CreateProjectSharingLinkInput = {
  canDownloadAssets: Scalars['Boolean'];
  canViewAssetComments: Scalars['Boolean'];
  canViewAssets: Scalars['Boolean'];
  canViewBrief: Scalars['Boolean'];
  /** Internal links are only displayed and handled by our team */
  isInternal?: InputMaybe<Scalars['Boolean']>;
};

export type CreateUserInput = {
  /** Country in ISO 3166-1 alpha-2 format (US, GB, ES, UA) */
  country?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  /** Locale in IETF Language Types format (en-US, es-ES) */
  locale?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  /** Phone in E.164 format (+34912760000) */
  phone?: InputMaybe<Scalars['String']>;
  surname: Scalars['String'];
  /** Timezone in IANA format (Europe/Madrid) */
  timezone?: InputMaybe<Scalars['String']>;
};

export type CustomSubscriptionPlanInput = {
  activeProjectsLimit?: InputMaybe<Scalars['Int']>;
  creditsLimit?: InputMaybe<Scalars['Int']>;
  isHasNoActiveProjectsLimit?: InputMaybe<Scalars['Boolean']>;
  isHasNoCreditsLimit?: InputMaybe<Scalars['Boolean']>;
};

export type DataRangeInput = {
  endDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};

export type EditAdConceptInput = {
  message?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type EditAssetsMainOutcomeFieldsInput = {
  assetId: Scalars['ID'];
  mainOutcomeId: Scalars['ID'];
};

export type EditBrandProfileInput = {
  colors?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description?: InputMaybe<Scalars['String']>;
  guidelinesRequirements?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  unwantedCharacteristics?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  wantedCharacteristics?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type EditCollectionInput = {
  coverAssetId?: InputMaybe<Scalars['ID']>;
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type EditConceptDirectionFieldsInput = {
  caption?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
};

export type EditConceptMoodboardInput = {
  filesData?: InputMaybe<Array<InputMaybe<ConceptMoodboardInput>>>;
};

export type EditConceptVisualGuideInput = {
  assetId?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  fileId?: InputMaybe<Scalars['ID']>;
  message?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type EditEventInput = {
  assetId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['String']>;
  emailSentAt?: InputMaybe<Scalars['String']>;
  eventId?: InputMaybe<Scalars['String']>;
  happenedAt?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['Int']>;
};

export type EditFileFieldsInput = {
  /** File name (customized or original) */
  fileName?: InputMaybe<Scalars['String']>;
  masterOrganizationId?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
};

export type EditFileStatusFieldsInput = {
  failedInternalDetails?: InputMaybe<Scalars['String']>;
  failedStage?: InputMaybe<Scalars['String']>;
  status: Scalars['String'];
};

export type EditFormatFieldsInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type EditMasterOrganizationInput = {
  avatarFileId?: InputMaybe<Scalars['ID']>;
  domain?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type EditMasterOrganizationUserPermissionsRolesInput = {
  organizationIds: Array<Scalars['ID']>;
  role: Role;
};

export type EditOrganizationSubscriptionPlanInput = {
  custom?: InputMaybe<CustomSubscriptionPlanInput>;
  expirationDate?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<SubscriptionPlanName>;
  startDate?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<SubscriptionPlanStatus>;
};

export type EditOutcomeFieldsInput = {
  amount?: InputMaybe<Scalars['Int']>;
  detailsNote?: InputMaybe<Scalars['String']>;
  orientation?: InputMaybe<OutcomeOrientation>;
  placement?: InputMaybe<Scalars['String']>;
  platform?: InputMaybe<OutcomePlatform>;
  ratio?: InputMaybe<OutcomeRatio>;
  serviceId?: InputMaybe<Scalars['ID']>;
};

export type EditUserInput = {
  /** Country in ISO 3166-1 alpha-2 format (US, GB, ES, UA) */
  country?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  /** Locale in IETF Language Types format (en-US, es-ES) */
  locale?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  /** Phone in E.164 format (+34912760000) */
  phone?: InputMaybe<Scalars['String']>;
  roleNotes?: InputMaybe<Scalars['String']>;
  surname?: InputMaybe<Scalars['String']>;
  /** Timezone in IANA format (Europe/Madrid) */
  timezone?: InputMaybe<Scalars['String']>;
};

export type EditUserRoleInput = {
  organizationId: Scalars['String'];
  role: Role;
};

export type EmailProjectSharingLinkInput = {
  canDownloadAssets: Scalars['Boolean'];
  canViewAssetComments: Scalars['Boolean'];
  canViewAssets: Scalars['Boolean'];
  canViewBrief: Scalars['Boolean'];
  /** Message that will appear in the emails */
  emailNote?: InputMaybe<Scalars['String']>;
  /** List of emails that will receive an unique link */
  emails: Array<Scalars['String']>;
  /** Internal links are only displayed and handled by our team */
  isInternal?: InputMaybe<Scalars['Boolean']>;
};

export enum FileJobStatus {
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Succeeded = 'SUCCEEDED'
}

export enum FileJobType {
  LabelDetection = 'LABEL_DETECTION'
}

export type GetAdConceptsFilter = {
  createdByUserId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  productName?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type GetAdStoryboardsFilter = {
  createdByUserId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  productName?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type GetAiBriefItemsFilter = {
  createdByUserId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Array<Scalars['ID']>>;
  search?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Array<AiBriefItemType>>;
};

export type GetAssetCommentsFilters = {
  assetId?: InputMaybe<Scalars['ID']>;
  assetVersionId?: InputMaybe<Scalars['ID']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  projectId?: InputMaybe<Scalars['ID']>;
  userRole?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type GetBrandProfilesFilter = {
  brandProfileId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  masterOrganizationId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  name?: InputMaybe<Scalars['String']>;
};

export type GetCollectionsFilters = {
  createdByUserId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  dateCreated?: InputMaybe<DataRangeInput>;
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  search?: InputMaybe<Scalars['String']>;
};

export type GetCollectionsPaginationParameters = {
  /** Number of results to be returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** Elements to jump in the response. It is a string as internally this is treated as BigInt to allow paginating large lists */
  offset?: InputMaybe<Scalars['Int']>;
  /** Sorting direction: most recent first? older first? */
  sorting?: InputMaybe<PaginationSortingDirectionValue>;
  /** Overrides the default field to be used when sorting */
  sortingField?: InputMaybe<CollectionSortingField>;
  /** Sorting priority values. If present it overrides sorting field. Use to sort by multiple field values. For example, if you want to sort by project status, you can provide the following array: ['submitted', 'approved', 'completed'] */
  sortingPriority?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type GetCommentsInput = {
  assetId?: InputMaybe<Scalars['ID']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  projectId?: InputMaybe<Scalars['ID']>;
  targetType?: InputMaybe<CommentTargetType>;
  userId?: InputMaybe<Scalars['ID']>;
  userRole?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type GetConceptMindMapsFilter = {
  createdByUserId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  productName?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type GetMasterOrganizationEventsFilter = {
  createdAt?: InputMaybe<DataRangeInput>;
};

export type GetMasterOrganizationsFilter = {
  masterOrganizationIds?: InputMaybe<Array<Scalars['ID']>>;
  name?: InputMaybe<Scalars['String']>;
};

export type GetOrganizationsFilter = {
  managedByUserId?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  masterOrganizationId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  name?: InputMaybe<Scalars['String']>;
  organizationsIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  subscriptionPlanName?: InputMaybe<Array<InputMaybe<SubscriptionPlanName>>>;
  subscriptionPlanStatus?: InputMaybe<Array<InputMaybe<SubscriptionPlanStatus>>>;
  subscriptionPlanType?: InputMaybe<Array<InputMaybe<SubscriptionPlanType>>>;
  type?: InputMaybe<Array<InputMaybe<OrganizationType>>>;
};

export type GetOutcomesFilterOptions = {
  /** Should the operation return the children entities outcomes or only the ones directly associated? */
  includeNested?: InputMaybe<Scalars['Boolean']>;
};

export type GetProductionBriefsFilter = {
  createdByUserId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  productName?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type GetProjectsFilter = {
  brandProfileId?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  briefCategory?: InputMaybe<Array<InputMaybe<BriefCategory>>>;
  createdAt?: InputMaybe<BetweenDatesFilter>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isShareableOnDate?: InputMaybe<Scalars['String']>;
  managedByUserId?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  masterOrganizationId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  projectId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  shareable?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Array<InputMaybe<ProjectStatusValues>>>;
  submittedAt?: InputMaybe<BetweenDatesFilter>;
  title?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type GetProjectsPaginationParameters = {
  /** Number of results to be returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** Elements to jump in the response. It is a string as internally this is treated as BigInt to allow paginating large lists */
  offset?: InputMaybe<Scalars['Int']>;
  /** Sorting direction: most recent first? older first? */
  sorting?: InputMaybe<PaginationSortingDirectionValue>;
  /** Overrides the default field to be used when sorting */
  sortingField?: InputMaybe<ProjectSortingField>;
  /** Sorting priority values. If present it overrides sorting field. Use to sort by multiple field values. For example, if you want to sort by project status, you can provide the following array: ['submitted', 'approved', 'completed'] */
  sortingPriority?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type GetRequestsFilter = {
  createdByUserId?: InputMaybe<Scalars['String']>;
  masterOrganizationId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Array<InputMaybe<RequestStatuses>>>;
  type?: InputMaybe<Array<InputMaybe<RequestTypes>>>;
};

export type GetServicesFilter = {
  isLegacy?: InputMaybe<Scalars['Boolean']>;
  serviceType?: InputMaybe<ServiceType>;
};

export type GetTagsFilter = {
  names?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  search?: InputMaybe<Scalars['String']>;
  tagId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type GetUgcIdeasFilter = {
  createdByUserId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  productName?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type GetUgcStoryboardsFilter = {
  createdByUserId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  productName?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type GetUsersFilter = {
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  masterOrganizationId?: InputMaybe<Scalars['String']>;
  organizationIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  role?: InputMaybe<Array<InputMaybe<Role>>>;
  search?: InputMaybe<Scalars['String']>;
};

export type ImageMetadataFieldsInput = {
  codec?: InputMaybe<Scalars['String']>;
  colorSpace?: InputMaybe<Scalars['String']>;
  /** Related with color management. Indicate if it has a color profile */
  hasICCProfile?: InputMaybe<Scalars['Boolean']>;
  /** Progressive images displays a low quality version first and gains quality as it is being downloaded. Helps to give the appearances of faster loading */
  isProgressive?: InputMaybe<Scalars['Boolean']>;
};

export type MetadataFieldsInput = {
  audio?: InputMaybe<AudioMetadataFieldsInput>;
  canvas?: InputMaybe<CanvasMetadataFieldsInput>;
  common?: InputMaybe<CommonMetadataFieldsInput>;
  image?: InputMaybe<ImageMetadataFieldsInput>;
  video?: InputMaybe<VideoMetadataFieldsInput>;
};

export type OffsetPaginationParameters = {
  /** Number of results to be returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** Elements to jump in the response. It is a string as internally this is treated as BigInt to allow paginating large lists */
  offset?: InputMaybe<Scalars['Int']>;
  /** Sorting direction: most recent first? older first? */
  sorting?: InputMaybe<PaginationSortingDirectionValue>;
  /** Overrides the default field to be used when sorting */
  sortingField?: InputMaybe<Scalars['String']>;
  /** Sorting priority values. If present it overrides sorting field. Use to sort by multiple field values. For example, if you want to sort by project status, you can provide the following array: ['submitted', 'approved', 'completed'] */
  sortingPriority?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export enum OrganizationCreditsEvent {
  AdditionalContent = 'ADDITIONAL_CONTENT',
  BriefAssistance = 'BRIEF_ASSISTANCE',
  ChangeCreditLimit = 'CHANGE_CREDIT_LIMIT',
  CreatorRebooking = 'CREATOR_REBOOKING',
  ExtendedLicense = 'EXTENDED_LICENSE',
  LateFee = 'LATE_FEE',
  LocationBooking = 'LOCATION_BOOKING',
  LocationFee = 'LOCATION_FEE',
  PostProduction = 'POST_PRODUCTION',
  ProductPurchase = 'PRODUCT_PURCHASE',
  ProjectAcceptance = 'PROJECT_ACCEPTANCE',
  ProjectCancellation = 'PROJECT_CANCELLATION',
  ProjectFinalization = 'PROJECT_FINALIZATION',
  ProjectSubmission = 'PROJECT_SUBMISSION',
  PropAllocation = 'PROP_ALLOCATION',
  PropPurchase = 'PROP_PURCHASE',
  PurchasedCredits = 'PURCHASED_CREDITS',
  Refill = 'REFILL',
  Refund = 'REFUND',
  ResetBalance = 'RESET_BALANCE',
  Reward = 'REWARD',
  RushTurnaround = 'RUSH_TURNAROUND',
  SetViaApi = 'SET_VIA_API',
  TalentBooking = 'TALENT_BOOKING',
  TalentFee = 'TALENT_FEE',
  TransferCreditsFrom = 'TRANSFER_CREDITS_FROM',
  TransferCreditsTo = 'TRANSFER_CREDITS_TO',
  UnlinkOrganization = 'UNLINK_ORGANIZATION'
}

export type OrganizationCreditsFieldsInput = {
  /** The company credits will be set to this amount */
  credits: Scalars['Int'];
  /** Only CreativelySquared team can see he internalNotes */
  internalNotes?: InputMaybe<Scalars['String']>;
  /** The current number of credits. Used for validation. This is requested to avoid race conditions causing unexpected results. */
  previousValue: Scalars['Int'];
  /** The client can see the publicNotes */
  publicNotes?: InputMaybe<Scalars['String']>;
  /** Type of operation / reason of change */
  type?: InputMaybe<OrganizationCreditsEvent>;
};

export type OrganizationFieldsInput = {
  adminEmail?: InputMaybe<Scalars['String']>;
  cname?: InputMaybe<Scalars['String']>;
  /** Free text to provide more details about how they discovered us */
  discoveredUsDetails?: InputMaybe<Scalars['String']>;
  /** How they heard about us. Please check with the backend for a list of accepted values */
  discoveredUsVia?: InputMaybe<Scalars['String']>;
  /** Indicates if the company have been self registered (true) or if it was registered by our team (false) */
  hasRegisteredItself?: InputMaybe<Scalars['Boolean']>;
  industry?: InputMaybe<Scalars['String']>;
  masterOrganizationId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<OrganizationType>;
};

export type OrganizationInternalNoteFieldsInput = {
  content: Scalars['String'];
};

export enum OrganizationType {
  Connected = 'CONNECTED',
  Integrated = 'INTEGRATED',
  Standalone = 'STANDALONE'
}

export type OrganizationUserProfileInput = {
  /** Biggest perceived challenges in the organization. Please check with the backend for a list of accepted values */
  businessChallenges?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Role in the organization. Please check with the backend for a list of accepted values */
  businessRole?: InputMaybe<Scalars['String']>;
};

export enum OutcomeOrientation {
  Any = 'ANY',
  Landscape = 'LANDSCAPE',
  Portrait = 'PORTRAIT',
  Square = 'SQUARE',
  Vertical = 'VERTICAL'
}

export enum OutcomePlatform {
  Amazon = 'AMAZON',
  Facebook = 'FACEBOOK',
  Instagram = 'INSTAGRAM',
  Other = 'OTHER',
  Pinterest = 'PINTEREST',
  TikTok = 'TIK_TOK',
  Youtube = 'YOUTUBE'
}

export enum OutcomeRatio {
  FourFive = 'FOUR_FIVE',
  NineSixteen = 'NINE_SIXTEEN',
  OneOne = 'ONE_ONE',
  Other = 'OTHER',
  SixteenNine = 'SIXTEEN_NINE'
}

export type OverrideProjectCompletionProtectionInput = {
  isForced: Scalars['Boolean'];
  reason?: InputMaybe<Scalars['String']>;
};

export type OverwriteProtection = {
  isActive: Scalars['Boolean'];
  updatedAt?: InputMaybe<Scalars['String']>;
};

export enum PaginationSortingDirectionValue {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum PermissionActions {
  All = 'All',
  ChangeOwner = 'ChangeOwner',
  ChangeToken = 'ChangeToken',
  Create = 'Create',
  Delete = 'Delete',
  Download = 'Download',
  Duplicate = 'Duplicate',
  Edit = 'Edit',
  Finalize = 'Finalize',
  Link = 'Link',
  ManageCredits = 'ManageCredits',
  MoveInQueue = 'MoveInQueue',
  Read = 'Read',
  RequestClarification = 'RequestClarification',
  Submit = 'Submit',
  Unlink = 'Unlink',
  Update = 'Update'
}

export enum PermissionResources {
  AccountAccessRequest = 'AccountAccessRequest',
  AdConcept = 'AdConcept',
  AdStoryboard = 'AdStoryboard',
  AiBriefItem = 'AiBriefItem',
  AiMessage = 'AiMessage',
  All = 'All',
  AssetComment = 'AssetComment',
  AssetComments = 'AssetComments',
  AssetCommentsWithDeleted = 'AssetCommentsWithDeleted',
  AssetGallery = 'AssetGallery',
  AssetRating = 'AssetRating',
  AssetTag = 'AssetTag',
  AssetVersion = 'AssetVersion',
  AssetVersions = 'AssetVersions',
  AssetsPublished = 'AssetsPublished',
  BrandProfile = 'BrandProfile',
  BrandProfileAsset = 'BrandProfileAsset',
  BrandProfileGuideline = 'BrandProfileGuideline',
  BrandProfileReference = 'BrandProfileReference',
  BrandProfileSharingLink = 'BrandProfileSharingLink',
  Brief = 'Brief',
  BriefComment = 'BriefComment',
  BriefComments = 'BriefComments',
  BriefMoodboardItem = 'BriefMoodboardItem',
  Collection = 'Collection',
  CollectionSharingLink = 'CollectionSharingLink',
  Collections = 'Collections',
  Comment = 'Comment',
  CommentReply = 'CommentReply',
  Comments = 'Comments',
  Concept = 'Concept',
  ConceptCreativeVision = 'ConceptCreativeVision',
  ConceptDirection = 'ConceptDirection',
  ConceptMindMap = 'ConceptMindMap',
  ConceptMoodboardItem = 'ConceptMoodboardItem',
  ConceptProduct = 'ConceptProduct',
  ConceptVisualGuide = 'ConceptVisualGuide',
  Creator = 'Creator',
  DelegatedBriefFileInput = 'DelegatedBriefFileInput',
  DeliveredAsset = 'DeliveredAsset',
  DeliveredAssets = 'DeliveredAssets',
  File = 'File',
  FileDownload = 'FileDownload',
  FileThumbnail = 'FileThumbnail',
  FileUploadUrl = 'FileUploadUrl',
  FileView = 'FileView',
  Files = 'Files',
  Format = 'Format',
  FormatDownloadUrl = 'FormatDownloadUrl',
  Gallery = 'Gallery',
  LastAssetVersion = 'LastAssetVersion',
  MasterOrganization = 'MasterOrganization',
  MasterOrganizationEvents = 'MasterOrganizationEvents',
  OrgOwner = 'OrgOwner',
  Organization = 'Organization',
  OrganizationCredits = 'OrganizationCredits',
  OrganizationCreditsHistory = 'OrganizationCreditsHistory',
  OrganizationEvents = 'OrganizationEvents',
  Organizations = 'Organizations',
  Outcome = 'Outcome',
  ProductionBrief = 'ProductionBrief',
  Project = 'Project',
  ProjectBasicField = 'ProjectBasicField',
  ProjectCoverImage = 'ProjectCoverImage',
  ProjectEstimatedCost = 'ProjectEstimatedCost',
  ProjectEvents = 'ProjectEvents',
  ProjectFeedback = 'ProjectFeedback',
  ProjectSharingLink = 'ProjectSharingLink',
  ProjectSharingLinks = 'ProjectSharingLinks',
  Projects = 'Projects',
  StoredData = 'StoredData',
  SubscriptionPlan = 'SubscriptionPlan',
  Tag = 'Tag',
  UgcIdea = 'UgcIdea',
  UgcStoryboard = 'UgcStoryboard',
  User = 'User',
  UserAvatar = 'UserAvatar',
  UserPermissions = 'UserPermissions',
  UserRole = 'UserRole',
  Users = 'Users'
}

export type ProductionBriefInput = {
  contentDescription: Scalars['String'];
  coreVisualsNotes: Scalars['String'];
  description: Scalars['String'];
  originalAudioNotes: Scalars['String'];
  productDescription?: InputMaybe<Scalars['String']>;
  productName?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  visualGuide: Array<VisualGuideItemInput>;
};

export type ProductionBriefScriptInput = {
  frames: Array<AdStoryboardFrameInput>;
  productName: Scalars['String'];
  title: Scalars['String'];
};

export enum ProjectActiveStatus {
  ClarificationRequested = 'CLARIFICATION_REQUESTED',
  Delivered = 'DELIVERED',
  InProgress = 'IN_PROGRESS',
  PartialDelivery = 'PARTIAL_DELIVERY',
  PendingReview = 'PENDING_REVIEW',
  Queued = 'QUEUED',
  UnderRevision = 'UNDER_REVISION'
}

export type ProjectDuplicateOverwriteProtectionInput = {
  brief?: InputMaybe<BriefTimestampInput>;
  concepts?: InputMaybe<Array<InputMaybe<ConceptTimestampInput>>>;
  isActive: Scalars['Boolean'];
  project?: InputMaybe<ProjectTimestampInput>;
};

export type ProjectEmailPreferencesInput = {
  all?: InputMaybe<Scalars['Boolean']>;
  brands?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mode: ProjectNotificationsModes;
};

export type ProjectFeedbackFieldsInput = {
  isFeedbackCallRequested?: InputMaybe<Scalars['Boolean']>;
  note?: InputMaybe<Scalars['String']>;
  vote?: InputMaybe<Scalars['Int']>;
};

export type ProjectFieldsInput = {
  brandName?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type ProjectInternalNoteFieldsInput = {
  content: Scalars['String'];
};

export enum ProjectNotificationRules {
  ProjectAccepted = 'PROJECT_ACCEPTED',
  ProjectCanceled = 'PROJECT_CANCELED',
  ProjectClarification = 'PROJECT_CLARIFICATION',
  ProjectDelivery = 'PROJECT_DELIVERY',
  ProjectFinalized = 'PROJECT_FINALIZED',
  ProjectNotificationsEnabled = 'PROJECT_NOTIFICATIONS_ENABLED',
  ProjectSubmitted = 'PROJECT_SUBMITTED'
}

export enum ProjectNotificationsModes {
  All = 'ALL',
  Brands = 'BRANDS',
  Submitted = 'SUBMITTED'
}

export enum ProjectSortingField {
  ApprovedAt = 'APPROVED_AT',
  CanceledAt = 'CANCELED_AT',
  CompletedAt = 'COMPLETED_AT',
  CreatedAt = 'CREATED_AT',
  DeletedAt = 'DELETED_AT',
  FinalizedAt = 'FINALIZED_AT',
  PartiallyCompletedAt = 'PARTIALLY_COMPLETED_AT',
  QueueOrder = 'QUEUE_ORDER',
  Status = 'STATUS',
  SubmittedAt = 'SUBMITTED_AT',
  Title = 'TITLE',
  TotalCostOnSubmit = 'TOTAL_COST_ON_SUBMIT',
  UnderRevisionAt = 'UNDER_REVISION_AT',
  UpdatedAt = 'UPDATED_AT'
}

export enum ProjectStatusValues {
  Canceled = 'CANCELED',
  ClarificationRequested = 'CLARIFICATION_REQUESTED',
  Delivered = 'DELIVERED',
  Draft = 'DRAFT',
  Finalized = 'FINALIZED',
  InProgress = 'IN_PROGRESS',
  PartialDelivery = 'PARTIAL_DELIVERY',
  PendingReview = 'PENDING_REVIEW',
  Queued = 'QUEUED',
  UnderRevision = 'UNDER_REVISION'
}

export type ProjectStatusesNotificationPreferencesInput = {
  projectAccepted?: InputMaybe<Scalars['Boolean']>;
  projectCanceled?: InputMaybe<Scalars['Boolean']>;
  projectClarification?: InputMaybe<Scalars['Boolean']>;
  projectDelivery?: InputMaybe<Scalars['Boolean']>;
  projectFinalized?: InputMaybe<Scalars['Boolean']>;
  projectSubmitted?: InputMaybe<Scalars['Boolean']>;
};

export type ProjectTimestampInput = {
  projectId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['String']>;
};

export enum RequestStatuses {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export enum RequestTypes {
  AccountAccess = 'ACCOUNT_ACCESS'
}

export enum Role {
  Anonymous = 'ANONYMOUS',
  Creator = 'CREATOR',
  CsAccountManager = 'CS_ACCOUNT_MANAGER',
  CsBot = 'CS_BOT',
  CsSuperadmin = 'CS_SUPERADMIN',
  MasterOrgOwner = 'MASTER_ORG_OWNER',
  OrgAdmin = 'ORG_ADMIN',
  OrgOwner = 'ORG_OWNER',
  OrgUser = 'ORG_USER',
  SharedContentGuest = 'SHARED_CONTENT_GUEST'
}

export enum ServiceType {
  AdaptAndOptimise = 'ADAPT_AND_OPTIMISE',
  ContentCreationCreative = 'CONTENT_CREATION_CREATIVE',
  Lifestyle = 'LIFESTYLE',
  OriginalCreative = 'ORIGINAL_CREATIVE',
  Studio = 'STUDIO'
}

export type SetBriefInput = {
  audienceAges?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  audienceCountries?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  audienceGenders?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  audienceNotes?: InputMaybe<Scalars['String']>;
  brandProfileId?: InputMaybe<Scalars['ID']>;
  category?: InputMaybe<BriefCategory>;
  hasRushOutcome?: InputMaybe<Scalars['Boolean']>;
  productAcquisitionMethod?: InputMaybe<BriefProductAcquisitionMethod>;
  productAvailableCountries?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  productAvailableLocations?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  projectTitle?: InputMaybe<Scalars['String']>;
  purposeContents?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  purposeContexts?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  purposeObjective?: InputMaybe<Scalars['String']>;
  purposeRequirement?: InputMaybe<Scalars['String']>;
  shareable?: InputMaybe<Scalars['String']>;
  shareableDate?: InputMaybe<Scalars['String']>;
  shareableReason?: InputMaybe<Scalars['String']>;
  shouldProductBeReturned?: InputMaybe<Scalars['Boolean']>;
  unwantedCharacteristics?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  wantedCharacteristics?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SetBriefMoodboardInput = {
  fileIds: Array<InputMaybe<Scalars['ID']>>;
};

export type SetProjectCoverImageInput = {
  centerX?: InputMaybe<Scalars['Int']>;
  centerY?: InputMaybe<Scalars['Int']>;
  fileId: Scalars['ID'];
};

export type SetUserAvatarInput = {
  fileId: Scalars['ID'];
};

export type SideEffectsProtection = {
  isActive: Scalars['Boolean'];
};

export type StoredDataFieldsInput = {
  metadata?: InputMaybe<MetadataFieldsInput>;
};

export type SubscriptionPlanInput = {
  activeProjectsLimit?: InputMaybe<Scalars['Int']>;
  creditsLimit?: InputMaybe<Scalars['Int']>;
  expirationDate?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<SubscriptionPlanName>;
  startDate?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<SubscriptionPlanStatus>;
  type?: InputMaybe<SubscriptionPlanType>;
};

export enum SubscriptionPlanName {
  Custom = 'CUSTOM',
  Grow = 'GROW',
  Launch = 'LAUNCH',
  Scale = 'SCALE'
}

export enum SubscriptionPlanStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Expired = 'EXPIRED',
  Pending = 'PENDING',
  Suspended = 'SUSPENDED',
  Trial = 'TRIAL',
  ViewOnly = 'VIEW_ONLY'
}

export enum SubscriptionPlanType {
  Credits = 'CREDITS',
  Custom = 'CUSTOM'
}

export type UgcIdeaInput = {
  approach: Scalars['String'];
  keyMessage: Scalars['String'];
  productDescription?: InputMaybe<Scalars['String']>;
  productName?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  type: Scalars['String'];
};

export enum UgcStoryboardFramework {
  DailyUse = 'DAILY_USE',
  HowItWorks = 'HOW_IT_WORKS',
  ProblemSolution = 'PROBLEM_SOLUTION',
  ProductReveal = 'PRODUCT_REVEAL',
  ResultsFirst = 'RESULTS_FIRST',
  ThreeReasonsWhy = 'THREE_REASONS_WHY'
}

export type UgcStoryboardIdeaInput = {
  approach: Scalars['String'];
  keyMessage: Scalars['String'];
};

export type UgcStoryboardInput = {
  productDescription?: InputMaybe<Scalars['String']>;
  productName?: InputMaybe<Scalars['String']>;
  scenes: Array<UgcStoryboardSceneInput>;
  title: Scalars['String'];
};

export type UgcStoryboardSceneInput = {
  scriptPrompt: Scalars['String'];
  title: Scalars['String'];
  visualPrompt: Scalars['String'];
};

export type UserEmailPreferencesInput = {
  activityPreferences?: InputMaybe<ActivityNotificationPreferencesInput>;
  projectStatuses?: InputMaybe<ProjectStatusesNotificationPreferencesInput>;
  projectsPreferences: ProjectEmailPreferencesInput;
};

export type UsersLikeSearchInput = {
  email?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export type VideoMetadataFieldsInput = {
  bitrate?: InputMaybe<Scalars['String']>;
  codec?: InputMaybe<Scalars['String']>;
  codecLong?: InputMaybe<Scalars['String']>;
  codecProfile?: InputMaybe<Scalars['String']>;
  durationInSeconds?: InputMaybe<Scalars['Float']>;
  fps?: InputMaybe<Scalars['Float']>;
};

export type VisualGuideItemInput = {
  description: Scalars['String'];
  message: Scalars['String'];
  title: Scalars['String'];
};
