import { OrganizationType } from "api/enums";
import { useProfileQuery } from "api/graphql";
import { useMemo } from "react";
import {} from "ts-toolbelt";

export const useUserPlan = () => {
  const { data: { me } = {} } = useProfileQuery({
    fetchPolicy: "cache-first",
  });
  return useMemo(() => {
    return me?.organization?.subscriptionPlan?.type;
  }, [me]);
};

export const useUserAccountStatus = () => {
  const { data: { me } = {} } = useProfileQuery({
    fetchPolicy: "cache-only",
  });

  return (
    (me?.organization?.type === OrganizationType.Integrated
      ? me?.masterOrganization?.subscriptionPlan?.status
      : me?.organization?.subscriptionPlan?.status) || undefined
  );
};
