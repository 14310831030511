import clsx from "clsx";
import { FC, HTMLAttributes } from "react";

export const TableCell: FC<HTMLAttributes<HTMLTableCellElement>> = ({
  className,
  ...props
}) => (
  <td
    className={clsx(
      "py-8 px-7 text-blackberry align-middle relative",
      className
    )}
    {...props}
  />
);
