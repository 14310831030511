import { ApolloError } from "@apollo/client";
import { useLoginLazyQuery } from "api/graphql";
import { links } from "App";
import { AuthContext } from "Authorization/authorization.context";
import { LoginPageMode } from "Authorization/Login";
import { useNotification } from "components";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { AuthErrorCodes } from "utils/auth";
import { useHomePageUrl } from "utils/hooks/useHomePageUrl";

export const useLogin = () => {
  const { setAccessToken } = useContext(AuthContext);
  const { replace } = useHistory();
  const { t } = useTranslation();
  const { setNotification, notificationTypes } = useNotification();
  const homePageUrl = useHomePageUrl();

  const [login, { loading: loginLoading }] = useLoginLazyQuery({
    onCompleted: ({ getAuthenticationTokens }) => {
      setAccessToken(getAuthenticationTokens?.accessToken);
      replace(homePageUrl);
    },
    onError: (error) => {
      const noOrganizationAccounts =
        (error as ApolloError).graphQLErrors?.[0]?.extensions?.code ===
        AuthErrorCodes.UserHasNoPermissionsError;

      if (noOrganizationAccounts) {
        replace(links.Login({ mode: LoginPageMode.AccountCreated }));
      } else {
        setNotification({
          message: error.message ?? t("common:common.error"),
          type: notificationTypes.Error,
        });
      }
    },
  });

  return { login, loginLoading };
};
