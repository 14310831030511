import { Button, Input } from "@CreativelySquared/uikit";
import { memo, useMemo } from "react";
import { useFormik } from "formik";
import { object, string } from "yup";
import { useTranslation } from "react-i18next";
import { ModalDescription, ModalTitle } from "components";

type FormData = { confirm: string };

type Props = {
  onSubmit: () => void;
  isSubmitting?: boolean;
  onClose: () => void;
};

export const TypeConfirmationModal = memo<Props>(
  ({ onSubmit, isSubmitting, onClose }) => {
    const { t } = useTranslation("accounts");

    const validationSchema = useMemo(
      () =>
        object({
          confirm: string()
            .required("common:validation.required")
            .lowercase()
            .oneOf([t("common:confirmation.change")]),
        }),
      []
    );

    const {
      handleSubmit,
      handleChange,
      handleBlur,
      isValid,
      values: { confirm },
      submitCount,
      errors,
    } = useFormik<FormData>({
      onSubmit,
      validationSchema,
      initialValues: {
        confirm: "",
      },
    });

    return (
      <form noValidate onSubmit={handleSubmit}>
        <ModalTitle>{t("details.edit.typeConfirmation.title")}</ModalTitle>
        <ModalDescription>
          {t("details.edit.typeConfirmation.description")}
        </ModalDescription>
        <Input
          name="confirm"
          variant={Input.variants.Secondary}
          value={confirm.toUpperCase()}
          className="w-full"
          placeholder={t("common:confirmation.placeholder", {
            validationWord: t("common:confirmation.change").toUpperCase(),
          })}
          error={!!submitCount && !!errors.confirm}
          onBlur={handleBlur}
          onChange={handleChange}
        />

        <section className="flex justify-end mt-7">
          <Button
            variant={Button.variants.Cancel}
            type="button"
            className="mr-5"
            onClick={onClose}
            outlined
            borderless
          >
            {t("common:actions.cancel")}
          </Button>
          <Button
            type="submit"
            loading={isSubmitting}
            disabled={!isValid || isSubmitting}
          >
            {t("common:actions.confirm")}
          </Button>
        </section>
      </form>
    );
  }
);
