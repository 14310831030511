import {
  Button,
  Loader,
  Alert,
  Modal,
  Tooltip,
} from "@CreativelySquared/uikit";
import {
  useProjectAssetsQuery,
  useAssetQuery,
  useGalleryProjectQuery,
  useProjectGalleryZipLazyQuery,
  useServicesQuery,
  GalleryProjectDocument,
  ProjectAssetsDocument,
} from "api/graphql";
import { links } from "App";
import { Filter, GalleryGrid, Protect, ShareBanner } from "components";
import { ProjectHeaderInjector } from "Projects/components/ProjectHeaderInjector";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { compose, Route } from "react-router-hoc";
import { idRegEx } from "utils/regex";
import { Roles } from "utils/roles";
import { Asset } from "Assets/components";
import { ProtectedRoute } from "utils/route";
import { ProjectStatuses } from "Projects/utils/types";
import clsx from "clsx";
import { AssetStatus, BriefCategory, SubscriptionPlanType } from "api/enums";
import { ReactComponent as ShareIcon } from "images/shareAlt.svg";
import { Services } from "utils/services";
import { ReactComponent as DetailsIcon } from "images/details.svg";
import { ReactComponent as GalleryIcon } from "images/appstore.svg";
import { useShareLink } from "Projects/hooks/useShareLink";
import { DOWNLOAD_ACCESS, VIEW_ONLY_ACCESS } from "Shared/utils/permissions";
import { ReactComponent as DownloadIcon } from "images/download.svg";
import { ReactComponent as FileIcon } from "images/fileText.svg";

import { ProjectGalleryRating } from "../components/GalleryRating";
import { FinaliseProject } from "../components/FinaliseProject";

import styles from "./styles.module.scss";

export const ProjectGalleryRoute = compose(
  ProtectedRoute({
    access: [Roles.cs_admins, Roles.customer],
  }),
  Route(
    {
      id: Route.params.string,
      concepts: Route.query.array(Route.query.regex(idRegEx)),
      mode: Route.query.oneOf("share", "rate", "cover"),
      view: Route.query.oneOf("gallery", "details"),
      versionId: Route.query.regex(idRegEx),
      assetId: Route.params.string.optional,
      statuses: Route.query.array(
        Route.query.oneOf(...Object.values(AssetStatus))
      ),
      types: Route.query.array(Route.query.oneOf(...Object.values(Services))),
      organizations: Route.query.array(Route.query.string),
    },
    ({ id, assetId }) => `/projects/${id}/gallery/${assetId}`
  )
);

const alertStatuses = [
  ProjectStatuses.delivered,
  ProjectStatuses.under_revision,
];

enum GalleryFilter {
  concepts = "concepts",
  statuses = "statuses",
  types = "types",
}

export const ProjectGallery = ProjectGalleryRoute(
  ({
    match: {
      params: { id, assetId },
      query: {
        concepts,
        statuses,
        types,
        organizations,
        mode = "cover",
        versionId,
        view = "details",
      },
      params,
      query,
    },
    history: { replace, push },
    link,
  }) => {
    const { t } = useTranslation("projects");
    const [finalise, setFinalise] = useState(false);

    const [galleryZip, { loading: galleryZipLoading }] =
      useProjectGalleryZipLazyQuery({
        variables: { projectId: id },
        fetchPolicy: "network-only",
      });

    const {
      data: { getProject: project } = {},
      loading: projectLoading,
      error: projectError,
      variables: galleryProjectVariables,
    } = useGalleryProjectQuery({
      variables: {
        projectId: id,
      },
      fetchPolicy: "cache-first",
    });

    const { data: { getServices } = {}, loading: serviceLoading } =
      useServicesQuery({
        variables: {
          filter: {
            serviceType: project?.brief?.category,
          },
        },
        skip: !project?.brief?.category,
      });

    const organizationId = project?.organization?.organizationId;
    const filters = {
      conceptIds: concepts as string[],
      assetStatuses: statuses as AssetStatus[],
      assetTypes: types as string[],
      organizations: organizations as string[],
    };

    const {
      data: { getAssetLibrary } = {},
      loading: assetsLoading,
      error: assetsError,
      variables: projectAssetsVariables,
    } = useProjectAssetsQuery({
      variables: {
        filters: {
          projects: [id],
          ...(organizationId && { organizations: [organizationId] }),
          ...filters,
        },
      },
      skip: !organizationId,
    });

    const refetchQueries = useMemo(
      () => [
        {
          query: GalleryProjectDocument,
          variables: galleryProjectVariables,
        },
        {
          query: ProjectAssetsDocument,
          variables: projectAssetsVariables,
        },
      ],
      [galleryProjectVariables, projectAssetsVariables]
    );

    const assets = getAssetLibrary?.nodes;

    const { data: { getAsset: asset } = {}, loading: assetLoading } =
      useAssetQuery({
        skip: !assetId,
        variables: {
          assetId: assetId!,
          includeComments: true,
        },
      });

    const onClose = useCallback(() => {
      replace(link({ ...params, ...query, mode: undefined }));
    }, [params, query, link]);

    const onGalleryDownload = async () => {
      const { data } = await galleryZip();
      const fileUrl = data?.getProjectGalleryZip?.download?.downloadUrl?.url;

      if (fileUrl) {
        window.location.href = fileUrl;
      }
    };

    const changesRequested = useMemo(() => {
      return assets?.some(
        (asset) => asset?.status === AssetStatus.NeedsChanges
      );
    }, [assets]);

    const prev = useMemo(() => {
      const index = assets?.findIndex((item) => item?.assetId === assetId);
      if (index === undefined) return;
      const asset = assets?.[index - 1];
      return (
        asset &&
        link({
          ...params,
          ...query,
          assetId: asset.assetId!,
          versionId: undefined,
          id,
          mode,
        })
      );
    }, [project, assets, assetId, id, mode]);

    const next = useMemo(() => {
      const index = assets?.findIndex((item) => item?.assetId === assetId);
      if (index === undefined) return;
      const asset = assets?.[index + 1];
      return (
        asset &&
        link({
          ...params,
          ...query,
          assetId: asset.assetId!,
          versionId: undefined,
          id,
          mode,
        })
      );
    }, [project, assets, assetId, id, mode]);

    const isContentCreationBrief =
      project?.brief?.category === BriefCategory.ContentCreationCreative;
    const partialAlertType = isContentCreationBrief ? "content" : "default";
    const onCloseFinalise = () => setFinalise(false);
    const errorMessage =
      projectError?.graphQLErrors?.[0]?.message ||
      projectError?.message ||
      assetsError?.graphQLErrors?.[0]?.message ||
      assetsError?.message;

    useEffect(() => {
      if (isContentCreationBrief) {
        replace(link({ ...params, ...query, view: "gallery" }));
      }
    }, [isContentCreationBrief]);

    const filterData = [
      {
        key: GalleryFilter.concepts,
        value: concepts,
      },
      {
        key: GalleryFilter.statuses,
        value: statuses,
      },
      {
        key: GalleryFilter.types,
        value: types,
      },
    ];

    const filterOptions = [
      ...(project?.brief?.concepts?.some((concept) => concept?.name)
        ? [
            {
              key: GalleryFilter.concepts,
              label: t("common:filter.value.concept"),
              value: project?.brief?.concepts?.map((concept) => ({
                id: concept?.conceptId ?? "",
                name: concept?.name ?? "",
              })),
              type: Filter.types.Checkbox,
            },
          ]
        : []),
      {
        key: GalleryFilter.statuses,
        label: t("common:filter.value.status"),
        value: Object.values(AssetStatus).map((status) => ({
          id: status,
          name: t(`common:asset.status.${status}.label`),
        })),
        type: Filter.types.Checkbox,
      },
      ...(!!getServices?.length
        ? [
            {
              key: GalleryFilter.types,
              label: t("common:filter.value.type"),
              value: getServices.map((service) => ({
                id: service?.serviceId ?? "",
                name: t(`common:asset.label.${service?.serviceId}.title`, {
                  count: 1,
                }),
              })),
              type: Filter.types.Checkbox,
            },
          ]
        : []),
    ];

    const loading = projectLoading || assetsLoading || serviceLoading;

    const {
      linkData: viewLink,
      linkLoading: viewLinkLoading,
      createLink: createViewLink,
    } = useShareLink({
      access: VIEW_ONLY_ACCESS,
      project,
      onUpdate: refetchQueries,
    });
    const {
      linkData: downloadLink,
      linkLoading: downloadLinkLoading,
      createLink: createDownloadLink,
    } = useShareLink({
      access: DOWNLOAD_ACCESS,
      project,
      onUpdate: refetchQueries,
    });

    if (errorMessage) {
      return (
        <section>
          <Alert
            icon
            variant={Alert.variants.Error}
            className="mb-8 w-full"
            title={errorMessage}
          />
        </section>
      );
    }

    return (
      <section>
        {assetId && (
          <section className="fixed flex items-center justify-center top-[0] left-[0] w-full h-full z-10 bg-white">
            {assetLoading && !asset ? (
              <Loader radius={50} />
            ) : (
              asset && (
                <Asset
                  className="w-full h-full"
                  prev={prev}
                  next={next}
                  backURL={link({
                    ...params,
                    ...query,
                    id,
                    mode,
                    versionId: undefined,
                    assetId: undefined,
                  })}
                  value={asset!}
                  setVersion={(versionId) =>
                    replace(link({ ...params, ...query, versionId }))
                  }
                  versionId={versionId}
                />
              )
            )}
          </section>
        )}

        <div className={styles.header}>
          {project && mode === "rate" && (
            <Protect access={Roles.customer}>
              <ProjectGalleryRating onClose={onClose} project={project} />
            </Protect>
          )}
          {project && mode === "share" && (
            <Protect access={Roles.customer}>
              <ShareBanner
                onClose={onClose}
                title={t("gallery.view.share.title")}
                fields={[
                  {
                    label: t("gallery.view.share.link.fields.view.label"),
                    placeholder: t(
                      "gallery.view.share.link.fields.view.placeholder"
                    ),
                    link: viewLink.link,
                    onCreate: createViewLink,
                    loading: viewLinkLoading,
                  },
                  {
                    label: t("gallery.view.share.link.fields.download.label"),
                    placeholder: t(
                      "gallery.view.share.link.fields.download.placeholder"
                    ),
                    link: downloadLink.link,
                    onCreate: createDownloadLink,
                    loading: downloadLinkLoading,
                  },
                ]}
              />
            </Protect>
          )}

          {mode === "cover" && (
            <section className={styles.cover}>
              {project?.coverImage?.file?.thumbnail?.downloadUrl?.url && (
                <img
                  src={project.coverImage.file.thumbnail.downloadUrl.url}
                  className="w-full h-full object-cover brightness-[0.6]"
                />
              )}
              <div className={styles.coverInfo}>
                {project?.organization?.name && (
                  <h5>{project.organization.name}</h5>
                )}
                {project?.title && <h4>{project.title}</h4>}

                <Protect access={Roles.customer}>
                  <Link
                    className="inline-flex mt-8 mx-auto"
                    to={link({ ...params, ...query, mode: "rate" })}
                  >
                    <Button type="button" className={styles.headerAction}>
                      {t(
                        `gallery.view.heading.actions.rate.${
                          project?.myFeedback?.projectFeedbackId
                            ? "edit"
                            : "create"
                        }`
                      )}
                    </Button>
                  </Link>
                </Protect>
              </div>
            </section>
          )}
        </div>

        {alertStatuses.includes(project?.status as ProjectStatuses) &&
          project?.organization?.subscriptionPlan?.type ===
            SubscriptionPlanType.Custom && (
            <Protect access={Roles.customer}>
              <section
                className={clsx(
                  styles.alertMessage,
                  "flex justify-between items-center mt-6"
                )}
              >
                <div>
                  <h4>
                    {t(`gallery.view.${project?.status}.title`, {
                      context: changesRequested && "revision",
                    })}
                  </h4>
                  <p>
                    {t(`gallery.view.${project?.status}.details`, {
                      context: changesRequested && "revision",
                    })}
                  </p>
                </div>
                {project?.status === ProjectStatuses.delivered && (
                  <Button
                    type="button"
                    className="ml-6"
                    onClick={() => setFinalise(true)}
                  >
                    {t(`gallery.view.${project?.status}.submit`)}
                  </Button>
                )}
              </section>
            </Protect>
          )}

        <section className="mt-9 mb-8 flex justify-between items-center">
          <Filter
            value={filterData?.flatMap(({ key, value }) =>
              value?.length ? [{ key, value: value as string[] }] : []
            )}
            options={filterOptions}
            onChange={(data) =>
              push(
                link({
                  ...params,
                  ...query,
                  ...data,
                })
              )
            }
          />
          <div className="flex items-center">
            {!!assets?.length && (
              <Button
                type="button"
                outlined
                borderless
                loading={galleryZipLoading}
                onClick={onGalleryDownload}
              >
                <DownloadIcon className="mr-4 w-7 h-7 stroke-[1.5]" />
                {t("gallery.view.heading.actions.download")}
              </Button>
            )}
            <div className="flex ml-4">
              <div className="mr-6">
                <Tooltip
                  placement={Tooltip.placements.Bottom}
                  title={t("common:gallery.mode.gallery")}
                  variant={Tooltip.variants.Secondary}
                  className="z-[1]"
                >
                  <Link to={link({ ...params, ...query, view: "gallery" })}>
                    <GalleryIcon
                      className={clsx(styles.galleryView, {
                        [styles.galleryViewActive]: view === "gallery",
                      })}
                    />
                  </Link>
                </Tooltip>
              </div>
              <Tooltip
                placement={Tooltip.placements.Bottom}
                title={t("common:gallery.mode.details")}
                variant={Tooltip.variants.Secondary}
                className="z-[1]"
              >
                <Link to={link({ ...params, ...query, view: "details" })}>
                  <DetailsIcon
                    className={clsx(styles.galleryView, {
                      [styles.galleryViewActive]: view === "details",
                    })}
                  />
                </Link>
              </Tooltip>
            </div>
          </div>
        </section>
        <Protect access={Roles.customer}>
          {project?.status === ProjectStatuses["partial_delivery"] && (
            <section className={clsx(styles.alertMessage, "-mt-6")}>
              <h4>{t(`gallery.view.partial.${partialAlertType}.title`)}</h4>
              <p>{t(`gallery.view.partial.${partialAlertType}.details`)}</p>
            </section>
          )}
        </Protect>

        {loading && (
          <div className="flex items-center justify-center">
            <Loader radius={50} className="mt-10" />
          </div>
        )}

        {!loading && !assets?.length && (
          <section className="text-light-blue-steel text-l text-center">
            {t(
              `gallery.empty.${
                Object.values(filters).some(Boolean) ? "filter" : "default"
              }`
            )}
          </section>
        )}

        <GalleryGrid
          className="mt-8"
          assets={assets}
          onOpen={(assetId) =>
            link({
              ...params,
              ...query,
              id,
              assetId,
              mode,
            })
          }
          actions
          projectId={id}
          viewDetails={view === "details"}
        />

        <Protect access={Roles.customer}>
          <ProjectHeaderInjector>
            <section className="flex gap-6">
              <Link to={link({ ...params, ...query, mode: "share" })}>
                <Button outlined borderless>
                  <ShareIcon className="mr-4 w-7 h-7 stroke-[1.5]" />
                  {t("gallery.view.heading.actions.share")}
                </Button>
              </Link>
              <Link to={links.Brief({ id })}>
                <Button outlined borderless>
                  <FileIcon className="mr-4 w-7 h-7 stroke-[1.5]" />
                  {t("gallery.view.heading.actions.brief")}
                </Button>
              </Link>
            </section>
          </ProjectHeaderInjector>
        </Protect>

        <Modal visible={finalise} onClose={onCloseFinalise}>
          <FinaliseProject projectId={id} onClose={onCloseFinalise} />
        </Modal>
      </section>
    );
  }
);
