import { useProjectEventsQuery, useProjectQuery } from "api/graphql";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { compose, Route } from "react-router-hoc";
import { EventDetails } from "utils/event";
import { useMemo } from "react";
import { Button } from "@CreativelySquared/uikit";
import { Roles } from "utils/roles";
import { ProtectedRoute } from "utils/route";
import { SortOrder } from "utils/order";
import { orderBy } from "lodash";
import { withSuspense } from "utils/hooks/suspense";
import { EventType } from "utils/event";
import { ActivityEvent, EventVariants } from "components";

import styles from "./styles.module.scss";

export const ProjectFeedbackRoute = compose(
  ProtectedRoute({ access: Roles.cs_admins }),
  Route(
    {
      id: Route.params.string,
    },
    ({ id }) => `/projects/${id}/feedback`
  )
);

const useProjectSuspenseQuery = withSuspense(useProjectQuery);

const useProjectEventsSuspenseQuery = withSuspense(useProjectEventsQuery);

export const ProjectFeedback = ProjectFeedbackRoute(
  ({
    match: {
      params: { id },
    },
  }) => {
    const { t } = useTranslation("projects");

    const { data: { getProject: project } = {} } = useProjectSuspenseQuery({
      variables: { projectId: id },
      suspense: true,
    });

    const { data: { getProjectEvents } = {} } = useProjectEventsSuspenseQuery({
      variables: {
        projectId: id,
        eventNames: [
          EventType.project_feedback_created,
          EventType.project_feedback_edited,
        ],
      },
      suspense: true,
      fetchPolicy: "no-cache",
    });

    const events = useMemo(
      () => orderBy(getProjectEvents?.nodes, "happenedAt", SortOrder.DESC),
      [getProjectEvents]
    );

    return (
      <section className={styles.projectFeedback}>
        <Helmet title={t("feedback.title")} />

        {events?.length === 0 && (
          <>
            <h1>{t("feedback.empty.title")}</h1>
            <p>{t("feedback.empty.explanation")}</p>
          </>
        )}

        {!!events?.length && (
          <div className={styles.feedbackList}>
            <section>
              {events?.map((event) => (
                <ActivityEvent
                  className={styles.event}
                  event={event as EventDetails}
                  key={event?.eventId}
                  variant={EventVariants.Feedback}
                />
              ))}
            </section>

            <Button readonly outlined>
              {project?.feedbackVotesAverage} / 5
            </Button>
          </div>
        )}
      </section>
    );
  }
);
