import { GetRouteProps } from "utils/types";
import { FC, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { SortFields, SortOrder } from "utils/order";
import { Role } from "api/enums";
import {
  Members as MembersLayout,
  MembersFilter,
  useMembersColumns,
  useMembersFilter,
  MemberRoles,
} from "Customers/components/Members";
import { getFullName } from "utils/users";
import { usePagination } from "utils/hooks/pagination";
import { useDebounceValue } from "utils/hooks/useDebounceValue";
import {
  useGetAccountUsersQuery,
  useSendActivationEmailMutation,
} from "api/graphql";
import { useNotification } from "components";

import { membersRouteParams } from "../route";

import { NewMemberModal } from "./NewMemberModal";
import { EditMemberModal } from "./EditMemberModal";
import { DeleteMemberModal } from "./DeleteMemberModal";

type Props = GetRouteProps<typeof membersRouteParams> & {
  accountId: string;
  readOnly?: boolean;
};

const ITEMS_PER_PAGE = 24;

const AccountMemberRoles = MemberRoles.filter(
  (role) => role !== Role.MasterOrgOwner
);

export const Members: FC<Props> = ({
  match: {
    query: {
      members_search,
      members_page = 1,
      members_sort = SortFields.createdAt,
      members_sortOrder = SortOrder.DESC,
      members_roles = [],
    },
    params,
    query,
  },
  link,
  history: { push },
  accountId,
  readOnly,
}) => {
  const { t } = useTranslation("accounts");
  const { setNotification, notificationTypes } = useNotification();
  const [deletingUserData, setDeletingUserData] = useState<{
    userId: string;
    name: string;
  } | null>(null);
  const [editingUserData, setEditingUserData] = useState<{
    userId: string;
    name: string;
    surname: string;
    email: string;
    role: Role;
  } | null>(null);
  const [newMemberVisible, setNewMemberVisible] = useState(false);

  const pagination = usePagination({
    page: members_page,
    limit: ITEMS_PER_PAGE,
    sort: members_sort,
    sortOrder: members_sortOrder,
  });

  const debouncedSearch = useDebounceValue(members_search);

  const {
    data: { users } = {},
    loading: usersLoading,
    refetch,
  } = useGetAccountUsersQuery({
    variables: {
      pagination,
      role: members_roles.length
        ? (members_roles as Role[])
        : AccountMemberRoles,
      organizationId: accountId,
      search: debouncedSearch,
    },
    fetchPolicy: "cache-and-network",
  });

  const [sendActivationEmail] = useSendActivationEmailMutation({
    onCompleted: () => {
      setNotification({
        message: t("common:user.invitation.success"),
        type: notificationTypes.Success,
      });
    },
    onError: (error) => {
      setNotification({
        message: error.message ?? t("common:user.invitation.error"),
        type: notificationTypes.Error,
      });
    },
  });

  const closeDeleteModal = useCallback(() => {
    setDeletingUserData(null);
  }, []);

  const closeEditModal = useCallback(() => {
    setEditingUserData(null);
  }, []);

  const toggleNewMemberModal = useCallback(() => {
    setNewMemberVisible((prev) => !prev);
  }, []);

  const columns = useMembersColumns({
    onDelete: (cell) => {
      const { userId, name, surname, email } = cell.row.original;

      setDeletingUserData({
        userId: userId || "",
        name: name || surname ? getFullName({ name, surname }) : email || "",
      });
    },
    onEdit: (cell) => {
      const { userId, email, surname, name, role, hasActions } =
        cell.row.original;

      if (!hasActions) {
        return;
      }

      setEditingUserData({
        userId: userId || "",
        name: name || "",
        surname: surname || "",
        email: email || "",
        role: (role as Role) || "",
      });
    },
    visibleColumns: {
      name: true,
      role: true,
      accounts: false,
      actions: !readOnly,
    },
    onResend: (cell) =>
      sendActivationEmail({
        variables: {
          userId: cell.row.original.userId || "",
          organizationId: accountId,
        },
      }),
  });
  const {
    filterData,
    filterOptions,
    loading: filtersLoading,
  } = useMembersFilter({
    roles: members_roles as Role[],
    accountIds: [accountId],
    availableFilters: [MembersFilter.roles],
    availableFilterRoles: AccountMemberRoles,
  });

  const sortOptions = useMemo(
    () => [
      {
        label: t("common:sort.options.title.asc"),
        to: link({
          ...query,
          ...params,
          members_page: 1,
          members_sortOrder: SortOrder.ASC,
          members_sort: SortFields.fullName,
        }),
      },
      {
        label: t("common:sort.options.title.desc"),
        to: link({
          ...query,
          ...params,
          members_page: 1,
          members_sortOrder: SortOrder.DESC,
          members_sort: SortFields.fullName,
        }),
      },
    ],
    [query, params]
  );

  const onFilterChange = useCallback(
    ({ roles }) =>
      push(
        link({
          ...params,
          ...query,
          members_roles: roles,
          members_page: 1,
        })
      ),
    [params, query]
  );

  const onSearch = useCallback(
    (value: string) =>
      push(
        link({
          ...params,
          ...query,
          members_page: 1,
          members_search: value ? value : undefined,
        })
      ),
    [push, query]
  );

  return (
    <>
      <MembersLayout
        title={t("members.title")}
        toggleNewMemberModal={toggleNewMemberModal}
        page={members_page}
        search={members_search}
        sortValue={link({
          ...params,
          ...query,
          members_page: 1,
        })}
        readOnly={readOnly}
        sortOptions={sortOptions}
        filterData={filterData}
        filterOptions={filterOptions}
        onFilterChange={onFilterChange}
        loading={usersLoading || filtersLoading}
        onSearch={onSearch}
        columns={columns}
        pageQueryParam="members_page"
        users={
          users?.nodes?.map((user) => ({
            ...user,
            roles: user?.organizationRoles || [],
          })) || []
        }
        totalPages={users?.pageInfo?.totalPages}
      />
      <NewMemberModal
        visible={newMemberVisible}
        onClose={toggleNewMemberModal}
        accountId={accountId}
        onComplete={refetch}
      />
      {deletingUserData && (
        <DeleteMemberModal
          visible={!!deletingUserData}
          onClose={closeDeleteModal}
          accountId={accountId}
          userId={deletingUserData.userId}
          name={deletingUserData.name}
        />
      )}
      {editingUserData && (
        <EditMemberModal
          visible={!!editingUserData}
          onClose={closeEditModal}
          accountId={accountId}
          userData={editingUserData}
        />
      )}
    </>
  );
};
