import "./locales";
import { lazy } from "react";
import { compose, Route } from "react-router-hoc";
import { Roles } from "utils/roles";
import { ProtectedRoute } from "utils/route";
import { EventType } from "utils/event";

export const ActivityRoute = compose(
  ProtectedRoute({
    access: [Roles.cs_admins, Roles.customer],
  }),
  Route(
    {
      manager: Route.query.string,
      eventId: Route.query.string,
      type: Route.query.array(Route.query.oneOf(...Object.values(EventType))),
      page: Route.query.number,
    },
    "/activity"
  )
);

export const Activity = ActivityRoute(
  lazy(() => import(/* webpackChunkName: "Activity" */ "./Activity"))
);
