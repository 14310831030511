import React, { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { SubscriptionPlan } from "api/types";
import { OrganizationSelector } from "Customers/components/OrganizationSelector";
import { OrganizationType } from "api/enums";
import { isNumber } from "lodash";
import { Link } from "react-router-dom";
import { links } from "App";
import { GetProps } from "react-router-hoc/lib/types";

type Props = {
  subscriptionPlan?: SubscriptionPlan | null;
  accountType?: OrganizationType | null;
  showAvailableCredits?: boolean;
} & GetProps<typeof OrganizationSelector>;

export const AccountOrganizationSelector: FC<Props> = ({
  organizationId,
  subscriptionPlan,
  accountType,
  showAvailableCredits = true,
  ...props
}) => {
  const { t } = useTranslation("accounts");

  return (
    <div className="flex flex-col">
      <OrganizationSelector organizationId={organizationId} {...props} />
      {showAvailableCredits &&
        accountType === OrganizationType.Integrated &&
        organizationId &&
        (subscriptionPlan?.name ? (
          isNumber(subscriptionPlan?.credits) && (
            <p className="text-sm text-mid-blue-steel mt-4">
              {t("details.organization.credits", {
                credits: subscriptionPlan?.credits,
              })}
            </p>
          )
        ) : (
          <p className="text-sm mt-4 text-orange">
            <Trans
              i18nKey="details.organization.errors.plan"
              t={t}
              components={{
                a: (
                  <Link
                    to={links.OrganizationDetails({
                      id: organizationId,
                    })}
                    className="text-teal font-medium"
                  />
                ),
                br: <br />,
              }}
            />
          </p>
        ))}
    </div>
  );
};
