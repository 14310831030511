import { FC, useCallback, useState } from "react";
import { EditToggler } from "Customers/components/EditToggler";
import { Button, Loader } from "@CreativelySquared/uikit";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { ReactComponent as PlusIcon } from "images/plus.svg";

import { UpdateCredits, UpdateCreditsFormData } from "./UpdateCredits";
import { PlanFormData, PlanSectionForm } from "./PlanSectionForm";

interface Props {
  readOnly?: boolean;
  isEdit?: boolean;
  toggleEdit?: () => void;
  isIntegrated?: boolean;
  loading?: boolean;
  onUpdateBalance?: (
    data: UpdateCreditsFormData,
    limit?: number | null
  ) => Promise<void>;
  updateCreditsLoading?: boolean;
  empty?: boolean;
  onAddPlan?: () => void;
  isUpdating?: boolean;
}

export const PlanSection: FC<Props> = ({
  readOnly,
  isEdit = false,
  toggleEdit,
  isIntegrated,
  loading,
  onUpdateBalance,
  updateCreditsLoading,
  empty = false,
  onAddPlan,
  isUpdating = false,
}) => {
  const { t } = useTranslation("accounts");

  const [isCreditsUpdate, setCreditsUpdate] = useState(false);

  const toggleUpdateCredits = useCallback(() => {
    setCreditsUpdate((prev) => !prev);
  }, []);

  const {
    values: { planLimit },
    isValid,
    handleSubmit,
    resetForm,
    isSubmitting,
  } = useFormikContext<PlanFormData>();

  return (
    <section>
      <form noValidate onSubmit={handleSubmit}>
        <div className="flex justify-between items-center mb-7">
          <h2 className="text-2xl">{t("plan.title")}</h2>
          {!readOnly && !isIntegrated && toggleEdit && !empty && (
            <EditToggler
              isEdit={isEdit}
              submitDisabled={!isValid}
              onToggleEdit={toggleEdit}
              loading={isSubmitting || isUpdating}
              onCancel={() => {
                resetForm();
                toggleEdit();
              }}
            />
          )}
          {empty && onAddPlan && (
            <Button onClick={onAddPlan} size={Button.sizes.Normal}>
              <PlusIcon className="w-7 h-7" /> {t("plan.addPlan")}
            </Button>
          )}
        </div>
        {loading ? (
          <Loader radius={50} className="m-auto" />
        ) : (
          !empty && (
            <PlanSectionForm
              isEdit={isEdit}
              readOnly={readOnly}
              isIntegrated={isIntegrated}
              updateCreditsLoading={updateCreditsLoading}
              onUpdateCredits={
                !isEdit && onUpdateBalance ? toggleUpdateCredits : undefined
              }
            />
          )
        )}
      </form>
      {onUpdateBalance && (
        <UpdateCredits
          visible={isCreditsUpdate}
          onClose={toggleUpdateCredits}
          max={planLimit ?? 0}
          onSubmit={async (data) => {
            await onUpdateBalance(data, planLimit);
            toggleUpdateCredits();
          }}
          loading={updateCreditsLoading}
        />
      )}
    </section>
  );
};
