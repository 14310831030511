import { Button, Input } from "@CreativelySquared/uikit";
import { memo, useMemo } from "react";
import { useFormik } from "formik";
import { object, string } from "yup";
import { Trans, useTranslation } from "react-i18next";
import { ModalDescription, ModalTitle } from "components";

type FormData = { confirm: string };

type Props = {
  onSubmit: () => void;
  isSubmitting?: boolean;
  name: string;
  onClose: () => void;
};

export const DeleteMemberModal = memo<Props>(
  ({ onSubmit, isSubmitting, name, onClose }) => {
    const { t } = useTranslation();

    const validationSchema = useMemo(
      () =>
        object({
          confirm: string()
            .required("validation.required")
            .lowercase()
            .oneOf([t("user.remove.validationWord")]),
        }),
      []
    );

    const {
      handleSubmit,
      handleChange,
      handleBlur,
      isValid,
      values: { confirm },
      submitCount,
      errors,
    } = useFormik<FormData>({
      onSubmit,
      validationSchema,
      initialValues: {
        confirm: "",
      },
    });

    return (
      <form noValidate onSubmit={handleSubmit}>
        <ModalTitle>
          <Trans
            t={t}
            i18nKey="user.remove.title"
            values={{
              name,
            }}
            components={{ span: <span className="text-red" /> }}
          />
        </ModalTitle>
        <ModalDescription>
          {
            <Trans
              t={t}
              i18nKey="user.remove.description"
              values={{
                name,
              }}
              components={{ br: <br /> }}
            />
          }
        </ModalDescription>
        <Input
          name="confirm"
          variant={Input.variants.Secondary}
          value={confirm.toUpperCase()}
          className="w-full"
          placeholder={t("user.remove.placeholder")}
          error={!!submitCount && !!errors.confirm}
          onBlur={handleBlur}
          onChange={handleChange}
        />

        <section className="flex justify-end mt-7">
          <Button
            variant={Button.variants.Cancel}
            type="button"
            className="mr-5"
            onClick={onClose}
            outlined
            borderless
          >
            {t("actions.cancel")}
          </Button>
          <Button
            loading={isSubmitting}
            type="submit"
            disabled={!isValid || isSubmitting}
          >
            {t("actions.delete")}
          </Button>
        </section>
      </form>
    );
  }
);
