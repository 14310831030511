import { useTranslation } from "react-i18next";
import {
  DetailedHTMLProps,
  HTMLAttributes,
  forwardRef,
  memo,
  useRef,
  useState,
} from "react";
import { Textarea } from "@CreativelySquared/uikit";
import { useFormik } from "formik";
import { object } from "yup";
import {
  AssetDocument,
  AssetLibraryDetailsDocument,
  BriefCommentsDocument,
  AccountNotesDocument,
  ProjectCommentsDocument,
  ProjectNotesDocument,
  useCreateCommentMutation,
  useCreateAccountNoteMutation,
  useCreateProjectNoteMutation,
  useEditCommentMutation,
  useEditAccountNoteMutation,
  useEditProjectNoteMutation,
} from "api/graphql";
import { noop, uniqueId } from "lodash";
import clsx from "clsx";
import { getOperationName } from "@apollo/client/utilities";
import { CommentTargetType } from "api/enums";
import { commentValidationSchema } from "utils/validation";
import { parseError } from "utils/form";
import { MentionsInputProps } from "react-mentions";
import { useClickOutside } from "utils/hooks/events";
import { useNotification } from "components/Notifications";
import { formatBytes } from "utils/formatBytes";
import { getLocalFileType } from "utils/file";
import { ReactComponent as DocumentIcon } from "images/fileText.svg";
import { useDragAndDrop } from "utils/hooks/dragAndDrop";
import { UploadType, useUpload } from "utils/hooks/upload";

import { MentionsTextarea } from "./MentionsTextarea";
import { NoteType } from "./types";
import { AttachmentData, Attachments } from "./Attachments";

import styles from "./styles.module.scss";

type FormData = {
  content: {
    message: string;
    mentions?: string[];
  };
};

type commentMetadata = {
  height?: number;
  width?: number;
  x?: number;
  y?: number;
  time?: number;
  sectionId?: string;
  conceptId?: string;
};

type Props = {
  compact?: boolean;
  content?: string;
  commentId?: string;
  targetId: string;
  noteType: NoteType;
  onClose?: () => void;
  onSubmit?: () => void;
  commentMetadata?: commentMetadata;
  changeRequest?: boolean;
  textareaInputClassName?: string;
  textareaClassName?: string;
  autofocus?: boolean;
  showControls?: boolean;
  organizationId?: string;
  projectId?: string;
  enableMentioning?: boolean;
  enableAttaching?: boolean;
} & DetailedHTMLProps<HTMLAttributes<HTMLFormElement>, HTMLFormElement>;

const maxFiles = 5;
const maxSize = 52428800; // 50MB

export const NoteForm = memo(
  forwardRef<HTMLTextAreaElement, Props>(
    (
      {
        compact = false,
        content: initialContent = "",
        commentId,
        targetId,
        noteType,
        onClose = noop,
        onSubmit,
        commentMetadata,
        changeRequest,
        textareaInputClassName,
        textareaClassName,
        autofocus,
        showControls: forceShowControls,
        organizationId,
        projectId,
        enableMentioning = true,
        enableAttaching = true,
        ...props
      },
      ref
    ) => {
      const { t } = useTranslation("common");
      const { setNotification, notificationTypes } = useNotification();
      const [isFocused, setIsFocused] = useState(false);
      const formRef = useRef<HTMLFormElement>(null);
      const [filesUploading, setFilesUploading] = useState(false);
      // TODO: Move files in form
      const [files, setFiles] = useState<
        Array<{ metadata: AttachmentData; file: File }>
      >([]);
      const [upload] = useUpload({
        onAttach: (ids) => publishComment(ids),
      });

      useClickOutside(formRef, () => setIsFocused(false));

      // Account notes

      const [createAccountNote, { loading: accountNoteCreateLoading }] =
        useCreateAccountNoteMutation({
          refetchQueries: [getOperationName(AccountNotesDocument)!],
          awaitRefetchQueries: true,
        });

      const [editAccountNote, { loading: accountNoteEditLoading }] =
        useEditAccountNoteMutation();

      // project internal notes

      const [createProjectNote, { loading: projectNoteCreateLoading }] =
        useCreateProjectNoteMutation({
          refetchQueries: [getOperationName(ProjectNotesDocument)!],
          awaitRefetchQueries: true,
        });

      const [editProjectNote, { loading: projectNoteEditLoading }] =
        useEditProjectNoteMutation();

      const [createComment, { loading: commentLoading }] =
        useCreateCommentMutation({
          refetchQueries: [
            getOperationName(BriefCommentsDocument)!,
            getOperationName(AssetDocument)!,
            getOperationName(AssetLibraryDetailsDocument)!,
            getOperationName(ProjectCommentsDocument)!,
          ],
          awaitRefetchQueries: true,
        });

      const [editComment, { loading: commentEditLoading }] =
        useEditCommentMutation({
          refetchQueries: [
            getOperationName(BriefCommentsDocument)!,
            getOperationName(AssetDocument)!,
            getOperationName(AssetLibraryDetailsDocument)!,
            getOperationName(ProjectCommentsDocument)!,
          ],
          awaitRefetchQueries: true,
        });

      // Will be removed during Comments refactoring
      const actions = {
        create: {
          [NoteType.AssetComment]: createComment,
          [NoteType.BriefComment]: createComment,
          [NoteType.OrganizationInternalNote]: createAccountNote,
          [NoteType.ProjectInternalNote]: createProjectNote,
          [NoteType.Comment]: createComment,
        },
        edit: {
          [NoteType.AssetComment]: editComment,
          [NoteType.BriefComment]: editComment,
          [NoteType.OrganizationInternalNote]: editAccountNote,
          [NoteType.ProjectInternalNote]: editProjectNote,
          [NoteType.Comment]: editComment,
        },
      };

      const validationSchema = object({
        content: commentValidationSchema,
      });

      const isComment = [
        NoteType.AssetComment,
        NoteType.BriefComment,
        NoteType.Comment,
      ].includes(noteType);

      const targetType = {
        [NoteType.AssetComment]: CommentTargetType.AssetVersion,
        [NoteType.BriefComment]: CommentTargetType.Brief,
        [NoteType.Comment]: CommentTargetType.Comment,
      } as { [key in NoteType]?: string };

      // will be good to move to the separate hook
      const publishComment = async (fileIds?: Array<string>) => {
        try {
          const variables: any = {
            // comments
            ...(isComment && {
              ...(commentId
                ? // edit
                  {
                    commentId: commentId ?? "",
                    content: content.message,
                    extra: { ...commentMetadata, mentions: content.mentions },
                  }
                : // create
                  {
                    comment: {
                      content: content.message,
                      targetId,
                      targetType: targetType[noteType],
                      fileIds,
                      extra: {
                        ...commentMetadata,
                        mentions: content.mentions,
                      },
                    },
                  }),
            }),
            // notes
            ...(!isComment && {
              id: commentId ?? targetId,
              data: { content: content.message },
            }),
          };

          const action = actions[commentId ? "edit" : "create"][noteType];

          return action({
            variables,
          }).then(() => {
            setFiles([]);
            onClose();
            setIsFocused(false);
            resetForm();
            onSubmit?.();
            setFilesUploading(false);
          });
        } catch {
          setErrors({ content: t("common:validation.required") });
        }
      };

      const {
        errors,
        setFieldValue,
        handleSubmit,
        isValid,
        isSubmitting,
        resetForm,
        setErrors,
        submitCount,
        touched,
        values: { content },
      } = useFormik<FormData>({
        onSubmit: () => {
          const attachments = files.map(({ file }) => file!);

          if (attachments.length) {
            setFilesUploading(true);
            return upload({
              files: attachments,
              type: UploadType.comment_attachment,
              organizationId,
            });
          }

          publishComment();
        },
        validationSchema,
        initialValues: {
          content: {
            message: initialContent,
            mentions: [],
          },
        },
      });

      const handleClose = () => {
        resetForm();
        onClose();
        setIsFocused(false);
        setFiles([]);

        if (document.activeElement instanceof HTMLElement) {
          document.activeElement.blur();
        }
      };

      const showControls =
        forceShowControls ||
        isFocused ||
        !!commentId ||
        !!files.length ||
        !!content.message ||
        !!commentMetadata?.sectionId;

      const translationPath = changeRequest
        ? "asset.changes"
        : isComment
        ? "comments"
        : "notes";
      const attachMessage = t("noteForm.attach.errors.general", {
        maxFiles,
        maxSize: formatBytes(maxSize),
      });

      const onAttach = (newFiles: File[]) => {
        if (newFiles?.length) {
          let isSizeLimit = false;
          let isMaxLimit = false;

          const validFiles = Array.from(newFiles).flatMap((file, index) => {
            if (files.length + (index + 1) > maxFiles) {
              isMaxLimit = true;
              return [];
            }
            if (file?.size >= maxSize) {
              isSizeLimit = true;
              return [];
            }
            return [
              {
                file,
                metadata: {
                  id: uniqueId(),
                  name: file.name,
                  type: getLocalFileType(file.type),
                  src: URL.createObjectURL(file),
                },
              },
            ];
          });

          if (isSizeLimit || isMaxLimit) {
            setNotification({
              message:
                isSizeLimit && isMaxLimit
                  ? attachMessage
                  : t(
                      `noteForm.attach.errors.${
                        isSizeLimit ? "size" : "limit"
                      }`,
                      { maxSize: formatBytes(maxSize), maxFiles }
                    ),
              type: notificationTypes.Error,
            });
          }
          setFiles((prev) => [...prev, ...validFiles]);
        }
      };

      const { dragOver, onDrop, onDragLeave, onDragOver } = useDragAndDrop({
        onUpload: onAttach,
      });

      const loading =
        commentEditLoading ||
        accountNoteCreateLoading ||
        accountNoteEditLoading ||
        projectNoteCreateLoading ||
        projectNoteEditLoading ||
        commentLoading ||
        filesUploading ||
        isSubmitting;

      return (
        <form
          noValidate
          ref={formRef}
          onDrop={onDrop}
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          {...props}
        >
          <Textarea
            className={clsx(styles.textarea, {
              [styles.compact]: compact && !content.message,
              [styles.create]: !commentId && !forceShowControls && !compact,
              [styles.change]: changeRequest,
              [styles.expanded]: isFocused || files.length,
              [styles.fullfilled]: !!content.message,
            })}
            error={
              !!submitCount && touched.content
                ? t(...parseError(errors.content?.message)).toString()
                : undefined
            }
            loading={loading}
            showControls={showControls}
            size={
              isFocused || content.message
                ? Textarea.sizes.Regular
                : Textarea.sizes.Small
            }
            variant={Textarea.variants.Advanced}
            value={content.message}
            onFocus={() => setIsFocused(true)}
            onChange={(e) =>
              setFieldValue("content", {
                message: e.target.value,
                mentions: undefined,
              })
            }
            autoFocus={autofocus}
            name="content"
            placeholder={
              props.placeholder ??
              t(
                `${translationPath}.form.placeholder.${
                  commentId ? "edit" : "create"
                }`
              )
            }
            ref={ref}
            renderComponent={
              enableMentioning
                ? ({ className, ...inputProps }) => (
                    <MentionsTextarea
                      {...(inputProps as MentionsInputProps)}
                      containerClassName={clsx(
                        className,
                        textareaClassName,
                        styles.textareaContainer
                      )}
                      inputClassName={clsx(
                        className,
                        textareaInputClassName,
                        styles.textareaInput
                      )}
                      onChange={(message, mentions) =>
                        setFieldValue("content", {
                          message,
                          mentions,
                        })
                      }
                      ref={ref}
                      organizationId={organizationId}
                      projectId={projectId}
                    />
                  )
                : undefined
            }
          >
            <Textarea.Cancel
              disabled={loading}
              onClick={handleClose}
              onMouseDown={(event: MouseEvent) => event.preventDefault()}
            >
              {t("actions.cancel")}
            </Textarea.Cancel>
            <Textarea.Save
              disabled={!isValid || initialContent === content.message}
              loading={loading}
              type="button"
              onClick={handleSubmit}
            >
              {t(`${translationPath}.actions.save`, {
                count: loading ? 0 : commentId ? 1 : 2,
              })}
            </Textarea.Save>
            <Attachments
              files={files.map((file) => file.metadata)}
              loading={loading}
              onRemove={(id) =>
                setFiles((prev) =>
                  prev.filter((item) => item.metadata.id !== id)
                )
              }
              autoPreview
              className="px-[20px]"
            />
            {enableAttaching && (
              <Textarea.Attach
                onChange={(event) => {
                  onAttach([...(event.target.files ?? [])]);

                  event.target.value = "";
                }}
                tooltipMessage={attachMessage}
                disabled={loading || files.length === maxFiles}
              />
            )}
            {dragOver && (
              <div className={styles.dragPreview}>
                <DocumentIcon />
              </div>
            )}
          </Textarea>
        </form>
      );
    }
  )
);
