import clsx from "clsx";
import { EventDetails, EventType } from "utils/event";
import { DetailedHTMLProps, HTMLAttributes, memo, useMemo } from "react";
import { SubscriptionPlanType } from "api/enums";
import { User } from "api/types";
import { cn } from "utils/styles";
import { useTranslation } from "react-i18next";
import { format, formatRelative } from "date-fns";
import { enGB } from "date-fns/locale";
import { Avatar } from "components/Avatar";
import { DividedList } from "components/DividedList";
import { Roles } from "utils/roles";
import { getFullName } from "utils/users";
import { links } from "App";
import { Link } from "react-router-dom";
import { Checkbox } from "@CreativelySquared/uikit";
import { useProtect } from "utils/hooks/protection";
import { CamelCase } from "utils/types";
import { contactSalesURL } from "utils/links";
import { useQueryParams } from "utils/hooks/query";
import { useProjectNavigation } from "Projects/utils/navigation";
import { formatDate } from "utils/time";

import { eventIcon, subscriptionPlanIcon } from "./icons";

import styles from "./styles.module.scss";

export enum EventVariants {
  Feedback = "feedback",
  Full = "full",
}

type Props = {
  event: EventDetails;
  variant?: EventVariants;
  projectId?: string;
  organizationId?: string;
} & DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;

const RATES: { [key: number]: string } = {
  1: "very_unsatisfied",
  2: "unsatisfied",
  3: "neutral",
  4: "satisfied",
  5: "very_satisfied",
};

enum LinkType {
  asset = "asset",
  assets = "assets",
  comment = "comment",
  account = "account",
  project = "project",
  brief = "brief",
  brand = "brand",
  gallery = "gallery",
  contact = "contact",
  organization = "organization",
}

export const ActivityEvent = memo<Props>(
  ({
    event,
    className,
    variant = EventVariants.Full,
    projectId,
    organizationId,
    ...props
  }) => {
    const { t } = useTranslation("activity");
    const getProjectNavigationLink = useProjectNavigation();
    const isAdmin = useProtect()(Roles.cs_admins);
    const queryParams = useQueryParams();

    const {
      asset,
      details,
      happenedAt,
      name,
      organization,
      project,
      comment,
      brandProfile,
      doneBy,
      subscriptionPlan,
      eventId,
      masterOrganization,
    } = event;

    if (!(String(name) in EventType)) {
      return null;
    }

    const theSameRating =
      event?.details?.current?.vote === event?.details?.previous?.vote;
    const isPAYG =
      event.subscriptionPlan?.type === SubscriptionPlanType.Credits;
    const subscriptionStatus = event.subscriptionPlan?.status ?? "default";

    const projectLink =
      project?.projectId && !project?.deletedAt
        ? getProjectNavigationLink(project)
        : "";

    const contactSalesLink = {
      type: LinkType.contact,
      link: { pathname: contactSalesURL },
      linkProps: { target: "_blank" },
    };

    const ActivityLink: Record<string, any> = {
      [EventType.subscription_plan_edited]: contactSalesLink,
      [EventType.subscription_plan_renewed]: contactSalesLink,
      [EventType.subscription_plan_expired]: contactSalesLink,
      [EventType.custom_subscription_expire_alert]: contactSalesLink,
      [EventType.auto_renewal_alert]: contactSalesLink,
      [EventType.org_credits_under_threshold]: contactSalesLink,
      [EventType.credits_expire_alert]: contactSalesLink,
      ...(brandProfile?.brandProfileId && {
        [EventType.brand_profile_edited]: {
          type: LinkType.brand,
          link: links.BrandDetails({
            id: brandProfile.brandProfileId,
          }),
        },
        [EventType.brand_profile_created]: {
          type: LinkType.brand,
          link: links.BrandDetails({
            id: brandProfile.brandProfileId,
          }),
        },
      }),
      ...(organization?.organizationId && {
        [EventType.organization_account_manager_assigned]: {
          type: LinkType.account,
          link: isAdmin
            ? links.AccountDetailsData({
                id: organization.organizationId,
              })
            : links.SettingsAccountDetails(),
        },
        [EventType.org_linked_to_master_org]: {
          type: LinkType.account,
          link: isAdmin
            ? links.AccountDetailsData({
                id: organization.organizationId,
              })
            : undefined,
        },
        [EventType.org_unlinked_from_master_org]: {
          type: LinkType.account,
          link: isAdmin
            ? links.AccountDetailsData({
                id: organization.organizationId,
              })
            : undefined,
        },
        [EventType.org_created]: {
          type: LinkType.account,
          link: isAdmin
            ? links.AccountDetailsData({
                id: organization.organizationId,
              })
            : undefined,
        },
        [EventType.user_added_to_org]: {
          type: LinkType.account,
          link: isAdmin
            ? links.AccountDetailsData({
                id: organization.organizationId,
              })
            : undefined,
        },
        [EventType.user_removed_from_organization]: {
          type: LinkType.account,
          link: isAdmin
            ? links.AccountDetailsData({
                id: organization.organizationId,
              })
            : undefined,
        },
        [EventType.subscription_plan_name_edited]: {
          type: LinkType.account,
          link: isAdmin
            ? links.AccountDetailsData({
                id: organization.organizationId,
              })
            : undefined,
        },
        [EventType.subscription_plan_credits_limit_edited]: {
          type: LinkType.account,
          link: isAdmin
            ? links.AccountDetailsData({
                id: organization.organizationId,
              })
            : undefined,
        },
        [EventType.subscription_plan_status_edited]: {
          type: LinkType.account,
          link: isAdmin
            ? links.AccountDetailsData({
                id: organization.organizationId,
              })
            : undefined,
        },
        [EventType.subscription_plan_date_edited]: {
          type: LinkType.account,
          link: isAdmin
            ? links.AccountDetailsData({
                id: organization.organizationId,
              })
            : undefined,
        },
        [EventType.org_type_edited]: {
          type: LinkType.account,
          link: isAdmin
            ? links.AccountDetailsData({
                id: organization.organizationId,
              })
            : undefined,
        },
      }),
      ...(comment?.asset?.assetId && {
        [EventType.asset_comment_created]: {
          assetDetails: comment.asset.latestVersion?.file?.fileName,
          type: LinkType.comment,
          link: links.AssetDetails({
            id: comment.asset.assetId,
            comments: "comments",
          }),
        },
      }),
      ...(masterOrganization?.masterOrganizationId && {
        [EventType.master_org_created]: {
          type: LinkType.organization,
          link: isAdmin
            ? links.OrganizationDetailsTab({
                id: masterOrganization.masterOrganizationId,
              })
            : links.SettingsOrganizationDetails(),
        },
        [EventType.master_org_edited]: {
          type: LinkType.organization,
          link: isAdmin
            ? links.OrganizationDetailsTab({
                id: masterOrganization.masterOrganizationId,
              })
            : links.SettingsOrganizationDetails(),
        },
      }),
      ...(project && {
        [EventType.project_partial_delivery]: {
          type: LinkType.project,
          link: projectLink,
        },
        [EventType.project_submitted]: {
          type: LinkType.project,
          link: projectLink,
        },
        [EventType.brief_comment_created]: {
          type: LinkType.brief,
          link: projectLink,
        },
        [EventType.brief_updated]: {
          type: LinkType.brief,
          link: projectLink,
        },
        [EventType.project_regressed_to_draft]: {
          type: LinkType.project,
          link: projectLink,
        },
        [EventType.project_clarification_requested]: {
          type: LinkType.project,
          link: projectLink,
        },
        [EventType.project_canceled]: {
          type: LinkType.project,
          link: projectLink,
        },
        [EventType.project_moved_in_queue]: {
          type: LinkType.project,
          link: projectLink,
        },
        [EventType.project_approved]: {
          type: LinkType.project,
          link: projectLink,
        },
        [EventType.project_moved_to_under_revision]: {
          type: LinkType.project,
          link: projectLink,
        },
        [EventType.project_finalized]: {
          type: LinkType.project,
          link: projectLink,
        },
        [EventType.project_account_manager_assigned]: {
          type: LinkType.project,
          link: projectLink,
        },
        [EventType.project_completed]: {
          type: LinkType.project,
          link: projectLink,
        },
        [EventType.project_moved_to_queue]: {
          type: LinkType.project,
          link: projectLink,
        },
        [EventType.project_created]: {
          type: LinkType.project,
          link: projectLink,
        },
        [EventType.project_feedback_created]: {
          type: LinkType.project,
          link: isAdmin
            ? links.ProjectFeedback({ id: project.projectId })
            : projectLink,
        },
        [EventType.project_gallery_downloaded]: {
          type: LinkType.gallery,
          link: links.ProjectGallery({ id: project.projectId }),
        },
        [EventType.project_feedback_edited]: {
          type: LinkType.project,
          link: isAdmin
            ? links.ProjectFeedback({ id: project.projectId })
            : projectLink,
        },
      }),
      ...(asset?.assetId && {
        [EventType.asset_downloaded]: {
          assetDetails: asset?.latestVersion?.file?.fileName,
          type: LinkType.asset,
          link: links.AssetDetails({
            id: asset.assetId,
          }),
        },
        [EventType.asset_rating_edited]: {
          type: LinkType.asset,
          link: links.AssetDetails({
            id: asset.assetId,
          }),
        },
        [EventType.new_asset_version_created]: {
          assetDetails: asset.latestVersion?.file?.fileName,
          type: LinkType.asset,
          link: links.AssetDetails({
            id: asset.assetId,
          }),
        },
        [EventType.asset_needs_changes]: {
          type: LinkType.asset,
          link: links.AssetDetails({
            id: asset.assetId,
          }),
        },
      }),
      ...(subscriptionPlan?.subscriptionPlanId && {
        ...(isPAYG && {
          [EventType.subscription_plan_expired]: contactSalesLink,
        }),
      }),
      ...(eventId && {
        [EventType.bulk_comments_created]: {
          type: LinkType.assets,
          link: projectId
            ? links.ProjectActivity({
                id: projectId,
                ...queryParams,
                eventId,
              })
            : organizationId
            ? links.AccountActivity({
                id: organizationId,
                ...queryParams,
                eventId,
              })
            : links.Activity({ ...queryParams, eventId }),
        },
      }),
    };

    const isFeedback = [
      EventType.project_feedback_created,
      EventType.project_feedback_edited,
    ].includes(name as EventType);

    const date =
      happenedAt &&
      formatRelative(+happenedAt, new Date(), {
        locale: {
          ...enGB,
          formatRelative: (token: string) =>
            t(`timeFormat.${token === "today" ? "today" : "other"}`),
        },
      });

    const isProject = variant === EventVariants.Feedback;

    const skipName =
      [
        EventType.brand_profile_edited,
        EventType.organization_account_manager_assigned,
        EventType.project_account_manager_assigned,
        EventType.subscription_plan_expired,
        EventType.org_credits_under_threshold,
        EventType.credits_expire_alert,
        EventType.subscription_plan_renewed,
        EventType.custom_subscription_expire_alert,
        EventType.auto_renewal_alert,
        EventType.subscription_plan_edited,
      ].includes(name as EventType) ||
      (isFeedback && theSameRating && !isProject);

    const Icon =
      event.name === EventType.subscription_plan_edited ||
      event.name === EventType.subscription_plan_status_edited
        ? subscriptionPlanIcon[subscriptionStatus]
        : eventIcon[
            name as Exclude<
              EventType,
              | EventType.subscription_plan_edited
              | EventType.subscription_plan_status_edited
            >
          ];

    const activeLink = ActivityLink[name as keyof typeof ActivityLink];

    const ratingChange = (event.current ?? 0) - (event.previous ?? 0);

    const isRotatedIcon =
      name === EventType.asset_rating_edited && ratingChange < 0;

    const isExpiredPlan = name === EventType.subscription_plan_expired;
    const isEditedPlan = name === EventType.subscription_plan_edited;
    const isCreditsUpdate = name === EventType.org_credits_updated;

    const translationPath = `card.${name}${
      isEditedPlan
        ? `.${subscriptionStatus}`
        : isExpiredPlan
        ? isPAYG
          ? ".PAYG"
          : ".subscription"
        : isFeedback
        ? theSameRating
          ? ".feedback"
          : isProject
          ? ".project"
          : ".activity"
        : name === EventType.org_type_edited
        ? ""
        : ""
    }`;

    const fullName = useMemo(() => getFullName(doneBy), [doneBy]);
    const organizationName = details?.organization?.name ?? organization?.name;

    return (
      <section className={clsx(styles.activityEvent, className)} {...props}>
        <div
          className={clsx(
            styles.icon,
            styles[cn("icon", name as CamelCase<EventType>)],
            {
              [styles.rotate]: isRotatedIcon,
            }
          )}
        >
          {isProject ? (
            <Avatar
              className={styles.avatar}
              src={(doneBy as User).avatar?.thumbnail?.downloadUrl?.url}
              name={fullName}
            />
          ) : isCreditsUpdate ? (
            <div className={styles.creditDetailsDifference}>
              {(details?.difference ?? 0) > 0 && "+"}
              {details?.difference}
            </div>
          ) : (
            !!Icon && <Icon />
          )}
        </div>
        <div className="flex-1">
          <h3 className={styles.title}>
            {t(translationPath, {
              ...event,
              assetDetails: activeLink?.assetDetails,
              commentDetails: `(${t("common:common.title")})`,
              brandName: brandProfile?.name ?? t("unknown"),
              managementType: t(
                `managementType.${
                  organizationName ? "account" : "organization"
                }`
              ),
              managementEntityName:
                organizationName ?? masterOrganization?.name ?? t("unknown"),
              organizationName: organizationName ?? t("unknown"),
              masterOrganizationName: masterOrganization?.name ?? t("unknown"),
              rate:
                details && details.current?.vote
                  ? t(`rates.${RATES[details.current.vote!]}`)
                  : "",
              ...(event?.subscriptionPlan?.expirationDate && {
                expirationDate: format(
                  new Date(+event.subscriptionPlan.expirationDate),
                  "dd MMMM yyyy"
                ),
              }),
              doneToUser: getFullName(event.doneTo) || event.doneTo?.email,
              user: details?.user,
              credits: details?.currentValue,
              reason: t(`creditsUpdateReason.${details?.type}`),
              old: details?.old,
              updated: details?.updated,
              oldPlanName: t(`common:customers.plans.${details?.old?.name}`),
              newPlanName: t(
                `common:customers.plans.${details?.updated?.name}`
              ),
              oldStatus: t(`common:customers.status.${details?.old?.status}`),
              newStatus: t(
                `common:customers.status.${details?.updated?.status}`
              ),
              oldExpirationDate: details?.old?.expirationDate
                ? formatDate(new Date(Number(details.old.expirationDate)))
                : "",
              newExpirationDate: details?.updated?.expirationDate
                ? formatDate(new Date(Number(details.updated.expirationDate)))
                : "",
              organizationType: details?.organization?.type
                ? t(`common:customers.types.${details.organization.type}`)
                : "",
              oldOrganizationType: details?.oldOrganization?.type
                ? t(`common:customers.types.${details.oldOrganization.type}`)
                : "",
              count: event.amount || 0,
              fromAccountName:
                details?.fromOrganization?.name ||
                event.fromSubscriptionPlan?.organization?.name ||
                "",
              toAccountName:
                details?.toOrganization?.name ||
                event.toSubscriptionPlan?.organization?.name ||
                "",
              fromOrganizationName:
                details?.fromMasterOrganization?.name ||
                event.fromSubscriptionPlan?.masterOrganization?.name ||
                "",
            })}
          </h3>

          <DividedList className={styles.info} separator="|">
            {!skipName && variant === EventVariants.Full && !!fullName && (
              <span>{fullName}</span>
            )}

            {date && <span>{date}</span>}

            {!!activeLink?.link && !isProject && (
              <Link to={activeLink.link} {...(activeLink.linkProps ?? {})}>
                {t(`link.${activeLink.type}`)}
              </Link>
            )}
          </DividedList>

          {isFeedback && (
            <footer>
              {event.details?.current?.note && (
                <div className={styles.note}>
                  {event.details?.current?.note}
                </div>
              )}
              {event.details?.current?.isFeedbackCallRequested && (
                <Checkbox checked className="mt-7 cursor-text" readOnly>
                  {t("common:feedback.requested")}
                </Checkbox>
              )}
            </footer>
          )}
        </div>
      </section>
    );
  }
);
