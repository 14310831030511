import { useCallback, useMemo, useRef, useState } from "react";
import { AssetItemFragment, File } from "api/types";
import { unionBy, xorBy } from "lodash";
import { useProfileQuery } from "api/graphql";
import { useProtect } from "utils/hooks/protection";
import { Roles } from "utils/roles";

export const useAssetsSelect = () => {
  const { data: { me } = {} } = useProfileQuery();
  const isAdmin = useProtect()(Roles.cs_admins);
  const [selectedAssets, setSelectedAssets] = useState<AssetItemFragment[]>([]);
  const assetsViewRef =
    useRef<{ getAllAssets: () => AssetItemFragment[] | null }>(null);

  const onSelectAsset = useCallback(
    (asset: AssetItemFragment) =>
      setSelectedAssets((selectedAssets) =>
        xorBy(selectedAssets, [asset], "assetId")
      ),
    []
  );

  const onSelectAllAssets = useCallback(() => {
    setSelectedAssets((selectedAssets) =>
      unionBy(
        selectedAssets,
        assetsViewRef.current?.getAllAssets() || [],
        "assetId"
      )
    );
  }, []);

  const onClearSelection = useCallback(() => setSelectedAssets([]), []);

  const selectedAssetIds = useMemo<string[]>(
    () =>
      selectedAssets.map((asset) => asset.assetId).filter(Boolean) as string[],
    [selectedAssets]
  );
  const selectedAssetVersionIds = useMemo<string[]>(
    () =>
      selectedAssets
        .map((asset) => asset.latestVersion?.assetVersionId)
        .filter(Boolean) as string[],
    [selectedAssets]
  );
  const selectedAssetFiles = useMemo<File[]>(
    () =>
      selectedAssets
        .map((asset) => asset.latestVersion?.file)
        .filter(Boolean) as File[],
    [selectedAssets]
  );
  const hasAccountAccess = useMemo(
    () =>
      isAdmin ||
      selectedAssets.every(
        (asset) => asset.organizationId === me?.organization?.organizationId
      ),
    [selectedAssets, isAdmin, me]
  );

  return {
    selectedAssets,
    selectedAssetIds,
    selectedAssetVersionIds,
    selectedAssetFiles,
    assetsViewRef,
    onSelectAsset,
    onSelectAllAssets,
    onClearSelection,
    hasAccountAccess,
  };
};
