export enum UserErrorCodes {
  UserAlreadyInOrganizationError = "UserAlreadyInOrganizationError",
}

export const getFullName = (
  user?: {
    name?: string | null;
    surname?: string | null;
    __typename?: string;
  } | null
) => `${user?.name ?? ""} ${user?.surname ?? ""}`.trim();
