import {
  Alert,
  Button,
  Loader,
  Modal,
  Tabs,
} from "@CreativelySquared/uikit/dist";
import { useCreateAssetsMutation, useProjectQuery } from "api/graphql";
import { links } from "App";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import {
  MediaCard,
  UploadAssetVersion,
  UploadMedia,
  DeleteAsset,
  MediaCardSkeleton,
} from "components";
import { useFormik } from "formik";
import { array, mixed, object } from "yup";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  InteraptionType,
  UploadType,
  availableStatuses,
  useUpload,
} from "utils/hooks/upload";
import { FileStatuses, assetFormats, GetRouteProps } from "utils/types";
import { getFileMetadata } from "utils/file";
import { xor } from "lodash";
import { ReactComponent as ArrowIcon } from "images/arrowRight.svg";

import { UploadActionBar } from "./UploadActionBar";

import { ProjectGalleryUploadRoute } from "./index";

import styles from "./styles.module.scss";

type FormData = {
  media: Array<{
    fileId: string;
    fileName: string;
    src: string;
    size: number;
    type: string;
    status: FileStatuses;
    conceptId: string;
    assetId: string;
  }>;
};

const validationSchema = object({
  media: array()
    .of(mixed())
    .required("gallery.manage.upload.errors.required")
    .min(1, "gallery.manage.upload.errors.required"),
});

export const ProjectGalleryUpload: FC<
  GetRouteProps<typeof ProjectGalleryUploadRoute>
> = ({
  match: {
    params: { id },
    query: { conceptId },
  },
  history: { push },
}) => {
  const { t } = useTranslation("projects");
  const {
    data: { getProject: project } = {},
    loading,
    refetch,
    updateQuery,
  } = useProjectQuery({
    variables: {
      projectId: id,
      includeBrief: true,
      includeAssets: true,
    },
  });

  const [preventAway, setPreventAway] = useState(false);
  const [createAssets] = useCreateAssetsMutation();
  const [newAssetVersion, setNewAssetVersion] = useState<string | null>(null);
  const [removeAsset, setRemoveAsset] = useState<string | null>(null);
  const { pathname, search } = useLocation();

  const [selectedAssets, setSelectedAssets] = useState<string[]>([]);

  const activeConceptId = useMemo(
    () => conceptId ?? project?.brief?.concepts?.[0]?.conceptId ?? null,
    [conceptId, project?.brief?.concepts]
  );

  const [
    upload,
    { uploadStatus, interaptionStatus, setStatus, uploadingCount },
  ] = useUpload({
    preventAway,
    onAttach: (fileIds) =>
      createAssets({
        variables: {
          projectId: project?.projectId ?? "",
          assetsFields:
            fileIds?.map((fileId) => ({
              conceptId: activeConceptId ?? "",
              fileId,
            })) ?? [],
        },
        fetchPolicy: "no-cache",
      }),
  });

  const onUpload = async (files: File[]) => {
    const uploaded = await upload({
      files,
      type: UploadType.asset,
      organizationId: project?.organization?.organizationId,
      wait: true,
    });

    if (uploaded?.length) {
      const data = uploaded.map((file) => ({
        ...file,
        conceptId: activeConceptId,
      }));

      setFieldValue("media", [...(mediaRef.current ?? []), ...data]);
    }
  };

  const {
    values: { media },
    errors,
    touched,
    submitCount,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = useFormik<FormData>({
    initialValues: {
      media: [],
    },
    onSubmit: async () => {
      if (uploaded) {
        setPreventAway(false);
        return push(links.ProjectGalleryAssign({ id: id, conceptId }));
      }
      setStatus({
        interaption: InteraptionType.submit,
      });
    },
    validationSchema,
  });

  // We use ref to correctly update uploaded assets when user uploads multiple items one by one
  const mediaRef = useRef(media);

  useEffect(() => {
    mediaRef.current = media;
  }, [media]);

  const uploaded = useMemo(
    () =>
      media.every((file) =>
        availableStatuses.includes(file?.status as FileStatuses)
      ),
    [media]
  );

  const visibleMedia = useMemo(
    () =>
      media
        ?.filter((item) => item.conceptId === activeConceptId)
        ?.sort(
          (current, next) =>
            Number(current.status === FileStatuses.failed) -
            Number(next?.status === FileStatuses.failed)
        ),
    [media, activeConceptId]
  );

  const onClearSelection = useCallback(() => setSelectedAssets([]), []);
  const onSelectAll = useCallback(
    () => setSelectedAssets(visibleMedia.map((item) => item.assetId)),
    [visibleMedia]
  );

  const onBulkDelete = useCallback((deletedIds) => {
    updateQuery((data) => ({
      ...data,
      getProject: {
        ...data.getProject!,
        assets2: data.getProject?.assets2?.filter(
          (asset) => !!asset && !deletedIds.includes(asset.assetId!)
        ),
      },
    }));
  }, []);

  useEffect(() => {
    setFieldValue(
      "media",
      media.map((file) => ({
        ...file,
        ...uploadStatus.find(
          (uploadFile) => uploadFile?.fileId === file.fileId
        ),
      }))
    );

    const hasNewFiles = media.some(
      (file) => !file.assetId && file.status === FileStatuses.completed
    );

    if (uploaded && hasNewFiles) {
      refetch();
    }
  }, [uploadStatus, uploaded]);

  useEffect(() => {
    const assets =
      project?.assets2?.map((asset) => {
        const mediaItem = media.find(
          (file) => file.fileId === asset?.latestVersion?.file?.fileId
        );
        const metadata = getFileMetadata(asset?.latestVersion?.file);

        return mediaItem
          ? {
              ...mediaItem,
              assetId: asset?.assetId,
            }
          : {
              conceptId: asset?.conceptId,
              src: asset?.latestVersion?.file?.thumbnail?.downloadUrl?.url,
              fileId: asset?.latestVersion?.file?.fileId,
              fileName: asset?.latestVersion?.file?.fileName,
              assetId: asset?.assetId,
              status: asset?.latestVersion?.file?.status,
              size: metadata?.common?.size,
              type: metadata?.common?.type,
            };
      }) ?? [];

    const failed = media.filter((file) => file.status === FileStatuses.failed);

    setFieldValue("media", [...assets, ...failed]);
  }, [project?.assets2]);

  useEffect(() => {
    setPreventAway(!uploaded);
    if (uploaded) {
      setStatus({ interaption: null });
    }
  }, [uploaded]);

  return (
    <>
      <section>
        <Tabs className="mt-7 flex-wrap" size={Tabs.sizes.Small}>
          {project?.brief?.concepts?.map((concept, key) => (
            <Tabs.Item
              key={concept?.conceptId}
              isActive={() => concept?.conceptId === activeConceptId}
              component={NavLink}
              to={links.ProjectGalleryUpload({
                id,
                conceptId: concept?.conceptId ?? "",
              })}
            >
              {concept?.name
                ? t(`common:credits.summary.concept.titles.named`, {
                    name: concept.name,
                  })
                : t(`common:credits.summary.concept.titles.${key + 1}`)}
            </Tabs.Item>
          ))}
        </Tabs>

        <form noValidate onSubmit={handleSubmit}>
          {((errors.media && (touched.media || submitCount)) ||
            interaptionStatus) && (
            <Alert
              icon
              className="mt-6"
              variant={
                interaptionStatus ? Alert.variants.Info : Alert.variants.Error
              }
              title={t(
                errors.media?.toString() ||
                  `common:uploadMedia.notification.${interaptionStatus}`
              )}
            />
          )}
          <UploadMedia
            onUpload={onUpload}
            className="mt-10"
            multiple
            formats={assetFormats}
            size={
              media.length ? UploadMedia.sizes.Small : UploadMedia.sizes.Regular
            }
          />
          {loading && (
            <section className="flex w-full items-center justify-center mt-8">
              <Loader radius={50} />
            </section>
          )}
          <section className="grid grid-cols-4 gap-x-8 mb-10 empty:mb-8">
            {visibleMedia.map(
              ({ assetId, status, fileId, type, size, src, fileName }) => (
                <MediaCard
                  fileId={fileId}
                  type={type}
                  size={size}
                  src={src}
                  fileName={fileName}
                  onRemove={
                    uploaded ? () => setRemoveAsset(assetId) : undefined
                  }
                  status={status}
                  key={fileId}
                  className={styles.card}
                  versionNumber={
                    project?.assets2?.find(
                      (asset) => asset?.assetId === assetId
                    )?.versions?.length
                  }
                  onAddAssetVersion={() => setNewAssetVersion(assetId)}
                  onTitleClick={() =>
                    push({
                      pathname: links.AssetDetails({
                        id: assetId,
                      }),
                      state: {
                        assetPath: `${pathname}${search}`,
                      },
                    })
                  }
                  onSelect={
                    uploaded
                      ? () =>
                          setSelectedAssets((selectedAssets) =>
                            xor(selectedAssets, [assetId])
                          )
                      : undefined
                  }
                  selected={selectedAssets.includes(assetId)}
                />
              )
            )}
            {Array.from({ length: uploadingCount }).map((_item, index) => (
              <MediaCardSkeleton key={index} className={styles.card} />
            ))}
          </section>

          <div className="flex justify-end">
            <Button loading={isSubmitting} type="submit">
              {t("common:navigation.next")}
              <ArrowIcon className="ml-4 stroke-[1.5] w-7 h-7" />
            </Button>
          </div>
        </form>
        {newAssetVersion && (
          <Modal
            visible={newAssetVersion}
            onClose={() => setNewAssetVersion(null)}
            className="p-0"
          >
            <UploadAssetVersion
              organizationId={project?.organization?.organizationId ?? ""}
              assetId={newAssetVersion}
              onClose={() => setNewAssetVersion(null)}
            />
          </Modal>
        )}
        {removeAsset && (
          <Modal
            visible={removeAsset}
            onClose={() => setRemoveAsset(null)}
            className="overflow-visible"
          >
            <DeleteAsset
              assetId={removeAsset}
              onClose={() => setRemoveAsset(null)}
              allOption
              onDelete={(versionId) => {
                if (!versionId) {
                  setFieldValue(
                    "media",
                    media.filter((file) => file.assetId !== removeAsset)
                  );
                  setSelectedAssets((selectedAssets) =>
                    selectedAssets.filter((asset) => asset !== removeAsset)
                  );
                }
                setRemoveAsset(null);
              }}
            />
          </Modal>
        )}
      </section>
      <UploadActionBar
        selectedAssets={selectedAssets}
        onSelectAll={onSelectAll}
        onClearSelection={onClearSelection}
        onDelete={onBulkDelete}
      />
    </>
  );
};
