import {
  AssetVotedEvent,
  CreditsTransferredEvent,
  GenericAssetCommentEvent,
  GenericAssetEvent,
  GenericBrandProfileEvent,
  GenericEvent,
  GenericOrganizationEvent,
  GenericOrganizationUserEvent,
  GenericProjectEvent,
  GenericProjectFeedbackEvent,
  GenericSubscriptionPlanEvent,
  GenericUserEvent,
  OrganizationCreditUpdatedEvent,
  ProjectGalleryDownloadedEvent,
} from "api/types";

export enum EventType {
  brand_profile_edited = "brand_profile_edited",
  brand_profile_created = "brand_profile_created",
  project_partial_delivery = "project_partial_delivery",
  new_asset_version_created = "new_asset_version_created",
  project_regressed_to_draft = "project_regressed_to_draft",
  project_canceled = "project_canceled",
  project_moved_in_queue = "project_moved_in_queue",
  project_approved = "project_approved",
  project_submitted = "project_submitted",
  project_moved_to_under_revision = "project_moved_to_under_revision",
  project_finalized = "project_finalized",
  project_completed = "project_completed",
  project_moved_to_queue = "project_moved_to_queue",
  project_deleted = "project_deleted",
  org_credits_under_threshold = "org_credits_under_threshold",
  org_credits_updated = "org_credits_updated",
  organization_account_manager_assigned = "organization_account_manager_assigned",
  project_account_manager_assigned = "project_account_manager_assigned",
  project_gallery_downloaded = "project_gallery_downloaded",
  asset_downloaded = "asset_downloaded",
  asset_rating_edited = "asset_rating_edited",
  asset_comment_created = "asset_comment_created",
  brief_comment_created = "brief_comment_created",
  project_feedback_created = "project_feedback_created",
  project_feedback_edited = "project_feedback_edited",
  subscription_plan_expired = "subscription_plan_expired",
  credits_expire_alert = "credits_expire_alert",
  subscription_plan_renewed = "subscription_plan_renewed",
  subscription_plan_name_edited = "subscription_plan_name_edited",
  subscription_plan_credits_limit_edited = "subscription_plan_credits_limit_edited",
  subscription_plan_status_edited = "subscription_plan_status_edited",
  subscription_plan_date_edited = "subscription_plan_date_edited",
  custom_subscription_expire_alert = "custom_subscription_expire_alert",
  auto_renewal_alert = "auto_renewal_alert",
  subscription_plan_edited = "subscription_plan_edited",
  asset_needs_changes = "asset_needs_changes",
  brief_updated = "brief_updated",
  bulk_comments_created = "bulk_comments_created",
  project_clarification_requested = "project_clarification_requested",
  project_created = "project_created",
  master_org_created = "master_org_created",
  master_org_edited = "master_org_edited",
  org_linked_to_master_org = "org_linked_to_master_org",
  org_unlinked_from_master_org = "org_unlinked_from_master_org",
  org_created = "org_created",
  user_added_to_org = "user_added_to_org",
  user_removed_from_organization = "user_removed_from_organization",
  user_removed_from_master_organization = "user_removed_from_master_organization",
  user_added_to_master_organization = "user_added_to_master_organization",
  org_type_edited = "org_type_edited",
  credits_transferred_between_organizations = "credits_transferred_between_organizations",
  credits_assigned_from_master_organization_credit_balance = "credits_assigned_from_master_organization_credit_balance",
}

export type EventDetails = GenericAssetCommentEvent &
  GenericBrandProfileEvent &
  ProjectGalleryDownloadedEvent &
  GenericAssetEvent &
  GenericEvent &
  GenericOrganizationEvent &
  GenericOrganizationUserEvent &
  GenericProjectEvent &
  GenericProjectFeedbackEvent &
  GenericUserEvent &
  AssetVotedEvent &
  GenericSubscriptionPlanEvent &
  OrganizationCreditUpdatedEvent &
  CreditsTransferredEvent;

export const adminEvents = [
  EventType.asset_comment_created,
  EventType.project_deleted,
  EventType.project_submitted,
  EventType.brief_comment_created,
  EventType.project_feedback_created,
  EventType.project_feedback_edited,
  EventType.asset_downloaded,
  EventType.asset_rating_edited,
  EventType.organization_account_manager_assigned,
  EventType.project_account_manager_assigned,
  EventType.project_gallery_downloaded,
  EventType.brand_profile_edited,
  EventType.brand_profile_created,
  EventType.project_finalized,
  EventType.project_moved_in_queue,
  EventType.asset_needs_changes,
  EventType.bulk_comments_created,
  EventType.project_created,
  EventType.subscription_plan_name_edited,
  EventType.subscription_plan_credits_limit_edited,
  EventType.subscription_plan_status_edited,
  EventType.subscription_plan_date_edited,
  EventType.org_credits_updated,
  EventType.master_org_created,
  EventType.master_org_edited,
  EventType.org_linked_to_master_org,
  EventType.org_unlinked_from_master_org,
  EventType.org_created,
  EventType.user_added_to_org,
  EventType.user_removed_from_organization,
  EventType.user_removed_from_master_organization,
  EventType.user_added_to_master_organization,
  EventType.org_type_edited,
  EventType.credits_transferred_between_organizations,
  EventType.credits_assigned_from_master_organization_credit_balance,
];

export const customerEvents = [
  EventType.asset_comment_created,
  EventType.brief_comment_created,
  EventType.organization_account_manager_assigned,
  EventType.new_asset_version_created,
  EventType.project_account_manager_assigned,
  EventType.org_credits_under_threshold,
  EventType.brand_profile_edited,
  EventType.project_completed,
  EventType.project_approved,
  EventType.project_canceled,
  EventType.project_regressed_to_draft,
  EventType.project_partial_delivery,
  EventType.project_moved_to_queue,
  EventType.project_moved_to_under_revision,
  EventType.project_finalized,
  EventType.project_moved_in_queue,
  EventType.subscription_plan_expired,
  EventType.credits_expire_alert,
  EventType.subscription_plan_renewed,
  EventType.custom_subscription_expire_alert,
  EventType.auto_renewal_alert,
  EventType.subscription_plan_edited,
  EventType.brief_updated,
  EventType.bulk_comments_created,
  EventType.project_clarification_requested,
  EventType.subscription_plan_name_edited,
  EventType.subscription_plan_credits_limit_edited,
  EventType.subscription_plan_status_edited,
  EventType.subscription_plan_date_edited,
  EventType.org_credits_updated,
  EventType.master_org_created,
  EventType.master_org_edited,
  EventType.org_linked_to_master_org,
  EventType.org_unlinked_from_master_org,
  EventType.org_created,
  EventType.user_added_to_org,
  EventType.user_removed_from_organization,
  EventType.user_removed_from_master_organization,
  EventType.user_added_to_master_organization,
  EventType.org_type_edited,
  EventType.credits_transferred_between_organizations,
  EventType.credits_assigned_from_master_organization_credit_balance,
];

export const filterOptions = {
  subscription_notifications: [
    EventType.subscription_plan_renewed,
    EventType.custom_subscription_expire_alert,
    EventType.auto_renewal_alert,
    EventType.subscription_plan_edited,
    EventType.subscription_plan_expired,
    EventType.subscription_plan_name_edited,
    EventType.subscription_plan_credits_limit_edited,
    EventType.subscription_plan_status_edited,
    EventType.subscription_plan_date_edited,
  ],
  asset_comments: [
    EventType.asset_comment_created,
    EventType.bulk_comments_created,
  ],
  asset_downloaded: [EventType.asset_downloaded],
  asset_versions: [EventType.new_asset_version_created],
  asset_ratings: [EventType.asset_rating_edited],
  brand_notifications: [
    EventType.brand_profile_edited,
    EventType.brand_profile_created,
  ],
  brief_comments: [EventType.brief_comment_created],
  credit_notifications: [
    EventType.org_credits_under_threshold,
    EventType.credits_expire_alert,
    EventType.org_credits_updated,
    EventType.credits_transferred_between_organizations,
    EventType.credits_assigned_from_master_organization_credit_balance,
  ],
  downloads: [EventType.project_gallery_downloaded],
  account_notifications: [
    EventType.organization_account_manager_assigned,
    EventType.org_linked_to_master_org,
    EventType.org_unlinked_from_master_org,
    EventType.org_created,
    EventType.org_type_edited,
  ],
  project_feedback: [
    EventType.project_feedback_created,
    EventType.project_feedback_edited,
  ],
  project_notifications: [
    EventType.project_account_manager_assigned,
    EventType.project_finalized,
    EventType.project_moved_in_queue,
    EventType.project_deleted,
    EventType.project_submitted,
    EventType.asset_needs_changes,
    EventType.project_created,
    EventType.project_completed,
    EventType.project_approved,
    EventType.project_canceled,
    EventType.project_regressed_to_draft,
    EventType.project_partial_delivery,
    EventType.project_moved_to_queue,
    EventType.project_moved_to_under_revision,
    EventType.brief_updated,
    EventType.project_clarification_requested,
  ],
  organization_notifications: [
    EventType.master_org_created,
    EventType.master_org_edited,
  ],
  member_notifications: [
    EventType.user_added_to_org,
    EventType.user_removed_from_organization,
    EventType.user_removed_from_master_organization,
    EventType.user_added_to_master_organization,
  ],
};
