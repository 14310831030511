import { Button } from "@CreativelySquared/uikit";
import clsx from "clsx";
import {
  memo,
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { ReactComponent as SpeachIcon } from "images/speach.svg";
import { NoteForm, NoteType } from "components/Note";
import { BriefCommentMetadata } from "Brief/utils/types";
import { BriefContext } from "Brief/brief.context";
import { Brief } from "api/types";
import { isProjectEditable } from "Projects/utils/types";
import {
  useBriefCommentDetailsQuery,
  useBriefCommentsIdsQuery,
  useProfileQuery,
} from "api/graphql";
import { useClickOutside } from "utils/hooks/events";
import { isActiveSection } from "utils/comparison";

import styles from "./styles.module.scss";

type Props = {
  error?: {
    message?: string | boolean | Array<string>;
    className?: string;
  };
  comment?: BriefCommentMetadata & {
    briefId?: Brief["briefId"];
    className?: string;
  };
};

export const SectionPopup = memo<PropsWithChildren<Props>>(
  ({ error, comment, children }) => {
    const sectionRef = useRef<HTMLDivElement>(null);
    const [hover, setHover] = useState(false);
    const [activeComment, setActiveComment] = useState(false);
    const { activeSection, setActiveSection } = useContext(BriefContext);
    const { data: profile } = useProfileQuery();
    const noteRef = useRef<HTMLTextAreaElement>(null);
    const commentSectionRef = useRef<HTMLDivElement>(null);

    const { data: { getBrief: brief } = {} } = useBriefCommentDetailsQuery({
      variables: {
        briefId: comment?.briefId,
      },
      skip: !comment?.briefId || !profile?.me?.user?.userId,
    });

    const { data: { getBriefCommentsByBriefId: comments } = {} } =
      useBriefCommentsIdsQuery({
        variables: {
          briefId: brief?.briefId ?? "",
        },
        skip: !brief?.briefId,
      });

    const onClose = () => {
      setActiveComment(false);
      setHover(false);
    };

    const sectionId = comment?.sectionId;
    const commentClassName = comment?.className;
    const isSelected = isActiveSection(activeSection, comment);

    const commentMetadata = {
      sectionId,
      conceptId: comment?.conceptId ?? undefined,
    };
    const isReadOnly = !isProjectEditable(brief?.project?.status);
    const commentsCount =
      comments?.nodes?.reduce((acc, item) => {
        if (
          item?.extra?.sectionId === sectionId &&
          item?.extra?.conceptId === comment?.conceptId
        ) {
          const parentComment = 1;
          const replyLength = item?.replies?.length ?? 0;
          return acc + parentComment + replyLength;
        }
        return acc;
      }, 0) ?? 0;

    const errorMessage = error?.message;
    const errorClassName = error?.className;
    const errors = new Array<string | boolean | undefined>()
      .concat(errorMessage)
      .filter(Boolean);

    useEffect(() => {
      if (isSelected) {
        sectionRef.current?.scrollIntoView({
          behavior: "smooth",
        });
      }
    }, [isSelected]);

    useClickOutside(commentSectionRef, () => {
      if (!noteRef?.current?.value) {
        onClose();
      }
    });

    return (
      <div
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        className={styles.sectionPopup}
        ref={sectionRef}
      >
        {<>{children as any}</>}
        {errorMessage && (
          <div
            className={clsx(styles.sectionPopupError, errorClassName, {
              hidden: !errorMessage,
            })}
          >
            {errors?.map((error, index) => (
              <div key={index} className={styles.sectionPopupErrorMessage}>
                {error}
              </div>
            ))}
          </div>
        )}

        {!!brief?.briefId && (
          <div
            className={clsx(styles.sectionPopupComment, commentClassName, {
              hidden:
                ((!activeComment && (!sectionId || !hover) && !isSelected) ||
                  (isReadOnly && !isSelected)) &&
                !commentsCount,
            })}
          >
            <div>
              <Button
                type="button"
                className={clsx(styles.sectionPopupCommentButton, {
                  [styles.active]: isSelected,
                })}
                variant={Button.variants.Icon}
                onClick={() => {
                  if (!isReadOnly) {
                    setActiveComment((prev) => !prev);
                  }
                  setActiveSection?.(!!commentsCount ? commentMetadata : null);
                }}
              >
                <SpeachIcon />
                {!!commentsCount && (
                  <div className={styles.sectionPopupCommentCounter}>
                    {commentsCount}
                  </div>
                )}
              </Button>
              {activeComment && (
                <div ref={commentSectionRef}>
                  <NoteForm
                    className={styles.sectionPopupCommentForm}
                    textareaClassName={styles.textarea}
                    noteType={NoteType.BriefComment}
                    targetId={brief.briefId}
                    commentMetadata={commentMetadata}
                    onClose={onClose}
                    onSubmit={() => {
                      setActiveComment(false);
                      setActiveSection?.(commentMetadata);
                    }}
                    organizationId={brief.organizationId || ""}
                    projectId={brief.project?.projectId || ""}
                    ref={noteRef}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
);
