import clsx from "clsx";
import { FC } from "react";

interface Props {
  className?: string;
}

export const SectionDivider: FC<Props> = ({ className }) => (
  <div className={clsx("h-1 my-10 bg-light-blue-steel", className)} />
);
