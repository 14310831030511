import "./locales";
import { FC } from "react";
import { Switch } from "react-router";
import logo from "images/logo.svg";
import { Roles } from "utils/roles";
import { links } from "App";

import { Login } from "./Login";
import { ForgotPassword } from "./ForgotPassword";
import { ResetPassword } from "./ResetPassword";
import { ActivateAccount } from "./ActivateAccount";
import { AccountSetup } from "./AccountSetup";
import { Maintenance } from "./Maintenance";
import { Logout } from "./Logout";

import styles from "./styles.module.scss";

const VIDEO_URL = "https://di3a5omk3xpwd.cloudfront.net/background.mp4";

export const Authorization: FC = () => {
  return (
    <main className={styles.authorization}>
      <div className={styles.authorizationBackground}>
        <video autoPlay loop controls={false} muted src={VIDEO_URL} />
      </div>

      <section className={styles.authorizationContent}>
        <img className={styles.authorizationLogo} src={logo} />
        <Switch>
          <Login access={Roles.unauthorized} redirect={{ pathname: "/" }} />
          <ForgotPassword />
          <ResetPassword />
          <ActivateAccount exact />
          <AccountSetup exact />
          <Maintenance />
          <Logout
            access={Roles.authorized}
            redirect={{ pathname: links.Login() }}
          />
        </Switch>
      </section>
    </main>
  );
};
