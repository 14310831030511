import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ReactElement, useState } from "react";
import { TableCell, TableHead, TableRow } from "components";
import clsx from "clsx";

interface Props<D> {
  data: D[];
  columns: ColumnDef<D>[];
}

export const Table = <D extends { subRows?: D[] }>({
  data,
  columns,
}: Props<D>): ReactElement => {
  const [hoveredParentRowId, setHoveredParentRowId] = useState<string | null>(
    null
  );

  const table = useReactTable({
    data,
    columns,
    initialState: {
      expanded: true,
    },
    getCoreRowModel: getCoreRowModel(),
    getSubRows: (row) => row.subRows,
    getExpandedRowModel: getExpandedRowModel(),
  });

  return (
    <table className="w-full">
      <TableHead data={table.getHeaderGroups()} />
      <tbody>
        {table.getRowModel().rows.map((row) => {
          const parentRow = row.getParentRow();
          const isSingle = !parentRow && !row.subRows.length;

          const isLastChild =
            isSingle || parentRow?.subRows.length === row.index + 1;

          return (
            <TableRow
              key={row.id}
              className={clsx(!isLastChild && "border-b-0")}
              onMouseEnter={() => {
                const hoveredId =
                  parentRow?.id || (row.subRows.length ? row.id : null);
                setHoveredParentRowId(hoveredId || null);
              }}
              onMouseLeave={() => setHoveredParentRowId(null)}
              hover={
                hoveredParentRowId === parentRow?.id ||
                hoveredParentRowId === row.id
              }
            >
              {row.getVisibleCells().map((cell) => {
                const isNameColumn =
                  cell.getContext().cell.column.id === "name";

                return (
                  <TableCell
                    key={cell.id}
                    className={clsx({
                      "!py-6 !pb-0 max-w-[500px]": isNameColumn,
                      "!pb-6": !isNameColumn && !isLastChild,
                      "!pt-6": !!parentRow,
                    })}
                    style={{ width: cell.column.getSize() }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      </tbody>
    </table>
  );
};
