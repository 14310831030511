import React, { DetailedHTMLProps, memo, PropsWithChildren } from "react";
import clsx from "clsx";
import { ReactComponent as CheckedIcon } from "images/checkCircleFilled.svg";
import { ReactComponent as StarIcon } from "images/star.svg";

import styles from "./styles.module.scss";

type Props = {
  selected: boolean;
  starred?: boolean;
  onSelect?: () => void;
  onStar?: () => void;
  selectClassName?: string;
  starClassName?: string;
} & DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const ItemSelector = memo<PropsWithChildren<Props>>(
  ({
    selected,
    starred,
    onSelect,
    onStar,
    className,
    children,
    selectClassName,
    starClassName,
    ...props
  }) => {
    return (
      <div
        className={clsx(
          styles.itemSelector,
          {
            [styles.active]: selected,
            [styles.starred]: starred,
            [styles.noop]: !onSelect && !onStar,
          },
          className
        )}
        {...props}
      >
        {children}
        {!!onSelect && (
          <button
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              onSelect();
            }}
            type="button"
            className={clsx(styles.select, selectClassName)}
          >
            <CheckedIcon
              className={clsx({
                "fill-white/[0.6] stroke-blackberry": !selected,
              })}
            />
          </button>
        )}
        {!!onStar && (
          <button
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              onStar();
            }}
            type="button"
            className={clsx(styles.star, starClassName)}
          >
            <StarIcon
              className={clsx("stroke-white w-[28px] h-[28px]", {
                "fill-teal": starred,
                "fill-white": !starred,
              })}
            />
          </button>
        )}
      </div>
    );
  }
);
