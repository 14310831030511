import { FC } from "react";
import { Button, Dropdown, Loader } from "@CreativelySquared/uikit";
import {
  Empty,
  Filter,
  Pagination,
  Search,
  FilterType,
  FilterDataType,
} from "components";
import { useTranslation } from "react-i18next";
import { ReactComponent as SortIcon } from "images/sort.svg";
import { Link } from "react-router-dom";

import { Table } from "./Table";
import { useMembersColumns, useMembersData } from "./useMembersTable";
import { UserData } from "./types";

interface Props {
  title: string;
  toggleNewMemberModal: () => void;
  page: number;
  sortValue: string;
  sortOptions: Array<{ label: string; to: string }>;
  filterData: FilterDataType;
  filterOptions: FilterType[];
  onFilterChange: (data: { [key: string]: string[] }) => void;
  loading?: boolean;
  onSearch: (value: string) => void;
  columns: ReturnType<typeof useMembersColumns>;
  search?: string;
  users?: UserData[];
  totalPages?: number | null;
  pageQueryParam?: string;
  readOnly?: boolean;
}

export const Members: FC<Props> = ({
  title,
  toggleNewMemberModal,
  page,
  sortValue,
  sortOptions,
  filterData,
  filterOptions,
  loading,
  onFilterChange,
  onSearch,
  search,
  columns,
  users,
  totalPages,
  pageQueryParam,
  readOnly,
}) => {
  const { t } = useTranslation("organizations");

  const tableData = useMembersData(users || []);

  return (
    <section>
      <div className="flex justify-between items-center">
        <h2 className="text-2xl">{title}</h2>
        {!readOnly && (
          <Button onClick={toggleNewMemberModal}>{t("members.add")}</Button>
        )}
      </div>
      <div className="flex my-8">
        <Dropdown
          className="mr-7"
          icon={<SortIcon className="w-7 h-7 stroke-[1.5]" />}
          label={t("common:sort.label")}
          type={Dropdown.types.Light}
          value={sortValue}
        >
          {sortOptions.map(({ label, to }) => (
            <Dropdown.Item key={to} component={Link} to={to}>
              {label}
            </Dropdown.Item>
          ))}
        </Dropdown>
        <Filter
          value={filterData}
          options={filterOptions}
          onChange={onFilterChange}
          className="mr-7"
        />
        <Search
          placeholder={t("members.table.search.placeholder")}
          onSearch={onSearch}
          value={search}
        />
      </div>
      {loading ? (
        <div className="flex justify-center">
          <Loader radius={50} className="mt-6" />
        </div>
      ) : (
        <>
          <Table data={tableData} columns={columns} />
          {!tableData?.length && (
            <div className="flex justify-center flex-col items-center">
              <Empty title={t("members.table.empty")} />
              <Button onClick={toggleNewMemberModal}>{t("members.add")}</Button>
            </div>
          )}
          {users && !loading && (
            <Pagination
              className="mt-11"
              page={page}
              total={totalPages}
              pageQueryParam={pageQueryParam}
            />
          )}
        </>
      )}
    </section>
  );
};
