import {
  DetailedHTMLProps,
  HTMLAttributes,
  PropsWithChildren,
  memo,
  useEffect,
  useMemo,
  useState,
} from "react";
import clsx from "clsx";
import { AddMedia, MediaCard, Skeleton, useNotification } from "components";
import { UploadType, availableStatuses, useUpload } from "utils/hooks/upload";
import { BriefFormProps } from "Brief/utils/types";
import { fileFinishedUploadStatuses, FileStatuses } from "utils/types";
import { Button, Input, Textarea } from "@CreativelySquared/uikit";
import { useTranslation } from "react-i18next";
import { Object as ObjectType } from "ts-toolbelt";
import { Maybe } from "graphql/jsutils/Maybe";
import { AssetItemFragment, ConceptVisualGuide } from "api/types";
import { useDeleteConceptVisualGuideMutation } from "api/graphql";
import { ReactComponent as CrossIcon } from "images/close.svg";
import { get } from "lodash";
import { ReactComponent as OptionIcon } from "images/ellipsis.svg";
import { ReactComponent as PlaceholderIcon } from "images/image.svg";

import styles from "./styles.module.scss";

type Props = Partial<BriefFormProps<true>> &
  PropsWithChildren<
    DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>
  > & {
    value?: Maybe<ObjectType.Partial<ConceptVisualGuide, "deep">> | null;
    index?: {
      concept: number;
      visualGuide: number;
    };
  };

export const BriefVisualGuide = memo<Props>(
  ({ index, concept, value, className, ...props }) => {
    const { t } = useTranslation("brief");
    const {
      handleBlur,
      setFieldValue,
      handleChange,
      touched,
      errors: allErrors,
    } = props;
    const [preventAway, setPreventAway] = useState(false);
    const [isOptionsOpen, setIsOptionsOpen] = useState(false);
    const { setNotification } = useNotification();
    const readOnly = !handleBlur && !handleChange && !setFieldValue;

    const path = `concepts[${index?.concept}].visualGuides[${index?.visualGuide}]`;

    const [deleteVisualGuide] = useDeleteConceptVisualGuideMutation({
      optimisticResponse({ id }) {
        return {
          destroyConceptVisualGuide: {
            id,
            concept: {
              ...concept,
              visualGuides: concept?.visualGuides?.filter(
                (item) => item?.id !== id
              ),
            },
            __typename: "ConceptVisualGuide",
          } as any,
        };
      },
      onCompleted() {
        setNotification({
          message: t("brief:brief.actions.saved"),
        });
      },
    });

    const [upload, { setStatus, uploadStatus, uploading }] = useUpload({
      preventAway,
      onAttach: setFieldValue
        ? ([id]) => {
            return setFieldValue(`${path}.fileId`, id);
          }
        : undefined,
    });

    const onUpload = async (files: File) => {
      const [file] = await upload({
        files: [files],
        type: UploadType.concept_visual_guide,
        organizationId: concept?.brief?.organizationId,
      });
      const data = {
        ...value,
        status: FileStatuses.waiting_data,
        file: {
          ...file,
          status: FileStatuses.waiting_data,
          originalMetadata: {
            common: {
              type: file?.type,
              size: file?.size,
            },
          },
          thumbnail: {
            fileId: file?.fileId,
            formatId: "",
            downloadUrl: {
              url: file?.src,
            },
          },
        },
        fileId: file?.fileId ?? "",
      };

      setFieldValue?.(path, data, true);
    };

    const onRemove = () => {
      deleteVisualGuide({
        variables: {
          id: value?.id ?? "",
        },
      });
      setFieldValue?.(
        `concepts[${index?.concept}].visualGuides`,
        concept?.visualGuides?.filter(
          (visualGuide) => visualGuide?.id !== value?.id
        ),
        true
      );
    };

    const uploaded = useMemo(
      () =>
        concept?.visualGuides?.every((item) =>
          item?.file?.status
            ? availableStatuses.includes(item?.file?.status as FileStatuses)
            : true
        ),
      [concept?.visualGuides]
    );

    useEffect(() => {
      setPreventAway(!uploaded);
      if (uploaded) {
        setStatus({ interaption: null });
      }
    }, [uploaded]);

    const isFileUploaded = fileFinishedUploadStatuses.includes(
      value?.file?.status as FileStatuses
    );

    const errors =
      get(allErrors, path) ?? ({} as Record<string, string | undefined>);

    const touches = get(touched, path);

    const onAdd = (asset: AssetItemFragment) => {
      setFieldValue?.(
        path,
        {
          ...concept?.visualGuides?.[index!.visualGuide!],
          fileId: asset.latestVersion?.file?.fileId,
          file: {
            ...asset.latestVersion?.file,
            status: FileStatuses.waiting_data,
          },
        },
        true
      );

      setFieldValue?.(`${path}.assetId`, asset.assetId).then(() => {
        setFieldValue?.(`${path}.file.status`, FileStatuses.completed, true);
      });
    };

    useEffect(() => {
      const newStatus = uploadStatus.find(
        (uploadFile) => uploadFile?.fileId === value?.fileId
      );

      if (!newStatus) return;

      const data = {
        ...value,
        file: {
          ...value?.file,
          ...newStatus,
        },
      };

      setFieldValue?.(path, data, true);
    }, [uploadStatus]);

    return (
      <>
        <section className={clsx(className, styles.visualGuide)}>
          <Input
            name={`${path}.title`}
            value={value?.title ?? ""}
            onBlur={handleBlur}
            readOnly={readOnly}
            onChange={handleChange}
            variant={Input.variants.Secondary}
            placeholder={t("concept.form.visualGuide.card.title.placeholder")}
            className={clsx(styles.visualGuideFieldsName, {
              [styles.visualGuideFieldsError]: touches?.title && errors?.title,
            })}
          />
          {value?.file?.fileId ? (
            <div
              className={styles.visualGuideMedia}
              onMouseLeave={() => {
                if (isOptionsOpen) {
                  setIsOptionsOpen(false);
                }
              }}
            >
              <MediaCard
                className={styles.visualGuideMediaCard}
                size={value.file?.originalMetadata?.common?.size}
                type={value.file?.originalMetadata?.common?.type}
                fileId={value?.file?.fileId}
                status={value?.file?.status as FileStatuses}
                src={value?.file?.thumbnail?.downloadUrl?.url}
                key={value?.fileId}
              />
              {!readOnly && !!isFileUploaded && (
                <div className={styles.options}>
                  <Button
                    variant={Button.variants.Icon}
                    type="button"
                    className={styles.optionsIcon}
                    onClick={() => setIsOptionsOpen((prev) => !prev)}
                  >
                    <OptionIcon />
                  </Button>

                  <div
                    className={clsx(styles.optionsMenu, {
                      hidden: !isOptionsOpen,
                    })}
                  >
                    <AddMedia
                      onUpload={onUpload}
                      onAdd={onAdd}
                      organizationId={concept?.brief?.organizationId}
                      render={
                        <Button variant={Button.variants.Cancel} type="button">
                          {t("concept.form.visualGuide.card.options.replace")}
                        </Button>
                      }
                    />
                    {onRemove && (
                      <Button
                        variant={Button.variants.Cancel}
                        onClick={onRemove}
                      >
                        {t("concept.form.visualGuide.card.options.remove")}
                      </Button>
                    )}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className={styles.visualGuideUpload}>
              {readOnly ? (
                <div className={styles.visualGuideUploadPlaceholder}>
                  <PlaceholderIcon className="stroke-[0.6] w-11 h-11" />
                </div>
              ) : (
                <Skeleton
                  loading={uploading}
                  className={clsx(uploading && "pointer-events-none")}
                >
                  <AddMedia
                    onUpload={onUpload}
                    onAdd={onAdd}
                    organizationId={concept?.brief?.organizationId}
                    className="h-full"
                  />
                  <Button
                    type="button"
                    variant={Button.variants.Icon}
                    className={styles.remove}
                    onClick={onRemove}
                  >
                    <CrossIcon />
                  </Button>
                </Skeleton>
              )}
            </div>
          )}
          <fieldset className="mt-6">
            <label className="text-sm text-blue-steel font-bold">
              {t("concept.form.visualGuide.card.description.label")}
            </label>
            <Textarea
              value={value?.description ?? ""}
              name={`${path}.description`}
              onBlur={handleBlur}
              onChange={handleChange}
              readOnly={readOnly}
              rows={3}
              className={clsx(styles.visualGuideFieldsInfo, {
                [styles.visualGuideFieldsError]:
                  touches?.description && errors?.description,
              })}
              placeholder={t(
                "concept.form.visualGuide.card.description.placeholder"
              )}
            />
          </fieldset>

          <fieldset>
            <label className="text-sm text-blue-steel font-bold">
              {t("concept.form.visualGuide.card.message.label")}
            </label>
            <Textarea
              value={value?.message ?? ""}
              name={`${path}.message`}
              onBlur={handleBlur}
              onChange={handleChange}
              readOnly={readOnly}
              rows={3}
              className={clsx(styles.visualGuideFieldsInfo, {
                [styles.visualGuideFieldsError]:
                  touches?.message && errors?.message,
              })}
              placeholder={t(
                "concept.form.visualGuide.card.message.placeholder"
              )}
            />
          </fieldset>
        </section>
      </>
    );
  }
);
