import clsx from "clsx";
import { FC, memo, useEffect, useImperativeHandle } from "react";
import { DetailedHTMLProps, HTMLAttributes } from "react";
import type { Object } from "ts-toolbelt";
import { Asset, AssetItemFragment, File } from "api/types";
import { AssetStatus } from "api/enums";
import { Media } from "components/Media";
import { useHistory } from "react-router-dom";
import { PhotoAlbum, RenderPhotoProps } from "react-photo-album";
import { useTranslation } from "react-i18next";
import { Button, Dropdown, Label } from "@CreativelySquared/uikit";
import { useDownload } from "utils/hooks/download";
import { useUpdateAssetStatusMutation } from "api/graphql";
import { Services } from "utils/services";
import { AssetLabel } from "components/AssetLabel";
import { StatusLabel } from "components/StatusLabel";
import { ReactComponent as ActionsIcon } from "images/ellipsis.svg";
import { useNotification } from "components/Notifications";
import { ApolloError } from "@apollo/client";
import { Roles } from "utils/roles";
import { Protect } from "components/Protect";
import { ProjectStatuses } from "Projects/utils/types";
import { ItemSelector } from "components/ItemSelector";
import { AssetActions } from "components/AssetActions";
import { getFileMetadata } from "utils/file";
import { ReactComponent as CommentsIcon } from "images/speach.svg";
import { useAssetsSelect } from "DAM/components/AssetsView";

import styles from "./styles.module.scss";

type Props = {
  assets?: Array<Object.Partial<Asset, "deep"> | null> | null;
  actions?: boolean;
  projectId?: string;
  onSelect?: (assetId: Array<Asset["assetId"]>) => void;
  onOpen?: (id: string) => string;
  viewDetails?: boolean;
} & Omit<
  DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>,
  "onClick" | "onSelect"
>;

type AssetItempProps = {
  id?: string;
  src?: string;
  width?: number;
  height?: number;
  selected?: boolean | null | "";
  fileId?: string;
  type?: string | null;
  versions?: number;
  status?: AssetStatus;
  comments?: number;
  projectStatus?: ProjectStatuses;
  onOpen?: (id: string) => string;
  onClick?: () => void | undefined;
  details?: {
    title?: string;
    conceptName?: string;
    serviceId?: string;
  };
} & Object.Partial<File, "deep">;

const AssetItem: FC<RenderPhotoProps & { photo: AssetItempProps }> = ({
  imageProps,
  photo,
}) => {
  const { push } = useHistory();
  const { t } = useTranslation("common");
  const [download, { downloading }] = useDownload();
  const { setNotification, notificationTypes } = useNotification();
  if (!photo.id) return null;

  const details = photo.details;
  const [updateStatus, { loading: updateStatusLoading }] =
    useUpdateAssetStatusMutation();
  const onUpdateStatus = (status: AssetStatus) =>
    updateStatus({
      variables: {
        ids: [photo.id ?? ""],
        status,
      },
    }).catch((error: ApolloError) =>
      setNotification({
        message: error.message ?? t("common:common.error"),
        type: notificationTypes.Error,
      })
    );

  return (
    <div
      className={clsx(styles.asset, {
        [styles.assetSelected]: photo.selected,
        [styles.assetDetails]: details,
      })}
      onClick={() => {
        if (photo.onOpen) {
          push(photo.onOpen(photo.id!));
        }
      }}
    >
      <ItemSelector
        selected={!!photo.selected}
        onSelect={photo.onClick}
        className={styles.media}
      >
        <Media
          title={imageProps?.title ?? ""}
          url={imageProps?.src}
          alt={imageProps?.alt}
          type={photo.originalMetadata?.common?.type}
          fileId={photo.fileId}
        />
        {details && (
          <div className={styles.info}>
            <div className="flex items-center justify-between mb-5">
              {details.title && (
                <p className={styles.infoTitle}>{details.title}</p>
              )}
              <section onClick={(event) => event.stopPropagation()}>
                <Dropdown
                  render={() => (
                    <Button
                      variant={Button.variants.Icon}
                      type="button"
                      className={styles.infoActions}
                    >
                      <ActionsIcon className="w-7 h-7" />
                    </Button>
                  )}
                  popoverClassName={styles.infoActionsMenu}
                >
                  {photo.projectStatus !== ProjectStatuses.finalized && (
                    <>
                      <Protect access={Roles.cs_admins}>
                        {photo.status === AssetStatus.Delivered && (
                          <Dropdown.Item>
                            <Button
                              variant={Button.variants.Cancel}
                              onClick={() =>
                                onUpdateStatus(AssetStatus.NeedsReview)
                              }
                              loading={updateStatusLoading}
                            >
                              {t(
                                `asset.status.${AssetStatus.NeedsReview}.label`
                              )}
                            </Button>
                          </Dropdown.Item>
                        )}
                        {photo.status === AssetStatus.NeedsReview && (
                          <Dropdown.Item>
                            <Button
                              variant={Button.variants.Cancel}
                              onClick={() =>
                                onUpdateStatus(AssetStatus.Delivered)
                              }
                              loading={updateStatusLoading}
                            >
                              {t(`asset.status.${AssetStatus.Delivered}.label`)}
                            </Button>
                          </Dropdown.Item>
                        )}
                      </Protect>
                      <Protect access={Roles.customer}>
                        {photo.status === AssetStatus.NeedsReview && (
                          <Dropdown.Item>
                            <Button
                              variant={Button.variants.Cancel}
                              onClick={() =>
                                onUpdateStatus(AssetStatus.Accepted)
                              }
                              loading={updateStatusLoading}
                            >
                              {t("asset.accept.action")}
                            </Button>
                          </Dropdown.Item>
                        )}
                      </Protect>
                    </>
                  )}
                  <Dropdown.Item>
                    <Button
                      variant={Button.variants.Cancel}
                      loading={downloading}
                      onClick={(event) => {
                        event.stopPropagation();
                        download([photo]);
                      }}
                    >
                      {t("gallery.download")}
                    </Button>
                  </Dropdown.Item>
                </Dropdown>
              </section>
            </div>
            <div className="flex">
              {details.conceptName && (
                <Label size={Label.sizes.Small} className={styles.infoLabel}>
                  <span className="text-ellipsis overflow-hidden whitespace-nowrap">
                    {details.conceptName}
                  </span>
                </Label>
              )}
              {details.serviceId && (
                <AssetLabel
                  className={styles.infoLabel}
                  showLabel
                  count={1}
                  type={details.serviceId as Services}
                />
              )}
            </div>
          </div>
        )}
      </ItemSelector>
      <div
        className={clsx(styles.details, {
          visible: details,
        })}
      >
        {(photo.versions ?? 0) > 1 && (
          <span className={styles.detailsItem}>
            {t("gallery.version", { version: photo.versions })}
          </span>
        )}
        {!!photo.comments && (
          <span className={styles.detailsItem}>
            <CommentsIcon className="mr-2 w-5 h-5 stroke-blackberry stroke-2" />
            {photo.comments}
          </span>
        )}
        {photo.status && (
          <StatusLabel
            type="asset"
            status={photo.status}
            size={Label.sizes.Tiny}
          />
        )}
      </div>
    </div>
  );
};

export const GalleryGrid = memo<Props>(
  ({
    assets,
    actions,
    onSelect,
    onOpen,
    projectId = "",
    viewDetails,
    className,
    ...props
  }) => {
    const {
      selectedAssetIds,
      selectedAssetVersionIds,
      selectedAssetFiles,
      onSelectAllAssets,
      onSelectAsset,
      onClearSelection,
      hasAccountAccess,
      assetsViewRef,
    } = useAssetsSelect();

    const images = assets?.map((asset) => {
      const metadata = getFileMetadata(asset?.latestVersion?.file);

      return {
        ...asset?.latestVersion?.file,
        id: asset?.assetId ?? "",
        src: asset?.latestVersion?.file?.thumbnail?.downloadUrl?.url ?? "",
        width: metadata?.canvas?.width ?? 1,
        height: metadata?.canvas?.height ?? 1,
        selected: asset?.assetId && selectedAssetIds?.includes(asset.assetId),
        fileId: asset?.latestVersion?.file?.fileId,
        versions: asset?.versions?.length,
        comments: asset?.comments?.reduce((acc, comment) => {
          if (
            !comment?.deletedAt &&
            comment?.assetVersionId === asset.latestVersion?.assetVersionId
          ) {
            const replyLength =
              comment?.replies?.filter((reply) => !reply?.deletedAt)?.length ??
              0;
            const parentComment = 1;

            return acc + replyLength + parentComment;
          }
          return acc;
        }, 0),
        status: (asset?.status as AssetStatus) ?? undefined,
        projectStatus: asset?.project?.status as ProjectStatuses,
        onOpen,
        onClick:
          onSelect || actions
            ? () => onSelectAsset(asset as AssetItemFragment)
            : undefined,
        ...(viewDetails && {
          details: {
            title: asset?.latestVersion?.file?.fileName ?? "",
            conceptName: asset?.concept?.name ?? "",
            serviceId: asset?.mainOutcome?.serviceId,
          },
        }),
      };
    });

    useImperativeHandle(
      assetsViewRef,
      () => {
        return {
          getAllAssets() {
            return assets?.filter((asset) => !!asset) as AssetItemFragment[];
          },
        };
      },
      [assets]
    );

    useEffect(() => onSelect?.(selectedAssetIds), [selectedAssetIds]);

    return (
      <section
        className={clsx(
          styles.container,
          {
            [styles.notClickable]: !onOpen,
          },
          className
        )}
        {...props}
      >
        {!!images?.length && (
          <PhotoAlbum
            photos={images}
            layout="masonry"
            onClick={({ index, photo }) => console.log(index, photo)}
            renderPhoto={AssetItem}
            spacing={10}
          />
        )}

        {!!selectedAssetIds.length && actions && (
          <AssetActions
            projectId={projectId}
            assetsIds={selectedAssetIds}
            assetVersionIds={selectedAssetVersionIds}
            assetFiles={selectedAssetFiles}
            onClear={onClearSelection}
            onSelectAll={onSelectAllAssets}
            hasAccountAccess={hasAccountAccess}
          />
        )}
      </section>
    );
  }
);
