import { Alert, Button, Loader } from "@CreativelySquared/uikit";
import {
  ProjectDocument,
  useProjectQuery,
  useRemoveProjectCoverMutation,
  useSetProjectCoverMutation,
} from "api/graphql";
import { ProjectCoverImage, File as UploadedFile } from "api/types";
import { links } from "App";
import { UploadMedia, UploadProgress } from "components";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { compose, Route } from "react-router-hoc";
import {
  availableStatuses,
  InteraptionType,
  UploadType,
  useUpload,
} from "utils/hooks/upload";
import { Roles } from "utils/roles";
import { ProtectedRoute } from "utils/route";
import { FileStatuses, MediaFormats } from "utils/types";
import { mixed, object } from "yup";
import type { Object as ObjectType } from "ts-toolbelt";
import { ReactComponent as ArrowIcon } from "images/arrowRight.svg";

import styles from "./styles.module.scss";

export const ProjectGalleryCoverRoute = compose(
  ProtectedRoute({ access: Roles.cs_admins }),
  Route(
    {
      id: Route.params.string,
    },
    ({ id }) => `/projects/${id}/manage/cover`
  )
);

const formats = [
  MediaFormats.jpg,
  MediaFormats.png,
  MediaFormats.jpeg,
  MediaFormats.webp,
];

const validationSchema = object({
  media: mixed().required("gallery.manage.cover.errors.required"),
});

export const ProjectGalleryCover = ProjectGalleryCoverRoute(
  ({
    match: {
      params: { id },
    },
    history: { push },
  }) => {
    const { t } = useTranslation("projects");
    const { data: { getProject: projectData } = {}, loading } = useProjectQuery(
      {
        variables: {
          projectId: id,
          includeAssets: true,
        },
      }
    );
    const [preventAway, setPreventAway] = useState(false);
    const [setProjectCover, { error: setCoverError }] =
      useSetProjectCoverMutation();
    const [removeProjectCover] = useRemoveProjectCoverMutation({
      onCompleted() {
        setFieldValue("media", null);
        setStatus({ upload: [] });
      },
    });

    useEffect(() => {
      if (projectData?.coverImage) {
        setFieldValue("media", {
          ...projectData.coverImage,
          status: projectData.coverImage.file?.status,
        });
      }
    }, [projectData]);

    const {
      handleSubmit,
      setFieldValue,
      values: { media },
      errors,
      touched,
      isSubmitting,
      submitCount,
    } = useFormik<{
      media?: ObjectType.Partial<
        ProjectCoverImage & { status: FileStatuses },
        "deep"
      > | null;
    }>({
      initialValues: {
        media: null,
      },
      onSubmit: async ({ media }) => {
        if (uploaded && media?.fileId) {
          return push(links.ProjectGalleryComplete({ id }));
        } else {
          setStatus({ interaption: InteraptionType.submit });
        }
      },
      validationSchema,
      enableReinitialize: true,
    });

    const [
      upload,
      { uploadStatus, setStatus, interaptionStatus, error: uplaodError },
    ] = useUpload({
      preventAway,
      onAttach: () =>
        setProjectCover({
          variables: {
            projectId: id,
            projectCoverImageFields: {
              fileId: media?.fileId ?? "",
            },
          },
          refetchQueries: [
            {
              query: ProjectDocument,
              variables: {
                projectId: id,
                includeAssets: true,
              },
            },
          ],
          awaitRefetchQueries: true,
        }),
    });

    const uploaded = useMemo(
      () => availableStatuses.includes(media?.status as FileStatuses),
      [media]
    );

    useEffect(() => {
      setPreventAway(!uploaded);

      if (uploaded) {
        setStatus({ interaption: null });
      }
    }, [uploaded]);

    useEffect(() => {
      if (media) {
        setFieldValue("media", { ...media, status: uploadStatus[0]?.status });
      }
    }, [uploadStatus]);

    const error = uplaodError || setCoverError;

    const onUpload = async (file: File) => {
      if (!file) return;
      const uploaded = await upload({
        files: [file],
        type: UploadType.project_cover,
        organizationId: projectData?.organization?.organizationId,
      });

      if (uploaded?.length) {
        setFieldValue("media", uploaded[0]);
      }
    };

    const onRemove = async () => {
      if (projectData?.coverImage?.projectCoverImageId) {
        await removeProjectCover({
          variables: {
            projectCoverImageId: projectData?.coverImage?.projectCoverImageId,
          },
        });
      }
    };

    return (
      <form noValidate onSubmit={handleSubmit}>
        <div className="my-9 empty:my-11">
          {(error ||
            (errors.media && (touched.media || submitCount)) ||
            interaptionStatus) && (
            <Alert
              icon
              variant={
                error || errors.media
                  ? Alert.variants.Error
                  : Alert.variants.Info
              }
              className={styles.errorMessage}
              title={
                error?.message ||
                (errors.media && t(String(errors.media))) ||
                t(`common:uploadMedia.notification.${interaptionStatus}`)
              }
            />
          )}
        </div>
        {loading ? (
          <Loader radius={50} className="m-auto" />
        ) : (
          <section className="relative">
            <UploadMedia
              onUpload={onUpload}
              placeholder={projectData?.coverImage?.file as UploadedFile}
              className="mb-8"
              formats={formats}
              size={UploadMedia.sizes.Regular}
              type={UploadMedia.types.Cover}
              onReset={
                availableStatuses.includes(media?.status as FileStatuses)
                  ? onRemove
                  : undefined
              }
            />
            <div className="absolute bottom-6 p-6 w-full">
              {uploadStatus[0] && (
                <UploadProgress
                  status={
                    uploadStatus[0].status === FileStatuses.completed
                      ? undefined
                      : (uploadStatus[0].status as FileStatuses)
                  }
                />
              )}
            </div>
          </section>
        )}

        <div className="flex justify-end mt-8">
          <Button loading={isSubmitting} type="submit">
            {t("common:navigation.next")}
            <ArrowIcon className="ml-4 stroke-[1.5] w-7 h-7" />
          </Button>
        </div>
      </form>
    );
  }
);
