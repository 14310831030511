import { FC } from "react";
import { useTranslation } from "react-i18next";

export const ErrorFallback: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-center h-full w-full">
      <h2>{t("common.error")}</h2>
    </div>
  );
};
