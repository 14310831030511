import { Roles } from "utils/roles";
import { links } from "App";

import { useProtect } from "./protection";

export const useHomePageUrl = () => {
  const isAdmin = useProtect()(Roles.cs_admins);

  return isAdmin ? links.Dashboard() : links.CustomerDashboard();
};
