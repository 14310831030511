import { lazy } from "react";
import { compose, Route } from "react-router-hoc";
import { SortFields, SortOrder } from "utils/order";
import { idRegEx } from "utils/regex";
import { Roles } from "utils/roles";
import { ProtectedRoute } from "utils/route";
import { MemberRoles } from "Customers/components/Members/useMembersTable";

export { OrganizationMembers } from "./MembersTab";

export const OrganizationMembersTabRoute = compose(
  ProtectedRoute({ access: Roles.cs_admins }),
  Route(
    {
      id: Route.params.regex(idRegEx),
      page: Route.query.number,
      search: Route.query.string,
      sort: Route.query.oneOf(SortFields.fullName),
      sortOrder: Route.query.oneOf(...Object.values(SortOrder)),
      roles: Route.query.array(Route.query.oneOf(...MemberRoles)),
      accountIds: Route.query.array(Route.query.string),
    },
    ({ id }) => `/customers/organizations/${id}/members`
  )
);

export const OrganizationMembersTab = OrganizationMembersTabRoute(
  lazy(
    () =>
      import(/* webpackChunkName: "OrganizationMembersTab" */ "./MembersTab")
  )
);
