import { useTranslation } from "react-i18next";
import { compose, Route } from "react-router-hoc";
import { Helmet } from "react-helmet";
import { ProtectedRoute } from "utils/route";
import { Roles } from "utils/roles";

import { SetPasswordForm } from "../components/SetPasswordForm";
import { useLogin } from "../hooks/useLogin";

const ActivateAccountRoute = compose(
  ProtectedRoute({ access: Roles.unauthorized }),
  Route(
    {
      userId: Route.query.string,
      code: Route.query.string,
    },
    "/activate-account"
  )
);

export const ActivateAccount = ActivateAccountRoute(
  ({
    match: {
      query: { userId, code },
    },
  }) => {
    const { t } = useTranslation("authorization");
    const { login, loginLoading } = useLogin();

    return (
      <section>
        <Helmet>
          <title>{t("resetPassword.title")}</title>
        </Helmet>
        <SetPasswordForm
          userId={userId}
          code={code}
          onSubmit={(variables) => login({ variables })}
          title={t("resetPassword.title")}
          submitButton={t("resetPassword.submit")}
          loading={loginLoading}
        />
      </section>
    );
  }
);
