import { DetailedHTMLProps, HTMLAttributes, memo } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { Button } from "@CreativelySquared/uikit";
import { Formik } from "formik";
import { date, object, string } from "yup";
import { advancedErrorMessage } from "utils/form";
import { domainRegEx } from "utils/regex";
import {
  OrganizationType,
  SubscriptionPlanName,
  SubscriptionPlanStatus,
} from "api/enums";
import {
  useCreateAccountMutation,
  useGetMasterOrganizationsQuery,
} from "api/graphql";
import { useNotification } from "components";
import { formatShortUTC, tomorrow } from "utils/time";
import { validationSchema as planFormValidationSchema } from "Customers/components/PlanForm";

import { FormData } from "./types";
import { Details } from "./components/Details";
import { Management } from "./components/Management";

import styles from "./styles.module.scss";

type Props = {
  onClose: () => void;
  organizationId?: string;
  onCreate?: () => void;
} & DetailedHTMLProps<HTMLAttributes<HTMLFormElement>, HTMLFormElement>;

export const accountValidationSchema = object().shape({
  name: string()
    .required("common:validation.required")
    .max(254, advancedErrorMessage("common:validation.max")),
  email: string()
    .required("common:validation.required")
    .email("common:validation.email"),
  domain: string()
    .required("common:validation.required")
    .matches(domainRegEx, "create.details.domain.validation.format"),
  accountType: string().required().oneOf(Object.values(OrganizationType)),
  organization: object()
    .nullable()
    .when("accountType", {
      is: (type: OrganizationType) => type !== OrganizationType.Standalone,
      then: (schema) => schema.required("common:validation.required"),
    }),
});

export const CreateAccount = memo<Props>(
  ({ organizationId, onClose, onCreate, className, ...props }) => {
    const { t } = useTranslation("accounts");
    const { notificationTypes, setNotification } = useNotification();

    const [createAccount, { loading: isCreating }] = useCreateAccountMutation({
      onCompleted() {
        onCreate?.();
        onClose();
        setNotification({
          message: t("create.success"),
          type: notificationTypes.Success,
        });
      },
    });

    const { data: { organizations: organizationById } = {} } =
      useGetMasterOrganizationsQuery({
        variables: {
          filters: {
            masterOrganizationIds: [organizationId!],
          },
          includeSubscriptionPlan: true,
        },
        skip: !organizationId,
      });

    const validationSchema = object({
      name: string()
        .required("common:validation.required")
        .max(254, advancedErrorMessage("common:validation.max")),
      email: string()
        .required("common:validation.required")
        .email("common:validation.email"),
      domain: string()
        .required("common:validation.required")
        .matches(domainRegEx, "create.details.domain.validation.format"),
      accountType: string().required().oneOf(Object.values(OrganizationType)),
      organization: object()
        .nullable()
        .when("accountType", {
          is: (type: OrganizationType) => type !== OrganizationType.Standalone,
          then: (schema) => schema.required("common:validation.required"),
        }),
      planName: string()
        .nullable()
        .when("accountType", {
          is: (type: OrganizationType) => type !== OrganizationType.Integrated,
          then: (schema) =>
            schema
              .required("common:validation.required")
              .oneOf(Object.values(SubscriptionPlanName)),
        }),
      accountStatus: string()
        .nullable()
        .when("accountType", {
          is: (type: OrganizationType) => type !== OrganizationType.Integrated,
          then: (schema) =>
            schema
              .required("common:validation.required")
              .oneOf(Object.values(SubscriptionPlanStatus)),
        }),
      creditLimit: planFormValidationSchema.fields.creditLimit,
      renewalDate: date()
        .nullable()
        .when("accountType", {
          is: (type: OrganizationType) => type !== OrganizationType.Integrated,
          then: (schema) =>
            schema
              .min(tomorrow, t("common:validation.date.tomorrow"))
              .required("common:validation.required"),
        }),
    });

    return (
      <Formik<FormData>
        onSubmit={(data) =>
          createAccount({
            variables: {
              organizationFields: {
                name: data.name,
                adminEmail: data.email,
                cname: data.domain,
                type: data.accountType,
                ...(data.accountType !== OrganizationType.Standalone && {
                  masterOrganizationId:
                    organizationId || data.organization?.masterOrganizationId,
                }),
              },
              subscriptionPlanFields: {
                creditsLimit: Number(data.creditLimit),
                ...(data.accountType !== OrganizationType.Integrated && {
                  name: data.planName,
                  status: data.accountStatus,
                  expirationDate: data.renewalDate
                    ? formatShortUTC(data.renewalDate)
                    : undefined,
                }),
              },
            },
            onError: (error) => {
              setNotification({
                message: error.message ?? t("common:common.error"),
                type: notificationTypes.Error,
              });
            },
          })
        }
        validationSchema={validationSchema}
        initialValues={{
          name: "",
          email: "",
          domain: "",
          accountType: organizationId
            ? OrganizationType.Connected
            : OrganizationType.Standalone,
          organization: organizationById?.nodes?.[0] ?? undefined,
          renewalDate: null,
        }}
        enableReinitialize
      >
        {({ submitCount, isValid, handleSubmit }) => (
          <form
            noValidate
            onSubmit={handleSubmit}
            className={clsx(styles.createAccount, className)}
            {...props}
          >
            <h3 className="mb-10">{t("create.title")}</h3>
            <Details
              className={styles.section}
              defaultOrganizationId={organizationId}
            />
            <Management
              className={clsx(
                styles.section,
                "pt-10 mt-7 border-t-[1px] border-light-blue-steel"
              )}
            />

            <section className="flex justify-end mt-7">
              <Button
                variant={Button.variants.Cancel}
                type="button"
                className="mr-5"
                onClick={onClose}
                outlined
              >
                {t("common:actions.cancel")}
              </Button>
              <Button
                type="submit"
                loading={isCreating}
                disabled={(!!submitCount && !isValid) || isCreating}
              >
                {t("common:actions.save")}
              </Button>
            </section>
          </form>
        )}
      </Formik>
    );
  }
);
