import {
  useAccountSubscriptionPlanLazyQuery,
  useProfileQuery,
} from "api/graphql";
import chameleon from "@chamaeleonidae/chmln";
import { useEffect } from "react";
import { getFullName } from "utils/users";
import { addDays } from "date-fns";

export const useChameleon = () => {
  const { data: { me } = {} } = useProfileQuery({
    fetchPolicy: "cache-only",
  });
  const [getSubscriptionPlan] = useAccountSubscriptionPlanLazyQuery();

  const user = me?.user;

  useEffect(() => {
    if (user?.userId) {
      chameleon.init(
        "ShGHHxdRAETxPCMB31P8kdOhZEjekuoBF91n1snbZrPmAX-1R5UCu-ENEotzwXns1txR5z",
        { fastUrl: "https://fast.chameleon.io/" }
      );

      const organizationId = me?.organization?.organizationId;
      const userData = {
        email: user?.email,
        name: getFullName(user),
      };

      if (organizationId) {
        getSubscriptionPlan({
          variables: { organizationId },
          onCompleted: ({ getOrganizationSubscriptionPlan }) => {
            if (
              user?.userId &&
              getOrganizationSubscriptionPlan?.expirationDate
            ) {
              const expiration_date = new Date(
                +getOrganizationSubscriptionPlan.expirationDate
              ).toISOString();
              const deactivate_date = new Date(
                addDays(+getOrganizationSubscriptionPlan?.expirationDate, 30)
              ).toISOString(); // 30 days after expiration date
              const subscriptionStatus =
                getOrganizationSubscriptionPlan?.status;

              chameleon?.identify?.(user?.userId, {
                ...userData,
                expiration_date,
                deactivate_date,
                subscriptionStatus,
              });
            }
          },
        });
      } else {
        chameleon?.identify?.(user?.userId, userData);
      }
    }
  }, [user?.userId]);
};
