import { Dropdown, Input, Tooltip } from "@CreativelySquared/uikit";
import clsx from "clsx";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ratioPercentage } from "Assets/utils/ratio";
import { Metadata } from "api/types";
import { RegionType } from "components";
import { formatNumberValue } from "utils/form";
import { ReactComponent as CropIcon } from "images/crop.svg";

import styles from "./styles.module.scss";

const ratios = [
  {
    width: 1,
    height: 1,
  },
  {
    width: 4,
    height: 3,
  },
  {
    width: 16,
    height: 9,
  },
  {
    width: 3,
    height: 4,
  },
  {
    width: 9,
    height: 16,
  },
];

enum Dimension {
  Width = "width",
  Height = "height",
}

interface Props {
  metadata?: Metadata | null;
  buttonClassName?: string;
  value: { [key in Dimension]?: string };
  onChange: (value: { [key in Dimension]?: string }) => void;
  onSelect: (region: RegionType[]) => void;
  onCustom: () => void;
  onClear: () => void;
}

export const AssetCrop: FC<Props> = ({
  metadata,
  buttonClassName,
  value,
  onChange,
  onSelect,
  onCustom,
  onClear,
}) => {
  const { t } = useTranslation();
  // storing local value to display dimension errors
  const [initialValue, setInitialValue] =
    useState<{ [key in Dimension]?: string }>(value);
  useEffect(() => setInitialValue(value), [value]);

  return (
    <Dropdown
      render={({ visible }) => (
        <div>
          <Tooltip
            placement={Tooltip.placements.Top}
            title={t("common:asset.actions.crop.label")}
            visibile={visible ? false : undefined}
            variant={Tooltip.variants.Secondary}
          >
            <button className={buttonClassName} type="button">
              <CropIcon />
            </button>
          </Tooltip>
        </div>
      )}
    >
      {ratios.map(({ width, height }, key) => (
        <Dropdown.Item
          key={key}
          onSelect={() => {
            const ratio = ratioPercentage({ width, height }, metadata?.canvas);
            onSelect([
              {
                data: { index: 0 },
                height: ratio?.height ?? 100,
                width: ratio?.width ?? 100,
                isChanging: false,
                x: 0,
                y: 0,
                ratio: (ratio?.width ?? 100) / (ratio?.height ?? 100),
                metadata: `${width}x${height}`,
              },
            ]);
          }}
          className="flex items-center justify-start"
        >
          <div
            style={{
              aspectRatio: `${width}/${height}`,
            }}
            className="border border-blue-steel w-7 mr-6"
          />
          {width}:{height}
        </Dropdown.Item>
      ))}
      <Dropdown.Item onSelect={onCustom} className="font-medium">
        {t("common:asset.actions.crop.actions.custom")}
      </Dropdown.Item>
      <Dropdown.Item
        onClick={(event: React.MouseEvent<HTMLLIElement, MouseEvent>) =>
          event.preventDefault()
        }
        className={styles.assetCrop}
      >
        {Object.values(Dimension).map((dimension) => (
          <div
            className={clsx(styles.assetCropOption, {
              [styles.error]:
                +(initialValue?.[dimension] ?? 0) >
                (metadata?.canvas?.[dimension] ?? 0),
            })}
            key={dimension}
          >
            <p>{t(`common:asset.actions.crop.options.${dimension}`)}:</p>
            <Input
              className={styles.assetCropInput}
              value={initialValue[dimension]}
              variant={Input.variants.Secondary}
              onChange={({ target: { value: input } = {} }) => {
                const formattedValue = formatNumberValue(input);

                const result = {
                  ...value,
                  [dimension]: formattedValue,
                };

                if (
                  Number(formattedValue) <=
                  Number(metadata?.canvas?.[dimension])
                ) {
                  onChange(result);
                } else {
                  setInitialValue(result);
                }
              }}
            />
            <p>{t("common:asset.actions.crop.px")}</p>
          </div>
        ))}
      </Dropdown.Item>
      <Dropdown.Item onSelect={onClear}>
        {t("common:asset.actions.crop.actions.cancel")}
      </Dropdown.Item>
    </Dropdown>
  );
};
