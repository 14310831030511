import { Tooltip } from "@CreativelySquared/uikit";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useControls, useTransformEffect } from "react-zoom-pan-pinch";
import { ReactComponent as ZoomIn } from "images/zoomIn.svg";
import { ReactComponent as ZoomOut } from "images/zoomOut.svg";

interface Props {
  buttonClassName?: string;
  disabled?: boolean;
}

export const ZoomControls: FC<Props> = ({ buttonClassName, disabled }) => {
  const { zoomIn, zoomOut, instance } = useControls();
  const maxZoom = instance.setup.maxScale;
  const minZoom = instance.setup.minScale;

  const { t } = useTranslation();

  const [isZoomInDisabled, setZoomInDisabled] = useState(false);
  const [isZoomOutDisabled, setZoomOutDisabled] = useState(false);

  useTransformEffect(({ state }) => {
    setZoomInDisabled(state.scale >= maxZoom);
    setZoomOutDisabled(state.scale <= minZoom);
  });

  return (
    <>
      <Tooltip
        placement={Tooltip.placements.Top}
        title={t("common:asset.actions.zoomIn.label")}
        variant={Tooltip.variants.Secondary}
      >
        <button
          onClick={() => zoomIn()}
          aria-label="Zoom in"
          className={buttonClassName}
          disabled={isZoomInDisabled || disabled}
        >
          <ZoomIn className="w-8 h-8" />
        </button>
      </Tooltip>
      <Tooltip
        placement={Tooltip.placements.Top}
        title={t("common:asset.actions.zoomOut.label")}
        variant={Tooltip.variants.Secondary}
      >
        <button
          onClick={() => zoomOut()}
          aria-label="Zoom out"
          className={buttonClassName}
          disabled={isZoomOutDisabled || disabled}
        >
          <ZoomOut className="w-8 h-8" />
        </button>
      </Tooltip>
    </>
  );
};
