import { compose, Route } from "react-router-hoc";
import { Roles } from "utils/roles";
import { ProtectedRoute } from "utils/route";

import { SettingsOrganization as SettingsOrganizationComp } from "./SettingsOrganization";
import { OrganizationTabs } from "./types";

export const SettingsOrganizationRoute = compose(
  ProtectedRoute({
    access: [Roles.subsidiary_organization, Roles.master_org_owner],
  }),
  Route(
    {
      tab: Route.params.oneOf(...Object.values(OrganizationTabs)).optional,
    },
    ({ tab }) => `/settings/organization/${tab}`
  )
);

export const SettingsOrganization = SettingsOrganizationRoute(
  SettingsOrganizationComp
);
