import { lazy } from "react";
import { compose, Route } from "react-router-hoc";
import { Roles } from "utils/roles";
import { ProtectedRoute } from "utils/route";
import { idRegEx } from "utils/regex";
import { SavedItemsTabs } from "AITools/SavedItems/types";
import { tabToUrl } from "AITools/utils/url";

export const SavedItemsRoute = compose(
  ProtectedRoute({
    access: Roles.cs_admins,
  }),
  Route(
    {
      id: Route.params.regex(idRegEx),
      tab: Route.params.oneOf(...Object.values(SavedItemsTabs).map(tabToUrl))
        .optional,
    },
    ({ tab, id }) => `/customers/accounts/${id}/saved/${tab}`
  )
);

export const AccountSavedItems = SavedItemsRoute(
  lazy(() => import(/* webpackChunkName: "AccountSavedItems" */ "./SavedItems"))
);
