import clsx from "clsx";
import { FC, MouseEventHandler, ReactElement, ReactNode } from "react";

import styles from "./styles.module.scss";

export enum Size {
  Regular = "regular",
  Small = "small",
}

type Props = {
  readOnly?: boolean;
  fontSize?: Size;
  textClassName?: string;
  children: ReactNode;
  onClick?: MouseEventHandler<HTMLInputElement>;
  label?: string;
  className?: string;
  value?: string | number | readonly string[] | null;
};

export const ViewableComponent: FC<Props> = ({
  readOnly,
  onClick,
  label,
  fontSize = Size.Regular,
  className,
  textClassName,
  children,
  value,
}: Props): ReactElement => (
  <div className={clsx(styles.container, styles[fontSize], className)}>
    {readOnly ? (
      <p className={clsx(styles.title, textClassName)} onClick={onClick}>
        {label && !value?.toString().startsWith(label) && (
          <span className="font-bold">{label}: </span>
        )}
        {value}
      </p>
    ) : (
      <>
        {label && (
          <label className={clsx(styles.title, "mb-4 block")}>{label}</label>
        )}
        {children}
      </>
    )}
  </div>
);
