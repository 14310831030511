import { FC, useMemo } from "react";
import clsx from "clsx";
import { ViewableDropdownField, ViewableInputField } from "components";
import { formatNumberValue, parseError } from "utils/form";
import { formatDate, inAYear } from "utils/time";
import { ViewableDatePickerField } from "components/ViewableField";
import { Tooltip } from "@CreativelySquared/uikit";
import { ReactComponent as UpdateIcon } from "images/sync.svg";
import { useTranslation } from "react-i18next";
import { SubscriptionPlanName, SubscriptionPlanStatus } from "api/enums";
import { useFormikContext } from "formik";
import { getCreditsPlan } from "Customers/utils/credits";

export interface PlanFormData {
  planType?: SubscriptionPlanName;
  planExpirationDate: Date;
  planLimit?: number | null;
  planBalance?: number | null;
  planStatus: SubscriptionPlanStatus;
}

interface Props {
  readOnly?: boolean;
  isEdit?: boolean;
  isIntegrated?: boolean;
  isUpdateBalanceActive?: boolean;
  onUpdateCredits?: () => void;
  updateCreditsLoading?: boolean;
  className?: string;
  isCreate?: boolean;
}

export const PlanSectionForm: FC<Props> = ({
  isEdit,
  isIntegrated,
  readOnly,
  onUpdateCredits,
  updateCreditsLoading,
  className,
  isCreate,
}) => {
  const { t } = useTranslation("accounts");

  const {
    values: {
      planType,
      planLimit,
      planExpirationDate,
      planBalance,
      planStatus,
    },
    values,
    setFieldValue,
    setValues,
    setFieldError,
    errors,
  } = useFormikContext<PlanFormData>();

  const planCredits = useMemo(() => getCreditsPlan(planLimit), [planLimit]);

  return (
    <div
      className={clsx(
        "grid gap-4",
        isEdit || isIntegrated ? "grid-cols-1" : "grid-cols-2",
        className
      )}
    >
      {!isIntegrated && (
        <ViewableDropdownField
          label={t("plan.type.label")}
          placeholder={t("plan.type.placeholder")}
          name="planType"
          value={planType}
          readOnly={!isEdit}
          onSelect={(value) => {
            const newBalance = planCredits[value as SubscriptionPlanName];
            setValues({
              ...values,
              planType: value as SubscriptionPlanName,
              planLimit: newBalance,
              planBalance: newBalance,
            });
          }}
          options={
            Object.keys(planCredits).map((type) => ({
              key: type,
              value: `${t(`common:customers.plans.${type}`)}`,
            })) || []
          }
          error={
            errors.planType && t(...parseError(errors.planType)).toString()
          }
        />
      )}
      {!isEdit && !isIntegrated && (
        <ViewableInputField
          label={t("plan.expirationDate.label")}
          name="planExpirationDate"
          value={formatDate(planExpirationDate)}
          readOnly
        />
      )}
      {isEdit && !isIntegrated && (
        <ViewableDatePickerField
          label={t("plan.expirationDate.label")}
          placeholder={t("plan.expirationDate.placeholder")}
          name="planExpirationDate"
          value={planExpirationDate}
          readOnly={!isEdit}
          onSelect={(value) => {
            setFieldValue("planExpirationDate", value);
          }}
          error={
            errors.planExpirationDate &&
            t(...parseError(errors.planExpirationDate as string)).toString()
          }
          openToDate={inAYear}
        />
      )}
      <ViewableInputField
        label={t("plan.limit.label")}
        placeholder={t("plan.limit.placeholder")}
        name="planLimit"
        value={
          planLimit !== null && planLimit !== undefined ? String(planLimit) : ""
        }
        readOnly={!isEdit}
        disabled={
          planType !== SubscriptionPlanName.Custom &&
          !(isIntegrated && isCreate)
        }
        onChange={({ target: { value } = {} }) => {
          const newBalance = Number(
            formatNumberValue(value, { allowZero: true })
          );
          setValues({
            ...values,
            planBalance: newBalance,
            planLimit: newBalance,
          });
        }}
        error={
          errors.planLimit && t(...parseError(errors.planLimit)).toString()
        }
      />
      {!isEdit && !isCreate && (
        <ViewableInputField
          label={t("plan.balance.label")}
          name="planBalance"
          value={String(planBalance ?? 0)}
          readOnly
          postfix={
            readOnly || !onUpdateCredits ? undefined : (
              <Tooltip
                variant={Tooltip.variants.Secondary}
                title={t("plan.balance.update.tooltip")}
                placement={Tooltip.placements.Top}
                className="whitespace-nowrap"
              >
                <UpdateIcon
                  onClick={onUpdateCredits}
                  className={clsx("cursor-pointer w-7 h-7 stroke-[1.5]", {
                    "animate-spin pointer-events-none": updateCreditsLoading,
                  })}
                />
              </Tooltip>
            )
          }
        />
      )}
      {isEdit && !isCreate && (
        <ViewableInputField
          label={t("plan.balance.label")}
          name="planBalance"
          value={String(planBalance ?? 0)}
          onChange={({ target: { value } = {} }) => {
            const newBalance = Number(
              formatNumberValue(value, { allowZero: true })
            );
            const hasLimitError = newBalance > Number(planLimit);
            setFieldValue("planBalance", newBalance, !hasLimitError);

            if (hasLimitError) {
              setFieldError(
                "planBalance",
                t("common:validation.credits.max", { max: planLimit })
              );
            }
          }}
          error={
            errors.planBalance &&
            t(...parseError(errors.planBalance)).toString()
          }
        />
      )}
      {!isIntegrated && (
        <ViewableDropdownField
          label={t("plan.status.label")}
          placeholder={t("plan.status.placeholder")}
          name="planStatus"
          value={planStatus}
          readOnly={!isEdit}
          onSelect={(value) => {
            setFieldValue("planStatus", value);
          }}
          options={
            Object.values(SubscriptionPlanStatus).map((plan) => ({
              key: plan,
              value: `${t(`common:customers.status.${plan}`)}`,
            })) || []
          }
          error={
            errors.planStatus && t(...parseError(errors.planStatus)).toString()
          }
        />
      )}
    </div>
  );
};
