import { Button, Dropdown } from "@CreativelySquared/uikit";
import { useGetMasterOrganizationsQuery } from "api/graphql";
import clsx from "clsx";
import { ErrorMessage, OptionsList, Search } from "components";
import React, { FC, useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { usePagination } from "utils/hooks/pagination";
import { OrganizationItemNodeFragment } from "api/types";

import styles from "./styles.module.scss";

interface Props {
  organizationId?: string;
  onSelect?: (organization: OrganizationItemNodeFragment) => void;
  error?: string;
  disabled?: boolean;
  popoverClassName?: string;
  dropdownClassName?: string;
}

export const OrganizationSelector: FC<Props> = ({
  organizationId,
  onSelect,
  error,
  disabled,
  popoverClassName,
  dropdownClassName,
}) => {
  const { t } = useTranslation("accounts");
  const [searchValue, setSearchValue] = useState("");
  const pagination = usePagination({ limit: 10 });
  const dropdownRef = useRef<{ close: () => void }>(null);

  const {
    data: { organizations } = {},
    loading: organizationsLoading,
    previousData: previousOrganizations,
  } = useGetMasterOrganizationsQuery({
    variables: {
      pagination,
      filters: {
        name: searchValue,
      },
      includeSubscriptionPlan: true,
    },
    skip: disabled,
    fetchPolicy: "cache-and-network",
  });

  const findOrganizationById = useCallback(
    (id?: string) =>
      organizations?.nodes?.find((item) => item?.masterOrganizationId === id) ||
      previousOrganizations?.organizations?.nodes?.find(
        (item) => item?.masterOrganizationId === id
      ),
    [organizations, previousOrganizations]
  );

  const currentOrganization = findOrganizationById(organizationId);

  const {
    data: { organizations: organizationById } = {},
    loading: selectedOrganizationLoading,
  } = useGetMasterOrganizationsQuery({
    variables: {
      filters: {
        masterOrganizationIds: [organizationId!],
      },
      includeSubscriptionPlan: true,
    },
    skip: !organizationId || !!currentOrganization,
  });

  const currentOrganizationName = useMemo(
    () => organizationById?.nodes?.[0]?.name || currentOrganization?.name,
    [organizationById, currentOrganization]
  );

  const moreResults =
    organizations?.pageInfo?.totalItems && organizations?.nodes
      ? organizations.pageInfo.totalItems - organizations?.nodes?.length
      : 0;

  const options = useMemo(
    () =>
      organizations?.nodes?.map((organization) => ({
        id: organization?.masterOrganizationId ?? "",
        name: organization?.name ?? "",
      })) || [],
    [organizations?.nodes]
  );

  return (
    <div className="relative">
      <Dropdown
        renderValue={() => (
          <div
            className={clsx(
              "truncate",
              !currentOrganizationName && "text-light-blue-steel"
            )}
          >
            {!selectedOrganizationLoading &&
              (currentOrganizationName ||
                t("create.details.organization.placeholder"))}
          </div>
        )}
        containerRef={dropdownRef}
        className={clsx(
          "w-full h-[42px]",
          error && "border-red",
          dropdownClassName
        )}
        popoverClassName={clsx("w-full", popoverClassName)}
        loading={!currentOrganizationName && selectedOrganizationLoading}
        variant={Button.variants.Soft}
        disabled={disabled}
        placement={Dropdown.placements.Bottom}
        shifting={false}
      >
        <Search
          value={searchValue}
          onSearch={setSearchValue}
          autoFocus
          autoComplete="off"
          className={styles.search}
          placeholder={t("common:filter.search.placeholder")}
          onClick={(event) => event.stopPropagation()}
        />
        <OptionsList
          loading={organizationsLoading}
          options={options}
          moreResults={moreResults}
          renderOption={(option) => (
            <Dropdown.Item
              key={option.id}
              onSelect={() => {
                const selectedOrganization = findOrganizationById(option.id);
                if (selectedOrganization) {
                  onSelect?.(selectedOrganization);
                  dropdownRef.current?.close();
                }
              }}
              selected={option.id === organizationId}
            >
              {option.name}
            </Dropdown.Item>
          )}
          className={styles.options}
        />
      </Dropdown>
      {error && <ErrorMessage message={error} />}
    </div>
  );
};
