import { Modal } from "@CreativelySquared/uikit";
import { ModalTitle, useNotification } from "components";
import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  useAssignCreditsFromOrganizationMutation,
  useGetMasterOrganizationQuery,
} from "api/graphql";
import { ApolloError } from "@apollo/client";

import {
  AllocateCreditsForm,
  AllocateCreditsProps,
  AllocateFormData,
} from "../AllocateCreditsForm";

type Props = Pick<
  AllocateCreditsProps,
  "accounts" | "isTransfer" | "onClose"
> & {
  visible: boolean;
  organizationId: string;
};

export const AllocateCreditsModal: FC<Props> = ({
  visible,
  onClose,
  organizationId,
  ...props
}) => {
  const { t } = useTranslation("organizations");
  const { setNotification, notificationTypes } = useNotification();

  const { data: { organization } = {} } = useGetMasterOrganizationQuery({
    variables: { masterOrganizationId: organizationId },
    fetchPolicy: "cache-and-network",
  });

  const [onAssign] = useAssignCreditsFromOrganizationMutation();

  const onSubmit = useCallback(
    async (data: AllocateFormData) => {
      try {
        await onAssign({
          variables: {
            masterOrganizationId: organizationId,
            organizationId: data.toAccountId || "",
            amount: data.amount ? Number(data.amount) : 0,
          },
        });
        onClose();
      } catch (error) {
        const _error = error as ApolloError;

        setNotification({
          message: _error.message ?? t("common:common.error"),
          type: notificationTypes.Error,
        });
      }
    },
    [onAssign]
  );

  return (
    <Modal visible={visible} onClose={onClose} disableBodyScroll>
      <ModalTitle>
        {t("details.accounts.transferCredits.assignFromBalance")}
      </ModalTitle>
      <AllocateCreditsForm
        onClose={onClose}
        onSubmit={onSubmit}
        organizationCreditBalance={organization?.subscriptionPlan?.credits}
        {...props}
      />
    </Modal>
  );
};
