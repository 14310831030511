import { ReactComponent as activity } from "images/activity.svg";
import { ReactComponent as dashboard } from "images/dashboard.svg";
import { ReactComponent as users } from "images/user.svg";
import { ReactComponent as dam } from "images/assetLibrary.svg";
import { ReactComponent as customers } from "images/company.svg";
import { ReactComponent as projects } from "images/camera.svg";
import { ReactComponent as brands } from "images/brand.svg";
import { ReactComponent as aitools } from "images/thunderbolt.svg";

export const icons = {
  activity,
  dashboard,
  users,
  dam,
  customers,
  projects,
  brands,
  aitools,
};
