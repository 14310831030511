import { useTranslation } from "react-i18next";
import { Brief, Concept } from "api/types";
import { BriefCategory } from "api/enums";
import { DetailedHTMLProps, HTMLAttributes, memo } from "react";
import type { Object } from "ts-toolbelt";
import { Button, Label, Loader } from "@CreativelySquared/uikit";
import clsx from "clsx";
import { MediaCard, Protect, SectionPopup } from "components";
import { FileStatuses } from "utils/types";
import { Roles } from "utils/roles";
import { useDownload } from "utils/hooks/download";
import { ReactComponent as DownloadIcon } from "images/download.svg";
import { BriefVisualGuide } from "Brief/VisualGuide";
import { BriefSection } from "Brief/utils/types";

import { BriefAccess } from "../components/Access";

import styles from "./styles.module.scss";

type Props = {
  briefId: Brief["briefId"];
  concept: Object.Partial<Concept, "deep">;
} & DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;

export const SummaryConcept = memo<Props>(
  ({ briefId, concept, className, ...props }) => {
    const { t } = useTranslation("brief");
    const [download, { downloading, DownloadFormatType }] = useDownload();

    const {
      conceptId,
      name,
      type,
      description,
      coreVisualsNotes,
      originalAudioNotes,
      suppliedVisualsNotes,
      optimiseAudioNotes,
      contentDescription,
      locationsNote,
      presentationNotes,
      participantsNotes,
      actionNotes,
      featuredProducts,
      moodboard,
      outcomes,
      visualGuides,
    } = concept;

    const activeProducts = featuredProducts?.filter(
      (product) => product?.name || product?.description
    );

    const activeVisualGuides = visualGuides?.filter(
      (visualGuide) =>
        visualGuide?.title ||
        visualGuide?.message ||
        visualGuide?.description ||
        visualGuide?.fileId
    );

    return (
      <section className={className} {...props}>
        <SectionPopup
          comment={{
            briefId,
            conceptId,
            sectionId: BriefSection.conceptName,
          }}
        >
          <h3>{name ?? t(`summary.form.concept.unnamed`)}</h3>
        </SectionPopup>

        <SectionPopup
          comment={{
            briefId,
            conceptId,
            sectionId: BriefSection.conceptDescription,
          }}
        >
          {type && (
            <div className={styles.summaryFormField}>
              <label className={styles.summaryFormFieldTitle}>
                {t(`summary.form.type.title`)}
              </label>
              <Label variant={Label.variants.Neutral} size={Label.sizes.Small}>
                {t(`summary.form.type.options.${type}`)}
              </Label>
            </div>
          )}
        </SectionPopup>

        <SectionPopup
          comment={{
            briefId,
            conceptId,
            sectionId: BriefSection.conceptDetails,
          }}
        >
          {description && (
            <div className={styles.summaryFormField}>
              <label className={styles.summaryFormFieldTitle}>
                {t("summary.form.description")}
              </label>
              <p className={styles.summaryFormFieldDescription}>
                {description}
              </p>
            </div>
          )}

          {suppliedVisualsNotes && (
            <div className={styles.summaryFormField}>
              <label className={styles.summaryFormFieldTitle}>
                {t("summary.form.suppliedVisualsNotes")}
              </label>
              <p className={styles.summaryFormFieldDescription}>
                {suppliedVisualsNotes}
              </p>
            </div>
          )}

          {coreVisualsNotes && (
            <div className={styles.summaryFormField}>
              <label className={styles.summaryFormFieldTitle}>
                {t("summary.form.coreVisualsNotes")}
              </label>
              <p className={styles.summaryFormFieldDescription}>
                {coreVisualsNotes}
              </p>
            </div>
          )}

          {contentDescription && (
            <div className={styles.summaryFormField}>
              <label className={styles.summaryFormFieldTitle}>
                {t("summary.form.contentDescription")}
              </label>
              <p className={styles.summaryFormFieldDescription}>
                {contentDescription}
              </p>
            </div>
          )}

          {originalAudioNotes && (
            <div className={styles.summaryFormField}>
              <label className={styles.summaryFormFieldTitle}>
                {t("summary.form.originalAudioNotes")}
              </label>
              <p className={styles.summaryFormFieldDescription}>
                {originalAudioNotes}
              </p>
            </div>
          )}

          {locationsNote && (
            <div className={styles.summaryFormField}>
              <label className={styles.summaryFormFieldTitle}>
                {t("summary.form.locationsNote")}
              </label>
              <p className={styles.summaryFormFieldDescription}>
                {locationsNote}
              </p>
            </div>
          )}

          {presentationNotes && (
            <div className={styles.summaryFormField}>
              <label className={styles.summaryFormFieldTitle}>
                {t("summary.form.presentationNotes")}
              </label>
              <p className={styles.summaryFormFieldDescription}>
                {presentationNotes}
              </p>
            </div>
          )}

          {participantsNotes && (
            <div className={styles.summaryFormField}>
              <label className={styles.summaryFormFieldTitle}>
                {t("summary.form.participantsNotes")}
              </label>
              <p className={styles.summaryFormFieldDescription}>
                {participantsNotes}
              </p>
            </div>
          )}

          {actionNotes && (
            <div className={styles.summaryFormField}>
              <label className={styles.summaryFormFieldTitle}>
                {t("summary.form.actionNotes")}
              </label>
              <p className={styles.summaryFormFieldDescription}>
                {actionNotes}
              </p>
            </div>
          )}

          {optimiseAudioNotes && (
            <div className={styles.summaryFormField}>
              <label className={styles.summaryFormFieldTitle}>
                {t("summary.form.optimiseAudioNotes")}
              </label>
              <p className={styles.summaryFormFieldDescription}>
                {optimiseAudioNotes}
              </p>
            </div>
          )}
        </SectionPopup>

        {!!activeProducts?.length && (
          <SectionPopup
            comment={{
              briefId,
              conceptId,
              sectionId: BriefSection.conceptGuidelines,
            }}
          >
            <div className={styles.summaryFormField}>
              <label className={styles.summaryFormFieldTitle}>
                {t("summary.form.featuredProducts")}
              </label>
              {activeProducts.map((product, idx) => (
                <div
                  key={product?.id}
                  className={clsx({
                    "mt-7": idx !== 0,
                  })}
                >
                  {product?.name && (
                    <Label
                      variant={Label.variants.Neutral}
                      size={Label.sizes.Small}
                      className="mb-4"
                    >
                      {product.name}
                    </Label>
                  )}
                  {product?.description && (
                    <p className={styles.summaryFormFieldDescription}>
                      {product.description}
                    </p>
                  )}
                </div>
              ))}
            </div>
          </SectionPopup>
        )}

        {!!activeVisualGuides?.length && (
          <SectionPopup
            comment={{
              briefId,
              conceptId,
              sectionId: BriefSection.conceptVisualGuide,
            }}
          >
            <div className={styles.summaryFormField}>
              <div className="w-full flex items-center justify-between">
                <label className={clsx(styles.summaryFormFieldTitle, "mb-0")}>
                  {t("summary.form.visualGuides")}
                </label>
                <Protect access={Roles.cs_admins}>
                  <Button
                    type="button"
                    variant={Button.variants.Icon}
                    className="h-10 w-10"
                    onClick={() =>
                      download(
                        activeVisualGuides.flatMap((item) =>
                          item?.file?.fileId ? [item.file] : []
                        ),
                        {
                          name: t(t("summary.form.visualGuides")),
                          format: DownloadFormatType.original,
                        }
                      )
                    }
                    disabled={downloading}
                  >
                    {downloading ? <Loader /> : <DownloadIcon />}
                  </Button>
                </Protect>
              </div>
              <div className="grid grid-cols-4 gap-4 w-full">
                {activeVisualGuides.map((guide) => (
                  <BriefVisualGuide
                    key={guide?.id}
                    value={guide}
                    className="mt-6"
                  />
                ))}
              </div>
            </div>
          </SectionPopup>
        )}

        {!!moodboard?.length && (
          <SectionPopup
            comment={{
              briefId,
              conceptId,
              sectionId: BriefSection.conceptInspiration,
            }}
          >
            <div className={styles.summaryFormField}>
              <div className="w-full flex items-center justify-between">
                <label className={styles.summaryFormFieldTitle}>
                  {t("summary.form.moodboard")}
                </label>
                <Protect access={Roles.cs_admins}>
                  <Button
                    type="button"
                    variant={Button.variants.Icon}
                    className="h-10 w-10"
                    onClick={() =>
                      download(
                        moodboard.map((item) => item?.file),
                        {
                          name: t(t("summary.form.moodboard")),
                          format: DownloadFormatType.original,
                        }
                      )
                    }
                    disabled={downloading}
                  >
                    {downloading ? <Loader /> : <DownloadIcon />}
                  </Button>
                </Protect>
              </div>
              <div className="grid grid-cols-4 gap-4 w-full">
                {moodboard.map((item) => (
                  <MediaCard
                    key={item?.id}
                    fileId={item?.file?.fileId}
                    fileName={item?.file?.fileName}
                    src={item?.file?.thumbnail?.downloadUrl?.url}
                    size={item?.file?.originalMetadata?.common?.size}
                    type={item?.file?.originalMetadata?.common?.type}
                    caption={item?.caption ?? ""}
                    status={
                      (item?.file?.status === FileStatuses.processing_formats
                        ? FileStatuses.completed
                        : item?.file?.status) as FileStatuses
                    }
                  />
                ))}
              </div>
            </div>
          </SectionPopup>
        )}

        {!!outcomes?.length && (
          <SectionPopup
            comment={{
              briefId,
              conceptId,
              sectionId: BriefSection.conceptDeliverable,
            }}
          >
            <div className={styles.summaryFormField}>
              <label className={styles.summaryFormFieldTitle}>
                {t("summary.form.outcomes")}
              </label>
              {outcomes.map((outcome, idx) => (
                <div
                  key={outcome?.id}
                  className={clsx("grid gap-4 items-center grid-flow-col", {
                    "mt-4": idx !== 0,
                  })}
                >
                  {!!outcome?.amount && (
                    <Label
                      variant={Label.variants.Neutral}
                      size={Label.sizes.Small}
                    >
                      {outcome.amount}
                    </Label>
                  )}
                  {outcome?.service?.serviceId && (
                    <Label
                      variant={Label.variants.Neutral}
                      size={Label.sizes.Small}
                    >
                      {t(
                        `deliverables.outcomes.fields.type.options.${outcome.service.serviceId}`
                      )}
                    </Label>
                  )}
                  <BriefAccess
                    categories={[
                      BriefCategory.OriginalCreative,
                      BriefCategory.AdaptAndOptimise,
                    ]}
                  >
                    {outcome?.platform && (
                      <Label
                        variant={Label.variants.Neutral}
                        size={Label.sizes.Small}
                      >
                        {t(
                          `deliverables.outcomes.fields.platform.options.${outcome.platform}`
                        )}
                      </Label>
                    )}
                  </BriefAccess>
                  <BriefAccess
                    categories={[
                      BriefCategory.OriginalCreative,
                      BriefCategory.AdaptAndOptimise,
                    ]}
                  >
                    {outcome?.placement && (
                      <Label
                        variant={Label.variants.Neutral}
                        size={Label.sizes.Small}
                        className="text-ellipsis overflow-hidden whitespace-nowrap"
                      >
                        {outcome.placement}
                      </Label>
                    )}
                  </BriefAccess>
                  <BriefAccess
                    categories={[BriefCategory.ContentCreationCreative]}
                  >
                    {outcome?.detailsNote && (
                      <Label
                        variant={Label.variants.Neutral}
                        size={Label.sizes.Small}
                        className="text-ellipsis overflow-hidden whitespace-nowrap"
                      >
                        {outcome.detailsNote}
                      </Label>
                    )}
                  </BriefAccess>
                  <BriefAccess
                    categories={[
                      BriefCategory.OriginalCreative,
                      BriefCategory.AdaptAndOptimise,
                    ]}
                  >
                    {outcome?.ratio && (
                      <Label
                        variant={Label.variants.Neutral}
                        size={Label.sizes.Small}
                      >
                        {t(
                          `deliverables.outcomes.fields.ratio.options.${outcome.ratio}`
                        )}
                      </Label>
                    )}
                  </BriefAccess>
                  <BriefAccess
                    categories={[BriefCategory.ContentCreationCreative]}
                  >
                    {outcome?.orientation && (
                      <Label
                        variant={Label.variants.Neutral}
                        size={Label.sizes.Small}
                      >
                        {t(
                          `deliverables.outcomes.fields.orientation.options.${outcome.orientation}`
                        )}
                      </Label>
                    )}
                  </BriefAccess>
                </div>
              ))}
            </div>
          </SectionPopup>
        )}
      </section>
    );
  }
);
