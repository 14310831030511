import clsx from "clsx";
import { FC, HTMLAttributes } from "react";

export const InputLabel: FC<HTMLAttributes<HTMLLabelElement>> = ({
  className,
  ...props
}) => (
  <label
    {...props}
    className={clsx("text-l block font-bold mb-5 text-blackberry", className)}
  />
);
