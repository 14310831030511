import { compose, Route } from "react-router-hoc";
import { Roles } from "utils/roles";
import { ProtectedRoute } from "utils/route";

import CustomersPage from "./Customers";
import { links as accountsLinks } from "./Accounts";
import { links as organizationsLinks } from "./Organizations";

export const CustomersRoute = compose(
  ProtectedRoute({
    access: Roles.cs_admins,
  }),
  Route("/customers")
);

const Customers = CustomersRoute(CustomersPage);

export const route = Customers;

export const links = {
  ...accountsLinks,
  ...organizationsLinks,
  Customers,
};
