import { lazy } from "react";
import { Route } from "react-router-hoc";

import { AccountTabs } from "../types";

export const SettingsAccountDetailsRoute = Route(
  `/settings/account/${AccountTabs.Details}`
);

export const SettingsAccountDetails = SettingsAccountDetailsRoute(
  lazy(
    () => import(/* webpackChunkName: "SettingsAccountDetails" */ "./Details")
  )
);
