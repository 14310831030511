import { Button, Input, Loader } from "@CreativelySquared/uikit";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { parseError } from "utils/form";
import { object, ref, string } from "yup";
import { passwordValidationSchema } from "utils/validation";
import { ReactComponent as BackIcon } from "images/arrowLeft.svg";
import { Link } from "react-router-dom";
import { links } from "App";
import {
  useChangePasswordUsingCodeMutation,
  useValidatePasswordRecoveryCodeQuery,
} from "api/graphql";
import { useNotification } from "components";
import { FC } from "react";

import PasswordIcon from "../../images/PasswordIcon";

interface Props {
  code?: string;
  userId?: string;
  loading?: boolean;
  onSubmit: (data: { email: string; password: string }) => void;
  title?: string;
  submitButton?: string;
}

const validationSchema = object({
  password: string().withMutation((schema) => passwordValidationSchema(schema)),
  confirmPassword: string()
    .oneOf([ref("password")], "common:validation.passwordMatch")
    .required("common:validation.required"),
});

export const SetPasswordForm: FC<Props> = ({
  code,
  userId,
  onSubmit,
  loading,
  title,
  submitButton,
}) => {
  const { t } = useTranslation("authorization");
  const { setNotification, notificationTypes } = useNotification();

  const {
    data: { validatePasswordRecoveryCode: isCodeValid } = {},
    loading: validateCodeLoading,
  } = useValidatePasswordRecoveryCodeQuery({
    variables: {
      code: code!,
    },
    skip: !code || !userId,
  });

  const [changePasswordUsingCode, { loading: createPasswordLoading }] =
    useChangePasswordUsingCodeMutation({
      onError: (error) => {
        setNotification({
          message: error.message ?? t("common:common.error"),
          type: notificationTypes.Error,
        });
      },
      onCompleted: ({ changePasswordUsingCode }) => {
        if (changePasswordUsingCode?.email) {
          onSubmit({ email: changePasswordUsingCode.email, password });
        }
      },
    });

  const {
    values: { password, confirmPassword },
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useFormik<{ password: string; confirmPassword: string }>({
    validationSchema,
    initialValues: { password: "", confirmPassword: "" },
    onSubmit: (data) => {
      if (userId && code) {
        changePasswordUsingCode({
          variables: { userId, code, newPassword: data.password },
        });
      } else {
        setNotification({
          message: t("common:common.error"),
          type: notificationTypes.Error,
        });
      }
    },
  });

  if (validateCodeLoading) {
    return <Loader radius={50} className="m-auto" />;
  }

  if (!isCodeValid || !code || !userId) {
    return (
      <div className="w-[500px]">
        <p className="mb-10 text-l text-blue-steel">
          {t("setPasswordForm.invalidCode")}
        </p>
        <Link to={links.Login()} className="mt-8">
          <Button type="button" className="w-full">
            <BackIcon className="mr-4 w-7 h-7" />
            {t("setPasswordForm.back")}
          </Button>
        </Link>
      </div>
    );
  }

  return (
    <form noValidate onSubmit={handleSubmit}>
      <h2 className="mb-10">{title}</h2>
      <Input
        className="w-full mb-7"
        type="password"
        value={password}
        placeholder={t("setPasswordForm.fields.password.placeholder")}
        name="password"
        autoComplete="off"
        icon={<PasswordIcon unlocked={!!password} />}
        error={touched.password && t(...parseError(errors.password)).toString()}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <Input
        className="w-full"
        type="password"
        value={confirmPassword}
        placeholder={t("setPasswordForm.fields.confirmPassword.placeholder")}
        name="confirmPassword"
        autoComplete="off"
        icon={<PasswordIcon unlocked={!!confirmPassword} />}
        error={
          touched.confirmPassword &&
          t(...parseError(errors.confirmPassword)).toString()
        }
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <section className="mt-10">
        <Button
          className="w-full"
          loading={createPasswordLoading || loading}
          type="submit"
        >
          {submitButton}
        </Button>
        <Link to={links.Login()} className="block mt-4">
          <Button type="button" outlined borderless className="w-full">
            <BackIcon className="mr-4" />
            {t("setPasswordForm.back")}
          </Button>
        </Link>
      </section>
    </form>
  );
};
