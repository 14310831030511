import { Button, Input, Modal } from "@CreativelySquared/uikit";
import { FC, useMemo } from "react";
import {
  GetAccessRequestsDocument,
  useRejectAccessRequestMutation,
} from "api/graphql";
import { ApolloError } from "@apollo/client";
import { ModalDescription, ModalTitle, useNotification } from "components";
import { Trans, useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { object, string } from "yup";
import { getOperationName } from "@apollo/client/utilities";

interface Props {
  visible: boolean;
  onClose: () => void;
  requestId: string;
}

type FormData = { confirm: string };

export const DeclineForm: FC<Props> = ({ requestId, onClose }) => {
  const { t } = useTranslation("organizations");
  const { setNotification, notificationTypes } = useNotification();

  const [rejectAccess, { loading: isSubmitting }] =
    useRejectAccessRequestMutation({
      onCompleted() {
        setNotification({
          message: t("accessRequests.decline.success"),
          type: notificationTypes.Success,
        });
        onClose();
      },
      onError(error) {
        setNotification({
          message: (error as ApolloError).message ?? t("common:common.error"),
          type: notificationTypes.Error,
        });
      },
      variables: {
        requestId,
      },
      awaitRefetchQueries: true,
      refetchQueries: [getOperationName(GetAccessRequestsDocument)!],
    });

  const validationSchema = useMemo(
    () =>
      object({
        confirm: string()
          .required("validation.required")
          .lowercase()
          .oneOf([t("accessRequests.decline.validationWord")]),
      }),
    []
  );

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    isValid,
    values: { confirm },
    submitCount,
    errors,
  } = useFormik<FormData>({
    onSubmit() {
      rejectAccess();
    },
    validationSchema,
    initialValues: {
      confirm: "",
    },
  });

  return (
    <form noValidate onSubmit={handleSubmit}>
      <ModalTitle>{t("accessRequests.decline.title")}</ModalTitle>
      <ModalDescription>
        {
          <Trans
            t={t}
            i18nKey="accessRequests.decline.description"
            values={{
              name,
            }}
            components={{ br: <br /> }}
          />
        }
      </ModalDescription>
      <Input
        name="confirm"
        variant={Input.variants.Secondary}
        value={confirm.toUpperCase()}
        className="w-full"
        placeholder={t("accessRequests.decline.placeholder", {
          validationWord: t("accessRequests.decline.validationWord")
            .toString()
            .toUpperCase(),
        })}
        error={!!submitCount && !!errors.confirm}
        onBlur={handleBlur}
        onChange={handleChange}
      />

      <section className="flex justify-end mt-7">
        <Button
          variant={Button.variants.Cancel}
          type="button"
          className="mr-5"
          onClick={onClose}
          outlined
          borderless
        >
          {t("common:actions.cancel")}
        </Button>
        <Button
          loading={isSubmitting}
          type="submit"
          disabled={!isValid || isSubmitting}
        >
          {t("accessRequests.table.actions.decline")}
        </Button>
      </section>
    </form>
  );
};

export const DeclineModal: FC<Props> = (props) => (
  <Modal visible={props.visible} onClose={props.onClose} closable>
    <DeclineForm {...props} />
  </Modal>
);
