import { FC } from "react";
import {
  ViewableDropdownField,
  ViewableField,
  ViewableInputField,
} from "components";
import { parseError } from "utils/form";
import clsx from "clsx";
import { PlanSectionForm } from "Customers/components/PlanSection/PlanSectionForm";
import { Link } from "react-router-dom";
import { links } from "App";
import { useProtect } from "utils/hooks/protection";
import { Roles } from "utils/roles";
import { PlanFormData } from "Customers/components/PlanSection/PlanSectionForm";
import {
  AccountMasterOrganizationFragment,
  AccountQuery,
  OrganizationType,
} from "api/types";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { useUserNamesQuery } from "api/graphql";
import { Role, OrganizationType as OrganizationTypeEnum } from "api/enums";
import { getFullName } from "utils/users";
import { AccountOrganizationSelector } from "Customers/Accounts/components/AccountOrganizationSelector";

import {
  checkSwitchingToPartOfOrganization,
  hasAllocatedCredits,
} from "../utils";

export interface FormData extends Partial<PlanFormData> {
  name: string;
  createdDate: string;
  createdBy: string;
  domain: string;
  manager?: string;
  accountType: OrganizationType;
  organization?: AccountMasterOrganizationFragment;
}

interface Props {
  isEdit: boolean;
  account: AccountQuery["account"];
}

export const MainDataForm: FC<Props> = ({ isEdit, account }) => {
  const { t } = useTranslation("accounts");

  const isAccountUser = useProtect()(Roles.org_user);
  const isAdmin = useProtect()(Roles.cs_admins);

  const { data: { getUsers } = {}, loading: managersLoading } =
    useUserNamesQuery({
      variables: {
        filters: {
          role: [Role.CsAccountManager],
          isDeleted: false,
        },
      },
      skip: !isEdit,
    });

  const { values, handleChange, errors, setFieldValue } =
    useFormikContext<FormData>();

  const organizationId = values.organization?.masterOrganizationId ?? "";

  const isSwitchingToPartOfOrganization = checkSwitchingToPartOfOrganization(
    account?.type,
    values.accountType
  );
  const hasAllocatedField = hasAllocatedCredits(account, values);

  return (
    <div className={clsx("grid gap-4", isEdit ? "grid-cols-1" : "grid-cols-2")}>
      <ViewableInputField
        label={t("details.name.label")}
        placeholder={t("details.name.placeholder")}
        name="name"
        value={values.name}
        readOnly={!isEdit}
        onChange={handleChange}
        error={errors.name && t(...parseError(errors.name)).toString()}
      />
      <ViewableInputField
        label={t("details.domain.label")}
        placeholder={t("details.domain.placeholder")}
        name="domain"
        value={values.domain}
        readOnly={!isEdit}
        tooltip={isEdit && t("common:workspaceDomain.tooltip").toString()}
        onChange={handleChange}
        error={errors.domain && t(...parseError(errors.domain)).toString()}
      />
      {!isEdit && (
        <ViewableInputField
          label={t("details.createdDate.label")}
          name="createdDate"
          value={values.createdDate}
          readOnly
        />
      )}
      {!isEdit && !isAccountUser && (
        <ViewableInputField
          label={t("details.type.label")}
          name="accountType"
          value={`${t(`type.${values.accountType}`)}${
            organizationId ? "," : ""
          }`}
          readOnly
          postfix={
            organizationId && (
              <Link
                className="font-medium text-teal text-sm truncate"
                to={
                  isAdmin
                    ? links.OrganizationDetailsTab({
                        id: organizationId,
                      })
                    : links.SettingsOrganizationDetails()
                }
              >
                {values.organization?.name}
              </Link>
            )
          }
        />
      )}
      {!isEdit && (
        <ViewableInputField
          label={t("details.createdBy.label")}
          name="createdBy"
          value={values.createdBy}
          readOnly
        />
      )}
      <ViewableDropdownField
        label={t("details.manager.label")}
        placeholder={t("details.manager.placeholder")}
        name="manager"
        value={values.manager}
        readOnly={!isEdit}
        onSelect={(value) => setFieldValue("manager", value)}
        loading={managersLoading}
        options={
          getUsers?.nodes?.map((user, index) => ({
            key: user?.userId || `${index}`,
            value: getFullName(user),
          })) || [
            {
              key: account?.managedBy?.userId || "",
              value: getFullName(account?.managedBy),
            },
          ]
        }
        error={errors.manager && t(...parseError(errors.manager)).toString()}
      />
      {isEdit && (
        <ViewableDropdownField
          label={t("details.type.label")}
          placeholder={t("details.type.placeholder")}
          name="accountType"
          value={values.accountType}
          readOnly={!isEdit}
          onSelect={(value) => setFieldValue("accountType", value)}
          options={
            [
              OrganizationTypeEnum.Standalone,
              OrganizationTypeEnum.Connected,
              OrganizationTypeEnum.Integrated,
            ].map((type) => ({
              key: type,
              value: `${t(`type.${type}`)}`,
            })) || []
          }
          error={
            errors.accountType &&
            t(...parseError(errors.accountType)).toString()
          }
        />
      )}
      {isEdit && values.accountType !== OrganizationTypeEnum.Standalone && (
        <ViewableField
          label={t("details.organization.label")}
          placeholder={t("details.organization.placeholder")}
          error={
            errors.organization &&
            t(...parseError(errors.organization)).toString()
          }
          className="!items-start"
          labelClassName="mt-[10px]"
        >
          <AccountOrganizationSelector
            organizationId={organizationId}
            onSelect={(value) => setFieldValue("organization", value)}
            popoverClassName="!w-[466px] z-10"
            dropdownClassName="!w-[466px] !text-sm !text-blue-steel !font-medium"
            accountType={values.accountType as OrganizationTypeEnum}
            subscriptionPlan={values.organization?.subscriptionPlan}
            showAvailableCredits={hasAllocatedField}
          />
        </ViewableField>
      )}
      {isEdit && (isSwitchingToPartOfOrganization || hasAllocatedField) && (
        <PlanSectionForm
          isEdit
          isCreate
          isIntegrated={hasAllocatedField}
          className="mt-7"
        />
      )}
    </div>
  );
};
