import { lazy } from "react";
import { compose, Route } from "react-router-hoc";
import { idRegEx } from "utils/regex";
import { Roles } from "utils/roles";
import { ProtectedRoute } from "utils/route";

export { AccessRequests } from "./AccessRequestsTab";

export const OrganizationAccessRequestsTabRoute = compose(
  ProtectedRoute({ access: Roles.cs_admins }),
  Route(
    {
      id: Route.params.regex(idRegEx),
      page: Route.query.number,
    },
    ({ id }) => `/customers/organizations/${id}/access-requests`
  )
);

export const OrganizationAccessRequestsTab = OrganizationAccessRequestsTabRoute(
  lazy(
    () =>
      import(
        /* webpackChunkName: "OrganizationAccessRequestsTab" */ "./AccessRequestsTab"
      )
  )
);
