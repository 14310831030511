import "./locales";
import { lazy } from "react";
import { Switch } from "react-router";
import { compose, Route } from "react-router-hoc";
import { Roles } from "utils/roles";
import { SortOrder } from "utils/order";
import { ProtectedRoute } from "utils/route";
import { BriefCategory } from "api/enums";
import { BriefShareableField } from "Brief/utils/types";

import { ProjectBrief } from "./Brief";
import { ProjectNotes } from "./Notes";
import { ProjectAssets } from "./Assets";
import { ProjectComments } from "./Comments";
import { ProjectFeedback } from "./Feedback";
import { ProjectActivity } from "./Activity";
import { links as galleryLinks } from "./Gallery";
import { ProjectSort, ProjectStatuses } from "./utils/types";
import { ProjectsViewType } from "./components/ProjectsView";

export const ProjectsRoute = compose(
  ProtectedRoute({
    access: [Roles.cs_admins, Roles.customer],
  }),
  Route(
    {
      view: Route.query.oneOf(...Object.values(ProjectsViewType)),
      search: Route.query.string,
      page: Route.query.number,
      sort: Route.query.oneOf(...Object.values(ProjectSort)),
      sortOrder: Route.query.oneOf(...Object.values(SortOrder)),
      manager: Route.query.string,
      statuses: Route.query.array(
        Route.query.oneOf(...Object.values(ProjectStatuses))
      ),
      categories: Route.query.array(
        Route.query.oneOf(...Object.values(BriefCategory))
      ),
      brands: Route.query.array(Route.query.string),
      users: Route.query.array(Route.query.string),
      submittedAt: Route.query.array(Route.query.string),
      shareable: Route.query.oneOf(...Object.values(BriefShareableField)),
      shareableOn: Route.query.string,
    },
    "/projects"
  )
);

export const Projects = ProjectsRoute(
  lazy(() => import(/* webpackChunkName: "Projects" */ "./Projects"))
);

export const ProjectDetailsRoute = compose(
  ProtectedRoute({
    access: [Roles.cs_admins, Roles.customer],
  }),
  Route(
    {
      id: Route.params.string,
      mode: Route.query.oneOf("delete"),
    },
    ({ id }) => `/projects/${id}`
  )
);

export const ProjectDetails = ProjectDetailsRoute(
  lazy(
    () => import(/* webpackChunkName: "ProjectDetails" */ "./ProjectDetails")
  )
);

export const links = {
  Projects,
  ProjectAssets,
  ProjectBrief,
  ProjectNotes,
  ProjectComments,
  ProjectDetails,
  ProjectFeedback,
  ProjectActivity,
  ...galleryLinks,
};

export const route = Route(
  Object.values(links).map((route) => route.defaultProps?.path as string)
)(() => {
  return (
    <Switch>
      <Projects exact />
      <ProjectDetails />
    </Switch>
  );
});
