import {
  memo,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { ActionBar, useNotification } from "components";
import { Asset, AssetVersion, Collection, File, Project } from "api/types";
import {
  AssetDocument,
  ProjectDocument,
  useProjectAssetsZipLazyQuery,
  useSetAssetRatingMutation,
} from "api/graphql";
import { getOperationName } from "@apollo/client/utilities";
import { Loader } from "@CreativelySquared/uikit";
import { useProtect } from "utils/hooks/protection";
import { Roles } from "utils/roles";
import { AddToCollection } from "components";
import { ReactComponent as SpeachIcon } from "images/speach.svg";
import { useDownload } from "utils/hooks/download";
import { Object } from "ts-toolbelt";
import { ReactComponent as SelectIcon } from "images/selectAll.svg";
import { ReactComponent as RemoveIcon } from "images/bin.svg";
import { ReactComponent as CollectionIcon } from "images/collection.svg";
import { ReactComponent as DownloadIcon } from "images/download.svg";
import { ReactComponent as LikeIcon } from "images/thumbUp.svg";
import { PermissionActions, PermissionResources } from "api/enums";
import { useHasPermission } from "UserSettingsContext";

import { RemoveFromCollectionModal } from "./RemoveFromCollectionModal";
import { Comment } from "./Comment";

enum Ratings {
  like = 1,
  dislike = -1,
}

type Props = {
  assetsIds: Array<Asset["assetId"]>;
  assetVersionIds: Array<AssetVersion["assetVersionId"]>;
  assetFiles: Array<Object.Partial<File, "deep"> | undefined | null>;
  onClear: () => void;
  onSelectAll: () => void;
  projectId?: Project["projectId"];
  collectionId?: Collection["collectionId"];
  hasAccountAccess?: boolean;
};

export const AssetActions = memo<PropsWithChildren<Props>>(
  ({
    assetsIds,
    assetVersionIds,
    assetFiles,
    projectId,
    collectionId,
    onClear,
    onSelectAll,
    hasAccountAccess,
  }) => {
    const { t } = useTranslation("common");
    const [isRemoveFromCollectionVisible, setIsRemoveFromCollectionVisible] =
      useState(false);
    const [rated, setRated] = useState<Ratings | null>(null);
    const [ratingLoading, setRatingLoading] = useState<Ratings | null>(null);
    const { setNotification, notificationTypes } = useNotification();
    const isCustomer = useProtect()(Roles.customer);
    const canUpdateRating = useHasPermission({
      resource: PermissionResources.AssetRating,
      actions: PermissionActions.Update,
    });
    const [download, { downloading }] = useDownload();
    const [isAddToCollectionVisible, setAddToCollectionVisible] =
      useState<boolean>(false);
    const [isAddComments, setAddComments] = useState<boolean>(false);

    const closeCollectionModals = useCallback(
      () => setAddToCollectionVisible(false),
      []
    );

    const [setRating] = useSetAssetRatingMutation({
      refetchQueries: [
        getOperationName(AssetDocument)!,
        getOperationName(ProjectDocument)!,
      ],
      awaitRefetchQueries: true,
      onError(error) {
        setNotification({
          message: error.message ?? t("common.error"),
          type: notificationTypes.Error,
        });
      },
    });

    const onRate = (assetId: Asset["assetId"], vote: Ratings) =>
      assetId &&
      setRating({
        variables: {
          assetId,
          vote,
        },
      });

    const [assetsZip, { loading: assetsZipLoading }] =
      useProjectAssetsZipLazyQuery({
        fetchPolicy: "network-only",
      });

    const onSetRating = async (vote: Ratings) => {
      try {
        setRatingLoading(vote);

        await Promise.all(
          assetsIds.map((assetId) => onRate?.(assetId ?? "", vote))
        );
      } finally {
        setRated(vote);
        setRatingLoading(null);
      }
    };

    const onAssetsDownload = async () => {
      if (projectId) {
        const { data } = await assetsZip({
          variables: {
            projectId,
            assetsIds: assetsIds as string[],
          },
        });
        const fileUrl = data?.getProjectAssetsZip;

        if (fileUrl) {
          window.location.href = fileUrl;
        }
      } else {
        download(assetFiles, { name: t("gallery.zipName") });
      }
    };

    const onAddToCollectionFinish = useCallback(() => {
      closeCollectionModals();
      onClear();
    }, []);

    const toggleAddComments = useCallback(
      () => setAddComments((prev) => !prev),
      []
    );

    const actionData = [
      [
        ...(hasAccountAccess
          ? [
              {
                tooltip: t("gallery.comment"),
                icon: <SpeachIcon />,
                onClick: toggleAddComments,
              },
            ]
          : []),
        ...(hasAccountAccess && isCustomer && canUpdateRating
          ? [
              {
                tooltip: t("gallery.dislike", {
                  count: Number(rated === Ratings.dislike),
                }),
                icon:
                  ratingLoading === Ratings.dislike ? (
                    <Loader radius={24} />
                  ) : (
                    <LikeIcon className="scale-y-[-1]" />
                  ),
                onClick: () => onSetRating(Ratings.dislike),
              },
              {
                tooltip: t("gallery.like", {
                  count: Number(rated === Ratings.like),
                }),
                icon:
                  ratingLoading === Ratings.like ? (
                    <Loader radius={24} />
                  ) : (
                    <LikeIcon />
                  ),
                onClick: () => onSetRating(Ratings.like),
              },
            ]
          : []),
      ],
      [
        {
          tooltip: t("gallery.select"),
          icon: <SelectIcon />,
          onClick: onSelectAll,
        },
        {
          tooltip: t("gallery.download"),
          icon:
            assetsZipLoading || downloading ? (
              <Loader radius={24} />
            ) : (
              <DownloadIcon />
            ),
          onClick: onAssetsDownload,
        },
        ...(collectionId
          ? [
              {
                tooltip: t("gallery.removeAssets"),
                icon: <RemoveIcon />,
                onClick: () => setIsRemoveFromCollectionVisible(true),
              },
            ]
          : [
              {
                tooltip: t("gallery.collection"),
                icon: <CollectionIcon />,
                onClick: () => setAddToCollectionVisible(!!assetsIds.length),
              },
            ]),
      ],
    ];

    const toggleRemoveFromCollectionModal = () =>
      setIsRemoveFromCollectionVisible((prev) => !prev);

    const onCommentSubmit = useCallback(() => {
      toggleAddComments();
      onClear();
    }, []);

    useEffect(() => {
      if (rated) setTimeout(() => setRated(null), 3000);
    }, [rated]);

    return (
      <>
        <ActionBar
          data={actionData}
          count={assetsIds.length}
          onClose={onClear}
        />
        {isAddToCollectionVisible && (
          <AddToCollection
            visible
            onClose={closeCollectionModals}
            assetIds={assetsIds}
            onFinish={onAddToCollectionFinish}
          />
        )}
        {collectionId && (
          <RemoveFromCollectionModal
            visible={isRemoveFromCollectionVisible}
            onClose={toggleRemoveFromCollectionModal}
            collectionId={collectionId}
            assetsIds={assetsIds as string[]}
            onSubmit={onClear}
          />
        )}
        <Comment
          visible={isAddComments}
          onClose={toggleAddComments}
          onFinish={onCommentSubmit}
          assetIds={assetVersionIds as string[]}
          projectId={projectId}
        />
      </>
    );
  }
);
