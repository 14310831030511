import { Input } from "@CreativelySquared/uikit";
import clsx from "clsx";
import { ErrorMessage } from "components";
import { ReactElement } from "react";
import { GetProps } from "react-router-hoc/lib/types";

import { ViewableComponent, Size } from "./ViewableComponent";

import styles from "./styles.module.scss";

type Props = GetProps<typeof Input> & {
  readOnly?: boolean;
  fontSize?: Size;
  textClassName?: string;
};

export const ViewableInput = Object.assign(
  ({
    readOnly,
    onClick,
    label,
    fontSize,
    className,
    textClassName,
    error,
    ...props
  }: Props): ReactElement => (
    <ViewableComponent
      readOnly={readOnly}
      onClick={onClick}
      label={label}
      fontSize={fontSize}
      className={className}
      textClassName={textClassName}
      value={props.value}
    >
      <Input
        inputClassName={clsx(styles.title, "w-full", textClassName)}
        className={clsx("w-full")}
        variant={Input.variants.Secondary}
        error={error}
        {...props}
      />
      {error && typeof error === "string" && (
        <ErrorMessage message={error} className="mt-5 block" />
      )}
    </ViewableComponent>
  ),
  {
    size: Size,
  }
);
