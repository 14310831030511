import { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNotification } from "components";
import { Formik } from "formik";
import { MasterOrganization, OrganizationType } from "api/types";
import {
  useAccountQuery,
  useEditAccountMutation,
  useSetAccountManagerMutation,
} from "api/graphql";
import { formatDate, formatShortUTC } from "utils/time";
import { mixed, object } from "yup";
import { accountValidationSchema } from "Customers/Accounts/Create";
import { getFullName } from "utils/users";
import { ApolloError } from "@apollo/client";
import { OrganizationType as OrganizationTypeEnum } from "api/enums";
import { Loader, Modal } from "@CreativelySquared/uikit";
import { validationSchema as planFormValidationSchema } from "Customers/components/PlanForm";
import { SubscriptionErrorCodes } from "utils/subscriptions";
import { EditToggler } from "Customers/components/EditToggler";

import { AccountErrorCodes } from "../errors";

import { TypeConfirmationModal } from "./TypeConfirmationModal";
import { MainDataForm, FormData } from "./MainDataForm";
import {
  checkSwitchingToPartOfOrganization,
  hasAllocatedCredits,
} from "./utils";

interface Props {
  accountId: string;
  readOnly?: boolean;
}

export const MainData: FC<Props> = ({ accountId, readOnly = false }) => {
  const { t } = useTranslation("accounts");
  const [isEdit, setEdit] = useState(false);
  const { setNotification, notificationTypes } = useNotification();
  const [formData, setFormData] = useState<FormData>();

  const toggleEdit = useCallback(() => {
    setEdit((prev) => !prev);
  }, []);

  const onCloseTypeConfirmation = useCallback(() => {
    setFormData(undefined);
  }, []);

  const { data: { account } = {}, loading: accountLoading } = useAccountQuery({
    variables: { id: accountId },
    onError: (error) => {
      setNotification({
        message: error.message ?? t("common:common.error"),
        type: notificationTypes.Error,
      });
    },
  });

  const [editAccount, { loading: isAccountUpdating }] = useEditAccountMutation({
    onCompleted: () => {
      setNotification({
        message: t("details.edit.success"),
        type: notificationTypes.Success,
      });
    },
  });

  const [setAccountManager, { loading: isAccountManagerUpdating }] =
    useSetAccountManagerMutation();

  const onSubmit = useCallback(
    async (data: FormData) => {
      try {
        if (data.manager && data.manager !== account?.managedBy?.userId) {
          await setAccountManager({
            variables: {
              organizationId: accountId,
              userId: data.manager,
            },
            update: (cache, { data }) => {
              cache.modify({
                id: cache.identify({
                  __typename: "Organization",
                  id: accountId,
                }),
                fields: {
                  managedBy() {
                    return data?.setOrganizationAccountManager;
                  },
                },
              });
            },
          });
        }

        await editAccount({
          variables: {
            organizationId: accountId,
            organizationFields: {
              name: data.name,
              cname: data.domain,
              type: data.accountType,
              masterOrganizationId:
                data.accountType !== OrganizationTypeEnum.Standalone
                  ? data.organization?.masterOrganizationId
                  : undefined,
            },
            subscriptionPlanFields: checkSwitchingToPartOfOrganization(
              account?.type,
              data.accountType
            )
              ? {
                  name: data.planType,
                  expirationDate:
                    data.planExpirationDate &&
                    formatShortUTC(data.planExpirationDate),
                  custom: {
                    creditsLimit: data.planLimit ?? 0,
                  },
                  status: data.planStatus,
                }
              : hasAllocatedCredits(account, data)
              ? {
                  custom: {
                    creditsLimit: data.planLimit ?? 0,
                  },
                }
              : undefined,
          },
        });
        toggleEdit();
      } catch (error) {
        const _error = error as ApolloError;
        const errorCode = _error.graphQLErrors?.[0]?.extensions?.code;

        if (!isEdit) {
          toggleEdit();
        }

        if (
          errorCode === AccountErrorCodes.OrganizationHasActiveProjectsError
        ) {
          setNotification({
            message: t("details.edit.errors.hasActiveProjects"),
            type: notificationTypes.Error,
          });
        } else if (
          errorCode ===
          AccountErrorCodes.OrganizationUsersBelongToDifferentOrganizationsError
        ) {
          setNotification({
            message: t("details.edit.errors.hasActiveUsers"),
            type: notificationTypes.Error,
          });
        } else if (
          errorCode ===
          SubscriptionErrorCodes.SubscriptionPlanExpirationDateError
        ) {
          setNotification({
            message: t("plan.balance.errors.expirationDate"),
            type: notificationTypes.Error,
          });
        } else {
          setNotification({
            message: _error.message ?? t("common:common.error"),
            type: notificationTypes.Error,
          });
        }
      }
    },
    [accountId, account]
  );

  return (
    <section>
      <Formik<FormData>
        initialValues={{
          name: account?.name ?? "",
          createdDate:
            (account?.createdAt && formatDate(Number(account.createdAt))) || "",
          createdBy: getFullName(account?.createdBy),
          domain: account?.cname ?? "",
          accountType: account?.type ?? OrganizationTypeEnum.Standalone,
          organization: account?.masterOrganization ?? undefined,
          manager: account?.managedBy?.userId,
          planLimit: account?.subscriptionPlan?.creditsLimit,
        }}
        validationSchema={object({
          organization: object()
            .nullable()
            .when("accountType", {
              is: (type: OrganizationType) =>
                type !== OrganizationTypeEnum.Standalone,
              then: (schema) => schema.required("common:validation.required"),
            }),
          planType: mixed().when("accountType", {
            is: (newType: OrganizationType) =>
              checkSwitchingToPartOfOrganization(account?.type, newType),
            then: planFormValidationSchema.fields.planName,
          }),
          planStatus: mixed().when("accountType", {
            is: (newType: OrganizationType) =>
              checkSwitchingToPartOfOrganization(account?.type, newType),
            then: planFormValidationSchema.fields.accountStatus,
          }),
          planLimit: mixed().when(["accountType", "organization"], {
            is: (
              accountType: OrganizationType,
              organization: MasterOrganization
            ) => hasAllocatedCredits(account, { accountType, organization }),
            then: planFormValidationSchema.fields.creditLimit,
          }),
          planExpirationDate: mixed().when("accountType", {
            is: (newType: OrganizationType) =>
              checkSwitchingToPartOfOrganization(account?.type, newType),
            then: planFormValidationSchema.fields.renewalDate,
          }),
        }).concat(
          accountValidationSchema.pick(["name", "domain", "accountType"])
        )}
        onSubmit={(data) => {
          if (
            data.accountType !== account?.type ||
            data.organization?.masterOrganizationId !==
              account.masterOrganization?.masterOrganizationId
          ) {
            setFormData(data);
          } else {
            onSubmit(data);
          }
        }}
        enableReinitialize
      >
        {({ isValid, handleSubmit, resetForm }) => {
          return (
            <form noValidate onSubmit={handleSubmit}>
              <div className="flex justify-between items-center mb-7">
                <h2 className="text-2xl">{t("details.title")}</h2>
                {!readOnly && (
                  <EditToggler
                    isEdit={isEdit}
                    submitDisabled={!isValid}
                    onToggleEdit={toggleEdit}
                    onCancel={() => {
                      resetForm();
                      toggleEdit();
                    }}
                    loading={isAccountUpdating || isAccountManagerUpdating}
                  />
                )}
              </div>
              {accountLoading ? (
                <Loader radius={50} className="m-auto" />
              ) : (
                <MainDataForm isEdit={isEdit} account={account} />
              )}
            </form>
          );
        }}
      </Formik>
      <Modal visible={!!formData} onClose={onCloseTypeConfirmation}>
        <TypeConfirmationModal
          onClose={onCloseTypeConfirmation}
          onSubmit={async () => {
            try {
              await onSubmit(formData!);
              onCloseTypeConfirmation();
            } catch {}
          }}
          isSubmitting={isAccountUpdating || isAccountManagerUpdating}
        />
      </Modal>
    </section>
  );
};
