import "./locales";
import { lazy } from "react";
import { Route } from "react-router-hoc";

import { SettingsAccount } from "./Account";
import { SettingsOrganization } from "./Organization";
import { SettingsOrganizationDetails } from "./Organization/Details";
import { SettingsOrganizationAccounts } from "./Organization/Accounts";
import { SettingsOrganizationMembers } from "./Organization/Members";
import { SettingsOrganizationActivity } from "./Organization/Activity";
import { SettingsProfile } from "./Profile";
import { SettingsNotifications } from "./Notifications";
import { SettingsAccountDetails } from "./Account/Details";
import { SettingsAccountMembers } from "./Account/Members";

export const SettingsRoute = Route("/settings");

export const route = SettingsRoute(
  lazy(() => import(/* webpackChunkName: "Settings" */ "./Settings"))
);

export const links = {
  Settings: route,
  SettingsAccount,
  SettingsAccountDetails,
  SettingsAccountMembers,
  SettingsOrganization,
  SettingsOrganizationDetails,
  SettingsOrganizationAccounts,
  SettingsOrganizationMembers,
  SettingsOrganizationActivity,
  SettingsProfile,
  SettingsNotifications,
};
