import { Label } from "@CreativelySquared/uikit";
import { SubscriptionPlanStatus as SubscriptionPlanStatusType } from "api/types";
import { SubscriptionPlanStatus } from "api/enums";
import clsx from "clsx";
import { FC } from "react";
import { GetProps } from "react-router-hoc/lib/types";

export const subscriptionStatuses = [
  {
    value: SubscriptionPlanStatus.Pending,
    className: "bg-grey !text-blackberry",
  },
  {
    value: SubscriptionPlanStatus.Trial,
    className: "bg-blue-steel",
  },
  {
    value: SubscriptionPlanStatus.Active,
    className: "bg-green",
  },
  {
    value: SubscriptionPlanStatus.Suspended,
    className: "bg-orange",
  },
  {
    value: SubscriptionPlanStatus.Canceled,
    className: "bg-light-blue-steel",
  },
  {
    value: SubscriptionPlanStatus.Expired,
    className: "bg-red",
  },
  {
    value: SubscriptionPlanStatus.ViewOnly,
    className: "bg-mid-blue-steel",
  },
];

export const PlanStatusLabel: FC<
  GetProps<typeof Label> & { status?: SubscriptionPlanStatusType | null }
> = ({ status, className, ...labelProps }) => (
  <Label
    className={clsx(
      subscriptionStatuses.find(
        (subscriptionStatus) => subscriptionStatus.value === status
      )?.className,
      "text-white",
      className
    )}
    {...labelProps}
  />
);
