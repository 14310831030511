import { flexRender, HeaderGroup } from "@tanstack/react-table";
import { ReactElement } from "react";

interface Props<D> {
  data: HeaderGroup<D>[];
}

export const TableHead = <D,>({ data }: Props<D>): ReactElement => {
  return (
    <thead className="bg-grey">
      {data.map((headerGroup) => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map((header) => {
            return (
              <th
                key={header.id}
                colSpan={header.colSpan}
                style={{ maxWidth: `${header.column.columnDef.maxSize}px` }}
                className="first-of-type:rounded-tl-lg last-of-type:rounded-tr-lg py-6 px-7 text-left text-sm font-medium text-blue-steel"
              >
                {header.isPlaceholder ? null : (
                  <div>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </div>
                )}
              </th>
            );
          })}
        </tr>
      ))}
    </thead>
  );
};
