import { usePermissionsQuery, useProfileQuery } from "api/graphql";
import { Route } from "react-router-dom";
import { UserSettingsProvider } from "UserSettingsContext";
import { Roles } from "utils/roles";
import { ProtectedRoute } from "utils/route";

export const AuthorizedRoute = ProtectedRoute({ access: Roles.authorized })(
  (routeProps) => {
    // Preload queries
    useProfileQuery();
    usePermissionsQuery();

    return (
      <UserSettingsProvider>
        <Route {...routeProps} />
      </UserSettingsProvider>
    );
  }
);
