import { Suspense, useState } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { Switch, Route, Redirect, Router } from "react-router-dom";
import { getLinks } from "react-router-hoc";
import { createBrowserHistory } from "history";
import i18next from "locales/i18n";
import { NotFound } from "NotFound";
import { Dashboard } from "Dashboard";
import { CustomerDashboard } from "CustomerDashboard";
import { Activity } from "Activity";
import { links as authorization, route as Authorization } from "Authorization";
import { links as customers, route as Customers } from "Customers";
import { links as projects, route as Projects } from "Projects";
import { links as brands, route as Brands } from "Brands";
import { links as settings, route as Settings } from "Settings";
import { links as assets, route as Assets } from "Assets";
import { links as brief, route as Brief } from "Brief";
import { links as shared, route as Shared } from "Shared";
import { links as dam, route as DAM } from "DAM";
import { links as aiTools, route as AITools } from "AITools";
import { AuthContext } from "Authorization/authorization.context";
import { useAuthData } from "Authorization/authorization.hooks";
import { Loader } from "@CreativelySquared/uikit";
import { Sidebar } from "components/Sidebar";
import { Protect } from "components/Protect";
import { PreviewProvider, Preview } from "components/Preview";
import { Roles } from "utils/roles";
import { useIntercom } from "utils/hooks/intercom";
import { useChameleon } from "utils/hooks/initChameleon";
import {
  NotificationsContext,
  NotificationAction,
  Notifications,
  ErrorBoundary,
} from "components";
/** TODO: Remove this once it's possible */
import { LegacyRedirection } from "Shared/components/LegacyRedirection";
import { GrowthBookProvider } from "@growthbook/growthbook-react";
import { useSetupFeatures } from "api/features.client";
import Intercom from "@intercom/messenger-js-sdk";
import { AuthorizedRoute } from "AuthorizedRoute";

import styles from "./styles.module.scss";

export const history = createBrowserHistory();

export const links = getLinks({
  ...authorization,
  ...customers,
  ...projects,
  ...brands,
  ...settings,
  ...assets,
  ...shared,
  ...brief,
  ...dam,
  ...aiTools,
  Dashboard,
  CustomerDashboard,
  Activity,
  NotFound,
});

Intercom({
  app_id: process.env.REACT_APP_INTERCOM_APP_ID || "",
  hide_default_launcher: true,
});

function App() {
  const authValues = useAuthData();
  const { t } = useTranslation();
  const [notification, setNotification] = useState<
    NotificationAction | undefined
  >();
  const { client } = useSetupFeatures();
  useIntercom();
  useChameleon();

  return (
    <AuthContext.Provider value={authValues}>
      <GrowthBookProvider growthbook={client}>
        <PreviewProvider>
          <NotificationsContext.Provider
            value={{ notification, setNotification }}
          >
            <Helmet titleTemplate={`%s - ${t("common.title")}`}>
              <html lang={i18next.languages[0]} />
              <script>
                {`
              (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:3580723,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
              })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
            `}
              </script>
            </Helmet>
            <ErrorBoundary>
              <Suspense
                fallback={
                  <div className="flex h-full w-full items-center justify-center">
                    <Loader radius={50} />
                  </div>
                }
              >
                <Router history={history}>
                  <Switch>
                    <Authorization />
                    <Shared />
                    <Route exact path="/">
                      <Protect access={Roles.unauthorized}>
                        <Redirect to={links.Login()} />
                      </Protect>
                      <Protect suspense access={Roles.cs_admins}>
                        <Redirect to={links.Dashboard()} />
                      </Protect>
                      <Protect access={Roles.customer} suspense>
                        <Redirect to={links.CustomerDashboard()} />
                      </Protect>
                      <LegacyRedirection />
                    </Route>
                    <Assets />
                    <AuthorizedRoute>
                      <div className={styles.wrapper}>
                        <Sidebar />

                        <main className={styles.container}>
                          <Suspense
                            fallback={
                              <Loader radius={50} className={styles.loader} />
                            }
                          >
                            <Switch>
                              <Customers />
                              <Dashboard />
                              <CustomerDashboard />
                              <Projects exact />
                              <Brands exact />
                              <Settings />
                              <DAM />
                              <Activity />
                              <Brief />
                              <AITools />
                              <NotFound />
                              <Redirect to={links.NotFound()} />
                            </Switch>
                          </Suspense>
                        </main>
                      </div>
                    </AuthorizedRoute>

                    <NotFound />
                    <Redirect to={links.NotFound()} />
                  </Switch>
                </Router>
              </Suspense>
            </ErrorBoundary>
            <Notifications />
            <Preview />
          </NotificationsContext.Provider>
        </PreviewProvider>
      </GrowthBookProvider>
    </AuthContext.Provider>
  );
}

export default App;
