import { useState } from "react";
export const ACCESS_TOKEN_KEY = "accessToken";
export const ACCOUNT_ID_KEY = "accountId";

let isListenerActive = false;

export const useAuthData = () => {
  const [accessToken, setAccessToken] = useState<string | undefined | null>(
    sessionStorage.getItem(ACCESS_TOKEN_KEY) ??
      localStorage.getItem(ACCESS_TOKEN_KEY)
  );
  const [accountId, setAccountId] = useState<string | undefined | null>(
    localStorage.getItem(ACCOUNT_ID_KEY)
  );

  const setToken = (token?: string | null) => {
    setAccessToken(token);
    localStorage.setItem(ACCESS_TOKEN_KEY, token ?? "");
  };

  const setSessionToken = (token?: string | null) => {
    setAccessToken(token);
    sessionStorage.setItem(ACCESS_TOKEN_KEY, token ?? "");

    if (!isListenerActive) {
      window.addEventListener("beforeunload", () => {
        sessionStorage.removeItem(ACCESS_TOKEN_KEY);
        isListenerActive = false;
      });

      isListenerActive = true;
    }
  };

  const setActiveAccount = (accountId?: string | null) => {
    setAccountId(accountId);
    localStorage.setItem(ACCOUNT_ID_KEY, accountId ?? "");
  };

  return {
    accessToken,
    setAccessToken: setToken,
    setSessionToken,
    accountId,
    setAccountId: setActiveAccount,
  };
};
