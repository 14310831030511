import { AssetByIdFragment } from "api/types";
import { links } from "App";
import {
  BaseHTMLAttributes,
  ChangeEventHandler,
  DetailedHTMLProps,
  memo,
  useCallback,
  useMemo,
  useReducer,
} from "react";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { Textarea } from "@CreativelySquared/uikit";
import { useEditAssetDescriptionMutation } from "api/graphql";
import { debounce } from "lodash";
import { Protect, useNotification } from "components";
import { useTranslation } from "react-i18next";
import { Roles } from "utils/roles";
import { Features } from "api/features";
import { ReactComponent as AngleIcon } from "images/down.svg";
import clsx from "clsx";
import { ReactComponent as PhotoIcon } from "images/camera.svg";
import { ReactComponent as StartIcon } from "images/brand.svg";
import { ReactComponent as UserIcon } from "images/user.svg";

import { AssetTags } from "../Tags";
import { Tag } from "../Tag";

type Props = {
  asset: AssetByIdFragment;
} & DetailedHTMLProps<BaseHTMLAttributes<HTMLElement>, HTMLElement>;

export const AssetInfo = memo<Props>(({ asset, ...props }) => {
  const { setNotification, notificationTypes } = useNotification();
  const { t } = useTranslation("assets");
  const [showAITags, toggleAITags] = useReducer((state) => !state, false);
  const [editDescription] = useEditAssetDescriptionMutation({
    onCompleted: () => {
      setNotification({
        message: t("info.description.actions.saved"),
        type: notificationTypes.Success,
      });
    },
    onError: (error) => {
      setNotification({
        message: error.message ?? t("common:common.error"),
        type: notificationTypes.Error,
      });
    },
  });

  const onUpdate = useCallback<ChangeEventHandler<HTMLTextAreaElement>>(
    ({ nativeEvent: { target } }) => {
      if (!asset?.assetId) return;
      editDescription({
        variables: {
          assetId: asset.assetId!,
          description: (target as HTMLTextAreaElement)?.value,
        },
      });
    },
    [asset?.assetId, editDescription]
  );

  const debouncedUpdate = useCallback(debounce(onUpdate, 500), [onUpdate]);
  const AITags = useMemo(() => {
    return asset?.tags?.filter((tag) => tag?.isGenerated);
  }, [asset?.tags]);
  return (
    <section {...props}>
      <p className="font-bold text-sm text-blackberry">
        {t("info.description.title")}
      </p>
      <Textarea
        defaultValue={asset?.description || ""}
        key={asset?.assetId}
        rows={5}
        onChange={debouncedUpdate}
        className="mt-6 resize-none"
        inputClassName="h-[130px]"
        placeholder={t("info.description.placeholder")}
      />
      <ul className="mt-7 [&_a]:text-teal [&_a]:font-medium [&_a:hover]:underline [&>*]:mb-6">
        {asset?.project?.brief?.brandProfile?.brandProfileId && (
          <li className="flex gap-5 items-center overflow-hidden">
            <StartIcon className="shrink-0 stroke-mid-blue-steel w-7 h-7 stroke-[1.5]" />
            <Link
              to={links.BrandDetails({
                id: asset?.project?.brief?.brandProfile?.brandProfileId,
              })}
              className="text-ellipsis overflow-hidden text-nowrap"
            >
              {asset?.project?.brief?.brandProfile?.name}
            </Link>
          </li>
        )}
        {asset?.project?.projectId && (
          <li className="flex gap-5 items-center overflow-hidden">
            <PhotoIcon className="shrink-0 stroke-mid-blue-steel w-7 h-7 stroke-[1.5]" />
            <Protect access={Roles.cs_admins}>
              <Link
                to={links.ProjectBrief({
                  id: asset?.project?.projectId,
                })}
                className="text-ellipsis overflow-hidden text-nowrap"
              >
                {asset?.project?.title}
              </Link>
            </Protect>
            <Protect access={Roles.customer}>
              <Link
                to={links.BriefSummary({
                  id: asset?.project?.projectId,
                })}
                className="text-ellipsis overflow-hidden text-nowrap"
              >
                {asset?.project?.title}
              </Link>
            </Protect>
          </li>
        )}
        {asset?.project?.submittedBy && (
          <li className="flex gap-5 items-start">
            <UserIcon className="shrink-0 stroke-mid-blue-steel w-7 h-7 stroke-[1.5]" />
            <section className="overflow-hidden">
              <p className="leading-5 text-ellipsis text-nowrap overflow-hidden">
                {t("info.brief.submittedBy", {
                  user: asset?.project?.submittedBy.fullName,
                })}
              </p>
              {asset.project.submittedAt && (
                <span className="text-xs text-mid-blue-steel leading-3">
                  {format(new Date(+asset.project.submittedAt), "PPP")}
                </span>
              )}
            </section>
          </li>
        )}
        <Protect feature={Features.dam_asset_details_tags}>
          <section className="mt-10">
            <p className="font-bold text-sm text-blackberry">
              {t("info.tags.title")}
            </p>
            <AssetTags asset={asset} />
          </section>
          {!!AITags?.length && (
            <section className="mt-10">
              <section
                onClick={toggleAITags}
                className="flex justify-between items-center mb-5 cursor-pointer"
              >
                <p className="font-bold  text-sm text-blackberry">
                  {t("info.AITags.title")}
                </p>
                <AngleIcon
                  className={clsx([
                    "h-8 w-8 transition-all",
                    { ["rotate-180"]: showAITags },
                  ])}
                />
              </section>

              {showAITags &&
                AITags.map(
                  (tag) =>
                    tag && (
                      <Tag
                        key={tag.tagId}
                        assetId={asset.assetId || ""}
                        tagId={tag.tagId}
                        tags={asset.tags || []}
                        label={tag.name}
                      />
                    )
                )}
            </section>
          )}
        </Protect>
      </ul>
    </section>
  );
});
