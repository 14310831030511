import { lazy } from "react";
import { compose, Route } from "react-router-hoc";
import { idRegEx } from "utils/regex";
import { Roles } from "utils/roles";
import { ProtectedRoute } from "utils/route";

export { Accounts } from "./Accounts";

export const OrganizationDetailsTabRoute = compose(
  ProtectedRoute({ access: Roles.cs_admins }),
  Route(
    {
      id: Route.params.regex(idRegEx),
    },
    ({ id }) => `/customers/organizations/${id}/details`
  )
);

export const OrganizationDetailsTab = OrganizationDetailsTabRoute(
  lazy(
    () =>
      import(/* webpackChunkName: "OrganizationDetailsTab" */ "./DetailsTab")
  )
);
