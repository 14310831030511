import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Route } from "react-router-hoc";
import { Link } from "react-router-dom";
import { ReactComponent as ArrowIcon } from "images/arrowLeft.svg";
import { useHomePageUrl } from "utils/hooks/useHomePageUrl";

import styles from "./styles.module.scss";

export const NotFound = Route("/not-found")(() => {
  const { t } = useTranslation();
  const homePageUrl = useHomePageUrl();

  return (
    <main className={styles.container}>
      <Helmet>
        <title>{t("notFound.title")}</title>
      </Helmet>

      <h2>{t("notFound.title")}</h2>

      <p>
        <Link to={homePageUrl}>
          <ArrowIcon className="w-7 h-7 stroke-teal stroke-[1.5]" />
          {t(`notFound.link`)}
        </Link>
      </p>
    </main>
  );
});
