import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Tabs } from "@CreativelySquared/uikit";
import { FC } from "react";
import { GetRouteProps } from "utils/types";
import { NavLink, Redirect, Switch } from "react-router-dom";

import { AccountTabs } from "./types";
import { SettingsAccountDetails } from "./Details";
import { SettingsAccountMembers } from "./Members";

import { SettingsAccountRoute } from "./index";

type Props = GetRouteProps<typeof SettingsAccountRoute>;

export const SettingsAccount: FC<Props> = (routeProps) => {
  const { t } = useTranslation("settings");

  return (
    <section>
      <Helmet>
        <title>{t("account.title")}</title>
      </Helmet>
      <Tabs size={Tabs.sizes.Small} className="mb-10">
        <Tabs.Item
          to={routeProps.link({ tab: AccountTabs.Details })}
          component={NavLink}
        >
          {t(`account.tabs.details`)}
        </Tabs.Item>
        <Tabs.Item
          to={routeProps.link({ tab: AccountTabs.Members })}
          component={NavLink}
        >
          {t(`account.tabs.members`)}
        </Tabs.Item>
      </Tabs>
      <Switch>
        <SettingsAccountDetails exact />
        <SettingsAccountMembers exact />
        <Redirect to={routeProps.link({ tab: AccountTabs.Details })} />
      </Switch>
    </section>
  );
};
