import { ApolloError } from "@apollo/client";
import {
  useCreateFilesMutation,
  useGetFilesQuery,
  useProfileQuery,
} from "api/graphql";
import { uniqBy } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { FileStatuses } from "utils/types";
import { File as FileType } from "api/types";
import { getLocalFileType, getMaxFileSize } from "utils/file";
import { useNotification } from "components";
import { useTranslation } from "react-i18next";
import { formatBytes } from "utils/formatBytes";

export enum UploadType {
  brief_moodboard = "brief_moodboard",
  project_cover = "project_cover",
  asset = "asset",
  user_avatar = "user_avatar",
  brand_avatar = "brand_avatar",
  brand_guideline = "brand_guideline",
  brand_asset = "brand_asset",
  brand_reference = "brand_reference",
  concept_direction = "concept_direction",
  concept_moodboard = "concept_moodboard",
  concept_visual_guide = "concept_visual_guide",
  concept_mind_map = "concept_mind_map",
  comment_attachment = "comment_attachment",
  master_org_avatar = "master_org_avatar",
}

export enum InteraptionType {
  away = "away",
  submit = "submit",
}

export const availableStatuses = [FileStatuses.completed, FileStatuses.failed];

type Props = {
  preventAway?: boolean;
  completeStatus?: FileStatuses | FileStatuses[];
  onAttach?: (ids: string[]) => Promise<unknown>;
};

const pollingDelay = 2000;

type UploadStatus = {
  fileId: string;
  status?: FileStatuses | string;
} | null;

export function useUpload(options?: Props) {
  const history = useHistory();
  const { t } = useTranslation();
  const { data: { me } = {} } = useProfileQuery({
    fetchPolicy: "cache-only",
  });
  const [preventAway, setPreventAway] = useState(false);
  const [pollInterval, setPollInterval] = useState(0);
  const [uploadingCount, setUploadingCount] = useState(0);
  const { setNotification, notificationTypes } = useNotification();
  const [createFiles, { data, error }] = useCreateFilesMutation({
    onCompleted: (data) => {
      setUploadStatus((prev) =>
        uniqBy(
          [
            ...(data.createFiles?.map((file) => ({
              fileId: file!.fileId,
              status: FileStatuses.waiting_data,
            })) ?? []),
            ...prev,
          ],
          "fileId"
        )
      );
    },
  });
  const [interaptionStatus, setInteraptionStatus] =
    useState<InteraptionType | null>(null);
  const [uploadStatus, setUploadStatus] = useState<UploadStatus[]>([]);
  const filesIds = [
    ...new Set([
      ...(data?.createFiles?.map((file) => file?.fileId) ?? []),
      ...uploadStatus.map((file) => file?.fileId),
    ]),
  ] as string[];
  const { data: { getFiles } = {}, stopPolling } = useGetFilesQuery({
    variables: {
      filesIds,
    },
    onCompleted: (data) => {
      const resultIds = data.getFiles?.map((file) => file?.fileId);
      const prevFailedFiles = uploadStatus.flatMap((file) =>
        file?.status === FileStatuses.failed ? [file.fileId] : []
      );
      const failed = filesIds.flatMap((fileId) =>
        !resultIds?.includes(fileId) || prevFailedFiles.includes(fileId)
          ? [fileId]
          : []
      );

      const updatedFiles =
        data?.getFiles?.map((file) =>
          failed.includes(file?.fileId ?? "")
            ? { fileId: file?.fileId ?? "", status: FileStatuses.failed }
            : file
        ) ?? [];

      setUploadStatus((prev) => uniqBy([...updatedFiles, ...prev], "fileId"));

      const isComplete = data.getFiles?.every((file) =>
        [FileStatuses.failed]
          .concat(
            options?.completeStatus ??
              (options?.onAttach
                ? availableStatuses
                : [...availableStatuses, FileStatuses.ready_to_attach])
          )
          .includes(file?.status as FileStatuses)
      );

      if (isComplete) {
        setPollInterval(0);
        stopPolling();
      }
    },
    fetchPolicy: "network-only",
    pollInterval,
    notifyOnNetworkStatusChange: true,
    skip: !pollInterval,
  });

  const setStatus = (options: {
    upload?: React.SetStateAction<UploadStatus[]>;
    interaption?: React.SetStateAction<InteraptionType | null>;
  }) => {
    options.upload && setUploadStatus(options.upload);
    options?.interaption !== undefined &&
      setInteraptionStatus(options.interaption);
  };

  useEffect(() => {
    if (uploadStatus.length && (options?.preventAway ?? preventAway)) {
      const blockAway = history.block(() => {
        setInteraptionStatus(InteraptionType.away);
        return false;
      });

      const awayAlert = (e: Event) => {
        e.preventDefault();
        e.returnValue = true;
      };

      window.addEventListener("beforeunload", awayAlert);
      return () => {
        window.removeEventListener("beforeunload", awayAlert);
        blockAway();
      };
    }
  }, [uploadStatus.length, options?.preventAway ?? preventAway]);

  useEffect(() => {
    if (!options?.onAttach) return;

    const readyToAttach = uploadStatus.flatMap((file) =>
      file?.status === FileStatuses.ready_to_attach ? [file.fileId] : []
    );

    const allFilesReady = uploadStatus.every((file) =>
      [...availableStatuses, FileStatuses.ready_to_attach].includes(
        file?.status as FileStatuses
      )
    );

    if (readyToAttach.length && allFilesReady) {
      setUploadStatus((prev) =>
        prev.flatMap((file) =>
          file
            ? readyToAttach.includes(file.fileId)
              ? [
                  {
                    ...file,
                    status: FileStatuses.processing_formats,
                  },
                ]
              : [file]
            : []
        )
      );
      options.onAttach(readyToAttach).catch((error: ApolloError) => {
        const failed = (
          error?.graphQLErrors?.[0]?.extensions?.failedFiles as FileType[]
        )?.map((file) => file.fileId);

        setUploadStatus((prev) =>
          prev.map((file) =>
            failed?.includes(file?.fileId ?? "")
              ? {
                  ...file,
                  fileId: file?.fileId ?? "",
                  status: FileStatuses.failed,
                }
              : file
          )
        );
        setNotification({
          message: error.message ?? t("common:common.error"),
          type: notificationTypes.Error,
        });
        setPollInterval(0);
      });
    }
  }, [uploadStatus, options?.onAttach]);

  const upload = async ({
    files,
    type,
    organizationId: _organizationId,
    wait = false,
  }: {
    files: File[];
    type: UploadType;
    organizationId?: string | null;
    wait?: boolean;
  }) => {
    const organizationId = me?.organization?.organizationId || _organizationId;

    const filesData = files.filter((file) => {
      const maxSize = getMaxFileSize(type, file?.type);
      const fileType = getLocalFileType(file?.type);

      if (!maxSize) {
        setNotification({
          message: t("upload.errors.type", { type: fileType }),
          type: notificationTypes.Error,
        });
        return false;
      }

      if (file.size > maxSize) {
        const formattedSize = formatBytes(maxSize);
        setNotification({
          message: t("upload.errors.size", {
            type: fileType,
            size: formattedSize,
          }),
          type: notificationTypes.Error,
        });
        return false;
      }
      return true;
    });

    setUploadingCount((prev) => prev + filesData.length);
    const URLs = filesData.length
      ? await createFiles({
          variables: {
            organizationId,
            filesFields: filesData.map((file) => ({
              fileName: file.name,
              type,
            })),
          },
        })
      : {};

    if (!URLs.data?.createFiles?.length) {
      return [];
    }

    const uploadPromise = Promise.allSettled(
      URLs.data.createFiles.map(async (file, id) => {
        if (!file?.uploadUrl) return null;

        await fetch(file.uploadUrl.url, {
          method: "PUT",
          body: filesData?.[id],
        });
      })
    )
      .then(() => {
        setUploadingCount((prev) => prev - filesData.length);
        setPollInterval(pollingDelay);
        uploadStatus.map((file, id) => {
          const fileId = URLs.data?.createFiles?.[id]?.fileId;

          if (file?.status === "rejected" && fileId) {
            setUploadStatus((prev) =>
              prev.map((uploadedFile) =>
                uploadedFile?.fileId === fileId
                  ? {
                      fileId,
                      status: FileStatuses.failed,
                    }
                  : uploadedFile
              )
            );
          }
        });
      })
      .catch(() => setUploadingCount((prev) => prev - filesData.length));

    const result =
      URLs?.data?.createFiles?.map((file, id) => ({
        fileId: file?.fileId,
        fileName: filesData?.[id]?.name,
        src: URL.createObjectURL(filesData?.[id]),
        size: filesData?.[id]?.size,
        type: getLocalFileType(filesData?.[id]?.type),
      })) ?? [];

    if (wait) {
      await uploadPromise;

      return result;
    }

    return result;
  };

  const allFilesReady = useMemo(
    () =>
      !!getFiles?.length &&
      getFiles.every((file) =>
        availableStatuses.includes(file?.status as FileStatuses)
      ),
    [getFiles]
  );

  const hasUnprocessedFiles = useMemo(
    () =>
      getFiles?.some(
        (file) =>
          file?.status &&
          !availableStatuses.includes(file.status as FileStatuses)
      ),
    [getFiles]
  );

  useEffect(() => {
    if (options?.preventAway) setPreventAway(hasUnprocessedFiles ?? false);
  }, [hasUnprocessedFiles, options?.preventAway]);

  useEffect(() => {
    if (allFilesReady && interaptionStatus) {
      setStatus({
        interaption: null,
      });
    }
  }, [allFilesReady]);

  return [
    upload,
    {
      uploadStatus,
      interaptionStatus,
      files: getFiles,
      setStatus,
      preventAway,
      uploading: !!uploadingCount,
      uploadingCount,
      error,
    },
  ] as const;
}
