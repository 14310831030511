import { Modal } from "@CreativelySquared/uikit";
import { FC } from "react";

import { Restriction, Props as RestrictionProps } from "./Restriction";

interface Props extends RestrictionProps {
  visible: boolean;
  onClose: () => void;
}

export const RestrictionModal: FC<Props> = ({ visible, onClose, ...props }) => (
  <Modal visible={visible} onClose={onClose} closable className="max-w-[545px]">
    <Restriction {...props} />
  </Modal>
);
