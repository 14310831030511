import { Button, Modal } from "@CreativelySquared/uikit";
import { FC } from "react";
import {
  GetAccessRequestsDocument,
  useApproveAccessRequestMutation,
} from "api/graphql";
import { ApolloError } from "@apollo/client";
import { ModalTitle, useNotification } from "components";
import { useTranslation } from "react-i18next";
import { Role } from "api/enums";
import { useFormik } from "formik";
import { object, string } from "yup";
import { RoleInput } from "Customers/Accounts/components/RoleInput";
import { getOperationName } from "@apollo/client/utilities";

interface Props {
  visible: boolean;
  onClose: () => void;
  requestId: string;
}

interface FormData {
  role?: Role;
}

const validationSchema = object({
  role: string().required("common:validation.required"),
});

export const ApproveForm: FC<Props> = ({ requestId, onClose }) => {
  const { t } = useTranslation("organizations");
  const { setNotification, notificationTypes } = useNotification();

  const [approve, { loading: isSubmitting }] = useApproveAccessRequestMutation({
    onCompleted() {
      setNotification({
        message: t("accessRequests.approve.success"),
        type: notificationTypes.Success,
      });
      onClose();
    },
    onError(error) {
      setNotification({
        message: (error as ApolloError).message ?? t("common:common.error"),
        type: notificationTypes.Error,
      });
    },
    awaitRefetchQueries: true,
    refetchQueries: [getOperationName(GetAccessRequestsDocument)!],
  });

  const {
    values: { role },
    errors,
    submitCount,
    isValid,
    setFieldValue,
    handleSubmit,
  } = useFormik<FormData>({
    validationSchema,
    initialValues: {
      role: undefined,
    },
    onSubmit: (data: FormData) => {
      approve({
        variables: {
          requestId,
          role: data.role!,
        },
      });
    },
  });

  return (
    <form onSubmit={handleSubmit} noValidate className="w-[450px]">
      <ModalTitle className="text-3xl mb-8">
        {t("accessRequests.approve.title")}
      </ModalTitle>
      <RoleInput
        className="mt-7"
        role={role}
        error={!!submitCount && errors.role}
        onChange={(role: Role) => setFieldValue("role", role)}
      />
      <Button
        type="submit"
        className="mt-7 w-full"
        loading={isSubmitting}
        disabled={(!!submitCount && !isValid) || isSubmitting}
      >
        {t("common:actions.confirm")}
      </Button>
    </form>
  );
};

export const ApproveModal: FC<Props> = (props) => (
  <Modal visible={props.visible} onClose={props.onClose} closable>
    <ApproveForm {...props} />
  </Modal>
);
