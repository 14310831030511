import { Button, Input, Loader } from "@CreativelySquared/uikit";
import { useTranslation } from "react-i18next";
import { compose, Route } from "react-router-hoc";
import { useFormik } from "formik";
import { Helmet } from "react-helmet";
import { advancedErrorMessage, parseError } from "utils/form";
import { object, ref, string } from "yup";
import { passwordValidationSchema } from "utils/validation";
import { ReactComponent as BackIcon } from "images/arrowLeft.svg";
import { Link } from "react-router-dom";
import { links } from "App";
import { ProtectedRoute } from "utils/route";
import { Roles } from "utils/roles";
import {
  useChangePasswordUsingCodeMutation,
  usePasswordRecoveryCodeInfoQuery,
} from "api/graphql";
import { useNotification } from "components";
import clsx from "clsx";
import { Role } from "api/enums";

import PasswordIcon from "../images/PasswordIcon";
import { useLogin } from "../hooks/useLogin";

import styles from "./styles.module.scss";

const AccountSetupRoute = compose(
  ProtectedRoute({ access: Roles.unauthorized }),
  Route(
    {
      code: Route.query.string,
    },
    "/account-setup"
  )
);

const validationSchema = object({
  name: string()
    .required("common:validation.required")
    .max(254, advancedErrorMessage("common:validation.max")),
  surname: string()
    .required("common:validation.required")
    .max(254, advancedErrorMessage("common:validation.max")),
  password: string().withMutation((schema) => passwordValidationSchema(schema)),
  confirmPassword: string()
    .oneOf([ref("password")], "common:validation.passwordMatch")
    .required("common:validation.required"),
});

export const AccountSetup = AccountSetupRoute(
  ({
    match: {
      query: { code },
    },
  }) => {
    const { t } = useTranslation("authorization");
    const { setNotification, notificationTypes } = useNotification();
    const { login, loginLoading } = useLogin();

    const {
      data: { getPasswordRecoveryCodeInfo: account } = {},
      loading: accountLoading,
    } = usePasswordRecoveryCodeInfoQuery({
      variables: {
        code: code!,
      },
      skip: !code,
    });

    const [changePasswordUsingCode, { loading: createPasswordLoading }] =
      useChangePasswordUsingCodeMutation({
        onError: (error) => {
          setNotification({
            message: error.message ?? t("common:common.error"),
            type: notificationTypes.Error,
          });
        },
        onCompleted: ({ changePasswordUsingCode }) => {
          login({
            variables: {
              email: changePasswordUsingCode?.email ?? "",
              password: password,
            },
          });
        },
      });

    const {
      values: { name, surname, password, confirmPassword },
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
    } = useFormik<{
      name: string;
      surname: string;
      password: string;
      confirmPassword: string;
    }>({
      validationSchema,
      initialValues: {
        name: "",
        surname: "",
        password: "",
        confirmPassword: "",
      },
      onSubmit: (data) => {
        if (code && account?.userId) {
          changePasswordUsingCode({
            variables: {
              userId: account?.userId,
              code,
              newPassword: data.password,
              name: data.name,
              surname: data.surname,
            },
          });
        } else {
          setNotification({
            message: t("common:common.error"),
            type: notificationTypes.Error,
          });
        }
      },
    });

    if (accountLoading) {
      return <Loader radius={50} className="m-auto" />;
    }

    if (!account?.userId || !code) {
      return (
        <div className="w-[500px]">
          <p className="mb-10 text-l text-blue-steel">
            {t("accountSetup.invalidCode")}
          </p>
          <Link to={links.Login()} className="mt-8">
            <Button type="button" className="w-full">
              <BackIcon className="mr-4 w-7 h-7" />
              {t("accountSetup.form.actions.back")}
            </Button>
          </Link>
        </div>
      );
    }

    return (
      <form noValidate onSubmit={handleSubmit}>
        <Helmet>
          <title>{t("accountSetup.title")}</title>
        </Helmet>
        <h2>{t("accountSetup.title")}</h2>
        <p className="mt-6 mb-10 text-l">
          {t(`accountSetup.description.${account.role}`, {
            organizationName: account?.masterOrganizationName,
            accountName: account?.organizationName,
          })}
        </p>
        {account?.masterOrganizationName && (
          <div className={clsx(styles.info, "mb-7")}>
            <p className={styles.infoTitle}>
              {t("accountSetup.form.organization")}
            </p>
            <p>{account.masterOrganizationName}</p>
          </div>
        )}
        {account?.email && (
          <div className={styles.info}>
            <p className={styles.infoTitle}>{t("accountSetup.form.email")}</p>
            <p>{account.email}</p>
          </div>
        )}
        <fieldset className={clsx(styles.fieldset, "mb-0 mt-9")}>
          <label className={styles.label}>
            {t("accountSetup.form.fields.name.label")}
          </label>
          <div className="flex gap-8">
            <div className="relative pb-8 flex-1">
              <Input
                variant={Input.variants.Secondary}
                placeholder={t(
                  "accountSetup.form.fields.name.firstName.placeholder"
                )}
                className="w-full"
                value={name}
                name="name"
                error={touched.name && t(...parseError(errors.name)).toString()}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {touched.name && (
                <p className={styles.error}>
                  {t(...parseError(errors.name)).toString()}
                </p>
              )}
            </div>
            <div className="relative pb-8 flex-1">
              <Input
                variant={Input.variants.Secondary}
                placeholder={t(
                  "accountSetup.form.fields.name.lastName.placeholder"
                )}
                className="w-full"
                value={surname}
                name="surname"
                error={
                  touched.surname && t(...parseError(errors.surname)).toString()
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {touched.surname && (
                <p className={styles.error}>
                  {t(...parseError(errors.surname)).toString()}
                </p>
              )}
            </div>
          </div>
        </fieldset>
        <fieldset className={styles.fieldset}>
          <label className={styles.label}>
            {t("accountSetup.form.fields.password.label")}
          </label>
          <div className="relative pb-8">
            <Input
              variant={Input.variants.Secondary}
              className={styles.password}
              type="password"
              value={password}
              placeholder={t(
                "accountSetup.form.fields.password.newPassword.placeholder"
              )}
              name="password"
              autoComplete="off"
              icon={<PasswordIcon unlocked={!!password} />}
              error={
                touched.password && t(...parseError(errors.password)).toString()
              }
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {touched.password && (
              <p className={styles.error}>
                {t(...parseError(errors.password)).toString()}
              </p>
            )}
          </div>
          <div className="relative pb-8">
            <Input
              variant={Input.variants.Secondary}
              className={styles.password}
              type="password"
              value={confirmPassword}
              placeholder={t(
                "accountSetup.form.fields.password.confirmPassword.placeholder"
              )}
              name="confirmPassword"
              autoComplete="off"
              icon={<PasswordIcon unlocked={!!confirmPassword} />}
              error={
                touched.confirmPassword &&
                t(...parseError(errors.confirmPassword)).toString()
              }
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {touched.confirmPassword && (
              <p className={styles.error}>
                {t(...parseError(errors.confirmPassword)).toString()}
              </p>
            )}
          </div>
        </fieldset>
        {[Role.MasterOrgOwner, Role.OrgAdmin].includes(
          account.role as Role
        ) && (
          <section className="my-10 bg-mint p-8 rounded-lg text-l">
            <p className="font-bold text-blackberry mb-6">
              {t("accountSetup.info.title")}
            </p>
            <ul className="list-disc list-inside">
              <li>{t(`accountSetup.info.manage.${account.role}`)}</li>
              {account.role === Role.MasterOrgOwner && (
                <li>{t("accountSetup.info.create")}</li>
              )}
              <li>{t("accountSetup.info.control")}</li>
            </ul>
          </section>
        )}
        <section className="mb-10">
          <Button
            className="w-full"
            loading={createPasswordLoading || loginLoading}
            type="submit"
          >
            {t("accountSetup.form.actions.create")}
          </Button>
          <Link to={links.Login()} className="block mt-4">
            <Button type="button" outlined borderless className="w-full">
              <BackIcon className="mr-4" />
              {t("accountSetup.form.actions.back")}
            </Button>
          </Link>
        </section>
      </form>
    );
  }
);
