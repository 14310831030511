import { Route, compose } from "react-router-hoc";
import { object, string } from "yup";
import { useFormik } from "formik";
import { useContext, useEffect } from "react";
import { Button, Input } from "@CreativelySquared/uikit";
import { ApolloError } from "@apollo/client/errors";
import { LoginQueryVariables } from "api/types";
import { useLoginLazyQuery } from "api/graphql";
import { ProtectedRoute } from "utils/route";
import { AuthContext } from "Authorization/authorization.context";
import { AuthErrorCodes } from "utils/auth";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import "utils/validation";
import { Link } from "react-router-dom";
import { links } from "App";
import { ValidationInput } from "components/ValidationInput";
import { ReactComponent as EmailIcon } from "images/email.svg";
import { useNotification } from "components";

import PasswordIcon from "../images/PasswordIcon";

import styles from "./styles.module.scss";

export enum LoginPageMode {
  Success = "success",
  AccountCreated = "accountCreated",
}

const LoginRoute = compose(
  ProtectedRoute,
  Route(
    {
      mode: Route.query.oneOf(...Object.values(LoginPageMode)),
    },
    "/login"
  )
);

const creatorLoginURL = "https://community.creativelysquared.com";

const validationSchema = object({
  email: string().sequence([
    () => string().required("common:validation.required"),
    () => string().email("common:validation.email"),
  ]),
  password: string().required("common:validation.required"),
});

export const Login = LoginRoute(
  ({
    location: { state },
    history: { replace },
    match: {
      query: { mode },
      query,
      params,
    },
    link,
  }) => {
    const { t } = useTranslation("authorization");
    const { setAccessToken, accountId } = useContext(AuthContext);
    const { setNotification, notificationTypes } = useNotification();
    const [login, { loading }] = useLoginLazyQuery();
    const {
      values: { email, password },
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      touched,
      setStatus,
      status,
      submitCount,
      isSubmitting,
      setSubmitting,
    } = useFormik<LoginQueryVariables>({
      validationSchema,
      initialValues: { email: "", password: "" },
      onSubmit: ({ email, password }) => {
        setStatus({ ...status, password: null });
        login({
          variables: {
            email,
            password,
            ...(accountId && { organizationId: accountId }),
          },
        })
          .then(({ data, error }) => {
            if (error) throw error;
            if (!data) return;
            setAccessToken(data?.getAuthenticationTokens?.accessToken);

            if ((state as Record<string, string>)?.redirectURL) {
              replace((state as Record<string, string>).redirectURL);
            }
          })
          .catch((error) => {
            const errorCode = (error as ApolloError).graphQLErrors?.[0]
              ?.extensions?.code;

            if (errorCode === AuthErrorCodes.AuthenticationError) {
              setStatus({
                ...status,
                password: "login.form.fields.password.errors.incorrect",
              });
            }

            if (errorCode === AuthErrorCodes.UserHasNoPermissionsError) {
              setNotification({
                message: t("login.form.errors.inactiveAccount"),
                type: notificationTypes.Error,
              });
            }

            setSubmitting(false);
          });
      },
    });

    useEffect(() => {
      if (mode) {
        setNotification({
          message: t(`login.notifications.${mode}`),
        });
        replace(link({ ...query, ...params, mode: undefined }));
      }
    }, [mode]);

    return (
      <form noValidate onSubmit={handleSubmit}>
        <Helmet>
          <title>{t("login.title")}</title>
        </Helmet>
        <h2 className="mb-10">{t("login.form.heading")}</h2>
        <ValidationInput
          className="mb-6 w-full"
          type="email"
          value={email}
          name="email"
          placeholder={t("login.form.fields.email.placeholder")}
          label={t("login.form.fields.email.label")}
          icon={<EmailIcon className="stroke-[1.5]" />}
          error={t(errors.email!)?.toString()}
          onChange={handleChange}
          existAllowed
          validationMessage={t("login.form.fields.email.errors.notExists")}
        />
        <Input
          className="mb-6 w-full"
          type="password"
          value={password}
          placeholder={t("login.form.fields.password.placeholder")}
          name="password"
          icon={<PasswordIcon unlocked={!!password} />}
          error={
            ((touched.password || !!submitCount) &&
              t(errors.password!).toString()) ||
            t(status?.password as string).toString()
          }
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <section className="mb-10 flex justify-end items-center">
          <Link
            to={links.ForgotPassword()}
            className={styles.forgotPasswordButton}
          >
            {t("login.form.actions.forgotPassword")}
          </Link>
        </section>
        <section>
          <Button
            className="w-full"
            loading={loading || isSubmitting}
            type="submit"
          >
            {t("login.form.actions.login")}
          </Button>
        </section>
        <section className={styles.divider}>
          <hr />
          <span>{t("login.form.divider")}</span>
          <hr />
        </section>
        <section className="flex justify-between items-center mb-9">
          <Link
            to={{ pathname: creatorLoginURL }}
            target="_blank"
            className="flex-1"
          >
            <Button
              variant={Button.variants.Secondary}
              className="w-full"
              type="button"
            >
              {t("login.form.actions.creatorLogin")}
            </Button>
          </Link>
        </section>
      </form>
    );
  }
);
