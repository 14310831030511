import { lazy } from "react";
import { Route } from "react-router-hoc";

import { OrganizationTabs } from "../types";

export const SettingsOrganizationAccountsRoute = Route(
  `/settings/organization/${OrganizationTabs.Accounts}`
);

export const SettingsOrganizationAccounts = SettingsOrganizationAccountsRoute(
  lazy(
    () =>
      import(
        /* webpackChunkName: "SettingsOrganizationAccounts" */ "./Accounts"
      )
  )
);
