import { Tabs } from "@CreativelySquared/uikit";
import { FC } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { GetRouteProps } from "utils/types";
import { Switch, Redirect, NavLink } from "react-router-dom";
import { Protect } from "components";
import { Roles } from "utils/roles";
import { PermissionActions, PermissionResources } from "api/enums";

import { OrganizationTabs } from "./types";
import { SettingsOrganizationDetails } from "./Details";
import { SettingsOrganizationAccounts } from "./Accounts";
import { SettingsOrganizationMembers } from "./Members";
import { SettingsOrganizationActivity } from "./Activity";
import { SettingsOrganizationAccessRequests } from "./AccessRequests";

import { SettingsOrganizationRoute } from "./index";

type Props = GetRouteProps<typeof SettingsOrganizationRoute>;

export const SettingsOrganization: FC<Props> = ({ link }) => {
  const { t } = useTranslation("settings");

  return (
    <section>
      <Helmet>
        <title>{t("organization.title")}</title>
      </Helmet>
      <Tabs size={Tabs.sizes.Small} className="mb-10">
        <Protect access={Roles.customer}>
          <Tabs.Item
            to={link({ tab: OrganizationTabs.Details })}
            component={NavLink}
          >
            {t(`organization.tabs.details`)}
          </Tabs.Item>
        </Protect>
        <Protect access={Roles.customer}>
          <Tabs.Item
            to={link({ tab: OrganizationTabs.Accounts })}
            component={NavLink}
          >
            {t(`organization.tabs.accounts`)}
          </Tabs.Item>
        </Protect>
        <Protect access={Roles.master_org_owner}>
          <Tabs.Item
            to={link({ tab: OrganizationTabs.Members })}
            component={NavLink}
          >
            {t(`organization.tabs.members`)}
          </Tabs.Item>
        </Protect>
        <Protect
          permission={{
            resource: PermissionResources.MasterOrganizationEvents,
            actions: PermissionActions.Read,
          }}
        >
          <Tabs.Item
            to={link({ tab: OrganizationTabs.Activity })}
            component={NavLink}
          >
            {t(`organization.tabs.activity`)}
          </Tabs.Item>
        </Protect>
        <Protect access={Roles.master_org_owner}>
          <Tabs.Item
            to={link({ tab: OrganizationTabs.AccessRequests })}
            component={NavLink}
          >
            {t(`organization.tabs.access-requests`)}
          </Tabs.Item>
        </Protect>
      </Tabs>
      <Switch>
        <SettingsOrganizationDetails exact />
        <SettingsOrganizationAccounts exact />
        <SettingsOrganizationMembers exact />
        <SettingsOrganizationActivity exact />
        <SettingsOrganizationAccessRequests exact />
        <Redirect to={link({ tab: OrganizationTabs.Details })} />
      </Switch>
    </section>
  );
};
