import clsx from "clsx";
import { FC, HTMLAttributes } from "react";

export const TableRow: FC<
  HTMLAttributes<HTMLTableRowElement> & { hover?: boolean }
> = ({ className, hover, ...props }) => (
  <tr
    className={clsx(
      "border-b last-of-type:border-b-0 border-light-blue-steel hover:bg-grey",
      hover && "bg-grey",
      className
    )}
    {...props}
  />
);
