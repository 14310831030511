import { memo, MouseEvent, useMemo, useState } from "react";
import { Dropdown } from "@CreativelySquared/uikit";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { ReactComponent as ArrowIcon } from "images/down.svg";
import { xor } from "lodash";
import { Search, MultiInput } from "components";
import { GetProps } from "react-router-hoc/lib/types";
import { parseError } from "utils/form";
import { ReactComponent as CountryIcon } from "images/global.svg";

import {
  allCountriesCode,
  filterCountries,
  getCodeByCountry,
  getCountryByCode,
  groupCountries,
  ungroupCountries,
} from "./countries";

import styles from "./styles.module.scss";

type Props = Omit<GetProps<typeof MultiInput>, "value" | "onSelect"> &
  (
    | {
        multiselect: true;
        value?: Array<string>;
        onSelect: (country: Array<string>) => void;
      }
    | {
        multiselect?: false | undefined;
        value?: string;
        onSelect: (country: string) => void;
      }
  );

export const SelectCountry = memo<Props>(
  ({ className, value, disabled, multiselect, error, onSelect, ...props }) => {
    const { t } = useTranslation("common");
    const [activeRegion, setActiveRegion] = useState<string[]>([]);
    const [search, setSearch] = useState<string>("");
    const countriesList = filterCountries(search);

    const selectCountry = (selectedCountry: string) => {
      if (multiselect) {
        onSelect(xor(value, [selectedCountry]));
      } else {
        onSelect(value === selectedCountry ? "" : selectedCountry);
      }
    };

    const allCountriesSelected = useMemo(
      () => allCountriesCode.every((country) => value?.includes(country)),
      [value]
    );

    const selectRegion = (region: string) => {
      if (multiselect) {
        const regionCountries = countriesList[
          region as keyof typeof countriesList
        ]?.map((country) => country.code);

        if (regionCountries?.every((country) => value?.includes(country))) {
          onSelect(
            value?.filter((country) => !regionCountries.includes(country)) ?? []
          );
        } else {
          onSelect([
            ...new Set([...(value ?? []), ...(regionCountries ?? [])]),
          ]);
        }
      }
    };

    return (
      <section
        className={clsx(
          "relative",
          {
            "pointer-events-none": disabled,
          },
          className
        )}
      >
        <Dropdown
          disabled={disabled}
          render={({ visible }) => (
            <div
              key={search + activeRegion + value} // To change the popover position with the height changes
            >
              <MultiInput
                icon={
                  <CountryIcon className="w-7 h-7 stroke-light-blue-steel" />
                }
                active={visible}
                arrow
                placeholder={t("country.placeholder")}
                value={
                  value
                    ? multiselect
                      ? groupCountries(value)
                      : [getCountryByCode(value) ?? ""]
                    : undefined
                }
                error={error && t(...parseError(error)).toString()}
                onChange={(countries) => {
                  if (multiselect) {
                    onSelect(ungroupCountries(countries));
                  } else {
                    onSelect(getCodeByCountry(countries[0]) ?? "");
                  }
                }}
                disabled={disabled}
                {...props}
              />
            </div>
          )}
          placement={Dropdown.placements.Top}
          popoverClassName={styles.countryPopover}
        >
          <Search
            className={styles.search}
            placeholder={t("country.search")}
            onClick={(event: MouseEvent) => event.stopPropagation()}
            onSearch={setSearch}
            value={search}
            autoFocus
          />
          {multiselect && (
            <Dropdown.Item
              onClick={(event: MouseEvent) => event.stopPropagation()}
              className={styles.item}
            >
              <div
                className={clsx(styles.region, {
                  [styles.active]: allCountriesSelected,
                })}
              >
                <button
                  type="button"
                  className={clsx(styles.regionTitle, "pl-[14px]")}
                  onClick={() => {
                    onSelect(allCountriesSelected ? [] : allCountriesCode);
                  }}
                >
                  {t("country.worldwide")}
                </button>
              </div>
            </Dropdown.Item>
          )}
          {Object.entries(countriesList).map(([region, countries]) => (
            <Dropdown.Item
              key={region}
              value={region}
              onClick={(event: MouseEvent) => {
                event.stopPropagation();
              }}
              className={styles.item}
            >
              <div
                className={clsx(styles.region, {
                  [styles.active]: countries.every((country) =>
                    value?.includes(country.code)
                  ),
                  [styles.multiselect]: multiselect,
                })}
              >
                <button
                  type="button"
                  className={clsx(styles.regionArrow, {
                    [styles.active]: activeRegion.includes(region),
                  })}
                  onClick={() => setActiveRegion((prev) => xor(prev, [region]))}
                >
                  <ArrowIcon />
                </button>
                <button
                  type="button"
                  className={styles.regionTitle}
                  onClick={() =>
                    multiselect
                      ? selectRegion(region)
                      : setActiveRegion((prev) => xor(prev, [region]))
                  }
                >
                  {region}
                </button>
              </div>
              <ul
                className={clsx(styles.countries, {
                  [styles.visible]: activeRegion.includes(region),
                })}
              >
                {countries.map((countryItem) => (
                  <li
                    key={countryItem.code}
                    onClick={() => selectCountry(countryItem.code)}
                    className={clsx(styles.country, {
                      [styles.selected]: value?.includes(countryItem.code),
                    })}
                  >
                    {countryItem.name}
                  </li>
                ))}
              </ul>
            </Dropdown.Item>
          ))}
        </Dropdown>
      </section>
    );
  }
);

SelectCountry.displayName = "SelectCountry";
