import { AccountNodeFragment, OrganizationType } from "api/types";
import { OrganizationType as OrganizationTypeEnum } from "api/enums";

export const checkSwitchingToPartOfOrganization = (
  oldType?: OrganizationType | null,
  newType?: OrganizationType
) =>
  oldType === OrganizationTypeEnum.Integrated &&
  newType !== OrganizationTypeEnum.Integrated;

export const checkSwitchingToIntegrated = (
  oldType?: OrganizationType | null,
  newType?: OrganizationType
) =>
  oldType !== OrganizationTypeEnum.Integrated &&
  newType === OrganizationTypeEnum.Integrated;

export const hasAllocatedCredits = (
  account?: AccountNodeFragment | null,
  newData?: {
    accountType: OrganizationType;
    organization?: { masterOrganizationId?: string | null };
  }
) =>
  checkSwitchingToIntegrated(account?.type, newData?.accountType) ||
  (account?.type === OrganizationTypeEnum.Integrated &&
    newData?.organization?.masterOrganizationId !==
      account?.masterOrganization?.masterOrganizationId);
