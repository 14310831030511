import { lazy } from "react";
import { compose, Route } from "react-router-hoc";
import { EventType } from "utils/event";
import { idRegEx } from "utils/regex";
import { Roles } from "utils/roles";
import { ProtectedRoute } from "utils/route";

export { OrganizationActivity } from "./ActivityTab";

export const OrganizationActivityTabRoute = compose(
  ProtectedRoute({ access: Roles.cs_admins }),
  Route(
    {
      id: Route.params.regex(idRegEx),
      page: Route.query.number,
      type: Route.query.array(Route.query.oneOf(...Object.values(EventType))),
    },
    ({ id }) => `/customers/organizations/${id}/activity`
  )
);

export const OrganizationActivityTab = OrganizationActivityTabRoute(
  lazy(
    () =>
      import(/* webpackChunkName: "OrganizationActivityTab" */ "./ActivityTab")
  )
);
